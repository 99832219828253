import { types, Instance } from 'mobx-state-tree';

export const ApplicableExtraDtoModel = types.model('ApplicableExtraDtoModel', {
  code: types.string,
  maxQuantity: types.maybe(types.integer),
  name: types.string,
  parentCode: types.maybe(types.string),
  parentName: types.maybe(types.string),
  parentType: types.maybe(types.frozen<Domain.Aggregates.Order.Entities.ExtrasType>()),
  price: types.maybe(types.number),
  recommendedRetailPrice: types.maybe(types.number),
});

export interface IApplicableExtraDtoModel extends Instance<typeof ApplicableExtraDtoModel> {}
