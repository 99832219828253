import { types, Instance } from 'mobx-state-tree';
import { SalesManagerDtoModel as DomainDtosSalesManagerDtoModel } from './../../../Domain/Dtos/SalesManagerDtoModel'
import { RoleLocationDtoModel as DomainDtosRoleLocationDtoModel } from './../../../Domain/Dtos/RoleLocationDtoModel'

export const UserProfileDtoModel = types.model('UserProfileDtoModel', {
  contractFeatureEnabled: types.boolean,
  externalId: types.string,
  id: types.identifier,
  isAftermarketPerson: types.boolean,
  isExecutiveManager: types.boolean,
  isFleetSalesAdmin: types.boolean,
  isNationalSalesManager: types.boolean,
  isSalesManager: types.boolean,
  localeCode: types.string,
  managers: types.array(DomainDtosSalesManagerDtoModel),
  managingLocations: types.array(types.string),
  name: types.string,
  orderFeatureEnabled: types.boolean,
  permissions: types.array(types.frozen<Domain.Aggregates.User.Entities.Permission>()),
  roleLevel: types.integer,
  roleLocations: types.array(DomainDtosRoleLocationDtoModel),
  userPictureUrl: types.maybe(types.string),
});

export interface IUserProfileDtoModel extends Instance<typeof UserProfileDtoModel> {}
