import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { SelectField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { QuoteSection } from 'views/routes/quotes/components/QuoteSection';
import styles from './CustomerSection.module.scss';

export const DealershipSection: React.FC = observer(function DealershipSection() {
  const { editQuoteModel, security } = useStore();
  const quote = editQuoteModel.temporaryQuote;
  const title = `Dealership${quote ? ': ' + quote.location : ''}`;

  useEffect(() => {
    if (editQuoteModel.isEditable && !quote.location && security.locations.length === 1) {
      editQuoteModel.updateLocation(security.locations[0].name);
    }
  }, [editQuoteModel, quote, security.locations]);

  const save = async () => {
    await editQuoteModel.saveLocation();
  };

  const cancel = () => {
    const currentLocation = quote.location;
    editQuoteModel.cancelEditingQuote();
    // prevent the close button from clearing the user's selected location when editing a new quote
    if (editQuoteModel.isNewQuote) {
      editQuoteModel.updateLocation(currentLocation);
    }
  };

  return (
    <QuoteSection
      sectionTitle={title}
      isFormActionAllowed={
        !editQuoteModel.isNewQuote &&
        editQuoteModel.isEditable &&
        !editQuoteModel.hasAssociatedContract  && 
        !security.currentUser.isAftermarketPerson
      }
      expandedDefault={editQuoteModel.isNewQuote}
      editableDefault={editQuoteModel.isNewQuote}
      onSubmit={save}
      canSave={true}
      canCancel={true}
      onCancel={cancel}>
      {({ focusFieldRef, editable }) => (
        <div className={styles.fields}>
          {!editable ? (
            <TextField
              name="dealership"
              label="Dealership"
              readonly={true}
              value={quote.location}
            />
          ) : (
            <SelectField
              name="dealership"
              className={styles.location}
              label="Dealership"
              options={[...new Set(security.locations)].map(x => ({ label: x.description, value: x.name }))}
              readonly={security.locations.length <= 1}
              value={quote.location}
              onChange={event =>
                editQuoteModel.updateLocation(
                  security.locations.find(l => l.name === event.target.value)!.name
                )
              }
              validationError={quote.location ? undefined : 'Required'}
              required={true}
            />
          )}
        </div>
      )}
    </QuoteSection>
  );
});
