import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import { IConditionModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/ConditionModel';
import { IMakeModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/MakeModel';
import { IModelModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/ModelModel';
import { ISeriesModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/SeriesModel';
import { ITypeModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/TypeModel';
import { IVehicleDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehiclesQuery/VehicleDtoModel';
import { AsAuCurrency } from "infrastructure/formatUtils";
import { stringComparer } from 'infrastructure/stringUtils';
import { observer } from "mobx-react-lite";
import { getSnapshot } from 'mobx-state-tree';
import React, { useEffect, useState } from "react";
import { Button, ButtonWithProgress, SelectField, TextField } from 'views/components/forms';
import { LoadingPane } from 'views/components/LoadingPane';
import { useStore } from 'views/hooks';
import styles from './AdvancedSearch.module.scss';
type ISearchVehicleDto = Domain.Queries.Vehicle.ISearchVehicleDto;

interface IAdvancedSearchProps {
  newBuildOnly: boolean
  allowManualEntry?: boolean
  onSelect: (vehicle: ISearchVehicleDto | undefined) => void
  onCancelEditing: () => void
}

interface IFilters {
  assetNo: string;
  assetType: string;
  idleLocation: string;
  buildDate: string;
  odometer:string;
  softFurnishing: string;
  hardFurnishing: string;
  benchTop: string;
}
interface IColumnDef {
  name: string;
  label: string;
  width: number;
  getFilterValue?: () => string;
  setFilter?: (value: string) => void;
  getValue: (vehicle: IVehicleDtoModel) => string;
}

export const AdvancedSearch: React.FC<IAdvancedSearchProps> = observer((props) => {
  const { vehicleSelection } = useStore();
  const [condition, setCondition] = useState<IConditionModel>();
  const [type, setType] = useState<ITypeModel>();
  const [make, setMake] = useState<IMakeModel>();
  const [model, setModel] = useState<IModelModel>();
  const [series, setSeries] = useState<ISeriesModel>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [warningMessage, setWarningMessage] = React.useState('');
  const [filters, setFilters] = React.useState<IFilters>({
    assetNo: '',
    assetType: '',
    idleLocation: '',
    buildDate: '',
    odometer:'',
    softFurnishing: '',
    hardFurnishing: '',
    benchTop: '',
  });
  const options = vehicleSelection.options;
  const vehicles = vehicleSelection.vehicles;
  const newBuildOnly = props.newBuildOnly;

  useEffect(() => {
    (async () => {
      vehicleSelection.resetVehicles();
      await vehicleSelection.getOptions(newBuildOnly);
      if (vehicleSelection.options?.conditions.length === 1)
        setCondition(vehicleSelection.options!.conditions[0])
    })();
  }, [vehicleSelection, newBuildOnly]);

  useEffect(() => {
    setPage(0);
  }, [filters])

  useEffect(() => {
    setPage(0);
  }, [vehicleSelection.vehicles.length]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
    setPage(0);
  };

  const mapToSelectOptions = (inputs: { code: string, name: string }[] | undefined): { label: string, value: string }[] => {
    if (!inputs) return [];
    return inputs.sort((i1, i2) => stringComparer(i1.name, i2.name)).map(i => ({ value: i.code, label: i.name }));
  }

  const reset = (i: number) => {
    i <= 1 && setType(undefined);
    i <= 2 && setMake(undefined);
    i <= 3 && setModel(undefined);
    i <= 4 && setSeries(undefined);
  }

  const onConditionChanged = (value?: string) => {
    reset(1);

    const selected = (!value || !options)
      ? undefined
      : options.conditions.find(c => c.code === value);
    setCondition(selected);
  }
  const onTypeChanged = (value?: string) => {
    reset(2);

    const selected = (!value || !condition)
      ? undefined
      : condition.types.find(c => c.code === value);
    setType(selected);
  }
  const onMakeChanged = (value?: string) => {
    reset(3);

    const selected = (!value || !type)
      ? undefined
      : type.makes.find(c => c.code === value);
    setMake(selected);
  }
  const onModelChanged = (value?: string) => {
    reset(4);

    const selected = (!value || !make)
      ? undefined
      : make.models.find(c => c.code === value);
    setModel(selected);
  }
  const onSeriesChanged = (value?: string) => {
    const selected = (!value || !model)
      ? undefined
      : model.series.find(c => c.code === value);
    setSeries(selected);
  }

  const searchVehicles = async () => {
    if (!condition) return;
    await vehicleSelection.search({
      condition: condition.code,
      type: type?.code,
      make: make?.code,
      model: model?.code,
      series: series?.code
    });
    setWarningMessage(vehicleSelection.vehicles.length === 0 ? 'No vehicle found' : '');
  }

  const selectVehicle = async (vehicle: IVehicleDtoModel) => {
    if (!vehicle.assetNo) return null;
    const result = await vehicleSelection.searchVehicle(vehicle.assetNo);
    props.onSelect(result);
  }


  const includesIgnoreCase = (str: string, searchStr: string) => str.toLowerCase().includes(searchStr.toLowerCase());
  const lessThan = (odo:number, searchOdo:number) => odo <= searchOdo;
  const filteredVehicles = getSnapshot(vehicles)
    .filter(v => !filters.assetNo || includesIgnoreCase(v.assetNo, filters.assetNo))
    .filter(v => !filters.assetType || includesIgnoreCase(v.assetType, filters.assetType))
    .filter(v => !filters.idleLocation || includesIgnoreCase(v.idleLocation, filters.idleLocation))
    .filter(v => !filters.buildDate || includesIgnoreCase(v.buildDate, filters.buildDate))
    .filter(v => !filters.odometer || lessThan(v.odometer??0, Number(filters.odometer)))
    .filter(v => !filters.softFurnishing || includesIgnoreCase(v.softFurnishing, filters.softFurnishing))
    .filter(v => !filters.hardFurnishing || includesIgnoreCase(v.hardFurnishing, filters.hardFurnishing))
    .filter(v => !filters.benchTop || includesIgnoreCase(v.benchTop, filters.benchTop));

  const columns: IColumnDef[] = [
    {
      name: 'assetNo', label: 'Asset No', width: 125,
      getFilterValue: () => filters.assetNo,
      setFilter: (value) => setFilters({ ...filters, assetNo: value ?? '' }),
      getValue: (vehicle) => vehicle.assetNo,
    },
    {
      name: 'assetType', label: 'Asset Type', width: 155,
      getFilterValue: () => filters.assetType,
      setFilter: (value) => setFilters({ ...filters, assetType: value ?? '' }),
      getValue: (vehicle) => vehicle.assetType,
    },
    {
      name: 'idleLocation', label: 'Idle Location', width: 115,
      getFilterValue: () => filters.idleLocation,
      setFilter: (value) => setFilters({ ...filters, idleLocation: value ?? '' }),
      getValue: (vehicle) => vehicle.idleLocation,
    },
    {
      name: 'buildDate', label: 'Build Date', width: 110,
      getFilterValue: () => filters.buildDate,
      setFilter: (value) => setFilters({ ...filters, buildDate: value ?? '' }),
      getValue: (vehicle) => vehicle.buildDate,
    },
    {
      name: 'Odometers', label: 'Odometers(<)', width: 110,
      getFilterValue: () => filters.odometer,
      setFilter: (value) => setFilters({ ...filters, odometer: value ?? '' }),
      getValue: (vehicle) => vehicle.odometer?.toString()??"",
    },
    {
      name: 'softFurnishing', label: 'Soft Furnishing', width: 155,
      getFilterValue: () => filters.softFurnishing,
      setFilter: (value) => setFilters({ ...filters, softFurnishing: value ?? '' }),
      getValue: (vehicle) => vehicle.softFurnishing,
    },
    {
      name: 'hardFurnishing', label: 'Hard Furnishing', width: 155,
      getFilterValue: () => filters.hardFurnishing,
      setFilter: (value) => setFilters({ ...filters, hardFurnishing: value ?? '' }),
      getValue: (vehicle) => vehicle.hardFurnishing,
    },
    {
      name: 'benchTop', label: 'Bench Top', width: 155,
      getFilterValue: () => filters.benchTop,
      setFilter: (value) => setFilters({ ...filters, benchTop: value ?? '' }),
      getValue: (vehicle) => vehicle.benchTop,
    },
    {
      name: 'vehiclePrice', label: 'Vehicle Price', width: 125,
      getValue: (vehicle) => AsAuCurrency(vehicle.primaryPrice) ?? ''
    },
    {
      name: 'extraPrice', label: 'Extra Price', width: 125,
      getValue: (vehicle) => AsAuCurrency(vehicle.extraPrice) ?? ''
    },
    {
      name: 'totalPrice', label: 'Total Price', width: 125,
      getValue: (vehicle) => AsAuCurrency(vehicle.totalPrice) ?? ''
    },
  ];
  return (
    <LoadingPane isLoading={vehicleSelection.isLoading || vehicleSelection.isSearchingVehicle}>
      <div className={styles.searchOptions}>
        <SelectField
          name="condition"
          className={styles.condition}
          label="Condition"
          options={mapToSelectOptions(options?.conditions)}
          value={condition?.code ?? ''}
          onChange={event => onConditionChanged(event.target.value as string)}
          readonly={vehicleSelection.options?.conditions.length === 1}
        />
        <SelectField
          name="type"
          className={styles.type}
          label="Type"
          options={mapToSelectOptions(condition?.types)}
          value={type?.code ?? ''}
          onChange={event => onTypeChanged(event.target.value as string)}
          emptyLabel="All"
        />
        <SelectField
          name="make"
          className={styles.make}
          label="Make"
          options={mapToSelectOptions(type?.makes)}
          value={make?.code ?? ''}
          onChange={event => onMakeChanged(event.target.value as string)}
          emptyLabel="All"
        />
        <SelectField
          name="model"
          className={styles.model}
          label="Model"
          options={mapToSelectOptions(make?.models)}
          value={model?.code ?? ''}
          onChange={event => onModelChanged(event.target.value as string)}
          emptyLabel="All"
        />
        <SelectField
          name="series"
          className={styles.series}
          label="Series"
          options={mapToSelectOptions(model?.series)}
          value={series?.code ?? ''}
          onChange={event => onSeriesChanged(event.target.value as string)}
          emptyLabel="All"
        />
      </div>
      <div>{warningMessage && <Alert severity="warning">{warningMessage}</Alert>}</div>
      <div className={styles.actions}>
        <ButtonWithProgress
          disabled={!condition}
          inProgress={vehicleSelection.isSearching}
          onClick={() => searchVehicles()}>
          Search
        </ButtonWithProgress>
        <div className={styles.spacer} aria-hidden="true" />
        {props.allowManualEntry && (
          <Button alt onClick={vehicleSelection.setEnterVehicleManually}>
            Enter manually
          </Button>
        )}
        <Button alt onClick={props.onCancelEditing}>
          Cancel
        </Button>
      </div>
      {vehicles && vehicles.length > 0 && (
        <div className={styles.tableContainer}>
          <TableContainer>
            <Table size="small" padding="none">
              <TableHead>
                <TableRow>
                  {columns.map(c => (
                    <TableCell
                      key={c.name}
                      style={{ minWidth: `${c.width}px` }}
                      className={styles.tableCellLabel}>
                      <label>{c.label}</label>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {columns.map(c => (
                    <TableCell key={c.name} className={styles.tableCellFilter}>
                      {c.getFilterValue && c.setFilter && (
                        <TextField
                          id={`${c.name}Filter`}
                          name={`${c.name}Filter`}
                          label=""                  
                          value={c.getFilterValue()}
                          onChange={event => c.setFilter && c.setFilter(event.target.value)}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVehicles.sort((a,b)=> {return a.assetType.localeCompare(b.assetType)})
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(vehicle => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={vehicle.assetNo}
                        className={styles.tableRowVehicle}
                        onClick={() => selectVehicle(vehicle)}>
                        {columns.map(c => (
                          <TableCell key={c.name} className={styles.tableCell}>
                            {c.getValue(vehicle)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredVehicles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(_, page) => handleChangePage(page)}
            onChangeRowsPerPage={event => handleChangeRowsPerPage(parseInt(event.target.value))}
          />
        </div>
      )}
    </LoadingPane>
  );
})