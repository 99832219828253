import { TextField as MuiTextField } from '@material-ui/core';
import cn from 'classnames';
import React from "react";
import NumberFormat from 'react-number-format';
import styles from './TextField.module.scss';

interface ICurrencyTextFieldProps {
  id?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  label: string;
  name: string;
  required?: boolean;
  autoFocus?: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | undefined;
  validationError?: string;
  onBlur?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  name: string;
}

function CurrencyFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

export const CurrencyTextField: React.FC<ICurrencyTextFieldProps> = ({
  id,
  className,
  inputRef,
  label,
  name,
  required,
  autoFocus,
  readonly,
  value,
  onChange,
  validationError,
  onBlur,
  onKeyDown,
}) => {

  return (
    <MuiTextField
      id={id}
      className={cn(className, { [styles.readonly]: readonly })}
      label={label}
      name={name}
      required={required}
      autoFocus={autoFocus}
      margin="normal"
      variant="outlined"
      size="small"
      inputRef={inputRef}
      inputProps={{
        readOnly: readonly
      }}
      InputProps={{
        inputComponent: CurrencyFormatCustom as any
      }}
      InputLabelProps={{ shrink: readonly || undefined }}
      value={value}
      error={!!validationError}
      helperText={validationError}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};