import { ExtrasType, extrasTypeDescription } from 'api/enums/ExtrasType';
import { OrderStatusCode } from 'api/enums/OrderStatusCode';
import { IOrderDtoModel, OrderDtoModel } from 'api/models/Domain/Dtos/OrderDtoModel';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { DateTime } from 'luxon';
import { cast, types } from 'mobx-state-tree';
import { AsAuCurrency } from '../../../../infrastructure/formatUtils';
import { IOrderLine } from '../../types/IOrderLine';
import { emptyOrderDetail, OrderDetailModel } from './OrderDetailModel';
import { OrderExtraModel } from './OrderExtraModel';
import { emptyOrderVehicle, OrderVehicleModel } from './OrderVehicleModel';

export const OrderModel = types
  .model('OrderModel', {
    ...OrderDtoModel.properties,
    lastModifiedAt: localDateTimeType,
    detail: OrderDetailModel,
    vehicle: OrderVehicleModel,
    extras: types.array(OrderExtraModel),
    modificationCutoffDate: types.maybe(localDateTimeType),
    plannedCompletionDate: types.maybe(localDateTimeType),
  })
  .extend(self => {
    function deleteExtra(id: string) {
      self.extras = cast(self.extras.filter(e => e.id !== id));
    }

    return {
      actions: {
        deleteExtra: deleteExtra,
      },
    };
  })
  .views(self => ({
    get buildOptions() {
      return self.extras.filter(e => e.parentType === ExtrasType.BuildOptions);
    },
    get internalAccessories() {
      return self.extras.filter(e => e.parentType === ExtrasType.InternalAccessories);
    },
    get externalAccessories() {
      return self.extras.filter(e => e.parentType === ExtrasType.ExternalAccessories);
    },
    get colourSchemeOptions() {
      return self.extras.filter(e => e.parentType === ExtrasType.ColourSchemeOptions);
    },
    get totalExtrasPriceFormatted() {
      return AsAuCurrency(self.totalExtrasPrice);
    },
    get totalPriceFormatted() {
      return AsAuCurrency(self.totalPrice);
    },
    get duplicateExtras() {
      const allDescriptions = self.extras.map(e => e.description);
      return allDescriptions.filter((desc, index) => allDescriptions.indexOf(desc) !== index);
    },
    get orderLines(): IOrderLine[] {
      const items = [
        {
          description: self.vehicle.description,
          priceFormatted: AsAuCurrency(self.vehicle.price),
          quantity: 1,
          index: 0,
          productType: 'Vehicle Sell',
          lineTotalFormatted: AsAuCurrency(self.vehicle.price),
        } as IOrderLine,
        ...self.extras.map<IOrderLine>(e => ({
          description: e.description,
          priceFormatted: AsAuCurrency(e.price),
          quantity: e.quantity,
          index: 0,
          productType: e.parentType !== undefined ? extrasTypeDescription(e.parentType) : '',
          lineTotalFormatted: e.totalPriceFormatted,
        })),
      ];

      return items.map((item, i) => ({ ...item, index: i + 1 }));
    },
    get isFirstVersion() {
      return self.version === 1;
    },
    get hasModificationCutoffDateLapsed() {
      return (
        self.modificationCutoffDate && DateTime.local().startOf('day') >= self.modificationCutoffDate
      );
    },
  }))
  .views(self => ({
    get isValid() {
      return self.detail.isValid && !self.vehicle.isEmpty && self.duplicateExtras.length === 0;
    },
  }));

export const emptyOrder: IOrderDtoModel = {
  id: '',
  numberAndVersion: '',
  number: '',
  version: 0,
  statusCode: OrderStatusCode.Draft,
  lastModifiedAt: DateTime.local().toString(),
  detail: { ...emptyOrderDetail },
  vehicle: { ...emptyOrderVehicle },
  extras: cast([]),
  totalExtrasPrice: 0,
  totalPrice: 0,
  cancellationReason: '',
  modificationCutoffDate: undefined,
  plannedCompletionDate: undefined,
  externalStatus: '',
  externalOrderId: '',
  rejectionReason: '',
  modificationReason: ''
};
