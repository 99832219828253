import { types, Instance } from 'mobx-state-tree';

export const ContractVersionDtoModel = types.model('ContractVersionDtoModel', {
  id: types.identifier,
  lastModifiedAt: types.string,
  lastModifiedBy: types.string,
  numberAndVersion: types.string,
  status: types.frozen<Domain.Aggregates.Contract.Entities.ContractStatusCode>(),
});

export interface IContractVersionDtoModel extends Instance<typeof ContractVersionDtoModel> {}
