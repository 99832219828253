import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, ButtonWithProgress, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import styles from './QuickSearch.module.scss';
import { VehicleCondition } from 'api/enums/VehicleCondition';

type ISearchVehicleDto = Domain.Queries.Vehicle.ISearchVehicleDto;

interface IQuickSearchProps {
  newBuildOnly: boolean;
  allowManualEntry?: boolean;
  onCancelEditing: () => void;
  onBeforeSearch?: () => void;
  onVehicleFound: (vehicle: ISearchVehicleDto | undefined) => void;
}

export const QuickSearch: React.FC<IQuickSearchProps> = observer(props => {
  const focusFieldRef = React.useRef<HTMLInputElement | null>(null);
  const { vehicleSelection } = useStore();
  const [assetNo, setAssetNo] = React.useState('');
  const [newBuildWarning, setNewBuildWarning] = React.useState(false);

  useEffect(() => {
    focusFieldRef.current && focusFieldRef.current.focus();
  }, []);

  const searchVehicle = async () => {
    if (!assetNo) return null;
    setNewBuildWarning(false);

    if (props.onBeforeSearch) props.onBeforeSearch();

    const vehicle = await vehicleSelection.searchVehicle(assetNo);

    if (props.newBuildOnly && vehicle && vehicle.condition !== VehicleCondition.NewBuild) {
      setNewBuildWarning(true);
      return;
    }

    props.onVehicleFound(vehicle);
  };

  return (
    <>
      <div className={styles.fields}>
        <TextField
          id="quote-vehicle-assetNo"
          className={styles.assetNo}
          name="assetNo"
          label="Asset No"
          inputRef={focusFieldRef}
          value={assetNo}
          onChange={event => setAssetNo(event.target.value)}
        />
        {vehicleSelection.vehicleNotFound && (
          <Alert className={styles.warning} severity="warning">
            Vehicle isn't found in Vibe, try to search again{' '}
            {props.allowManualEntry && <>or enter vehicle manually</>}
          </Alert>
        )}
        {newBuildWarning && (
          <Alert className={styles.warning} severity="warning">
            Only new build vehicles can be selected
          </Alert>
        )}
      </div>
      <div className={styles.actions}>
        <ButtonWithProgress
          type="submit"
          inProgress={vehicleSelection.isSearchingVehicle}
          onClick={searchVehicle}>
          Search
        </ButtonWithProgress>
        <div className={styles.spacer} aria-hidden="true" />
        {props.allowManualEntry && (
          <Button alt onClick={vehicleSelection.setEnterVehicleManually}>
            Enter manually
          </Button>
        )}
        <Button alt onClick={props.onCancelEditing}>
          Cancel
        </Button>
      </div>
    </>
  );
});
