export type IRules<T> = { [key in keyof T]?: (value: T[key], form: T) => string | undefined };

export const validator = <T>(r: IRules<T>) => (form: T) => (k: keyof T) => {
  const func = r[k];
  if (!func) return;
  return func(form[k], form);
};

export const isValid = <T>(form: T, r: IRules<T>) => {
  const errors = Object.keys(form)
    .map(k => k as keyof T)
    .reduce(
      (acc, next) => ({
        ...acc,
        [next]: validator(r)(form)(next),
      }),
      []
    );

  const valid = Object.keys(errors).every(k => !errors[k as keyof typeof errors]);
  if (!valid) console.log(errors);
  return valid;
};


export const required = <T>(s: T | undefined | null) => (s === undefined || s === null ? 'Required' : undefined);

export const requiredString = (s: string | undefined) => (!s ? 'Required' : undefined);

export const requiredBoolean = (v: boolean | undefined) => (v === undefined ? 'Required' : undefined);

export const matches = (s: string | undefined, pattern: string, error: string) => {
  if (!s) return undefined;
  return s.match(new RegExp(pattern)) ? undefined : error;
};

export const requiredNumber = (n: number | undefined) => 
    (n === undefined || isNaN(n) ? 'Required' : undefined);
