import { Chip } from '@material-ui/core';
import { OrderStatusCode, orderStatusCodeDescription } from 'api/enums/OrderStatusCode';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './OrderStatus.module.scss';

export interface IOrderStatusProps {
  statusCode: OrderStatusCode;
  className?: string;
}

const getStatusColor = (statusCode: OrderStatusCode) => {
  switch (statusCode) {
    case OrderStatusCode.Open:
      return styles.primary;
    case OrderStatusCode.OldVersion:
    case OrderStatusCode.Rejected:
    case OrderStatusCode.Cancelled:
      return 'red';
    case OrderStatusCode.AwaitingApproval:
      return '#295576';
    case OrderStatusCode.Placed:
      return 'green';
    default:
      return 'grey';
  }
};

export const OrderStatus: React.FC<IOrderStatusProps> = observer(function OrderStatus({
  statusCode,
  className,
}) {
  const statusColor = getStatusColor(statusCode);
  const labelText = orderStatusCodeDescription(statusCode);
  return (
    <Chip
      className={className}
      label={labelText}
      component="span"
      color="primary"
      style={{ backgroundColor: statusColor, width: '6rem' }}
      variant="default"
    />
  );
});
