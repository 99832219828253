import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, ButtonWithProgress } from 'views/components/forms';
import { StatusCode } from 'api/enums/StatusCode';
import { DeleteDraftDialog } from './DeleteDraftDialog';
import { useStore } from '../../../hooks';
import { saveAs } from 'file-saver';
import { useHistory } from 'react-router-dom';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { QuoteApprovalStatus } from 'api/enums/QuoteApprovalStatus';
import styles from './QuoteActions.module.scss';

export const QuoteActions: React.FC = observer(function QuoteActions() {
  const store = useStore();
  const history = useHistory();
  const editQuoteModel = store.editQuoteModel;
  const contractFeatureEnabled = store.security.currentUser.contractFeatureEnabled;
  const quote = editQuoteModel.temporaryQuote;
  const [downloadInProgress, setDownloadInProgress] = React.useState<boolean>(false);
  const [finaliseInProgress, setFinaliseInProgress] = React.useState<boolean>(false);
  const [amendInProgress, setAmendInProgress] = React.useState<boolean>(false);
  const [acceptInProgress, setAcceptInProgress] = React.useState<boolean>(false);
  const [contractInProgress, setContractInProgress] = React.useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const downloadPdf = async () => {
    if (!quote) return;

    try {
      setDownloadInProgress(true);
      const pdf = await store.quotesRepo.downloadQuotePdf(quote?.id);
      saveAs(pdf, quote?.numberAndVersion);
    } finally {
      setDownloadInProgress(false);
    }
  };

  const amendQuote = async () => {
    if (!quote) return;

    try {
      setAmendInProgress(true);
      const id = await editQuoteModel.amendQuote();
      history.push(`/quotes/${id}`);
    } finally {
      setAmendInProgress(false);
    }
  };

  const finaliseQuote = async () => {
    if (!quote) return;

    try {
      setFinaliseInProgress(true);
      await editQuoteModel.finaliseQuote();
    } finally {
      setFinaliseInProgress(false);
    }
  };

  const acceptQuote = async () => {
    if (!quote) return;

    try {
      setAcceptInProgress(true);
      await editQuoteModel.acceptQuote();
    } finally {
      setAcceptInProgress(false);
    }
  };

  const createContract = async () => {
    if (!quote) return;

    try {
      setContractInProgress(true);
      const contractId = await editQuoteModel.createContractFromQuote();
      history.replace(`/contracts/${contractId}`);
    } finally {
      setContractInProgress(false);
    }
  };

  const disableFinaliseButton =
    ((quote.statusCode === StatusCode.Draft || quote.statusCode === StatusCode.Approved) &&
      editQuoteModel.associatedContract?.statusCode === ContractStatusCode.Cancelled) ||
    !quote.vehicle.displayPrice ||
    (quote.needManagerApproval &&
      !quote.canBeApprovedBy(store.security.currentUser) &&
      quote.latestApproval?.status !== QuoteApprovalStatus.Approved) ||
    editQuoteModel.editCounter > 0;

    //AftermarketPerson is not allowed to duplicate an order
  const hideDuplicateButton =
    (editQuoteModel.associatedContract &&
    editQuoteModel.associatedContract.statusCode !== ContractStatusCode.Cancelled)
    || store.security.currentUser.isAftermarketPerson;

  return (
    <>
      <div className={styles.actions}>
        {editQuoteModel.vehicleIsConfirmed && (
          <>
            {contractFeatureEnabled && quote && !editQuoteModel.isReadOnly && (
              <ButtonWithProgress
                inProgress={contractInProgress}
                onClick={createContract}
                disabled={editQuoteModel.disableGenerateContract}>
                Generate Contract
              </ButtonWithProgress>
            )}
            {quote && quote.canBeFinalised && !editQuoteModel.isReadOnly && (
              <ButtonWithProgress
                disabled={disableFinaliseButton}
                inProgress={finaliseInProgress}
                onClick={finaliseQuote}>
                Finalise
              </ButtonWithProgress>
            )}
            {!contractFeatureEnabled && quote && quote.statusCode === StatusCode.Open && !editQuoteModel.isReadOnly && (
              <ButtonWithProgress inProgress={acceptInProgress} onClick={acceptQuote}>
                Accept
              </ButtonWithProgress>
            )}
            {quote &&
              (quote.statusCode === StatusCode.Open ||
                quote.statusCode === StatusCode.Accepted) && (
                <ButtonWithProgress inProgress={downloadInProgress} onClick={downloadPdf}>
                  Download
                </ButtonWithProgress>
              )}
            {!hideDuplicateButton && !editQuoteModel.isReadOnly && (
              <ButtonWithProgress
                disabled={editQuoteModel.disableDuplicateButton}
                inProgress={amendInProgress}
                onClick={amendQuote}>
                Duplicate
              </ButtonWithProgress>
            )}
          </>
        )}
        {quote && !editQuoteModel.isReadOnly && quote.statusCode === StatusCode.Draft && (
          <Button onClick={() => setOpenDeleteDialog(true)}>Delete</Button>
        )}
      </div>
      {openDeleteDialog && <DeleteDraftDialog onClose={() => setOpenDeleteDialog(false)} />}
    </>
  );
});
