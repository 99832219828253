import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { IContractDtoModel } from 'api/models/Domain/Dtos/ContractDtoModel';
import { DateTime } from 'luxon';
import { cast } from 'mobx-state-tree';
import { emptyContractCustomer } from './ContractCustomerModel';
import { emptyContractSettlement } from './ContractSettlementModel';
import { emptyContractTradeIn } from './ContractTradeInModel';
import { emptyContractVehicle } from './ContractVehicleModel';
import { emptyContractLicensee } from "./ContractLicenseeModel";


export const emptyContract: IContractDtoModel = {
  id: '',
  number: '',
  version: 1,
  numberAndVersion: '',
  statusCode: ContractStatusCode.Draft,
  lastModifiedAt: DateTime.local().toString(),
  leadId:'',
  customer: { ...emptyContractCustomer },
  vehicle: { ...emptyContractVehicle },
  tradeIn: { ...emptyContractTradeIn },
  settlement: { ...emptyContractSettlement },
  licensee: { ...emptyContractLicensee },
  extras: cast([]),
  onRoads: cast([]),
  totalExtrasPrice: 0,
  subTotal: 0,
  totalDiscount: 0,
  totalShortfall: 0,
  totalBalancePayable: 0,
  netTradeInAllowance: 0,
  specialConditions: '',
  assignedTo: '',
  assignedToId: '',
  deliveryDate: '',
  isFromQuote: true,
  initialContractDate: '',
  applicableMinimumDepositRules: 0,
  minimumDepositAmountSatisfied: true,
  minimumDepositRequired: 0
};
