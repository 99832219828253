export enum ExtrasType {
    BuildOptions = 0,
    InternalAccessories = 1,
    ExternalAccessories = 2,
    ColourSchemeOptions = 3
}
export function extrasTypeDescription(value: ExtrasType) {
    switch (value) {
        case ExtrasType.BuildOptions:
            return `Build Options`;
        case ExtrasType.InternalAccessories:
            return `Internal Accessories`;
        case ExtrasType.ExternalAccessories:
            return `External Accessories`;
        case ExtrasType.ColourSchemeOptions:
            return `Colour Scheme Options`;
    }
}
export interface IExtrasTypeDescriptor { value: ExtrasType; name: string; description: string; }
export var allExtrasType: IExtrasTypeDescriptor[] = [
    { value: ExtrasType.BuildOptions, name: `BuildOptions`, description: `Build Options` },
    { value: ExtrasType.InternalAccessories, name: `InternalAccessories`, description: `Internal Accessories` },
    { value: ExtrasType.ExternalAccessories, name: `ExternalAccessories`, description: `External Accessories` },
    { value: ExtrasType.ColourSchemeOptions, name: `ColourSchemeOptions`, description: `Colour Scheme Options` }
];

export function getExtrasTypeDescriptor(value: ExtrasType) {
    switch (value) {
        case ExtrasType.BuildOptions:
            return { value: ExtrasType.BuildOptions, name: `BuildOptions`, description: `Build Options` };
        case ExtrasType.InternalAccessories:
            return { value: ExtrasType.InternalAccessories, name: `InternalAccessories`, description: `Internal Accessories` };
        case ExtrasType.ExternalAccessories:
            return { value: ExtrasType.ExternalAccessories, name: `ExternalAccessories`, description: `External Accessories` };
        case ExtrasType.ColourSchemeOptions:
            return { value: ExtrasType.ColourSchemeOptions, name: `ColourSchemeOptions`, description: `Colour Scheme Options` };
    }
}
