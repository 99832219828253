export enum Permission {
    EditQuote = 0,
    EditContract = 1,
    EditOrder = 2,
    ViewQuote = 3,
    ViewContract = 4,
    ViewOrder = 5,
    AssignContract = 6,
    InitiateStockOrder = 7,
    InitiateCustomerOrder = 8,
    PlaceOrder = 9,
    ApproveOrders = 10,
    CancelOrders = 11,
    AssignOrder = 12,
    AssignLeadAndVehicle = 13
}
export function permissionDescription(value: Permission) {
    switch (value) {
        case Permission.EditQuote:
            return `EditQuote`;
        case Permission.EditContract:
            return `EditContract`;
        case Permission.EditOrder:
            return `EditOrder`;
        case Permission.ViewQuote:
            return `ViewQuote`;
        case Permission.ViewContract:
            return `ViewContract`;
        case Permission.ViewOrder:
            return `ViewOrder`;
        case Permission.AssignContract:
            return `AssignContract`;
        case Permission.InitiateStockOrder:
            return `InitiateStockOrder`;
        case Permission.InitiateCustomerOrder:
            return `InitiateCustomerOrder`;
        case Permission.PlaceOrder:
            return `PlaceOrder`;
        case Permission.ApproveOrders:
            return `ApproveOrders`;
        case Permission.CancelOrders:
            return `CancelOrders`;
        case Permission.AssignOrder:
            return `AssignOrder`;
        case Permission.AssignLeadAndVehicle:
            return `AssignLeadAndVehicle`;
    }
}
export interface IPermissionDescriptor { value: Permission; name: string; description: string; }
export var allPermission: IPermissionDescriptor[] = [
    { value: Permission.EditQuote, name: `EditQuote`, description: `EditQuote` },
    { value: Permission.EditContract, name: `EditContract`, description: `EditContract` },
    { value: Permission.EditOrder, name: `EditOrder`, description: `EditOrder` },
    { value: Permission.ViewQuote, name: `ViewQuote`, description: `ViewQuote` },
    { value: Permission.ViewContract, name: `ViewContract`, description: `ViewContract` },
    { value: Permission.ViewOrder, name: `ViewOrder`, description: `ViewOrder` },
    { value: Permission.AssignContract, name: `AssignContract`, description: `AssignContract` },
    { value: Permission.InitiateStockOrder, name: `InitiateStockOrder`, description: `InitiateStockOrder` },
    { value: Permission.InitiateCustomerOrder, name: `InitiateCustomerOrder`, description: `InitiateCustomerOrder` },
    { value: Permission.PlaceOrder, name: `PlaceOrder`, description: `PlaceOrder` },
    { value: Permission.ApproveOrders, name: `ApproveOrders`, description: `ApproveOrders` },
    { value: Permission.CancelOrders, name: `CancelOrders`, description: `CancelOrders` },
    { value: Permission.AssignOrder, name: `AssignOrder`, description: `AssignOrder` },
    { value: Permission.AssignLeadAndVehicle, name: `AssignLeadAndVehicle`, description: `AssignLeadAndVehicle` }
];

export function getPermissionDescriptor(value: Permission) {
    switch (value) {
        case Permission.EditQuote:
            return { value: Permission.EditQuote, name: `EditQuote`, description: `EditQuote` };
        case Permission.EditContract:
            return { value: Permission.EditContract, name: `EditContract`, description: `EditContract` };
        case Permission.EditOrder:
            return { value: Permission.EditOrder, name: `EditOrder`, description: `EditOrder` };
        case Permission.ViewQuote:
            return { value: Permission.ViewQuote, name: `ViewQuote`, description: `ViewQuote` };
        case Permission.ViewContract:
            return { value: Permission.ViewContract, name: `ViewContract`, description: `ViewContract` };
        case Permission.ViewOrder:
            return { value: Permission.ViewOrder, name: `ViewOrder`, description: `ViewOrder` };
        case Permission.AssignContract:
            return { value: Permission.AssignContract, name: `AssignContract`, description: `AssignContract` };
        case Permission.InitiateStockOrder:
            return { value: Permission.InitiateStockOrder, name: `InitiateStockOrder`, description: `InitiateStockOrder` };
        case Permission.InitiateCustomerOrder:
            return { value: Permission.InitiateCustomerOrder, name: `InitiateCustomerOrder`, description: `InitiateCustomerOrder` };
        case Permission.PlaceOrder:
            return { value: Permission.PlaceOrder, name: `PlaceOrder`, description: `PlaceOrder` };
        case Permission.ApproveOrders:
            return { value: Permission.ApproveOrders, name: `ApproveOrders`, description: `ApproveOrders` };
        case Permission.CancelOrders:
            return { value: Permission.CancelOrders, name: `CancelOrders`, description: `CancelOrders` };
        case Permission.AssignOrder:
            return { value: Permission.AssignOrder, name: `AssignOrder`, description: `AssignOrder` };
        case Permission.AssignLeadAndVehicle:
            return { value: Permission.AssignLeadAndVehicle, name: `AssignLeadAndVehicle`, description: `AssignLeadAndVehicle` };
    }
}
