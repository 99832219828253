import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IDeleteDraftDialogProps {
  onClose(): void;
}

export const DeleteDraftDialog: React.FC<IDeleteDraftDialogProps> = props => {
  const { editContractModal } = useStore();
  const history = useHistory();

  const deleteDraftContract = async () => {
    if (!editContractModal) return;
    const previousId = await editContractModal.deleteDraftContract();
    if (previousId) history.replace(`/contracts/${previousId}`);
    else history.replace(`/home/contracts`);
  };

  return (
    <BasicSubmitCancelDialog
      title="Delete Draft Contract"
      formik={{
        initialValues: {},
        onSubmit: deleteDraftContract,
      }}
      onClose={() => props.onClose()}
      content={
        <p>
          Are you sure you want to delete this draft contract?
          {editContractModal.contract.version > 1 && (
            <>
              <br />
              The previous version will be reinstated.
            </>
          )}
        </p>
      }
    />
  );
};
