import { AsAuCurrency } from 'infrastructure/formatUtils';
import React from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';
import styles from './SyncVibeVehicleDialog.module.scss';

interface ITextProps {
  text?: string
}
const GreenText: React.FC<ITextProps> = ({ text }: ITextProps) => (<span className={styles.greenText}>{text}</span>)
const RedText: React.FC<ITextProps> = ({ text }: ITextProps) => (<span className={styles.redText}>{text}</span>)
const convertFittedExtraToString = (fittedExtra: { description: string, recommendedRetailPrice?: number, quantity?: number }) =>
  `${fittedExtra.description}, total price ${AsAuCurrency((fittedExtra.recommendedRetailPrice ?? 0) * (fittedExtra.quantity ?? 0))}`;
const convertOptionalExtraToString = (extra: { description: string, unitPrice?: number, maxQuantity?: number }) =>
  `${extra.description}, max QTY ${extra.maxQuantity ?? 'none'}`;

export const SyncVibeVehicleDialog: React.FC = (() => {
  const { editQuoteModel } = useStore();
  const model = editQuoteModel.vehicleOutOfSyncDialogModel!;

  const fittedExtrasChanged = !!model.fittedExtrasCompareResult.added.length
    || !!model.fittedExtrasCompareResult.updated.length
    || !!model.fittedExtrasCompareResult.removed.length;
  const optionalExtrasChanged = !!model.optionalExtrasCompareResult.removed.length
    || !!model.optionalExtrasCompareResult.updated.length;
  const rebateChanged = model.oldRebate !== model.newRebate;
  // We only want to notify the user when the RRP is greater than the current total of the quote
  // (e.g. if the customer has offered higher than the cost of the vehicle)
  const notifyAboutRrpChanged = (model.oldRrp !== model.newRrp && (model.newRrp ?? 0) > model.quoteTotal);

  return (
    <BasicSubmitCancelDialog
      title='Sync Vibe Vehicle'
      readonly={true}
      formik={{
        initialValues: {},
        onSubmit: editQuoteModel.confirmSyncVehicleDialog
      }}
      onClose={editQuoteModel.confirmSyncVehicleDialog}
      content={
        <div>
          {
            !notifyAboutRrpChanged && !fittedExtrasChanged && !optionalExtrasChanged &&
            // Show a message on silent sync, for example, min sell changed
            <p>Vehicle detail has been synced with Vibe</p>
          }
          {
            notifyAboutRrpChanged && <p>Vehicle recommended retail price (RRP) has changed. Current RRP is <RedText text={AsAuCurrency(model.oldRrp)} /> and new RRP is <GreenText text={AsAuCurrency(model.newRrp)} />. </p>
          }
          {
            rebateChanged &&
              <p>Vehicle rebate has changed. Current vehicle rebate is <RedText text={AsAuCurrency(model.oldRebate)} /> and new rebate is <GreenText text={AsAuCurrency(model.newRebate)} />.</p>
          }

          {
            fittedExtrasChanged &&
            <>
              <p>Vehicle fitted extras have been changed:</p>
              <ul className={styles.extraList}>
                {
                  model.fittedExtrasCompareResult.added
                    .map((e, i) => <li key={`added-${i}`}>Added <GreenText text={convertFittedExtraToString({ description: e.name, recommendedRetailPrice: e.price, quantity: e.quantity})} /></li>)
                }
                {
                  model.fittedExtrasCompareResult.removed
                    .map((e, i) => <li key={`removed-${i}`}>Removed <RedText text={convertFittedExtraToString(e)} /></li>)
                }
                {
                  model.fittedExtrasCompareResult.updated
                    .map((e, i) => <li key={`update-${i}`}>
                      <RedText text={convertFittedExtraToString(e.old)} />
                      &nbsp;has been updated to&nbsp;
                      <GreenText text={convertFittedExtraToString({ description: e.new.name, recommendedRetailPrice: e.new.price, quantity: e.new.quantity})} /></li>)
                }
              </ul>
            </>
          }
          {
            optionalExtrasChanged &&
            <>
              <p>Vehicle optional extras have been changed:</p>
              <ul className={styles.extraList}>
                {
                  model.optionalExtrasCompareResult.removed
                    .map((e, i) => <li key={`added-${i}`}>Removed <RedText text={`${e.description}`} /></li>)
                }
                {
                  model.optionalExtrasCompareResult.updated
                    .map((e, i) => <li key={`update-${i}`}>
                      <RedText text={convertOptionalExtraToString({ description: e.old.description, unitPrice: e.old.unitPrice, maxQuantity: e.old.maxQuantity})} />
                      &nbsp;has been updated to&nbsp;
                      <GreenText text={convertOptionalExtraToString({ description: e.new.name, unitPrice: e.new.price, maxQuantity: e.new.maxQuantity})} /></li>)
                }
              </ul>
            </>
          }
        </div>
      }
    />
  )
})
