import { types, Instance } from 'mobx-state-tree';

export const ContractTradeInDtoModel = types.model('ContractTradeInDtoModel', {
  accessories: types.maybe(types.string),
  accountNumber: types.maybe(types.string),
  bodyColour: types.maybe(types.string),
  compliancePlateDate: types.maybe(types.string),
  creditPayoutRequired: types.maybe(types.number),
  creditPayoutValidationDueDate: types.maybe(types.string),
  creditProvider: types.maybe(types.string),
  description: types.maybe(types.string),
  engineNumber: types.maybe(types.string),
  engineSize: types.maybe(types.string),
  floorPlan: types.maybe(types.string),
  isTradeInUnderFinance: types.maybe(types.boolean),
  kms: types.maybe(types.integer),
  make: types.maybe(types.string),
  model: types.maybe(types.string),
  netTradeInValue: types.maybe(types.number),
  price: types.maybe(types.number),
  registration: types.maybe(types.string),
  registrationExpiryDate: types.maybe(types.string),
  trimColour: types.maybe(types.string),
  vinNumber: types.maybe(types.string),
  year: types.maybe(types.string),
});

export interface IContractTradeInDtoModel extends Instance<typeof ContractTradeInDtoModel> {}
