export enum VehicleCondition {
    New = 0,
    Used = 1,
    NewBuild = 2
}
export function vehicleConditionDescription(value: VehicleCondition) {
    switch (value) {
        case VehicleCondition.New:
            return `New`;
        case VehicleCondition.Used:
            return `Used`;
        case VehicleCondition.NewBuild:
            return `New Build`;
    }
}
export interface IVehicleConditionDescriptor { value: VehicleCondition; name: string; description: string; }
export var allVehicleCondition: IVehicleConditionDescriptor[] = [
    { value: VehicleCondition.New, name: `New`, description: `New` },
    { value: VehicleCondition.Used, name: `Used`, description: `Used` },
    { value: VehicleCondition.NewBuild, name: `NewBuild`, description: `New Build` }
];

export function getVehicleConditionDescriptor(value: VehicleCondition) {
    switch (value) {
        case VehicleCondition.New:
            return { value: VehicleCondition.New, name: `New`, description: `New` };
        case VehicleCondition.Used:
            return { value: VehicleCondition.Used, name: `Used`, description: `Used` };
        case VehicleCondition.NewBuild:
            return { value: VehicleCondition.NewBuild, name: `NewBuild`, description: `New Build` };
    }
}
