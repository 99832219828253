import { types, Instance } from 'mobx-state-tree';
import { FittedExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel } from './../../Domain/Queries/Vehicle/SearchVehicleDto/FittedExtraDtoModel'
import { QuoteFittedExtraDtoModel as DomainDtosQuoteFittedExtraDtoModel } from './QuoteFittedExtraDtoModel'
import { UpdatedFittedExtraDtoModel as DomainDtosUpdatedFittedExtraDtoModel } from './UpdatedFittedExtraDtoModel'

export const FittedExtrasCompareResultDtoModel = types.model('FittedExtrasCompareResultDtoModel', {
  added: types.array(DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel),
  removed: types.array(DomainDtosQuoteFittedExtraDtoModel),
  updated: types.array(DomainDtosUpdatedFittedExtraDtoModel),
});

export interface IFittedExtrasCompareResultDtoModel extends Instance<typeof FittedExtrasCompareResultDtoModel> {}
