import { getLocationDescriptor, ILocationDescriptor } from 'api/enums/Location';
import { UserProfileDtoModel } from 'api/models/Web/Controllers/UsersController/UserProfileDtoModel';
import { getAjax } from 'domain/store/RootStoreModel';
import { HTTPError } from 'ky';
import { flow, Instance, types } from 'mobx-state-tree';
import * as qs from 'query-string';
import { Permission } from 'api/enums/Permission';

export interface IUserProfileDto extends Instance<typeof UserProfileModel> {}

export const UserProfileModel = UserProfileDtoModel.named('UserProfileModel').views(self => ({
  can(permission: Permission) {
    return self.permissions.includes(permission);
  },
}));

export const SecurityModel = types
  .model('SecurityModel', {
    loadedUser: types.maybe(UserProfileModel),
  })
  .views(self => ({
    get currentUser() {
      if (self.loadedUser) {
        return self.loadedUser;
      }
      // We should always have a loaded user when running the app, so throw if this happens
      throw new Error('User has not been loaded');
    },
  }))
  .views(self => ({
    get locations(): ILocationDescriptor[] {
      const locations = self.currentUser.roleLocations.map(rl => getLocationDescriptor(rl.location));

      // distinct locations
      return [...new Set(locations)];
    },
  }))
  .actions(self => {
    const ajax = getAjax(self);

    function signIn() {
      const { pathname, search } = window.location;
      const returnUrl = pathname + search;
      const signInUrl = `/sign-in?${qs.stringify({ returnUrl })}`;
      window.location.assign(signInUrl);
    }

    function signOut() {
      window.location.assign('/sign-out');
    }

    function* loadCurrentUser() {
      const profile = yield ajax.get('/api/users/me').json<IUserProfileDto>();
      self.loadedUser = UserProfileModel.create(profile);
    }

    function* signInCurrentUser() {
      try {
        yield* loadCurrentUser();
        return true;
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 401) {
          signIn();
          return false;
        }
        throw e;
      }
    }

    return {
      signInCurrentUser: flow(signInCurrentUser),
      signOut,
    };
  });

export interface ISecurityModel extends Instance<typeof SecurityModel> {}
