import {
  ContractSettlementDtoModel,
  IContractSettlementDtoModel,
} from 'api/models/Domain/Dtos/ContractSettlementDtoModel';
import { cast, SnapshotIn } from 'mobx-state-tree';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import {
  IRules,
  isValid,
  requiredNumber,
  requiredString,
  validator,
} from 'infrastructure/validation';

const rules: IRules<IContractSettlementDtoModel> = {
  depositReceived: requiredNumber,
  depositPaymentType: (val, form) => (val !== undefined ? undefined : 'Required'),
  financeProvider: (val, form) => {
    if (!form.amountFinanced) return;
    return requiredString(val);
  },
};

export const ContractSettlementModel = ContractSettlementDtoModel.named('ContractSettlementModel')
  .views(self => ({
    get depositFormatted() {
      return AsAuCurrency(self.depositReceived);
    },
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      return isValid(self, rules);
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));
export const emptyContractSettlement: IContractSettlementDtoModel = {
  depositReceived: 0,
  depositPaymentType: undefined,
  amountFinanced: 0,
  financeProvider: '',
  otherPayment: 0,
};
