import React from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';

interface IFilterBarProps {
  value: string;
  placeholder?: string;
  className?: string;
  onChange: (newValue: string) => void | Promise<void>;
}

export const FilterBar: React.FC<IFilterBarProps> = observer(function FilterBar({
  value,
  placeholder,
  className,
  onChange,
}) {
  const [onChangeDebounced] = useDebouncedCallback((newValue: string) => {
    onChange(newValue);
  }, 500);

  return (
    <TextField
      label="Filter By"
      type="search"
      placeholder={placeholder}
      defaultValue={value}
      onChange={event => onChangeDebounced(event.target.value)}
      fullWidth
      autoFocus
      className={className}
    />
  );
});
