import { ContractCustomerDtoModel, IContractCustomerDtoModel } from "api/models/Domain/Dtos/ContractCustomerDtoModel";
import { ICustomerDtoModel } from "api/models/Domain/Queries/Customer/CustomerDtoModel";
import { IRules, isValid, requiredBoolean, requiredString, validator } from "infrastructure/validation";
import { cast, SnapshotIn } from "mobx-state-tree";

const rules: IRules<IContractCustomerDtoModel> = {
  dob: requiredString,
  driversLicense: requiredString,
  insuranceQuoteRequest: requiredBoolean,
  financeQuoteRequest: requiredBoolean,
  address: (value, form) => {
    return (!value?.streetAddress || !value.suburb || value.country === undefined || value.state === undefined || !value.postCode || !value.city)
      ? 'All address fields are required' : undefined
  },
  firstName: requiredString,
  surname: requiredString,
  phone: requiredString
};

export const ContractCustomerModel = ContractCustomerDtoModel.named('ContractCustomerModel')
  .views(self => ({
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      return isValid(self, rules);
    }
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K]
    ) {
      self[key] = cast(value);
    },
    getSyncState(lmsCustomer: ICustomerDtoModel | undefined) {
      if (!lmsCustomer) return;
      return {
        "abnChanged": self.abn !== lmsCustomer?.abn && !(self.abn == null && lmsCustomer?.abn === ""),
        "streetAddressChanged": self.address?.streetAddress !== lmsCustomer?.address?.streetAddress,
        "suburbChanged": self.address?.suburb !== lmsCustomer?.address?.suburb,
        "countryChanged": self.address?.country !== lmsCustomer?.address?.country,
        "stateChanged": self.address?.state !== lmsCustomer?.address?.state,
        "cityChanged": self.address?.city !== lmsCustomer?.address?.city,
        "postCodeChanged": self.address?.postCode !== lmsCustomer?.address?.postCode,
        "companyNameChanged": self.companyName !== lmsCustomer?.companyName && !(self.companyName == null && lmsCustomer?.companyName === ""),
        "emailChanged": self.email !== lmsCustomer?.email && !(self.email == null && lmsCustomer?.email === ""),
        "firstNameChanged": self.firstName !== lmsCustomer?.firstName,
        "phoneChanged": self.phone !== lmsCustomer?.phone && !(self.phone == null && lmsCustomer?.phone === ""),
        "phoneAlternateChanged": self.phoneAlternate !== lmsCustomer?.phoneAlternate && !(self.phoneAlternate == null && lmsCustomer?.phoneAlternate === ""),
        "surNameChanged": self.surname !== lmsCustomer?.surname && !(self.surname == null && lmsCustomer?.surname === ""),
        "dobChanged": self.dob !== lmsCustomer?.dob,
        "spouseFirstNameChanged": self.secondaryFirstName !== lmsCustomer?.spouseFirstName && !(self.secondaryFirstName == null && lmsCustomer?.spouseFirstName === ""),
        "spouseSurNameChanged": self.secondarySurname !== lmsCustomer?.spouseSurname && !(self.secondarySurname == null && lmsCustomer?.spouseSurname === ""),
        "spouseDobChanged": self.secondaryDob !== lmsCustomer?.spouseDob && !(self.secondaryDob == null && lmsCustomer?.spouseDob === "")
      };
    },
    getSyncedDetails(lmsCustomer: ICustomerDtoModel | undefined): IContractCustomerDtoModel | undefined {
      if (!lmsCustomer) return;
      return {
        abn: lmsCustomer?.abn,
        address: lmsCustomer?.address,
        companyName: lmsCustomer?.companyName,
        contactId: self.contactId,
        dob: lmsCustomer?.dob,
        driversLicense: self.driversLicense,
        email: lmsCustomer?.email,
        financeQuoteRequest: self.financeQuoteRequest,
        firstName: lmsCustomer?.firstName ?? "",
        fullName: lmsCustomer?.fullName ?? "",
        insuranceQuoteRequest: self.insuranceQuoteRequest,
        phone: lmsCustomer?.phone,
        phoneAlternate: lmsCustomer?.phoneAlternate,
        secondaryDob: lmsCustomer?.spouseDob,
        secondaryDriversLicense: self.secondaryDriversLicense,
        secondaryFirstName: lmsCustomer?.spouseFirstName,
        secondarySurname: lmsCustomer?.spouseSurname,
        surname: lmsCustomer?.surname ?? "",
      };
    }
  }));

export const emptyContractCustomer: IContractCustomerDtoModel = {
  firstName: '',
  surname: '',
  fullName: '',
  dob: '',
  driversLicense: '',
  secondaryFirstName: '',
  secondarySurname: '',
  secondaryDob: '',
  secondaryDriversLicense: '',
  phone: '',
  phoneAlternate: '',
  address: undefined,
  email: '',
  companyName: '',
  abn: '',
  contactId: undefined,
  insuranceQuoteRequest: undefined,
  financeQuoteRequest: undefined
};
