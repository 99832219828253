import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { FormSection } from 'views/components/forms/FormSection';
import styles from './ExtrasSection.module.scss';
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { IOrderExtraModel } from 'domain/store/repos/orders/OrderExtraModel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IApplicableExtraDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel';
import { NumberTextField } from 'views/components/forms';
import { ExtrasType } from 'api/enums/ExtrasType';
import Alert from '@material-ui/lab/Alert';

export const ExtrasSection: React.FC = observer(function ExtrasSection() {
  const { editOrderModel } = useStore();
  const order = editOrderModel.temporaryOrder;

  const save = async () => {
    await editOrderModel.updateVehicle();
  };

  const cancel = () => {
    editOrderModel.cancelEditingOrder();
  };

  const isValid = () => order.duplicateExtras.length === 0;

  return (
    <FormSection
      sectionTitle={'Extras'}
      sectionSummary={`Extras Price: ${order?.totalExtrasPriceFormatted}`}
      isFormActionAllowed={editOrderModel.isEditable}
      expandedDefault={false}
      canSave={true}
      onValidate={isValid}
      hasErrors={!isValid()}
      canCancel={true}
      onCancel={cancel}
      onSubmit={save}>
      {({ focusFieldRef, editable }) => (
        <>
          <Table>
            <TableHead className={styles.tablehead}>
              <TableRow>
                <TableCell className={styles.icon} />
                <TableCell className={styles.description}>Description</TableCell>
                <TableCell className={styles.price}>RRP (GST Incl.)</TableCell>
                <TableCell className={styles.qty}>QTY</TableCell>
                <TableCell align="right">Line Total (GST Incl.)</TableCell>
              </TableRow>
            </TableHead>
          </Table>

          <ExtrasTable
            title="Build Options"
            selectedExtras={order.buildOptions}
            applicableExtras={editOrderModel.applicableBuildOptions}
            editable={editable}
            onDeleteExtra={order.deleteExtra}
            onAddExtra={() => editOrderModel.addExtra(ExtrasType.BuildOptions)}
          />
          <ExtrasTable
            title="Internal Accessories"
            selectedExtras={order.internalAccessories}
            applicableExtras={editOrderModel.applicableInternalAccessories}
            editable={editable}
            onDeleteExtra={order.deleteExtra}
            onAddExtra={() => editOrderModel.addExtra(ExtrasType.InternalAccessories)}
          />
          <ExtrasTable
            title="External Accessories"
            selectedExtras={order.externalAccessories}
            applicableExtras={editOrderModel.applicableExternalAccessories}
            editable={editable}
            onDeleteExtra={order.deleteExtra}
            onAddExtra={() => editOrderModel.addExtra(ExtrasType.ExternalAccessories)}
          />
          <ExtrasTable
            title="Colour Scheme Options"
            selectedExtras={order.colourSchemeOptions}
            applicableExtras={editOrderModel.applicableColourSchemeOptions}
            editable={editable}
            onDeleteExtra={order.deleteExtra}
            onAddExtra={() => editOrderModel.addExtra(ExtrasType.ColourSchemeOptions)}
          />
          {order.duplicateExtras.length > 0 && (
            <Alert severity="error">Duplicate extras: {order.duplicateExtras.join(',')}</Alert>
          )}
        </>
      )}
    </FormSection>
  );
});

interface IExtraRowProps {
  index: number;
  extra: IOrderExtraModel;
  applicableExtras?: IApplicableExtraDtoModel[];
  onDeleteExtra: () => void;
}

interface IReadOnlyExtraRowProps {
  index: number;
  extra: IOrderExtraModel;
}

interface IExtrasTableProps {
  title: string;
  selectedExtras: IOrderExtraModel[];
  applicableExtras?: IApplicableExtraDtoModel[];
  editable: boolean;
  onAddExtra: () => void;
  onDeleteExtra: (id: string) => void;
}

export const ExtrasTable: React.FC<IExtrasTableProps> = observer(props => {
  const useStyles = makeStyles(theme => ({
    removeButton: {
      color: theme.palette.error.dark,
    },
    addButton: {
      color: theme.paletteExtensions.success.dark,
    },
  }));
  const classes = useStyles();

  if (!props.editable && !props.selectedExtras.length) return <></>;
  return (
    <div>
      <h1>{props.title}</h1>
      <Table>
        <TableBody>
          {props.selectedExtras.map((e, i) =>
            props.editable ? (
              <EditExtraTableRow
                index={i}
                extra={e}
                applicableExtras={props.applicableExtras}
                key={e.id}
                onDeleteExtra={() => props.onDeleteExtra(e.id!)}
              />
            ) : (
              <ReadOnlyExtraTableRow index={i} extra={e} key={e.id} />
            )
          )}
          {props.editable && (props.applicableExtras || []).length > 0 && (
            <TableRow>
              <TableCell className={styles.icon}>
                <IconButton
                  color="primary"
                  className={classes.addButton}
                  onClick={props.onAddExtra}>
                  <AddCircleIcon />
                </IconButton>
              </TableCell>
              <TableCell className={styles.description} />
              <TableCell className={styles.price} />
              <TableCell className={styles.qty} />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
      {props.applicableExtras && !props.applicableExtras.length && <i>None available</i>}
    </div>
  );
});

export const ReadOnlyExtraTableRow: React.FC<IReadOnlyExtraRowProps> = observer(
  ({ index, extra }) => {
    return (
      <TableRow className={styles.row}>
        <TableCell className={styles.icon}>{index + 1}</TableCell>
        <TableCell className={styles.description}>{extra.description}</TableCell>
        <TableCell className={styles.price}>{extra.priceFormatted}</TableCell>
        <TableCell className={styles.qty}>{extra.quantity}</TableCell>
        <TableCell align="right">{extra.totalPriceFormatted}</TableCell>
      </TableRow>
    );
  }
);

const EditExtraTableRow: React.FC<IExtraRowProps> = observer(props => {
  const useStyles = makeStyles(theme => ({
    removeButton: {
      color: theme.palette.error.dark,
    },
    addButton: {
      color: theme.paletteExtensions.success.dark,
    },
  }));
  const classes = useStyles();

  return (
    <TableRow className={styles.row}>
      <TableCell className={styles.cell}>
        <IconButton className={classes.removeButton} onClick={props.onDeleteExtra}>
          <RemoveCircleIcon />
        </IconButton>
      </TableCell>
      <TableCell className={styles.cell}>
        <Autocomplete
          freeSolo={true}
          filterOptions={filterOptions}
          autoSelect={true}
          options={(props.applicableExtras || [])}
          getOptionLabel={(option: IApplicableExtraDtoModel | string) =>
            typeof option === 'string'
              ? (option as string)
              : (option as IApplicableExtraDtoModel).name
          }
          getOptionSelected={(option: IApplicableExtraDtoModel) => option.code === props.extra.code}
          className={styles.field}
          renderInput={params => (
            <TextField
              {...params}
              label="Description"
              variant="outlined"
              size="small"
              margin="normal"
            />
          )}
          onChange={(event: ChangeEvent<{}>, value: IApplicableExtraDtoModel | null) => {
            if (!value) return;
            props.extra.set(value!);
          }}
          value={props.extra.description as any}
        />
      </TableCell>
      <TableCell className={styles.cell}>{props.extra.priceFormatted}</TableCell>
      <TableCell className={styles.cell}>
        <NumberTextField
          label="Quantity"
          name="quantity"
          className={styles.field}
          value={props.extra.quantity}
          onChange={event =>
            props.extra.setQuantity(event.target.value ? parseInt(event.target.value) : undefined)
          }
        />
      </TableCell>
      <TableCell align="right" className={styles.cell}>
        {props.extra.totalPriceFormatted}
      </TableCell>
    </TableRow>
  );
});

const filterOptions = createFilterOptions({
  stringify: (option: IApplicableExtraDtoModel) => option.name,
});
