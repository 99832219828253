import React, { useState } from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { TextField } from 'views/components/forms';

interface IDeliveredDialogProps {
  onClose(): void;
  onSave: (deliveryDate: string) => void;
}

export const DeliveredDialog: React.FC<IDeliveredDialogProps> = props => {
  const [deliveryDate, setDeliveryDate] = useState(null as null | string);

  return (
    <BasicSubmitCancelDialog
      title="Vehicle Delivered"
      formik={{
        initialValues: {},
        onSubmit: () => {
          if (!deliveryDate) return;
          props.onSave(deliveryDate);
        },
      }}
      onClose={() => props.onClose()}
      content={
        <div>
          <p>
            Please provide a delivery date. Further updates to this
            contract cannot be made.
          </p>
          <TextField
            name="deliveryDate"
            label="Delivery Date"
            type="date"
            shrink={true}
            value={deliveryDate ?? ''}
            onChange={event => setDeliveryDate(event.target.value)}
          />
        </div>
      }
    />
  );
};
