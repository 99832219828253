import React, { useState } from 'react';
import { useEditQuote } from 'hooks/useEditQuote';
import { useHistory } from 'react-router-dom';
import { useStore } from 'views/hooks';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { ActionMenu, ActionMenuItem } from 'views/components/ActionMenu';
import { Button } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { CustomerSection } from 'views/routes/quotes/components/CustomerSection';
import { TradeInSection } from 'views/routes/quotes/components/TradeInSection';
import { CancelledWarning } from './components/CancelledWarning';
import { DealershipSection } from './components/DealershipSection';
import { DepositSection } from './components/DepositSection';
import { ExtrasSection } from './components/ExtrasSection';
import { ManagerApprovalSection } from './components/ManagerApprovalSection';
import { QuoteActions } from './components/QuoteActions';
import { QuoteHistoryDialog } from './components/QuoteHistoryDialog';
import { QuoteStatus } from './components/QuoteStatus';
import { SearchCustomerSection } from './components/SearchCustomerSection';
import { SearchVehicleSection } from './components/SearchVehicleSection';
import { SummarySection } from './components/SummarySection';
import { SyncVibeVehicleDialog } from './components/SyncVibeVehicleDialog';
import { VehicleSection } from './components/VehicleSection';
import styles from './Quote.module.scss';

export const Quote: React.FC = observer(function Quote() {
  const editQuoteModel = useEditQuote();
  const { security, vehicleSelection } = useStore();
  const history = useHistory();
  const quote = editQuoteModel.temporaryQuote;
  const [openQuoteHistory, setOpenQuoteHistory] = useState(false);
  const contractFeatureEnabled = security.currentUser.contractFeatureEnabled;

  const confirmVehicle = async () => {
    const id = await editQuoteModel.addQuote();
    if (id) history.replace(`/quotes/${id}`);
  };

  const subTitle = quote && (
    <div className={styles.subtitle}>
      <QuoteStatus className={styles.status} statusCode={quote.statusCode} />
      {editQuoteModel.associatedContract && (
        <a href={`/contracts/${editQuoteModel.associatedContract.id}`}>
          {editQuoteModel.associatedContract.numberAndVersion}
        </a>
      )}
    </div>
  );

  const audit = quote && (
    <span>
      Updated: <span>{quote.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED)}</span>
    </span>
  );

  const newQuoteFromLms = (): boolean => {
    return editQuoteModel.isNewQuote && !!quote.customer.contactId;
  };

  const vehicleIsEmpty = (): boolean => quote.vehicle.isEmpty;
  const customerIsEmpty = (): boolean => quote.customer.isEmpty;

  const showSearchVehicleSection = (): boolean => {
    return !editQuoteModel.isNewQuote && !customerIsEmpty() && !vehicleSelection.enterVehicleManually && vehicleIsEmpty();
  };

  const showSearchCustomerSection = (): boolean => {
    return  customerIsEmpty();
  };

  const showVehicleSection = (): boolean => {
    return !editQuoteModel.isNewQuote && !customerIsEmpty() && (vehicleSelection.enterVehicleManually || !vehicleIsEmpty());
  };

  const showCustomerSection = (): boolean => {
    return  !customerIsEmpty();
  };

  const cancelEditing = () => {
    editQuoteModel.cancelEditingQuote();
    editQuoteModel.setVehicleConfirmation(!editQuoteModel.temporaryQuote.vehicle.isEmpty);
  };

  const menu = contractFeatureEnabled ? (
    <ActionMenu>
      {{
        items: [
          <ActionMenuItem
            title="Quote History"
            subtext="View Previous Versions"
            onClick={() => setOpenQuoteHistory(true)}
          />,
        ],
      }}
    </ActionMenu>
  ) : (
    <></>
  );

  return (
    <>
      {!editQuoteModel.failedToLoadQuote && !editQuoteModel.vehicleNotFound &&(
        <PageLayout
          title={editQuoteModel.title}
          subtitle={subTitle}
          parent={['All Quotes', '/home/quotes']}
          menu={menu}
          audit={audit}>
          <LoadingPane
            isLoading={
              editQuoteModel.isLoadingQuote ||
              editQuoteModel.isLoadingCustomerDetails
            }>
            <div className={styles.content}>
              <CancelledWarning />
              <DealershipSection />
              {showCustomerSection() && (<CustomerSection />)}
              {showSearchCustomerSection() && (
                <SearchCustomerSection
                  onCancelEditing={cancelEditing}
                  onBeforeSearch={editQuoteModel.clearCustomer}
                  onCustomerFound={editQuoteModel.setCustomer}
                />
              )}
              {showSearchVehicleSection() && (
                <SearchVehicleSection
                  onCancelEditing={cancelEditing}
                  onBeforeSearch={editQuoteModel.clearVehicle}
                  onVehicleFound={editQuoteModel.setVehicle}
                  allowManualEntry={false}
                />
              )}
              {showVehicleSection() && <VehicleSection />}
              {showVehicleSection() && <ExtrasSection />}
              {!editQuoteModel.isNewQuote && editQuoteModel.vehicleIsConfirmed && (
                <TradeInSection />
              )}
              {!editQuoteModel.isNewQuote && editQuoteModel.vehicleIsConfirmed && (
                <DepositSection />
              )}
              {!editQuoteModel.isNewQuote && editQuoteModel.vehicleIsConfirmed && (
                <SummarySection />
              )}
              {quote.needManagerApproval && !quote.canBeApprovedBy(security.currentUser) && (
                <ManagerApprovalSection />
              )}
              {editQuoteModel.vehicleOutOfSyncDialogModel && <SyncVibeVehicleDialog />}
              {newQuoteFromLms() &&
                (!vehicleIsEmpty() || vehicleSelection.enterVehicleManually) && (
                  <Button onClick={confirmVehicle}>Confirm Vehicle</Button>
                )}
              {!editQuoteModel.isNewQuote && <QuoteActions />}
            </div>
          </LoadingPane>
        </PageLayout>
      )}
      {editQuoteModel.vehicleNotFound && (
          <PageLayout title="Quote" parent={['All Quotes', '/home/quotes']}>
            <p>Vehicle is no longer available in VIBE</p>
          </PageLayout>
      )}
      {editQuoteModel.failedToLoadQuote && (
        <PageLayout title="Quote" parent={['All Quotes', '/home/quotes']}>
          <p>The server has experienced an error. Please contact your system administrator.</p>
        </PageLayout>
      )}
      {openQuoteHistory && <QuoteHistoryDialog onClose={() => setOpenQuoteHistory(false)} />}
    </>
  );
});
