import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { Instance, types } from 'mobx-state-tree';
import { OrderVersionDtoModel } from 'api/models/Domain/Queries/Order/GetOrderVersionsQuery/OrderVersionDtoModel';

export const OrderVersionModel = types.model('OrderVersionModel', {
  ...OrderVersionDtoModel.properties,
  lastModifiedAt: localDateTimeType,
});

export interface IOrderVersionModel extends Instance<typeof OrderVersionModel> {}
