import { allPaymentType, paymentTypeDescription } from 'api/enums/PaymentType';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, SelectField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { QuoteSection } from 'views/routes/quotes/components/QuoteSection';
import styles from './DepositSection.module.scss';

export const DepositSection: React.FC = observer(function TradeInSection() {
  const { editQuoteModel,security } = useStore();
  const quote = editQuoteModel.temporaryQuote;
  const deposit = quote.deposit;
  const isEmpty = !quote || quote.deposit.isEmpty;
  const sectionTitle =
    isEmpty || !quote?.deposit.paymentType
      ? 'Deposit'
      : `Deposit: ${paymentTypeDescription(quote?.deposit.paymentType)}`;

  const save = async() => {
    await editQuoteModel.updateDeposit();
  };

  return (
    <QuoteSection
      sectionTitle={sectionTitle}
      sectionSummary={
        isEmpty ? undefined : `Deposit Amount: ${quote?.deposit.amountFormatted || '<not set>'}`
      }
      isFormActionAllowed={editQuoteModel.isEditable && !editQuoteModel.hasAssociatedContract && 
        !security.currentUser.isAftermarketPerson}
      expandedDefault={false}
      canSave={!editQuoteModel.isNewQuote && !deposit.validationError}
      canCancel={true}
      onSubmit={save}
      onCancel={editQuoteModel.cancelEditingQuote}
      onClear={editQuoteModel.resetDeposit}
      >
      {({ editable }) => (
        <div className={styles.fields}>
          <SelectField
            name="paymentType"
            className={styles.type}
            label="Payment Type"
            options={allPaymentType.map(t => ({ label: t.description, value: t.value.toString() }))}
            readonly={!editable}
            value={deposit.paymentType?.toString() ?? ''}
            onChange={(event => deposit.updatePaymentType(event.target.value as string))}
          />
          <CurrencyTextField
            id="quote-deposit-amount"
            className={styles.amount}
            required={true}
            name="amount"
            label="Amount"
            readonly={!editable}
            value={deposit.amount ?? ''}
            onChange={(event) => event.target.value ? deposit.updateAmount(parseFloat(event.target.value)) : deposit.updateAmount(undefined) }
            validationError={deposit.validationError}
          />
        </div>
      )}
    </QuoteSection>
  );
});
