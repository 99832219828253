import { AsAuCurrency } from 'infrastructure/formatUtils';
import { observer } from 'mobx-react-lite';
import React, { useState } from "react";
import { CurrencyTextField } from "./CurrencyTextField";

const ENTER_KEY = "Enter";

interface IEditableCurrencyLabelProps {
  label: string;
  name: string;
  initialValue?: number;
  isEditable?:boolean;
  finishEditing: (value?: string) => void | Promise<void>;
}

export const EditableCurrencyLabel: React.FC<IEditableCurrencyLabelProps> = observer(({
  label,
  name,
  initialValue,
  isEditable,
  finishEditing,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue?.toString());
  const focusFieldRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setValue(initialValue?.toString());
  }, [initialValue]);

  React.useEffect(() => {
    isEditing && focusFieldRef.current && focusFieldRef.current.focus();
  }, [isEditing]);

  const valueHasChanged = () => {
    return value !== initialValue?.toString();
  }

  const handleFocus = () => {
    if (isEditing && valueHasChanged()) {
      finishEditing(value);
    }
    handleEditState();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY) {
      handleFocus();
    }
  };

  const handleEditState = () => {
    setEditing(prev => !prev);
  };

  if (isEditing && isEditable) {
    return (
      <CurrencyTextField
        id="quote-customer-offer"
        name={name}
        label={label}
        value={value ?? ''}
        onChange={(event) => setValue(event.target.value as string)}
        onBlur={handleFocus}
        onKeyDown={handleKeyDown}
        inputRef={focusFieldRef}
      />
    );
  }

  return <span onClick={handleFocus}>{AsAuCurrency(initialValue)}</span>;
});
