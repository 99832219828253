import { OrderDtoModel as OrderListItemDtoModel } from 'api/models/Domain/Queries/Order/GetOrdersQuery/OrderDtoModel';
import { getAjax } from 'domain/store/RootStoreModel';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { observable } from 'mobx';
import { cast, flow, Instance, types } from 'mobx-state-tree';

type IOrdersDto = Domain.Queries.Order.GetOrdersQuery.IOrdersDto;

const OrderListItemModel = types
  .model('OrderListItemModel', {
    ...OrderListItemDtoModel.properties,
    lastModifiedAt: localDateTimeType,
  })
  .views(self => ({
  }));
interface ILoadingOrderProcess {
  key: string;
  aborter: AbortController;
}

interface ILoadingOrdersProcess {
  filter: string;
  aborter: AbortController;
}

export const OrdersRepo = types
  .model('OrdersRepo', {
    orders: types.array(OrderListItemModel),
    moreRecordsExist: false,
    filter: ''
  })
  .extend(self => {
    const localState = observable({
      orderLoading: null as ILoadingOrderProcess | null,
      ordersLoading: null as ILoadingOrdersProcess | null,
    });

    function* loadOrders(filter: string = '') {
      if (localState.ordersLoading?.filter === self.filter) {
        return;
      }
      self.filter = filter;
      localState.ordersLoading?.aborter.abort();
      try {
        localState.ordersLoading = {
          filter: self.filter,
          aborter: new AbortController()
        };
        const dto: IOrdersDto = yield getAjax(self)
          .get(`/api/orders`, {
            searchParams: { filter: self.filter },
            signal: localState.ordersLoading.aborter.signal,
          })
          .json();
        self.orders = cast(dto.orders);
        self.moreRecordsExist = dto.moreRecordsExist;
      } finally {
        localState.ordersLoading = null;
      }
    }

    return {
      views: {
        get isLoadingOrders(): boolean {
          return !!localState.ordersLoading;
        },
      },
      actions: {
        loadOrders: flow(loadOrders)
      },
    };
  });

export interface IOrdersRepo extends Instance<typeof OrdersRepo> { }
