import { flow, types } from 'mobx-state-tree';
import { HTTPError } from 'ky';
import { observable } from 'mobx';
import { getAjax } from './RootStoreModel';
import { CustomerDtoModel, ICustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';

interface ILoadingProgress {
  id?: string;
  aborter: AbortController;
}

export const CustomerSelectionStore = types
  .model('CustomerSelectionStore', {
    notFound: types.maybe(types.boolean),
    customer: types.maybe(CustomerDtoModel)
  })
  .extend(self => {
    const localState = observable({
      searchingCustomer: null as ILoadingProgress | null
    });


    function* searchCustomer(dealerId: string, leadId: string) {
      if (localState.searchingCustomer) return;

      localState.searchingCustomer = { id: leadId, aborter: new AbortController() };

      try {
        const dto: ICustomerDtoModel = yield fetchCustomer(dealerId, leadId);
        if (dto) {
          self.notFound = false;
          return dto;
        }
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          self.notFound = true;
        } else {
          throw error;
        }
      } finally {
        localState.searchingCustomer = null;
      }
    }

    const fetchCustomer = async (dealerId: string, leadId: string): Promise<ICustomerDtoModel> => {
      return await getAjax(self)
        .get(`/api/customers/${dealerId}?leadId=${leadId}`)
        .json();
    };

    function resetCustomers() {
      self.customer = undefined;
    }

    function reset() {
      self.notFound = undefined;
    }

    return {
      views: {
        get isSearchingCustomer(): boolean {
          return !!localState.searchingCustomer;
        }
      },
      actions: {
        searchCustomer: flow(searchCustomer),
        reset: reset,
        resetCustomers: resetCustomers
      },
    };
  });
