import { AppBar, Container, Toolbar } from '@material-ui/core';
import logo from 'images/Apollo_WORD_WHITE.svg';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Shell.module.scss';
import { UserMenu } from './UserMenu';

export const Shell: React.FC = observer(function Shell({ children }) {
  return (
    <div className={styles.shell}>
      <AppBar position="static" color="secondary" role="banner">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="logo" />
            </Link>
            <span className={styles.title}>Retail Operations</span>
            <UserMenu />
          </Toolbar>
        </Container>
      </AppBar>
      <main data-testid="shell-main" className={styles.main}>
        <Container maxWidth="lg">{children}</Container>
      </main>
    </div>
  );
});
