import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface ICreateOrderDialogProps {
  onClose(): void;
}

export const CreateOrderDialog: React.FC<ICreateOrderDialogProps> = props => {
  const { editContractModal } = useStore();
  const history = useHistory();

  const createOrderFromContract = async () => {
    if (!editContractModal) return;
    const orderId = await editContractModal.createOrderFromContract();
    history.push(`/orders/${orderId}`);
  };

  return(
    <BasicSubmitCancelDialog
      title="Create Order"
      formik={{
        initialValues: {},
        onSubmit: createOrderFromContract,
      }}
      onClose={() => props.onClose()}
      content={
        <p>
          Are you sure you want to create an Order?
        </p>
      }
    />
  );
};