export enum OrderType {
    Stock = 0,
    Customer = 1
}
export function orderTypeDescription(value: OrderType) {
    switch (value) {
        case OrderType.Stock:
            return `Stock`;
        case OrderType.Customer:
            return `Customer`;
    }
}
export interface IOrderTypeDescriptor { value: OrderType; name: string; description: string; }
export var allOrderType: IOrderTypeDescriptor[] = [
    { value: OrderType.Stock, name: `Stock`, description: `Stock` },
    { value: OrderType.Customer, name: `Customer`, description: `Customer` }
];

export function getOrderTypeDescriptor(value: OrderType) {
    switch (value) {
        case OrderType.Stock:
            return { value: OrderType.Stock, name: `Stock`, description: `Stock` };
        case OrderType.Customer:
            return { value: OrderType.Customer, name: `Customer`, description: `Customer` };
    }
}
