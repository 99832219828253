import { types, Instance } from 'mobx-state-tree';
import { RoleLocationDtoModel as DomainDtosRoleLocationDtoModel } from './RoleLocationDtoModel'

export const SalesManagerDtoModel = types.model('SalesManagerDtoModel', {
  email: types.string,
  id: types.identifier,
  isExecutiveManager: types.boolean,
  isNationalSalesManager: types.boolean,
  isSalesManager: types.boolean,
  managingLocations: types.array(types.string),
  name: types.string,
  phoneNumber: types.string,
  roleLevel: types.integer,
  roleLocations: types.array(DomainDtosRoleLocationDtoModel),
});

export interface ISalesManagerDtoModel extends Instance<typeof SalesManagerDtoModel> {}
