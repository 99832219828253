import React from 'react';
import { ConfirmationDialog } from 'views/components/forms/Dialog';
import { observer } from 'mobx-react-lite';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import styles from './HistoryDialog.module.scss';

interface IHistoryDialogProps {
  title: string;
  items: IHistoryItem[];
  onClose(): void;
}

interface IHistoryItem {
  number: React.ReactNode;
  status: React.ReactNode;
  lastModifiedBy: string;
  lastModifiedAt: string;
}

export const HistoryDialog: React.FC<IHistoryDialogProps> = observer(props => {
  return (
    <ConfirmationDialog
      dialogTitle={props.title}
      open={true}
      okLabel="Close"
      onOk={() => props.onClose()}
      dialogContent={
        <div>
          <Table>
            <TableHead className={styles.tablehead}>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell>Last Updated By</TableCell>
                <TableCell>Last Updated Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.items.map((v, i) => (
                <TableRow key={i}>
                  <TableCell>{v.number}</TableCell>
                  <TableCell>{v.status}</TableCell>
                  <TableCell>{v.lastModifiedBy}</TableCell>
                  <TableCell>{v.lastModifiedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      }
    />
  );
});
