import { types, Instance } from 'mobx-state-tree';

export const OrderExtraDtoModel = types.model('OrderExtraDtoModel', {
  code: types.string,
  description: types.string,
  parentCode: types.string,
  parentDescription: types.string,
  parentType: types.maybe(types.frozen<Domain.Aggregates.Order.Entities.ExtrasType>()),
  price: types.number,
  quantity: types.integer,
});

export interface IOrderExtraDtoModel extends Instance<typeof OrderExtraDtoModel> {}
