import {
  Button as MuiButton, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { PropsType } from 'infrastructure/typeUtils';
import React from 'react';
import { Button, ButtonWithProgress } from 'views/components/forms';
import { useComponentActive } from 'views/hooks';
import styles from './Dialog.module.scss';

interface IDialogSubmitCancelActionsProps {
  onCancel: () => void;
  okLabel?: string;
  submitInProgress: boolean;
}

const DialogSubmitCancelActions: React.FC<IDialogSubmitCancelActionsProps> = function({
  onCancel,
  okLabel,
  submitInProgress,
}) {
  return (
    <>
      <ButtonWithProgress
        type="submit"
        color="primary"
        variant="contained"
        inProgress={submitInProgress}>
        {okLabel || 'Ok'}
      </ButtonWithProgress>
      <MuiButton onClick={onCancel} color="primary">
        Cancel
      </MuiButton>
    </>
  );
};

interface IBasicSubmitCancelDialogProps<TFormValues extends FormikValues> {
  title: string;
  content: React.ReactNode;
  okLabel?: string;
  closeAfterSubmit?: boolean;
  formik: FormikConfig<TFormValues>;
  readonly?: boolean;
  onClose: () => void | Promise<void>;
  additionalActions?: React.ReactNode;
  onValidate?: () => boolean
}

export function BasicSubmitCancelDialog<TFormValues extends FormikValues>({
  title,
  content,
  okLabel,
  closeAfterSubmit,
  readonly,
  formik,
  onClose,
  additionalActions,
  onValidate
}: IBasicSubmitCancelDialogProps<TFormValues>) {
  const componentActive = useComponentActive();
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit: FormikConfig<TFormValues>['onSubmit'] = (vs, h) => {
    if (onValidate && !onValidate()) return;
    setSubmitting(true);
    const result = formik.onSubmit(vs, h);
    if (result) {
      result.finally(() => {
        if (componentActive.current) {
          setSubmitting(false);
          onClose();
        }
      });
    } else {
      setSubmitting(false);
      if(closeAfterSubmit){onClose()};
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <Formik<TFormValues> {...formik} onSubmit={handleSubmit}>
        <Form noValidate autoComplete="off">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          {readonly ? (
            <DialogActions>
              <MuiButton color="primary" variant="contained" onClick={onClose}>
                Ok
              </MuiButton>
            </DialogActions>
          ) : (
            <DialogActions className={styles.actions} disableSpacing>
              <div className={styles['additional-actions']}>{additionalActions}</div>
              <div className={styles['standard-actions']}>
                <DialogSubmitCancelActions
                  onCancel={onClose}
                  okLabel={okLabel}
                  submitInProgress={submitting}
                />
              </div>
            </DialogActions>
          )}
        </Form>
      </Formik>
    </Dialog>
  );
}

interface IFormDialogButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function FormDialogButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IFormDialogButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MuiButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </MuiButton>
      {open && (
        <BasicSubmitCancelDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface IFormDialogIconButtonProps<TFormValues extends FormikValues>
  extends Omit<PropsType<typeof IconButton>, 'onClick'> {
  dialogTitle: string;
  dialogContent: React.ReactNode;
  okLabel?: string;
  readonly?: boolean;
  suppressDialog?: boolean;
  formik: FormikConfig<TFormValues>;
}

export function FormDialogIconButton<TFormValues extends FormikValues>({
  dialogTitle,
  dialogContent,
  okLabel,
  readonly,
  suppressDialog,
  formik,
  children,
  ...rest
}: IFormDialogIconButtonProps<TFormValues>) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton {...rest} onClick={() => !suppressDialog && setOpen(true)}>
        {children}
      </IconButton>
      {open && (
        <BasicSubmitCancelDialog
          title={dialogTitle}
          content={dialogContent}
          okLabel={okLabel}
          readonly={readonly}
          formik={formik}
          onClose={handleClose}
        />
      )}
    </>
  );
}

interface IConfirmationDialogProps {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  okLabel?: string;
  open: boolean;
  onOk: () => void | Promise<unknown>;
  onCancel?: () => void;
}

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = function({
  dialogTitle,
  dialogContent,
  okLabel,
  open,
  onOk,
  onCancel,
}) {
  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onEscapeKeyDown={onCancel}>
      <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions className={styles['confirmation-dialog-actions']} disableSpacing>
        <Button onClick={onOk} color="primary" variant="contained">
          {okLabel || 'Ok'}
        </Button>
        {onCancel && <MuiButton onClick={onCancel} color="primary">
          Cancel
        </MuiButton>}
      </DialogActions>
    </Dialog>
  );
};

interface IConfirmationDialogButtonProps extends Omit<PropsType<typeof MuiButton>, 'onClick'> {
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  okLabel?: string;
  onOk: () => void | Promise<void>;
}

export const ConfirmationDialogButton: React.FC<IConfirmationDialogButtonProps> = function({
  dialogTitle,
  dialogContent,
  okLabel,
  onOk,
  children,
  ...rest
}) {
  const componentActive = useComponentActive();
  const [open, setOpen] = React.useState(false);

  const handleOK = () => {
    const result = onOk();
    if (result) {
      return result.finally(() => componentActive.current && setOpen(false));
    } else {
      setOpen(false);
    }
  };
  const handleCancel = () => setOpen(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} {...rest}>
        {children}
      </Button>
      {open && (
        <ConfirmationDialog
          dialogTitle={dialogTitle}
          dialogContent={dialogContent}
          okLabel={okLabel}
          open={open}
          onOk={handleOK}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};
