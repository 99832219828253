import { VibeSalesPeopleRepo } from './repos/VibeSalesPeopleRepo';
import { Bus } from 'domain/store/bus/Bus';
import { globalErrorHandlerMiddleware } from 'domain/store/middlewares/globalErrorHandler';
import { ContractsRepo } from 'domain/store/repos/ContractsRepo';
import { OrdersRepo } from 'domain/store/repos/OrdersRepo';
import { QuotesRepo } from 'domain/store/repos/QuotesRepo';
import { ISecurityModel, SecurityModel } from 'domain/store/singletons/SecurityModel';
import ky from 'ky';
import { DateTime } from 'luxon';
import {
  addMiddleware,
  getEnv as origGetEnv,
  getRoot as origGetRoot,
  Instance,
  IStateTreeNode,
  types
} from 'mobx-state-tree';
import { CustomerSelectionStore } from './CustomerSelectionStore';
import { EditContractModel } from './EditContractModel';
import { EditOrderModel } from './EditOrderModel';
import { EditQuoteModel } from './EditQuoteModel';
import { QuoteApprovalStore } from './QuoteApprovalStore';
import { QuoteApprovalsRepo } from './repos/QuoteApprovalsRepo';
import { UsersRepo } from './repos/UsersRepo';
import { INotificationsModel, NotificationsModel } from './singletons/NotificationsModel';
import { VehicleSelectionStore } from './VehicleSelectionStore';

export const RootStoreModel = types
  .model('rootStoreModel', {
    security: types.optional(SecurityModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    quotesRepo: types.optional(QuotesRepo, {}),
    quoteApprovalsRepo: types.optional(QuoteApprovalsRepo, {}),
    editQuoteModel: types.optional(EditQuoteModel, {
      editCounter: 0,
      vehicleIsConfirmed: false,
      customerIsConfirmed: false
    }),
    editOrderModel: types.optional(EditOrderModel, {
      vehicleIsConfirmed: false,
    }),
    quoteApprovalStore: types.optional(QuoteApprovalStore, {}),
    contractsRepo: types.optional(ContractsRepo, {}),
    editContractModal: types.optional(EditContractModel, {}),
    usersRepo: types.optional(UsersRepo, {}),
    vibeSalesPeopleRepo: types.optional(VibeSalesPeopleRepo,{}),
    vehicleSelection: types.optional(VehicleSelectionStore, {}),
    customerSelection: types.optional(CustomerSelectionStore, {}),
    ordersRepo: types.optional(OrdersRepo, {}),
  })
  .actions(self => {
    const dispose = addMiddleware(self, globalErrorHandlerMiddleware);
    return { beforeDestroy: dispose };
  })
  .actions(self => {
    function formatDateInCurrentUserLocale(offsetDateTime: DateTime) {
      return offsetDateTime.setLocale(self.security.currentUser.localeCode).toLocaleString();
    }

    return { formatDateInCurrentUserLocale };
  });

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

export function getRoot(model: IStateTreeNode) {
  const root = origGetRoot<IRootStoreModel>(model);

  // Explicitly type each item in the returned "root" object to help prevent
  // typescript recursive type errors
  const notifications: INotificationsModel = root.notifications;
  const security: ISecurityModel = root.security;

  return {
    notifications,
    security,
  };
}

interface IStoreEnvironment {
  ajax: typeof ky;
  bus: Bus;
}

function getEnv(model: IStateTreeNode) {
  return origGetEnv<IStoreEnvironment>(model);
}

export function getAjax(model: IStateTreeNode): typeof ky {
  return getEnv(model).ajax;
}

export function getBus(model: IStateTreeNode): Bus {
  return getEnv(model).bus;
}

export function getDefaultStore(): IRootStoreModel {
  const storeEnv: IStoreEnvironment = {
    ajax: ky.create({
      retry: { limit: 1 },
      timeout: 30000,
      headers: { 'Client-Tz-Offset': `${new Date().getTimezoneOffset() / -60}` },
    }),
    bus: new Bus(),
  };
  const store = RootStoreModel.create({}, storeEnv);
  return store;
}
