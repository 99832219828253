import { cast, flow, types } from 'mobx-state-tree';
import { ISearchVehicleDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehicleDtoModel';
import { HTTPError } from 'ky';
import { observable } from 'mobx';
import { getAjax } from './RootStoreModel';
import { AdvanceSearchVehicleOptionsDtoModel } from 'api/models/Domain/Queries/Vehicle/AdvanceSearchVehicleOptionsQuery/AdvanceSearchVehicleOptionsDtoModel';
import { VehicleDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehiclesQuery/VehicleDtoModel';

interface ILoadingProcess {
  id?: string;
  aborter: AbortController;
}

export const VehicleSelectionStore = types
  .model('VehicleSelectionStore', {
    vehicleFoundInSearch: types.maybe(types.boolean),
    vehicleNotFound: types.maybe(types.boolean),
    enterVehicleManually: types.maybe(types.boolean),
    options: types.maybe(AdvanceSearchVehicleOptionsDtoModel),
    vehicles: types.array(VehicleDtoModel),
  })
  .extend(self => {
    const localState = observable({
      searchingVehicle: null as ILoadingProcess | null,

      loading: null as ILoadingProcess | null,
      searching: null as ILoadingProcess | null,
    });

    function* searchVehicle(assetNo: string) {
      if (localState.searchingVehicle) {
        return;
      }

      try {
        localState.searchingVehicle = { id: assetNo, aborter: new AbortController() };
        const dto: ISearchVehicleDtoModel = yield fetchVehicle(assetNo);
        if (dto) {
          self.vehicleFoundInSearch = true;
          return dto;
        }
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          self.vehicleNotFound = true;
        } else {
          throw error;
        }
      } finally {
        localState.searchingVehicle = null;
      }
    }

    const fetchVehicle = async (assetNo: string): Promise<ISearchVehicleDtoModel> => {
      return await getAjax(self)
        .get(`/api/vehicles/${assetNo}`)
        .json();
    };

    function* getOptions(newBuildOnly: boolean = false) {
      if (self.options) return;
      if (localState.loading) return;

      try {
        localState.loading = { aborter: new AbortController() };
        self.options = yield getAjax(self)
          .get(`/api/advanceSearchOptions${newBuildOnly ? '?newBuildOnly=true' : ''}`)
          .json();
      } finally {
        localState.loading = null;
      }
    }

    function* search(query: {
      condition: string;
      type?: string;
      make?: string;
      model?: string;
      series?: string;
    }) {
      if (localState.searching) return;
      const tokens = Object.getOwnPropertyNames(query).map(
        key => `${key}=${(query as any)[key] ?? ''}`
      );
      const queryString = tokens.join('&');

      try {
        localState.searching = { aborter: new AbortController() };
        const response = yield getAjax(self)
          .get(`/api/vehicles?${queryString}`)
          .json();
        self.vehicles = cast(response);
      } finally {
        localState.searching = null;
      }
    }

    function resetVehicles() {
      self.vehicles = cast([]);
    }

    function setEnterVehicleManually() {
      self.enterVehicleManually = true;
    }

    function reset() {
      self.vehicleNotFound = undefined;
      self.vehicleFoundInSearch = undefined;
      self.enterVehicleManually = false;
      self.options = undefined;
    }

    return {
      views: {
        get isSearchingVehicle(): boolean {
          return !!localState.searchingVehicle;
        },
        get isLoading(): boolean {
          return !!localState.loading;
        },
        get isSearching(): boolean {
          return !!localState.searching;
        },
      },
      actions: {
        searchVehicle: flow(searchVehicle),
        setEnterVehicleManually: setEnterVehicleManually,
        reset: reset,
        getOptions: flow(getOptions),
        search: flow(search),
        resetVehicles: resetVehicles,
      },
    };
  });
