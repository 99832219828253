import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IDeleteDraftDialogProps {
  onClose(): void;
}

export const DeleteDraftDialog: React.FC<IDeleteDraftDialogProps> = props => {
  const { editOrderModel } = useStore();
  const history = useHistory();

  const deleteDraft = async () => {
    if (!editOrderModel) return;
    await editOrderModel.deleteDraft();
    history.goBack();
  };

  return (
    <BasicSubmitCancelDialog
      title="Delete Draft Order"
      formik={{
        initialValues: {},
        onSubmit: deleteDraft,
      }}
      onClose={() => props.onClose()}
      content={
        <p>
          Are you sure you want to delete this draft order?
          {editOrderModel.order.version > 1 && (
            <>
              <br />
              The previous version will be reinstated.
            </>
          )}
        </p>
      }
    />
  );
};
