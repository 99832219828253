import { VehicleCondition } from 'api/enums/VehicleCondition';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, RadioField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { QuoteSection } from 'views/routes/quotes/components/QuoteSection';
import styles from './VehicleSection.module.scss';

export const VehicleSection: React.FC = observer(function VehicleSection() {
  const { editQuoteModel, vehicleSelection ,security} = useStore();

  const quote = editQuoteModel.temporaryQuote;
  const vehicle = quote.vehicle;
  const isEmpty = vehicle.isEmpty;
  const sectionTitle = `Vehicle${isEmpty ? '' : ': ' + vehicle.description}`;
  const isVibeVehicle = !!vehicle.assetNo;
  const editingByDefault = isEmpty
    || editQuoteModel.isNewQuote
    || (vehicleSelection.vehicleFoundInSearch || false)
    || (vehicleSelection.enterVehicleManually || false);
  const [isEditing, setIsEditing] = React.useState<boolean>(editingByDefault);

  const vehicleChanged = () => {
    const oldVehicle = editQuoteModel.quote.vehicle;
    const newVehicle = editQuoteModel.temporaryQuote.vehicle;
    return oldVehicle.assetNo !== newVehicle.assetNo
      || oldVehicle.registration !== newVehicle.registration;
  }

  const save = async () => {
    const vehicleWasChanged = vehicleChanged();
    await editQuoteModel.updateVehicle();
    if (vehicleWasChanged) {
      await editQuoteModel.alertIfQuotesExistsForVehicle();
    }
    setIsEditing(false);
    editQuoteModel.cancelEditingQuote();
    editQuoteModel.setVehicleConfirmation(true);
  };

  const edit = () => {
    setIsEditing(true);
    editQuoteModel.startEditingQuote();
    editQuoteModel.setVehicleConfirmation(false);
  }

  const cancelEditing = () => {
    setIsEditing(false);
    editQuoteModel.cancelEditingQuote();
    editQuoteModel.setVehicleConfirmation(!editQuoteModel.temporaryQuote.vehicle.isEmpty);
  }

  const onPriceChange = (value?: number) => {
    if (vehicle.condition === VehicleCondition.Used) {
      vehicle.updateRecommendedRetailPrice(value);
      vehicle.updatePrice(value);
    } else {
      if (!isVibeVehicle) {
        vehicle.updateRecommendedRetailPrice(value);
      }
    }
  }
  
  const onClear = () => {
    editQuoteModel.resetVehicle();
    vehicleSelection.reset();
  }

  return (
    <QuoteSection
      sectionTitle={sectionTitle}
      sectionSummary={
        isEmpty ? undefined : `Vehicle Price: ${AsAuCurrency(vehicle.displayPrice) || '<not set>'}`
      }
      isFormActionAllowed={editQuoteModel.isEditable && !security.currentUser.isAftermarketPerson}
      expandedDefault={isEditing}
      editableDefault={isEditing}
      clearBtnText="Search Again"
      canSave={!editQuoteModel.isNewQuote}
      saveBtnText={"Confirm"}
      canCancel={!editQuoteModel.isNewQuote}
      onClear={editQuoteModel.hasAssociatedContract ? undefined : onClear}
      onSubmit={save}
      onCancel={cancelEditing}
      onEditClick={edit}
      disableEditing={editQuoteModel.editCounter > 0}
    >
      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            <RadioField
              inputRef={focusFieldRef}
              id="quote-vehicle-condition"
              className={styles.condition}
              name="condition"
              label="Condition"
              readonly={!editable || isVibeVehicle}
              direction="row"
              options={[
                { label: 'New', value: VehicleCondition.New.toString() },
                { label: 'Used', value: VehicleCondition.Used.toString() },
                { label: 'New Build', value: VehicleCondition.NewBuild.toString() },
              ]}
              value={vehicle.condition?.toString()}
              onChange={(event) => vehicle.updateCondition(event.target.value)}
            />
            <TextField
              id="quote-vehicle-asset-no"
              className={styles.assetNo}
              name="assetNo"
              label="Asset No"
              readonly={true}
              value={vehicle.assetNo ?? ''}
            />
            <TextField
              id="quote-vehicle-make"
              className={styles.make}
              name="make"
              label="Make"
              readonly={!editable || isVibeVehicle}
              value={vehicle.make ?? ''}
              onChange={(event) => vehicle.updateMake(event.target.value)}
            />
            <TextField
              id="quote-vehicle-model"
              className={styles.model}
              name="model"
              label="Model"
              readonly={!editable || isVibeVehicle}
              value={vehicle.model ?? ''}
              onChange={(event) => vehicle.updateModel(event.target.value)}
            />
            <TextField
              id="quote-vehicle-year"
              className={styles.year}
              name="year"
              label="Year"
              readonly={!editable || isVibeVehicle}
              value={vehicle.year ?? ''}
              onChange={(event) => vehicle.updateYear(event.target.value)}
              validationError={vehicle.yearValidationError}
            />
             <TextField
              id="quote-vehicle-idle-location"
              className={styles.idleLocation}
              name="idleLocation"
              label="Idle Location"
              readonly={!editable || isVibeVehicle}
              value={vehicle.idleLocation ?? ''}
              onChange={(event) => vehicle.updateIdleLocation(event.target.value)}
            />
            <TextField
              id="quote-vehicle-registration"
              className={styles.registration}
              name="registration"
              label="Registration"
              readonly={!editable || isVibeVehicle}
              value={vehicle.registration ?? ''}
              onChange={(event) => vehicle.updateRegistration(event.target.value)}
            />
            <TextField
              id="quote-vehicle-floor-plan"
              className={styles.floorPlan}
              name="floorPlan"
              label="Floor Plan"
              readonly={!editable || isVibeVehicle}
              value={vehicle.floorPlan ?? ''}
              onChange={(event) => vehicle.updateFloorPlan(event.target.value)}
            />
            <CurrencyTextField
              id="quote-vehicle-price"
              className={styles.price}
              name="price"
              label="Price (Inc. GST)"
              readonly={!editable || (isVibeVehicle && vehicle.condition !== VehicleCondition.Used)}
              value={vehicle.displayRrp ?? ''}
              onChange={(event) => { 
                if (!editable) return;
                const value = event.target.value ? parseFloat(event.target.value) : undefined;
                onPriceChange(value);
              }}
            />
            <CurrencyTextField
              id="manufacturers-rebate"
              className={styles.manufacturersRebate}
              label="Rebate"
              name="manufacturers-rebate"
              readonly={true}
              value={Math.abs(!!vehicle.rebate ? vehicle.rebate.amount : 0) || ''}
            />
            {
              vehicle.condition !== VehicleCondition.Used &&
              <CurrencyTextField
                id="quote-vehicle-discount"
                className={styles.discount}
                name="discount"
                label="Discount"
                readonly={!editable}
                value={vehicle.displayDiscount}
                onChange={(event) => {
                  if (!editable) return;
                  vehicle.updateDiscount(event.target.value ? parseFloat(event.target.value) : undefined);
                }}
              />
            }
          </div>
        </>
      )}
    </QuoteSection>
  );
});
