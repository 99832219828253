/* eslint-disable react/jsx-no-undef */
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs/Tabs';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { AdvancedSearch } from './AdvancedSearch';
import { QuickSearch } from './QuickSearch';
import styles from './SearchVehicleSection.module.scss';

type ISearchVehicleDto = Domain.Queries.Vehicle.ISearchVehicleDto;

interface ISearchVehicleSectionProps {
  newBuildOnly?: boolean;
  allowManualEntry?: boolean;
  onCancelEditing: () => void;
  onBeforeSearch?: () => void;
  onVehicleFound: (vehicle: ISearchVehicleDto | undefined) => void;
}

export const SearchVehicleSection: React.FC<ISearchVehicleSectionProps> = observer(
  ({ allowManualEntry = true, newBuildOnly = false, ...props }) => {
    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <span className={cn(styles.title, styles.editingSection)}>Search Vehicle</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <>
            <AppBar position="static" color="default">
              <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
                <Tab label="Quick Search" />
                <Tab label="Advanced Search" />
              </Tabs>
            </AppBar>
            <div className={styles.tabBodyWrapper}>
              {selectedTab === 0 && (
                <QuickSearch
                  newBuildOnly={newBuildOnly}
                  allowManualEntry={allowManualEntry}
                  onCancelEditing={props.onCancelEditing}
                  onVehicleFound={props.onVehicleFound}
                  onBeforeSearch={props.onBeforeSearch}
                />
              )}
              {selectedTab === 1 && (
                <AdvancedSearch
                  newBuildOnly={newBuildOnly}
                  allowManualEntry={allowManualEntry}
                  onCancelEditing={props.onCancelEditing}
                  onSelect={props.onVehicleFound}
                />
              )}
            </div>
          </>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
);
