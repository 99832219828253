import { OrderStatusCode } from 'api/enums/OrderStatusCode';
import { Permission } from 'api/enums/Permission';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'views/components/forms';
import { BasicSubmitCancelDialog, ConfirmationDialog } from 'views/components/forms/Dialog';
import { useStore } from '../../../hooks';
import { DeleteDraftDialog } from './DeleteDraftDialog';
import styles from './OrderActions.module.scss';
import { OrderCancellationDialog } from './OrderCancellationDialog';
import { OrderModificationDialog } from './OrderModificationDialog';
import { PlaceOrderDialog } from './PlaceOrderDialog';
import { RejectOrderDialog } from './RejectOrderDialog';
import { DateTime } from 'luxon';

export const OrderActions: React.FC = observer(function OrderActions() {
  const { editOrderModel, security } = useStore();
  const [openPlaceOrderDialog, setPlaceOrderDialog] = useState<boolean>(false);
  const [openPromptNewVersion, setOpenPromptNewVersion] = useState(false);
  const [openModificationNotAllowedDialog, setOpenModificationNotAllowedDialog] = useState(false);
  const [openFleetSalesAdminPromptNewVersion, setFleetSalesAdminOpenPromptNewVersion] = useState(false);
  const [openCancellationDialog, setOpenCancellationDialog] = useState(false);
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
  const [openModificationReasonDialog, setOpenModificationDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);


  const history = useHistory();
  const createNewDraftOrder = async () => {
    const newOrderId = await editOrderModel.createNewDraftVersion();
    history.push(`/orders/${newOrderId}`);
  };

  const finaliseOrder = async () => {
    editOrderModel.requiresModificationReason
      ? setOpenModificationDialog(true)
      : editOrderModel.finaliseOrder();
  };

  const downloadOrderPdf = async (): Promise<void> => {
    const pdf = await editOrderModel.downloadOrderPdf(editOrderModel.order.id);
    saveAs(pdf, `${editOrderModel.order.numberAndVersion}.pdf`);
  };

  // check if currentUser's role  is a FleetSalesAdmin
  const isFleetSalesAdmin = security.currentUser.isFleetSalesAdmin; 
  const modifyHandler = (editOrderModel: { order: { hasModificationCutoffDateLapsed: any; }; },isFleetSalesAdmin: boolean) => {
         if(editOrderModel.order.hasModificationCutoffDateLapsed){
           if(isFleetSalesAdmin){
            setFleetSalesAdminOpenPromptNewVersion(true)
           }else{
            setOpenModificationNotAllowedDialog(true)
           }
         }
         else{
          setOpenPromptNewVersion(true);
         }
  }

  const cutoffDate = editOrderModel.order.modificationCutoffDate?.toJSDate();

  return (
    <>
      <div className={styles.actions}>
        {editOrderModel.order.statusCode === OrderStatusCode.Draft && (
          <Button onClick={() => setOpenDeleteDialog(true)}>Delete</Button>
        )}
        {editOrderModel.vehicleIsConfirmed && (
          <>
            {!editOrderModel.isReadOnly && (
              <>
                {editOrderModel.order.statusCode === OrderStatusCode.Draft && (
                  <Button onClick={finaliseOrder}
                  disabled={!editOrderModel.order.isValid}
                  >
                    Submit
                  </Button>
                )}
                {editOrderModel.canModify && (
                  <Button
                    onClick={() => modifyHandler(editOrderModel,isFleetSalesAdmin)}
                    // allow modification if the requestedDelliveryDate is null
                    disabled={!editOrderModel.order.isValid && !editOrderModel.order.detail.requestedDeliveryDate == null}>
                    Modify
                  </Button>
                )}
              </>
            )}
            {editOrderModel.order.statusCode === OrderStatusCode.Open &&
              security.currentUser.can(Permission.PlaceOrder) && (
                <Button onClick={() => setPlaceOrderDialog(true)}>Placed</Button>
              )}
            {editOrderModel.canBeCancelled(security.currentUser) && (
              <Button
                disabled={cutoffDate && cutoffDate < new Date()}
                onClick={() => setOpenCancellationDialog(true)}>
                Cancel
              </Button>
            )}
            {editOrderModel.order.statusCode === OrderStatusCode.AwaitingApproval &&
              security.currentUser.can(Permission.ApproveOrders) && (
                <>
                  <Button onClick={() => setOpenRejectionDialog(true)}>Reject</Button>
                  <Button onClick={editOrderModel.approveOrder}>Approve</Button>
                </>
              )}
            {editOrderModel.canGeneratePdf && (
              <Button onClick={() => downloadOrderPdf()}>Download</Button>
            )}
          </>
        )}
      </div>
      {openPromptNewVersion && (
        <BasicSubmitCancelDialog
          title="Modify Order"
          okLabel="Create new draft >"
          formik={{
            initialValues: {},
            onSubmit: createNewDraftOrder,
          }}
          onClose={() => setOpenPromptNewVersion(false)}
          content={<p>To modify this order a new draft version must be created.</p>}
        />
      )}
        {openFleetSalesAdminPromptNewVersion && (
        <BasicSubmitCancelDialog
          title="Modify Order Past Cutoff Date"
          okLabel="Create new draft >"
          formik={{
            initialValues: {},
            onSubmit: createNewDraftOrder,
          }}
          onClose={() => setFleetSalesAdminOpenPromptNewVersion(false)}
          content={<p> Order cut off date is {' '}{editOrderModel.order.modificationCutoffDate!.toLocaleString(DateTime.DATE_MED)}.{' '}Are you sure you want to modify this order?<br/>
            To modify this order a new draft version must be created.</p>}
        />
      )}

      {openModificationNotAllowedDialog && (
        <ConfirmationDialog
          dialogTitle="Cannot Modify Order"
          open={true}
          onOk={() => setOpenModificationNotAllowedDialog(false)}
          dialogContent={
            <p>
              This order cannot be modified because the cutoff date of {' '} 
              {editOrderModel.order.modificationCutoffDate!.toLocaleString(DateTime.DATE_MED)}
               {' '}
              has lapsed.
            </p>
          }
        />
      )}
      {openPlaceOrderDialog && <PlaceOrderDialog onClose={() => setPlaceOrderDialog(false)} />}
      {openCancellationDialog && (
        <OrderCancellationDialog onClose={() => setOpenCancellationDialog(false)} />
      )}
      {openRejectionDialog && <RejectOrderDialog onClose={() => setOpenRejectionDialog(false)} />}
      {openModificationReasonDialog && (
        <OrderModificationDialog onClose={() => setOpenModificationDialog(false)} />
      )}
      {openDeleteDialog && <DeleteDraftDialog onClose={() => setOpenDeleteDialog(false)} />}
    </>
  );
});
