import Alert from '@material-ui/lab/Alert';
import { OrderStatusCode } from 'api/enums/OrderStatusCode';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'views/hooks';

export const ReadOnlyWarning: React.FC = observer(function ReadOnlyWarning() {
  const { editOrderModel } = useStore();
  const order = editOrderModel.order;
  const renderWarning = [
    OrderStatusCode.Cancelled,
    OrderStatusCode.OldVersion,
    OrderStatusCode.Rejected,
  ].includes(order.statusCode);

  return editOrderModel.isReadOnly && renderWarning ? (
    <Alert severity="warning">
      {order.statusCode === OrderStatusCode.Cancelled ? (
        <>No changes can be made to this order as it has been cancelled. Reason: {order.cancellationReason}</>
      ) : order.statusCode === OrderStatusCode.OldVersion ? (
        <>No changes can be made to old versions.</>
      ) : order.statusCode === OrderStatusCode.Rejected ? (
        <>
          Modifications requested for this order have been rejected. The previous version has been
          re-instated. Please check the Order History list for the current version. Reason: {order.rejectionReason}
        </>
      ) : (
        <></>
      )}
    </Alert>
  ) : (
    <></>
  );
});
