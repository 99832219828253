import React, { useState, useEffect } from 'react';
import { useStore } from 'views/hooks';
import { allCountry, Country } from 'api/enums/Country';
import { allState, IStateDescriptor } from 'api/enums/State';
import { StatusCode } from 'api/enums/StatusCode';
import { observer } from 'mobx-react-lite';
import { SelectField, TextField } from 'views/components/forms';
import { QuoteSection } from 'views/routes/quotes/components/QuoteSection';
import { SyncLmsCustomerDialog } from '../../../components/dialogs/SyncLmsCustomerDialog';
import styles from './CustomerSection.module.scss';

export const CustomerSection: React.FC = observer(function CustomerSection() {
  const { editQuoteModel, customerSelection, security } = useStore();

  const [stateOptions, setStateOptions] = useState<IStateDescriptor[]>([]);

  const isNewQuote = editQuoteModel.isNewQuote;
  const quote = editQuoteModel.temporaryQuote;
  let customer = quote.customer;
  let surname = customer.surname ?? "";
  let leadId = quote.leadId ?? "";
  const sectionTitle = `Customer${quote ? ': ' + customer.firstName + ' ' + surname : ''} ${leadId !== '' ? '(LeadId: ' + leadId + ')' : ''}`;

  const [editingState, setEditingState] = React.useState<boolean>(false);

  useEffect(() => {
    setStateOptions(customer?.address?.country === Country.NZ ? allState.slice(8) : allState.slice(0,8));
  }, [customer]);

  const saveCustomer = async () => {
      await editQuoteModel.updateCustomer();
      setEditingState(false);
      editQuoteModel.cancelEditingQuote();
      editQuoteModel.setCustomerConfirmation(true);
  };

  const edit = () => {
    setEditingState(true);
    editQuoteModel.startEditingQuote();
    editQuoteModel.setCustomerConfirmation(false);
  }

  const cancelEditing = () => {
    setEditingState(false);
    editQuoteModel.cancelEditingQuote();
    editQuoteModel.setCustomerConfirmation(!editQuoteModel.temporaryQuote.customer.isEmpty);
  }

  const onClear = () => {
    if (editQuoteModel.hasAssociatedContract) return;
    editQuoteModel.resetCustomer();
    customerSelection.reset();
  }

  return (
    <QuoteSection
      sectionTitle={sectionTitle}
      isFormActionAllowed={editQuoteModel.isEditable && !editQuoteModel.hasAssociatedContract &&
        !security.currentUser.isAftermarketPerson}
      expandedDefault={editingState}
      editableDefault={editingState}
      onEditClick={edit}
      onValidate={() => customer.isValid}
      onSubmit={saveCustomer}
      canSave={!isNewQuote}
      saveBtnText={"Confirm"}
      canCancel={!isNewQuote}
      onCancel={cancelEditing}
      onClear={onClear}
      clearBtnText="Search Again"
    >
      {({ focusFieldRef, editable }) => (
        <>
          {editQuoteModel.isCustomerOutOfSync &&  quote.statusCode === StatusCode.Draft && (
          <SyncLmsCustomerDialog
            currentCustomer = { customer }
            lmsCustomer = { editQuoteModel.lmsCustomer! }
            syncState = { customer.getSyncState(editQuoteModel.lmsCustomer!) }
            onOk = { () => {
              editQuoteModel.setCustomer(editQuoteModel.lmsCustomer);
              editQuoteModel.updateCustomer();
              editQuoteModel.confirmSyncCustomerDialog();}}
            onCancel = { editQuoteModel.confirmSyncCustomerDialog }
          />)}
        <div className={styles.fields}>
          <TextField
            id="quote-customer-first-name"
            inputRef={focusFieldRef}
            name="first-name"
            label="First Name"
            required={true}
            readonly={!editable}
            value={customer.firstName}
            validationError={customer.firstNameValidationError()}
            onChange={(event) => customer.set('firstName', event.target.value)}
          />
          <TextField
            id="quote-customer-surname"
            required={true}
            name="surname"
            label="Surname"
            readonly={!editable}
            value={customer.surname ?? ''}
            validationError={customer.surnameValidationError()}
            onChange={(event) => customer.set('surname', event.target.value)}
          />
          <TextField
            id="quote-customer-email"
            name="email"
            label="Email"
            readonly={!editable}
            value={customer.email ?? ''}
            onChange={(event) => customer.set('email', event.target.value)}
          />
          <TextField
            id="quote-customer-phone"
            name="phone"
            label="Phone"
            readonly={!editable}
            value={customer.phone ?? ''}
            onChange={(event) => customer.set('phone', event.target.value)}
          />
          <TextField
            id="quote-customer-phone-alternate"
            name="phoneAlternate"
            label="Alternate Phone"
            readonly={!editable}
            value={customer.phoneAlternate ?? ''}
            onChange={(event) => customer.set('phoneAlternate', event.target.value)}
          />
          </div>
          <div className={styles.fields}>
              <TextField
                id="contract-customer-street-address"
                name="streetAddress"
                label="Street Address"
                readonly={!editable}
                value={customer.address?.streetAddress ?? ''}
                onChange={event => customer.set('address', {...customer.address, streetAddress: event.target.value as string})}
              />
              <TextField
                id="contract-customer-suburb"
                name="suburb"
                label="Suburb"
                readonly={!editable}
                value={customer.address?.suburb ?? ''}
                onChange={event => customer.set('address', {...customer.address, suburb: event.target.value})}
              />
              <TextField
                id="quote-customer-city"
                name="city"
                label="City"
                readonly={!editable}
                value={customer.address?.city ?? ''}
                onChange={event => customer.set('address', {...customer.address, city: event.target.value})}
              />
              <SelectField
                name="country"
                label="Country"
                options={allCountry.map(x => ({ label: x.description, value: x.value.toString() }))}
                ignoreDefaultOption={true}
                readonly={!editable}
                value={customer.address?.country?.toString() ?? ''}
                onChange={event => {
                  customer.set('address', {
                    ...customer.address,
                    country: allCountry.find(s => s.value.toString() === event.target.value)!.value,
                    state: undefined
                  });
                  setStateOptions(customer?.address?.country === Country.AU? allState.slice(0,8) : allState.slice(8));
                }}
              />
               <SelectField
                name="state"
                label={customer.address?.country === Country.NZ ? "Province" : "State"}
                options={stateOptions.map(x => ({ label: x.description, value: x.value.toString() }))}
                ignoreDefaultOption={true}
                readonly={!editable}
                value={customer.address?.state?.toString() ?? ''}
                onChange={event =>
                  customer.set('address', {...customer.address, state: allState.find(s => s.value.toString() === event.target.value)!.value})
                }
              />
              <TextField
                id="quote-customer-postcode"
                name="postcode"
                label="Postcode"
                readonly={!editable}
                value={customer.address?.postCode ?? ''}
                onChange={event => customer.set('address', {...customer.address, postCode: event.target.value})}
              />
            </div>
            <div className={styles.fields}>
              <TextField
                id="quote-customer-companyName"
                name="companyName"
                label="Company Name"
                readonly={!editable}
                value={customer.companyName ?? ''}
                onChange={(event) => customer.set('companyName', event.target.value)}
              />
              <TextField
                id="quote-customer-abn"
                name="abn"
                label="ABN"
                readonly={!editable}
                value={customer.abn ?? ''}
                onChange={(event) => customer.set('abn', event.target.value)}
              />
            </div>
        </>
      )}
    </QuoteSection>
  );
});
