import { StatusCode, statusCodeDescription } from 'api/enums/StatusCode';
import { QuoteDtoModel } from 'api/models/Domain/Dtos/QuoteDtoModel';
import { IUserProfileDtoModel } from 'api/models/Web/Controllers/UsersController/UserProfileDtoModel';
import { IOrderLine } from "domain/store/types/IOrderLine";
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { types } from 'mobx-state-tree';
import { QuoteApprovalModel } from './QuoteApprovalModel';
import { QuoteCustomerModel } from './QuoteCustomerModel';
import { QuoteDepositModel } from './QuoteDepositModel';
import { QuoteFittedExtraModel } from './QuoteFittedExtraModel';
import { OptionalExtraModel } from './QuoteOptionalExtraModel';
import { QuoteTradeInModel } from './QuoteTradeInModel';
import { VehicleModel } from './VehicleModel';

export const QuoteModel = types
  .model('QuoteModel', {
    ...QuoteDtoModel.properties,
    lastModifiedAt: localDateTimeType,
    customer: QuoteCustomerModel,
    vehicle: VehicleModel,
    tradeIn: QuoteTradeInModel,
    optionalExtras: types.array(OptionalExtraModel),
    fittedExtras: types.array(QuoteFittedExtraModel),
    deposit: QuoteDepositModel,
    opportunityId: types.maybe(types.string),
    latestApproval: types.maybe(QuoteApprovalModel),
  })
  .views(self => ({
    get totalExtrasPriceFormatted() {
      if (self.statusCode === StatusCode.New) {
        // if new quote, return sum of fitted extras
        const totalFitted = self.fittedExtras.reduce((total, extra) => total += extra.totalPrice, 0);
        return AsAuCurrency(totalFitted);
      }

      // if an existing quote, return the calculated price from the server
      return AsAuCurrency(self.totalExtrasPrice);
    },
    get subTotalFormatted() {
      return AsAuCurrency(self.subtotal);
    },
    get totalPriceFormatted() {
      return AsAuCurrency(self.totalPrice);
    },
    get statusCodeDescription() {
      return statusCodeDescription(self.statusCode);
    },
    get needManagerApproval() {
      return self.belowMinSell || self.belowRecommendedRetailPrice
        || (!self.vehicle.isEmpty && !self.vehicle.isVibeVehicle);
    },
    canBeApprovedBy(user: IUserProfileDtoModel): boolean {
      const locationGuard = user.managingLocations.includes(self.location)
        || user.isNationalSalesManager
        || user.isExecutiveManager;
      if (!locationGuard) return false;

      if (self.belowMinSellAmount && self.belowMinSellAmount > 500)
        return user.isExecutiveManager;

      if (self.belowMinSellAmount && self.belowMinSellAmount <= 500 && self.belowMinSellAmount > 0)
        return user.isExecutiveManager || user.isNationalSalesManager;

      if (!self.belowMinSell && self.belowRecommendedRetailPrice)
        return user.isExecutiveManager || user.isNationalSalesManager || user.isSalesManager;

      if (!self.vehicle.isVibeVehicle) {
        return user.isExecutiveManager || user.isNationalSalesManager || user.isSalesManager;
      }

      return false;
    },
    get canBeFinalised() {
      return [ StatusCode.Draft, StatusCode.Approved ].includes(self.statusCode);
    },
    get totalDiscount() {
      return Math.max(self.vehicle.discount ?? 0, 0)
        + self.fittedExtras.reduce((sum, current) => sum + Math.max(current.discount, 0), 0)
        + self.optionalExtras.reduce((sum, current) => sum + Math.max(current.discount, 0), 0);
    },
    get canGenerateContract() {
      return self.statusCode === StatusCode.Open;
    }
  }))
  .views(self => ({
    get orderLines(): IOrderLine[] {
      let items: IOrderLine[] = self.fittedExtras.map<IOrderLine>(e => ({
        description: e.description,
        discountFormatted: e.discountFormatted,
        priceFormatted: AsAuCurrency(Math.max(e.unitPrice, e.recommendedRetailPrice)),
        quantity: e.quantity,
        index: 0,
        productType: 'Fitted Extra',
        lineTotalFormatted: e.totalPriceFormatted,
      }));
      items = [
        ...items,
        ...self.optionalExtras.map<IOrderLine>(e => ({
          description: e.isVibeExtra ? e.description : `(D) ${e.description}`,
          discountFormatted: e.discountFormatted,
          priceFormatted: AsAuCurrency(Math.max(e.unitPrice, e.recommendedRetailPrice)),
          quantity: e.quantity,
          index: 0,
          productType: 'Optional Extra',
          lineTotalFormatted: e.totalPriceFormatted,
        }))];

      if (!self.vehicle.isEmpty) {
        items = [
          {
            description: self.vehicle.makeAndModel,
            discountFormatted: self.vehicle.discountFormatted,
            priceFormatted: AsAuCurrency(self.vehicle.displayRrp),
            quantity: 1,
            index: 0,
            productType: 'Vehicle Sell',
            lineTotalFormatted: AsAuCurrency(self.vehicle.displayPrice),
          },
          ...items,
        ];
      }
      return items.map((item, i) => ({ ...item, index: i + 1 }));
    },
    get duplicateExtras(): string[] {
      const allDescriptions = self.optionalExtras.filter(e => !e.deleted && e.description).map(e => e.description);
      return allDescriptions
        .filter((desc, index) => allDescriptions.indexOf(desc) !== index);
    },
  }))
  .views(self => ({
    get canSaveExtra(): boolean {
      return self.duplicateExtras.length === 0
        && self.optionalExtras.every(e => !e.isInvalid)
    },
  }))
  .actions(self => {
    function setAsApproved(updateBy: string, note?: string) {
      self.statusCode = StatusCode.Approved;
      self.latestApproval?.setAsApproved(updateBy, note);
    }

    function setAsRejected(updateBy: string, note?: string) {
      self.statusCode = StatusCode.Draft;
      self.latestApproval?.setAsRejected(updateBy, note);
    }

    return {
      setAsApproved,
      setAsRejected
    }
  });