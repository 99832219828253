import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { NumberTextField, SelectField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { FormSection } from 'views/components/forms/FormSection';
import styles from './VehicleSection.module.scss';
import { VehicleCondition } from 'api/enums/VehicleCondition';

export const VehicleSection: React.FC = observer(function VehicleSection() {
  const { editContractModal,security } = useStore();

  const contract = editContractModal.temporaryContract;
  const vehicle = contract.vehicle;

  const save = async () => {
    await editContractModal.updateVehicle();
  };

  const HideForNewBuild = (props: { children?: ReactNode }) =>
    vehicle.condition !== VehicleCondition.NewBuild ? <>{props.children}</> : <></>;

  return (
    <FormSection
      sectionTitle={`Vehicle: ${vehicle.description}`}
      sectionSummary={`Vehicle Price: ${vehicle.priceFormatted}`}
      isFormActionAllowed={editContractModal.isEditable && !security.currentUser.isAftermarketPerson}
      expandedDefault={editContractModal.isSectionToModify('vehicle')}
      editableDefault={false}
      canSave={true}
      canCancel={true}
      onSubmit={save}
      onCancel={editContractModal.cancelEditingContract}
      shouldModifyVersion={contract.isFinalised}
      hasErrors={!vehicle.isValid}
      onValidate={() => vehicle.isValid}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'vehicle')}>
      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            <HideForNewBuild>
              <TextField
                id="asset-no"
                name="assetNo"
                label="Asset No"
                readonly={true}
                value={vehicle.assetNo ?? ''}
              />
            </HideForNewBuild>
            {vehicle.isVibeVehicle ? (
              <TextField
                id="vehicle-type"
                name="type"
                label="Vehicle Type"
                readonly={true}
                value={vehicle.vehicleType ?? ''}
                onChange={event => vehicle.set('vehicleType', event.target.value)}
              />
            ) : (
              <SelectField
                name="type"
                readonly={!editable}
                label="Vehicle Type"
                options={['Caravan', 'Motorhome'].map(x => ({ label: x, value: x }))}
                value={vehicle.vehicleType ?? ''}
                onChange={event => vehicle.set('vehicleType', event.target.value as string)}
                validationError={vehicle.validator('vehicleType')}
                required={true}
              />
            )}
            <HideForNewBuild>
              <TextField
                id="compliance-plate-date"
                name="compliancePlateDate"
                label="Compl Plate Date"
                readonly={!editable || vehicle.isVibeVehicle}
                type="date"
                shrink={true}
                value={vehicle.compliancePlateDate ?? ''}
                onChange={event => vehicle.set('compliancePlateDate', event.target.value)}
              />
            </HideForNewBuild>
            <TextField
              id="vin"
              name="vin"
              label="VIN Number"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.vinNumber ?? ''}
              onChange={event => vehicle.set('vinNumber', event.target.value)}
            />
            <HideForNewBuild>
              <TextField
                id="build-date"
                name="buildDate"
                label="Build Date"
                readonly={!editable || vehicle.isVibeVehicle}
                type="date"
                shrink={true}
                value={vehicle.buildDate ?? ''}
                onChange={event => vehicle.set('buildDate', event.target.value)}
              />
            </HideForNewBuild>
            <TextField
              id="engine-number"
              name="engineNumber"
              label="Engine Number"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.engineNumber ?? ''}
              onChange={event => vehicle.set('engineNumber', event.target.value)}
            />
            <TextField
              id="registration-number"
              name="registrationNumber"
              label="Registration Number"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.registration ?? ''}
              onChange={event => vehicle.set('registration', event.target.value)}
            />
            <TextField
              id="registration-expiry"
              name="registrationExpiry"
              label="Registration Expiry"
              readonly={!editable || vehicle.isVibeVehicle}
              type="date"
              shrink={true}
              value={vehicle.registrationExpiryDate ?? ''}
              onChange={event => vehicle.set('registrationExpiryDate', event.target.value)}
            />
            <NumberTextField
              id="odometer"
              name="odometer"
              label="Odometer"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.odometer ?? ''}
              onChange={event =>
                event.target.value
                  ? vehicle.set('odometer', parseFloat(event.target.value))
                  : vehicle.set('odometer', undefined)
              }
            />
            <TextField
              id="body-colour"
              name="bodyColour"
              label="Body Colour"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.bodyColour ?? ''}
              onChange={event => vehicle.set('bodyColour', event.target.value)}
            />
            <TextField
              id="trim-colour"
              name="trimColour"
              label="Trim Colour"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.trimColour ?? ''}
              onChange={event => vehicle.set('trimColour', event.target.value)}
            />
            <SelectField
              name="transmission"
              readonly={!editable || vehicle.isVibeVehicle}
              label="Transmission"
              options={[
                { label: 'Automatic', value: 'A' },
                { label: 'Manual', value: 'M' },
              ]}
              value={vehicle.transmission ?? ''}
              onChange={event => vehicle.set('transmission', event.target.value as string)}
            />
            <TextField
              id="engineSize"
              name="engine-size"
              label="Engine Size"
              readonly={!editable || vehicle.isVibeVehicle}
              value={vehicle.engineSize ?? ''}
              onChange={event => vehicle.set('engineSize', event.target.value)}
            />
          </div>
          <div className={styles.fields}>
            <TextField
              id="delivery-date"
              name="deliveryDate"
              label="Est Delivery Date"
              type="date"
              shrink={true}
              readonly={!editable}
              value={vehicle.estDeliveryDate ?? ''}
              onChange={event => vehicle.set('estDeliveryDate', event.target.value)}
            />
          </div>
        </>
      )}
    </FormSection>
  );
});
