export enum ContractPaymentType {
    MasterCard = 0,
    Visa = 1,
    Diners = 2,
    Amex = 3,
    Eftpos = 4,
    Eft = 5,
    Cash = 6,
    Cheque = 7,
    TradeIn = 8
}
export function contractPaymentTypeDescription(value: ContractPaymentType) {
    switch (value) {
        case ContractPaymentType.MasterCard:
            return `MasterCard`;
        case ContractPaymentType.Visa:
            return `VISA`;
        case ContractPaymentType.Diners:
            return `Diners`;
        case ContractPaymentType.Amex:
            return `Amex`;
        case ContractPaymentType.Eftpos:
            return `EFTPOS`;
        case ContractPaymentType.Eft:
            return `EFT`;
        case ContractPaymentType.Cash:
            return `Cash`;
        case ContractPaymentType.Cheque:
            return `Cheque`;
        case ContractPaymentType.TradeIn:
            return `Trade In`;
    }
}
export interface IContractPaymentTypeDescriptor { value: ContractPaymentType; name: string; description: string; }
export var allContractPaymentType: IContractPaymentTypeDescriptor[] = [
    { value: ContractPaymentType.MasterCard, name: `MasterCard`, description: `MasterCard` },
    { value: ContractPaymentType.Visa, name: `Visa`, description: `VISA` },
    { value: ContractPaymentType.Diners, name: `Diners`, description: `Diners` },
    { value: ContractPaymentType.Amex, name: `Amex`, description: `Amex` },
    { value: ContractPaymentType.Eftpos, name: `Eftpos`, description: `EFTPOS` },
    { value: ContractPaymentType.Eft, name: `Eft`, description: `EFT` },
    { value: ContractPaymentType.Cash, name: `Cash`, description: `Cash` },
    { value: ContractPaymentType.Cheque, name: `Cheque`, description: `Cheque` },
    { value: ContractPaymentType.TradeIn, name: `TradeIn`, description: `Trade In` }
];

export function getContractPaymentTypeDescriptor(value: ContractPaymentType) {
    switch (value) {
        case ContractPaymentType.MasterCard:
            return { value: ContractPaymentType.MasterCard, name: `MasterCard`, description: `MasterCard` };
        case ContractPaymentType.Visa:
            return { value: ContractPaymentType.Visa, name: `Visa`, description: `VISA` };
        case ContractPaymentType.Diners:
            return { value: ContractPaymentType.Diners, name: `Diners`, description: `Diners` };
        case ContractPaymentType.Amex:
            return { value: ContractPaymentType.Amex, name: `Amex`, description: `Amex` };
        case ContractPaymentType.Eftpos:
            return { value: ContractPaymentType.Eftpos, name: `Eftpos`, description: `EFTPOS` };
        case ContractPaymentType.Eft:
            return { value: ContractPaymentType.Eft, name: `Eft`, description: `EFT` };
        case ContractPaymentType.Cash:
            return { value: ContractPaymentType.Cash, name: `Cash`, description: `Cash` };
        case ContractPaymentType.Cheque:
            return { value: ContractPaymentType.Cheque, name: `Cheque`, description: `Cheque` };
        case ContractPaymentType.TradeIn:
            return { value: ContractPaymentType.TradeIn, name: `TradeIn`, description: `Trade In` };
    }
}
