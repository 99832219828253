import { types, Instance } from 'mobx-state-tree';

export const OrderVersionDtoModel = types.model('OrderVersionDtoModel', {
  id: types.identifier,
  lastModifiedAt: types.string,
  lastModifiedBy: types.string,
  numberAndVersion: types.string,
  status: types.frozen<Domain.Aggregates.Order.Entities.OrderStatusCode>(),
});

export interface IOrderVersionDtoModel extends Instance<typeof OrderVersionDtoModel> {}
