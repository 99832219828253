import React, { ReactElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react-lite';
import styles from './ActionMenu.module.scss';
import { Permission } from 'api/enums/Permission';
import { useStore } from '../hooks';

interface IActionMenuItemProps {
  title: string;
  subtext: string;
  restrictedTo?: Permission;
  onClick(): void;
}

export const ActionMenuItem: React.FC<IActionMenuItemProps> = observer(function ActionMenuItem(
  props
) {
  const { security } = useStore();

  if (props.restrictedTo && !security.currentUser.can(props.restrictedTo)) return <></>;

  return (
    <MenuItem onClick={props.onClick}>
      <ul>
        <li>{props.title}</li>
        <li>
          <label>{props.subtext}</label>
        </li>
      </ul>
    </MenuItem>
  );
});

interface IActionMenuProps {
  children: {
    items: Array<ReactElement | { props: IActionMenuItemProps }>;
  };
}

export const ActionMenu: React.FC<IActionMenuProps> = observer(function ActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={onClick}>
        <MenuIcon fontSize={'large'} />
      </IconButton>
      <Menu
        className={styles.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}>
        {React.Children.map(props.children.items, (item, index) => {
          return React.cloneElement(item as ReactElement, {
            onClick: () => {
              item.props.onClick();
              onClose();
            },
          });
        })}
      </Menu>
    </>
  );
});
