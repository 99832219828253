import { types, Instance } from 'mobx-state-tree';
import { ApplicableExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel } from './../../Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel'
import { OrderExtraDtoModel as DomainDtosOrderExtraDtoModel } from './OrderExtraDtoModel'

export const UpdatedOrderExtraDtoModel = types.model('UpdatedOrderExtraDtoModel', {
  new:DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel,
  old:DomainDtosOrderExtraDtoModel,
});

export interface IUpdatedOrderExtraDtoModel extends Instance<typeof UpdatedOrderExtraDtoModel> {}
