import { types, Instance } from 'mobx-state-tree';
import { MakeModel as DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryMakeModel } from './MakeModel'

export const TypeModel = types.model('TypeModel', {
  code: types.string,
  makes: types.array(DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryMakeModel),
  name: types.string,
});

export interface ITypeModel extends Instance<typeof TypeModel> {}
