import { Chip } from '@material-ui/core';
import { ContractStatusCode, contractStatusCodeDescription } from 'api/enums/ContractStatusCode';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './ContractStatus.module.scss';

export interface IContractStatusProps {
  statusCode: ContractStatusCode;
  className?: string;
}

const getStatusColour = (statusCode: ContractStatusCode) => {
  switch (statusCode) {
    case ContractStatusCode.Open:
    return styles.primary;
  case ContractStatusCode.Delivered:
    return 'seagreen';
  case ContractStatusCode.Cancelled:
    return 'red';
  case ContractStatusCode.OldVersion:
    return 'red';
  case ContractStatusCode.Draft:
    return 'grey';
    default:
      return styles.draft
  }
}

export const ContractStatus: React.FC<IContractStatusProps> = observer(function ContractStatus({
  statusCode,
  className
}) {
  const statusColour = getStatusColour(statusCode);
  const labelText = contractStatusCodeDescription(statusCode);
  return (
    <Chip
      className={className}
      label={labelText}
      component="span"
      color="primary"
      style={{ backgroundColor: statusColour, width: '6rem' }}
      variant="default"
    />
  );
});
