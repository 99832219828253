import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { HubConnection } from '@microsoft/signalr';
import { getBus, IRootStoreModel } from 'domain/store/RootStoreModel';
import { History } from 'history';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Router } from 'react-router-dom';
import { Routes } from 'views/routes/Routes';
import styles from './App.module.scss';
import { Notifications } from './components/application/Notifications';
import * as theming from './theming';

export const StoreContext = React.createContext<IRootStoreModel | undefined>(undefined);
export const ModalContext = React.createContext<React.RefObject<HTMLDivElement | null>>(
  React.createRef()
);

interface IAppProps {
  history: History;
  store: IRootStoreModel;
  signalRHubConnection: HubConnection;
}

export const App: React.FC<IAppProps> = observer(function App({ history, store, signalRHubConnection }) {
  const modalContainerRef = React.useRef<HTMLDivElement | null>(null);

  signalRHubConnection.start()
    .then(_ => {
      console.log('SignalR connected!');

      signalRHubConnection.on('ApproveQuote', (quoteId, updatedBy, note) => {
        getBus(store).publish('QUOTE_APPROVED', { quoteId: quoteId, updatedBy: updatedBy, note: note } );
      });
      signalRHubConnection.on('RejectQuote', (quoteId, updatedBy, note) => {
        getBus(store).publish('QUOTE_REJECTED', { quoteId: quoteId, updatedBy: updatedBy, note: note });
      });
    })
    .catch(e => console.log('Connection failed: ', e));

  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theming.theme}>
        <div className={styles.root} style={theming.cssVariables}>
          <ModalContext.Provider value={modalContainerRef}>
            <StoreContext.Provider value={store}>
              <Router history={history}>
                <Routes />
              </Router>
              <div ref={modalContainerRef} />
              <Notifications />
            </StoreContext.Provider>
          </ModalContext.Provider>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
});
