import { observer } from 'mobx-react-lite';
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { useStore } from '../../../hooks';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';

export const CancelledWarning: React.FC = observer(function CancelledWarning() {
  const { editQuoteModel } = useStore();
  return editQuoteModel.associatedContract?.statusCode === ContractStatusCode.Cancelled ? (
    <Alert severity="warning">
      The contract associated to this Quote has been cancelled. Changes to this quote cannot be made.
    </Alert>
  ) : (
    <></>
  );
});
