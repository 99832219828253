import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProductsTable } from 'views/components/ProductsTable';
import { useStore } from '../../../hooks';
import styles from './SummarySection.module.scss';

export const SummarySection: React.FC = observer(function SummarySection() {
  const { editOrderModel } = useStore();

  return (
    <ExpansionPanel expanded>
      <ExpansionPanelSummary className={styles.header}>
        <Typography>
          <span className={styles.title}>Order Summary</span>
        </Typography>
        <span className={styles.spacer} aria-hidden="true" />
        <Typography>
          <span>Total Price (Inc GST): {editOrderModel.order?.totalPriceFormatted}</span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <ProductsTable orderLines={editOrderModel.order.orderLines} hideDiscount={true} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});
