import { requiredString } from 'infrastructure/validation';
import React, { useState } from 'react';
import { TextField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IOrderModificationDialogProps {
  onClose(): void;
}

export const OrderModificationDialog: React.FC<IOrderModificationDialogProps> = props => {
  const { editOrderModel } = useStore();
  const [modificationReason, setModifyingReason] = useState('');
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  
  const submitOrderWithModReason = async () => {
    await editOrderModel.finaliseOrder(modificationReason);
  };

  return (
    <BasicSubmitCancelDialog
      title="Submit Order"
      formik={{
        initialValues: {},
        onSubmit: submitOrderWithModReason,
      }}
      onValidate={() => {
        setAttemptedSubmit(true);
        return !requiredString(modificationReason);
      }}
      onClose={() => props.onClose()}
      okLabel="Submit Order"
      content={
        <div>
          <p>Please enter the reason why this order was modified.</p>
          <TextField
            id="modification-reason"
            name="modification-reason"
            label="Reason for Modifying"
            value={modificationReason}
            onChange={event => setModifyingReason(event.target.value)}
            multiline={true}
            rows={3}
            required={true}
            shrink={true}
            autoFocus={true}
            fullWidth={true}
            validationError={attemptedSubmit ? requiredString(modificationReason) : undefined}
          />
        </div>
      }
    />
  );
}