import React, { useState } from 'react';
import { TextField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IPlaceOrderDialogProps {
  onClose(): void;
}

export const PlaceOrderDialog: React.FC<IPlaceOrderDialogProps> = props => {
  const { editOrderModel } = useStore();
  const [externalOrderId, setExtOrderNumber] = useState(editOrderModel.order.externalOrderId ?? '');

  const placeOrder = async () => {
    await editOrderModel.orderPlaced(externalOrderId);
  };
  
  return (
    <BasicSubmitCancelDialog
      title="Update Order as Placed"
      formik={{
        initialValues: {},
        onSubmit: placeOrder,
      }}
      onValidate={() => !!externalOrderId}
      onClose={() => props.onClose()}
      content={
        <div>
          <p>Please record the manufacturer order reference number</p>
          <TextField
            id="external-id"
            name="externalId"
            label="Order Reference Number"
            value={externalOrderId}
            autoFocus={true}
            onChange={event => setExtOrderNumber(event.target.value)}
            required={true}
            shrink={true}
            validationError={!externalOrderId ? 'Required' : undefined}
          />
        </div>
      }
    />
  );
};
