import { types, Instance } from 'mobx-state-tree';

export const FittedExtraDtoModel = types.model('FittedExtraDtoModel', {
  code: types.string,
  name: types.string,
  parentCode: types.maybe(types.string),
  parentName: types.maybe(types.string),
  price: types.maybe(types.number),
  quantity: types.maybe(types.integer),
  recommendedRetailPrice: types.maybe(types.number),
});

export interface IFittedExtraDtoModel extends Instance<typeof FittedExtraDtoModel> {}
