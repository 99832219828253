import { types, Instance } from 'mobx-state-tree';

export const QuoteOptionalExtraDtoModel = types.model('QuoteOptionalExtraDtoModel', {
  code: types.maybe(types.string),
  description: types.string,
  maxQuantity: types.maybe(types.integer),
  parentCode: types.maybe(types.string),
  parentDescription: types.maybe(types.string),
  quantity: types.integer,
  recommendedRetailPrice: types.number,
  soldBy: types.maybe(types.string),
  soldById: types.maybe(types.string),
  unitPrice: types.number,
});

export interface IQuoteOptionalExtraDtoModel extends Instance<typeof QuoteOptionalExtraDtoModel> {}
