import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { ContractStatus } from './ContractStatus';
import { DateTime } from 'luxon';
import { IContractVersionModel } from 'domain/store/repos/contracts/ContractVersionModel';
import { Link } from 'react-router-dom';
import { HistoryDialog } from 'views/components/dialogs/HistoryDialog';

interface IContractHistoryDialogProps {
  onClose(): void;
}

export const ContractHistoryDialog: React.FC<IContractHistoryDialogProps> = observer(props => {
  const { editContractModal } = useStore();
  useEffect(() => {
    (async () => {
      await editContractModal.loadContractVersions();
    })();
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryDialog
      title="Contract History"
      items={(editContractModal.versions || ([] as IContractVersionModel[])).map(v => ({
        number: <Link to={`/contracts/${v.id}`}>{v.numberAndVersion}</Link>,
        status: <ContractStatus statusCode={v.status} />,
        lastModifiedAt: v.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED),
        lastModifiedBy: v.lastModifiedBy,
      }))}
      onClose={props.onClose}
    />
  );
});
