import queryString from 'query-string';
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStore } from "views/hooks";

interface IEditQuoteParameters {
  customerId?: string;
  opportunityId?: string;
}

export function useEditQuote() {
  const { editQuoteModel, security, customerSelection, vehicleSelection } = useStore();
  const { quoteId } = useParams<{ quoteId?: string }>();
  const location = useLocation();
  const params: IEditQuoteParameters = queryString.parse(location.search);
  const customerId = params?.customerId;
  const opportunityId = params?.opportunityId;

  useEffect(() => {
    editQuoteModel.clearQuote();
    customerSelection.reset();
    vehicleSelection.reset();
    if (quoteId) {
      (async () => {
        await Promise.all([
          editQuoteModel.loadQuote(quoteId, security.currentUser),
          editQuoteModel.loadAssociatedContract(quoteId),
        ]);
      })();
    }
    return () => {
      editQuoteModel.clearQuote();
      customerSelection.reset();
      vehicleSelection.reset();
    };
  }, [quoteId, editQuoteModel, security.currentUser, customerSelection, vehicleSelection]);

  useEffect(() => {
    !opportunityId && customerId && editQuoteModel.loadCustomer(customerId);
  }, [customerId, editQuoteModel, opportunityId]);

  return editQuoteModel;
}
