import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { orderTypeDescription } from 'api/enums/OrderType';
import { Permission } from 'api/enums/Permission';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { LoadingPane } from 'views/components/LoadingPane';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { useStore } from 'views/hooks';
import styles from '../../../components/Listings.module.scss';
import { OrderStatus } from './OrderStatus';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const OrdersList: React.FC = observer(function OrdersList() {
  const { security, ordersRepo } = useStore();
  const query = useQuery();
  const filter = query.get('filter') || '';
  const history = useHistory();

  const canCreateStockOrdersOnly =
    security.currentUser.can(Permission.InitiateStockOrder) &&
    !security.currentUser.can(Permission.InitiateCustomerOrder) &&
    // If the Contracts flow is not enabled allow dealers to create customer orders regardless
    security.currentUser.contractFeatureEnabled;

  useEffect(() => {
    ordersRepo.loadOrders(filter);
  }, [ordersRepo, filter]);

  return (
    <>
      <div className={styles.actions}>
        <FilterBar
          className={styles.filterbar}
          value={ordersRepo.filter}
          placeholder="Order #, Customer or Dealer Reference"
          onChange={newValue => ordersRepo.loadOrders(newValue)}
        />
        {security.currentUser.can(Permission.EditOrder) && (
          <Button
            className={styles.newitem}
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/orders/new">
            {canCreateStockOrdersOnly ? 'Create Stock Order' : 'Create Order'}
          </Button>
        )}
      </div>
      <LoadingPane isLoading={ordersRepo.isLoadingOrders}>
        <Table className={styles.table}>
          <TableHead className={styles.tablehead}>
            <TableRow>
              <TableCell className={styles.number}>Order #</TableCell>
              <TableCell>Vehicle Description</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell align="center">Customer Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Dealer Contact</TableCell>
              <TableCell>Dealer / Reference</TableCell>
              <TableCell>Last Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersRepo.orders.map(o => {
              const openOrder = () => history.push(`/orders/${o.id}`);
              return (
                <TableRow key={o.id} className={styles.row}>
                  <TableCell onClick={openOrder}>{o.numberAndVersion}</TableCell>
                  <TableCell onClick={openOrder}>{o.vehicleDescription}</TableCell>
                  <TableCell onClick={openOrder}>{orderTypeDescription(o.orderType)}</TableCell>
                  <TableCell onClick={openOrder}>{o.customerName}</TableCell>
                  <TableCell onClick={openOrder} align="center">
                    <OrderStatus statusCode={o.statusCode} />
                  </TableCell>
                  <TableCell onClick={openOrder}>{o.dealerContact}</TableCell>
                  <TableCell onClick={openOrder}>{o.dealerReference}</TableCell>
                  <TableCell onClick={openOrder}>
                    {o.lastModifiedAt.toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {ordersRepo.orders.length === 0 && (
          <p className={styles.nodata}>There are no matching Orders.</p>
        )}
        {ordersRepo.moreRecordsExist && <p className={styles.hasmore}>More records exist.</p>}
      </LoadingPane>
    </>
  );
});
