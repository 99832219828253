import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import cn from 'classnames';
import React from 'react';
import styles from './TextField.module.scss';

interface ISelectFieldProps {
  label: string;
  name: string;
  className?: string;
  required?: boolean;
  options: { label: string; value: string }[];
  readonly?: boolean;
  value?: string;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => void;
  emptyLabel?: string;
  validationError?: string;
  ignoreDefaultOption?: boolean;
}

export const SelectField: React.FC<ISelectFieldProps> = function ({
  label,
  name,
  className,
  options,
  readonly,
  value,
  onChange,
  emptyLabel,
  validationError,
  required,
  ignoreDefaultOption,
}) {
  const labelId = `${name}-label-id`;
  const hasError = !!validationError;
  return (
    <FormControl
      className={cn(className, { [styles.readonly]: readonly }, styles.selectField)}
      margin="normal"
      variant="outlined"
      fullWidth
      size="small"
      required={required}
      error={hasError}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={name}
        value={value}
        onChange={onChange}
        inputProps={{ readOnly: readonly }}
        label={label}>
        {!ignoreDefaultOption && <MenuItem value="">{emptyLabel ?? 'None'}</MenuItem>}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {hasError && <FormHelperText error={true}>{validationError}</FormHelperText>}
    </FormControl>
  );
};
