export enum PaymentType {
    MasterCard = 0,
    Visa = 1,
    Diners = 2,
    Amex = 3,
    Eftpos = 4,
    Eft = 5,
    Cash = 6,
    Cheque = 7
}
export function paymentTypeDescription(value: PaymentType) {
    switch (value) {
        case PaymentType.MasterCard:
            return `MasterCard`;
        case PaymentType.Visa:
            return `VISA`;
        case PaymentType.Diners:
            return `Diners`;
        case PaymentType.Amex:
            return `Amex`;
        case PaymentType.Eftpos:
            return `EFTPOS`;
        case PaymentType.Eft:
            return `EFT`;
        case PaymentType.Cash:
            return `Cash`;
        case PaymentType.Cheque:
            return `Cheque`;
    }
}
export interface IPaymentTypeDescriptor { value: PaymentType; name: string; description: string; }
export var allPaymentType: IPaymentTypeDescriptor[] = [
    { value: PaymentType.MasterCard, name: `MasterCard`, description: `MasterCard` },
    { value: PaymentType.Visa, name: `Visa`, description: `VISA` },
    { value: PaymentType.Diners, name: `Diners`, description: `Diners` },
    { value: PaymentType.Amex, name: `Amex`, description: `Amex` },
    { value: PaymentType.Eftpos, name: `Eftpos`, description: `EFTPOS` },
    { value: PaymentType.Eft, name: `Eft`, description: `EFT` },
    { value: PaymentType.Cash, name: `Cash`, description: `Cash` },
    { value: PaymentType.Cheque, name: `Cheque`, description: `Cheque` }
];

export function getPaymentTypeDescriptor(value: PaymentType) {
    switch (value) {
        case PaymentType.MasterCard:
            return { value: PaymentType.MasterCard, name: `MasterCard`, description: `MasterCard` };
        case PaymentType.Visa:
            return { value: PaymentType.Visa, name: `Visa`, description: `VISA` };
        case PaymentType.Diners:
            return { value: PaymentType.Diners, name: `Diners`, description: `Diners` };
        case PaymentType.Amex:
            return { value: PaymentType.Amex, name: `Amex`, description: `Amex` };
        case PaymentType.Eftpos:
            return { value: PaymentType.Eftpos, name: `Eftpos`, description: `EFTPOS` };
        case PaymentType.Eft:
            return { value: PaymentType.Eft, name: `Eft`, description: `EFT` };
        case PaymentType.Cash:
            return { value: PaymentType.Cash, name: `Cash`, description: `Cash` };
        case PaymentType.Cheque:
            return { value: PaymentType.Cheque, name: `Cheque`, description: `Cheque` };
    }
}
