import React, { useState } from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { SalesPersonAutoComplete } from 'views/components/SalesPersonAutoComplete';
type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;

interface IAssignContractDialogProps {
  assignee: IUserDto | undefined,
  onClose(): void;
  onAssign: (user: IUserDto | undefined) => void;
}

export const AssignContractDialog: React.FC<IAssignContractDialogProps> = props => {
  const [user, setUser] = useState(props.assignee);

  return (
    <BasicSubmitCancelDialog
      title="Assign Contract"
      formik={{
        initialValues: {},
        onSubmit: () => props.onAssign(user),
      }}
      onClose={() => props.onClose()}
      content={
        <div>
          <p>
            Assign this contract to another user. This will allow the assigned user to act on this
            contract. The Sales Person will remain unchanged.
          </p>
          <SalesPersonAutoComplete
            label="Assigned To"
            value={user}
            required={true}
            onChange={user => setUser(user)}
          />
        </div>
      }
    />
  );
};
