import React from 'react';

export const PermissionError = (): React.ReactElement => {
  return (
    <div style={{ margin: '5em' }}>
      <p>
        You don't have permission to view this resource.
      </p>
    </div>
  );
};
