import { TextField as MuiTextField } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import styles from './TextField.module.scss';

interface ITextFieldProps {
  id?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  label: string;
  name: string;
  required?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  readonly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string | number | undefined;
  validationError?: string;
  type?: string;
  shrink?: boolean;
  fullWidth?: boolean;
}

export const TextField: React.FC<ITextFieldProps> = ({
  id,
  className,
  inputRef,
  label,
  name,
  required,
  autoFocus,
  multiline,
  rows,
  readonly,
  value,
  onChange,
  validationError,
  type,
  shrink,
  fullWidth,
}) => {
  return (
    <MuiTextField
      id={id}
      className={cn(className, { [styles.readonly]: readonly }, styles.textField)}
      label={label}
      name={name}
      required={required}
      autoFocus={autoFocus}
      margin="normal"
      variant="outlined"
      size="small"
      multiline={multiline}
      rows={rows}
      inputRef={inputRef}
      inputProps={{ readOnly: readonly }}
      InputLabelProps={{ shrink: readonly || shrink || undefined }}
      value={value}
      error={!!validationError}
      helperText={validationError}
      type={type}
      fullWidth={fullWidth}
      onChange={event => onChange && onChange(event)}
    />
  );
};
