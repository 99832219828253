import { types, Instance } from 'mobx-state-tree';

export const QuoteDtoModel = types.model('QuoteDtoModel', {
  customerFirstName: types.string,
  customerSurname: types.maybe(types.string),
  id: types.identifier,
  lastModifiedAt: types.string,
  leadId: types.maybe(types.string),
  numberAndVersion: types.string,
  quoteNumber: types.integer,
  salesName: types.string,
  status: types.frozen<Domain.Aggregates.Quote.Entities.StatusCode>(),
  totalPrice: types.number,
  vehicleDescription: types.string,
});

export interface IQuoteDtoModel extends Instance<typeof QuoteDtoModel> {}
