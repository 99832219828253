export enum ContractExtraType {
    Fitted = 0,
    Extra = 1
}
export function contractExtraTypeDescription(value: ContractExtraType) {
    switch (value) {
        case ContractExtraType.Fitted:
            return `Fitted`;
        case ContractExtraType.Extra:
            return `Extra`;
    }
}
export interface IContractExtraTypeDescriptor { value: ContractExtraType; name: string; description: string; }
export var allContractExtraType: IContractExtraTypeDescriptor[] = [
    { value: ContractExtraType.Fitted, name: `Fitted`, description: `Fitted` },
    { value: ContractExtraType.Extra, name: `Extra`, description: `Extra` }
];

export function getContractExtraTypeDescriptor(value: ContractExtraType) {
    switch (value) {
        case ContractExtraType.Fitted:
            return { value: ContractExtraType.Fitted, name: `Fitted`, description: `Fitted` };
        case ContractExtraType.Extra:
            return { value: ContractExtraType.Extra, name: `Extra`, description: `Extra` };
    }
}
