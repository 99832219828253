import { types, Instance } from 'mobx-state-tree';
import { ContractCustomerDtoModel as DomainDtosContractCustomerDtoModel } from './ContractCustomerDtoModel'
import { ContractExtraDtoModel as DomainDtosContractExtraDtoModel } from './ContractExtraDtoModel'
import { LicenseeDtoModel as DomainDtosLicenseeDtoModel } from './LicenseeDtoModel'
import { ContractSettlementDtoModel as DomainDtosContractSettlementDtoModel } from './ContractSettlementDtoModel'
import { ContractTradeInDtoModel as DomainDtosContractTradeInDtoModel } from './ContractTradeInDtoModel'
import { ContractVehicleDtoModel as DomainDtosContractVehicleDtoModel } from './ContractVehicleDtoModel'

export const ContractDtoModel = types.model('ContractDtoModel', {
  applicableMinimumDepositRules: types.frozen<Domain.Aggregates.Contract.Entities.ApplicableMinimumDepositRules>(),
  assignedTo: types.string,
  assignedToId: types.string,
  customer:DomainDtosContractCustomerDtoModel,
  deliveryDate: types.maybe(types.string),
  extras: types.array(DomainDtosContractExtraDtoModel),
  id: types.identifier,
  initialContractDate: types.string,
  isFromQuote: types.boolean,
  lastModifiedAt: types.string,
  leadId: types.maybe(types.string),
  licensee:DomainDtosLicenseeDtoModel,
  minimumDepositAmountSatisfied: types.boolean,
  minimumDepositRequired: types.number,
  netTradeInAllowance: types.number,
  number: types.string,
  numberAndVersion: types.string,
  onRoads: types.array(DomainDtosContractExtraDtoModel),
  settlement:DomainDtosContractSettlementDtoModel,
  specialConditions: types.maybe(types.string),
  statusCode: types.frozen<Domain.Aggregates.Contract.Entities.ContractStatusCode>(),
  subTotal: types.number,
  totalBalancePayable: types.number,
  totalDiscount: types.number,
  totalExtrasPrice: types.number,
  totalShortfall: types.number,
  tradeIn:DomainDtosContractTradeInDtoModel,
  vehicle:DomainDtosContractVehicleDtoModel,
  version: types.integer,
});

export interface IContractDtoModel extends Instance<typeof ContractDtoModel> {}
