import { types, Instance } from 'mobx-state-tree';
import { QuoteCustomerDtoModel as DomainDtosQuoteCustomerDtoModel } from './QuoteCustomerDtoModel'
import { QuoteDepositDtoModel as DomainDtosQuoteDepositDtoModel } from './QuoteDepositDtoModel'
import { QuoteFittedExtraDtoModel as DomainDtosQuoteFittedExtraDtoModel } from './QuoteFittedExtraDtoModel'
import { QuoteApprovalDtoModel as DomainDtosQuoteApprovalDtoModel } from './QuoteApprovalDtoModel'
import { QuoteOptionalExtraDtoModel as DomainDtosQuoteOptionalExtraDtoModel } from './QuoteOptionalExtraDtoModel'
import { QuoteTradeInDtoModel as DomainDtosQuoteTradeInDtoModel } from './QuoteTradeInDtoModel'
import { VehicleDtoModel as DomainDtosVehicleDtoModel } from './VehicleDtoModel'

export const QuoteDtoModel = types.model('QuoteDtoModel', {
  belowMinSell: types.boolean,
  belowMinSellAmount: types.maybe(types.number),
  belowRecommendedRetailPrice: types.boolean,
  customer:DomainDtosQuoteCustomerDtoModel,
  customerOffer: types.maybe(types.number),
  deposit:DomainDtosQuoteDepositDtoModel,
  fittedExtras: types.array(DomainDtosQuoteFittedExtraDtoModel),
  id: types.identifier,
  lastModifiedAt: types.string,
  latestApproval: types.maybe(DomainDtosQuoteApprovalDtoModel),
  leadId: types.maybe(types.string),
  location: types.string,
  minSell: types.maybe(types.number),
  note: types.maybe(types.string),
  number: types.string,
  numberAndVersion: types.string,
  opportunityId: types.maybe(types.string),
  opportunityInstanceId: types.maybe(types.string),
  optionalExtras: types.array(DomainDtosQuoteOptionalExtraDtoModel),
  quoteNumber: types.integer,
  recommendedRetailPrice: types.maybe(types.number),
  statusCode: types.frozen<Domain.Aggregates.Quote.Entities.StatusCode>(),
  subtotal: types.number,
  totalExtrasPrice: types.number,
  totalNonVibeExtrasPrice: types.number,
  totalPrice: types.number,
  totalVibeExtrasPrice: types.number,
  tradeIn:DomainDtosQuoteTradeInDtoModel,
  vehicle:DomainDtosVehicleDtoModel,
});

export interface IQuoteDtoModel extends Instance<typeof QuoteDtoModel> {}
