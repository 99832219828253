import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps, FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import styles from './RadioField.module.scss';

interface IRadioFieldProps {
  id?: string;
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  label: string;
  name: string;
  readonly?: boolean;
  direction?: 'row' | 'column';
  value?: string;
  required?: boolean;
  validationError?: string;
  options: Array<
    Omit<FormControlLabelProps, 'control' | 'value'> & {
      value?: string; // type the label's value prop (rather than unknown)
      color?: 'primary' | 'secondary' | 'default';
      size?: 'small' | 'medium';
    }
  >;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const RadioField: React.FC<IRadioFieldProps> = function({
  id,
  className,
  inputRef,
  label,
  name,
  readonly,
  direction,
  value,
  options,
  required,
  validationError,
  onChange,
}) {
  return (
    <FormControl component="fieldset" id={id} className={className} error={!!validationError}>
      <FormLabel component="legend" required={required}>{label}</FormLabel>
      <RadioGroup
        id={id}
        className={cn(className, { [styles.readonly]: readonly })}
        name={name}
        row={direction === 'row'}
        value={value}
        onChange={onChange}>
        {options.map((o, i) => {
          const { color, size, ...labelProps } = o;
          return (
            <FormControlLabel
              key={labelProps.id || labelProps.value || i}
              labelPlacement="end"
              {...labelProps}
              className={cn(styles.option, labelProps.className)}
              classes={{ label: styles.readonly }}
              disabled={readonly}
              innerRef={i === 0 ? inputRef : undefined}
              control={<Radio color={color} size={size || 'small'} />}
            />
          );
        })}
      </RadioGroup>
      {validationError 
        && <FormHelperText error={true}>{validationError}</FormHelperText>}
    </FormControl>
  );
};
