import {
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'views/components/forms';
import { ProductsTable } from 'views/components/ProductsTable';
import { useStore } from 'views/hooks';
import { BasicSubmitCancelDialog } from '../../../components/forms/Dialog';
import styles from './PricingSummary.module.scss';

export const PricingSummary: React.FC = observer(function PricingSummary() {
  const { editContractModal } = useStore();
  const history = useHistory();
  const [openModifyPricing, setOpenModifyPricing] = useState(false);
  const contract = editContractModal.contract;

  const modifyPricing = async () => {
    const newQuoteId = await editContractModal.modifyPricing();
    history.push(`/quotes/${newQuoteId}`);
  };

  return (
    <>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>
            <span className={styles.title}>Pricing</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ProductsTable orderLines={contract.orderLines} className={styles.products} />
          <section className={styles.summary}>
            <div className={styles.onroads}>
              <h1>Includes On Road Costs:</h1>
              <ul>
                {contract.onRoads.map(r => (
                  <li key={r.description}>
                    <span>{r.description}</span>
                    <span>Included</span>
                  </li>
                ))}
              </ul>
              {contract.onRoads.length === 0 && (
                <p>
                  <i>No on road costs specified.</i>
                </p>
              )}
            </div>
            <ul className={styles.totals}>
              <li>
                <span>Subtotal:</span>
                <span>{contract.subTotalFormatted}</span>
              </li>
              {contract.totalDiscount > 0 && (
                <li>
                  <span>Includes Discount:</span>
                  <span>{contract.totalDiscountFormatted}</span>
                </li>
              )}
              <li>
                <span>Less Deposit Taken:</span>
                <span>{contract.settlement.depositFormatted}</span>
              </li>
              <li>
                <span>Less Net Trade-in Allowance:</span>
                <span>{contract.netTradeInAllowanceFormatted}</span>
              </li>
              <li>
                <span>Balance Payable:</span>
                <span>{contract.totalBalancePayableFormatted}</span>
              </li>
            </ul>
          </section>
          {!editContractModal.isReadOnly && (
            <ExpansionPanelActions className={styles.actions}>
              <Button
                onClick={() => setOpenModifyPricing(true)}
                disabled={!editContractModal.isEditable || !editContractModal.canModifyPricing}>
                Modify Pricing
              </Button>
            </ExpansionPanelActions>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {openModifyPricing && (
        <BasicSubmitCancelDialog
          title="Modify Pricing"
          okLabel="Modify quote >"
          formik={{
            initialValues: {},
            onSubmit: modifyPricing,
          }}
          onClose={() => setOpenModifyPricing(false)}
          content={
            <p>
              To modify contract pricing you must modify a new version of the original quote then
              generate a new version of this contract from the quote.
            </p>
          }
        />
      )}
    </>
  );
});
