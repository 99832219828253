import { ExtrasType } from 'api/enums/ExtrasType';
import { OrderStatusCode } from 'api/enums/OrderStatusCode';
import { OrderType } from 'api/enums/OrderType';
import { Permission } from 'api/enums/Permission';
import { HTTPError } from 'ky';
import { observable } from 'mobx';
import { cast, flow, getSnapshot, types } from 'mobx-state-tree';
import { OrderVehicleDiffModel } from './OrderVehicleDiffModel';
import { OrderExtraModel } from './repos/orders/OrderExtraModel';
import { emptyOrder, OrderModel } from './repos/orders/OrderModel';
import { emptyOrderVehicle, OrderVehicleModel } from './repos/orders/OrderVehicleModel';
import { OrderVersionModel } from './repos/orders/OrderVersionModel';
import { getAjax, getBus } from './RootStoreModel';
import { SearchVehicleResultModel } from './SearchVehicleResultModel';
import { IUserProfileDto } from './singletons/SecurityModel';

type INewOrderVersionResponse = Domain.Aggregates.Order.Commands.NewOrderVersionCommand.INewOrderVersionResponse;
type IUpdateOrderDetailCommand = Domain.Aggregates.Order.Commands.IUpdateOrderDetailCommand;
type ISearchVehicleDto = Domain.Queries.Vehicle.ISearchVehicleDto;
type IUpdateOrderResponse = Domain.Dtos.IOrderDto;
type ILoadOrderResponse = Domain.Dtos.ISyncedOrderDto;
type IOrderVersionsResponse = Domain.Queries.Order.GetOrderVersionsQuery.IOrderVersionDto[];

interface ILoadingQuoteProcess {
  key: string;
  aborter: AbortController;
}

export const EditOrderModel = types
  .model('EditOrderModel', {
    order: types.optional(OrderModel, () => OrderModel.create(emptyOrder)),
    temporaryOrder: types.optional(OrderModel, () => OrderModel.create(emptyOrder)),
    vibeVehicle: types.maybe(SearchVehicleResultModel),
    vehicleIsConfirmed: types.boolean,
    failedToLoadOrder: types.maybe(types.boolean),
    versions: types.maybe(types.array(OrderVersionModel)),
    vehicleOutOfSyncDialogModel: types.maybe(OrderVehicleDiffModel),
    vehicleNotFound: types.maybe(types.boolean),
  })
  .views(self => ({
    get isNewOrder() {
      return !self.order.id;
    },
    get applicableBuildOptions() {
      return self.vibeVehicle?.applicableExtras.filter(
        e => e.parentType === ExtrasType.BuildOptions
      );
    },
    get applicableInternalAccessories() {
      return self.vibeVehicle?.applicableExtras.filter(
        e => e.parentType === ExtrasType.InternalAccessories
      );
    },
    get applicableExternalAccessories() {
      return self.vibeVehicle?.applicableExtras.filter(
        e => e.parentType === ExtrasType.ExternalAccessories
      );
    },
    get applicableColourSchemeOptions() {
      return self.vibeVehicle?.applicableExtras.filter(
        e => e.parentType === ExtrasType.ColourSchemeOptions
      );
    },
  }))
  .extend(self => {
    const localState = observable({
      orderLoading: null as ILoadingQuoteProcess | null,
      isReadOnly: false,
    });

    function clearVehicle() {
      self.temporaryOrder = {
        ...self.temporaryOrder,
        vehicle: OrderVehicleModel.create({ ...emptyOrderVehicle }),
        extras: cast([]),
      };
    }

    function clearOrder() {
      localState.orderLoading?.aborter.abort();
      localState.orderLoading = null;
      self.failedToLoadOrder = false;
      self.vehicleNotFound = false;

      self.order = OrderModel.create({ ...emptyOrder });
      self.temporaryOrder = OrderModel.create({ ...emptyOrder });
      self.vibeVehicle = undefined;
      self.vehicleIsConfirmed = false;
    }

    const setVehicle = (vehicle: ISearchVehicleDto | undefined) => {
      if (!vehicle) return;
      self.temporaryOrder = {
        ...self.temporaryOrder,
        vehicle: OrderVehicleModel.create({
          assetNo: vehicle.assetNo,
          disposalOrderNo: vehicle.disposalOrderNumber,
          make: vehicle.make,
          model: vehicle.model,
          price: vehicle.price!,
          year: vehicle.year?.toString(),
          floorPlan: vehicle.floorPlan,
        }),
      };

      self.vibeVehicle = SearchVehicleResultModel.create({ ...vehicle });
    };

    function resetVehicle() {
      self.temporaryOrder.vehicle = OrderVehicleModel.create({ ...emptyOrderVehicle });
      self.temporaryOrder.extras = cast([]);
      self.vibeVehicle = undefined;
    }

    function cancelEditingOrder() {
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });
    }

    function* loadOrder(id: string, user: IUserProfileDto) {
      if (localState.orderLoading?.key === id) {
        return;
      }

      self.failedToLoadOrder = false;
      self.vehicleNotFound = false;

      try {
        localState.orderLoading = { key: id, aborter: new AbortController() };

        const dto: ILoadOrderResponse = yield getAjax(self)
          .get(`/api/orders/${id}`, { signal: localState.orderLoading?.aborter.signal })
          .json();

        self.order = cast(dto.order);
        self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });
        self.vibeVehicle = cast(dto.vibeVehicle);
        if (dto.vehicleDiff) {
          self.vehicleOutOfSyncDialogModel = cast(dto.vehicleDiff);
        }

      } catch (exception) {
        if (exception instanceof HTTPError && exception.response.status === 404) {
          self.vehicleNotFound = true;
        }
        if (exception instanceof HTTPError && exception.response.status >= 500) {
          self.failedToLoadOrder = true;
        }
      } finally {
        localState.orderLoading = null;
        self.vehicleIsConfirmed = !self.temporaryOrder.vehicle.isEmpty;
      }

      localState.isReadOnly = !user.can(Permission.EditOrder);
    }

    function* addOrder() {

      try {
        const dto: IUpdateOrderResponse = yield getAjax(self)
        .post('/api/orders/', {
          json: {
            ...self.temporaryOrder.detail,
          },
        })
        .json();
      self.order = cast(dto);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });
      return dto.id;
      } catch (exception) {
        if (exception instanceof HTTPError && exception.response.status === 400) {
          self.temporaryOrder.detail.dealerReference = "";
          self.order.detail.dealerReference = "";
          throw exception;
        }
      }
    }

    function* updateVehicle() {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .put(`/api/orders/${orderId}/vehicle`, {
          json: {
            orderId: orderId,
            vehicle: self.temporaryOrder.vehicle,
            extras: self.temporaryOrder.extras.filter(e => e.description !== ''),
          },
        })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });
    }

    function* updateDetail() {
      const orderId = self.temporaryOrder.id;

      const request =
        self.temporaryOrder.detail.orderType === OrderType.Stock
          ? {
              orderId: orderId,
              ...self.temporaryOrder.detail,
              soldDate:undefined,
              customerName: undefined,             
            }
          : {
              orderId: orderId,
              ...self.temporaryOrder.detail,
            };

            try {
              const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
              .put(`/api/orders/${orderId}/detail`, {
                json: request as IUpdateOrderDetailCommand,
              })
              .json();
            self.order = cast(response);
            self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });
            } catch (exception) {
              if (exception instanceof HTTPError && exception.response.status === 400) {
                self.temporaryOrder.detail.dealerReference = "";
                self.order.detail.dealerReference = "";
                throw exception;
              }
            }
    }

    function* finaliseOrder(modificationReason?: string | undefined) {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .post(`/api/orders/${orderId}/finalise`, { json: { orderId, modificationReason } })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });

      if (self.order.statusCode === OrderStatusCode.Rejected) {
        yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
          message: `Order ${self.temporaryOrder.numberAndVersion} has been rejected. 
          The previous version has been reinstated. Please check the Order History list for the current version: ${self.order.rejectionReason}`,
          level: 'warning',
        });
      }
      else if (self.order.statusCode === OrderStatusCode.AwaitingApproval) {
        yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
          message: `Order ${self.temporaryOrder.numberAndVersion} has been submitted for approval.`,
          level: 'warning',
        });
      }
      else {
        yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
          message: `Order ${self.temporaryOrder.numberAndVersion} has been submitted`,
          level: 'success',
        });
      }
    }

    function* approveOrder() {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .put(`/api/order-approvals/${orderId}/approve`, { json: { orderId } })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });

      yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
        message: `Order ${self.temporaryOrder.numberAndVersion} has been approved.`,
        level: 'success',
      });
    }

    function* rejectOrder(rejectionReason: string) {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .put(`/api/order-approvals/${orderId}/reject`, { json: { orderId, rejectionReason } })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });

      yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
        message: `Order ${self.temporaryOrder.numberAndVersion} has been rejected.`,
        level: 'success',
      });
    }

    function* cancelOrder(cancellationReason: string | undefined) {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .post(`/api/orders/${orderId}/cancel`, { json: { orderId, cancellationReason } })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });

      yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
        message: `Order ${self.temporaryOrder.numberAndVersion} has been cancelled`,
        level: 'success',
      });
    }

    function* createNewDraftVersion() {
      const orderId = self.temporaryOrder.id;
      const response: INewOrderVersionResponse = yield getAjax(self)
        .post(`/api/orders/${orderId}/new-version`, { json: { orderId } })
        .json();
      return response.orderId;
    }

    function* loadOrderVersions() {
      const response: IOrderVersionsResponse = yield getAjax(self)
        .get(`/api/orders/${self.order.number}/versions`)
        .json();
      self.versions = cast(response);
    }

    function* orderPlaced(externalOrderId: string) {
      const orderId = self.temporaryOrder.id;
      const response: IUpdateOrderResponse = yield getAjax(self.temporaryOrder)
        .post(`/api/orders/${orderId}/placed`, { json: { orderId, externalOrderId } })
        .json();
      self.order = cast(response);
      self.temporaryOrder = OrderModel.create({ ...getSnapshot(self.order) });

      yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
        message: `Order ${self.temporaryOrder.numberAndVersion} status has been updated`,
        level: 'success',
      });
    }

    function* downloadOrderPdf(id: string) {
      const response = yield getAjax(self).get(`/api/orders/pdf/${id}`);
      return response.blob();
    }

    function* deleteDraft() {
      yield getAjax(self).delete(`/api/orders/${self.order.id}`);
      yield getBus(self.temporaryOrder).publish('ADD_NOTIFICATION', {
        message: `Draft ${self.temporaryOrder.numberAndVersion} has been deleted`,
        level: 'success',
      });
    }

    function addExtra(parentType: ExtrasType) {
      self.temporaryOrder.extras = cast([
        ...self.temporaryOrder.extras,
        OrderExtraModel.create({
          description: '',
          price: 0,
          code: '',
          parentCode: '',
          parentDescription: '',
          quantity: 1,
          parentType: parentType,
        }),
      ]);
    }

    return {
      views: {
        get title() {
          if (self.isNewOrder) return 'New Order';
          return `Order ${self.order?.numberAndVersion}`;
        },
        get isLoadingOrder() {
          return !!localState.orderLoading;
        },
        get canModify() {
          return (
            self.order.statusCode === OrderStatusCode.Open ||
            self.order.statusCode === OrderStatusCode.Placed
          );
        },
        get isReadOnly() {
          return (
            localState.isReadOnly ||
            self.order.statusCode === OrderStatusCode.Rejected ||
            self.order.statusCode === OrderStatusCode.Cancelled
          );
        },
        get requiresModificationReason() {
          return (
            self.order.statusCode === OrderStatusCode.Draft &&
            self.order.version > 1 &&
            !self.order.modificationReason
          );
        },
        get canGeneratePdf() {
          return [
            OrderStatusCode.Placed,
            OrderStatusCode.Open,
            OrderStatusCode.AwaitingApproval,
          ].includes(self.order.statusCode);
        },
        canBeCancelled(user: IUserProfileDto): boolean {
          return (
            user.can(Permission.CancelOrders) &&
            ((self.order.statusCode === OrderStatusCode.Draft && self.order.version > 1) ||
              self.order.statusCode === OrderStatusCode.Open ||
              self.order.statusCode === OrderStatusCode.AwaitingApproval ||
              self.order.statusCode === OrderStatusCode.Placed)
          );
        },
      },
      actions: {
        resetVehicle: resetVehicle,
        clearVehicle: clearVehicle,
        setVehicle: setVehicle,
        setVehicleConfirmation: (vehicleConfirmationState: boolean) =>
          (self.vehicleIsConfirmed = vehicleConfirmationState),
        updateVehicle: flow(updateVehicle),
        addOrder: flow(addOrder),
        updateDetail: flow(updateDetail),
        clearOrder: clearOrder,
        loadOrder: flow(loadOrder),
        cancelEditingOrder: cancelEditingOrder,
        finaliseOrder: flow(finaliseOrder),
        approveOrder: flow(approveOrder),
        rejectOrder: flow(rejectOrder),
        cancelOrder: flow(cancelOrder),
        createNewDraftVersion: flow(createNewDraftVersion),
        orderPlaced: flow(orderPlaced),
        addExtra: addExtra,
        loadOrderVersions: flow(loadOrderVersions),
        downloadOrderPdf: flow(downloadOrderPdf),
        confirmSyncVehicleDialog: () => {
          self.vehicleOutOfSyncDialogModel = undefined;
        },
        deleteDraft: flow(deleteDraft)
      },
    };
  })
  .views(self => ({
    get isEditable() {
      return !self.isReadOnly && self.order.statusCode === OrderStatusCode.Draft;
    },
  }));
