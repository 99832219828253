import { AsAuCurrency } from 'infrastructure/formatUtils';
import React from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';
import styles from './SyncVibeVehicleDialog.module.scss';

interface ITextProps {
  text?: string;
}

const GreenText: React.FC<ITextProps> = ({ text }: ITextProps) => (
  <span className={styles.greenText}>{text}</span>
);
const RedText: React.FC<ITextProps> = ({ text }: ITextProps) => (
  <span className={styles.redText}>{text}</span>
);
const convertExtraToString = (extra: { description: string; rrp?: number }) =>
  `${extra.description}, RRP ${extra.rrp}`;

export const SyncVibeVehicleDialog: React.FC = () => {
  const { editOrderModel } = useStore();
  const model = editOrderModel.vehicleOutOfSyncDialogModel!;

  const extrasChanged =
    !!model.orderExtrasCompareResult.removed.length ||
    !!model.orderExtrasCompareResult.updated.length;

  const notifyAboutRrpChanged = model.oldRrp !== model.newRrp;

  return (
    <BasicSubmitCancelDialog
      title="Sync Vibe Vehicle"
      readonly={true}
      formik={{
        initialValues: {},
        onSubmit: editOrderModel.confirmSyncVehicleDialog,
      }}
      onClose={editOrderModel.confirmSyncVehicleDialog}
      content={
        <>
          {notifyAboutRrpChanged && (
            <p>
              Vehicle recommended retail price (RRP) has changed. Current RRP is{' '}
              <RedText text={AsAuCurrency(model.oldRrp)} /> and new RRP is{' '}
              <GreenText text={AsAuCurrency(model.newRrp)} />.
            </p>
          )}
          {extrasChanged && (
            <>
              <p>Vehicle extras have been changed:</p>
              <ul className={styles.extraList}>
                {model.orderExtrasCompareResult.removed.map((e, i) => (
                  <li key={`removed-${i}`}>
                    Removed <RedText text={`${e.description}`} />
                  </li>
                ))}
                {model.orderExtrasCompareResult.updated.map((e, i) => (
                  <li key={`update-${i}`}>
                    <RedText
                      text={convertExtraToString({
                        description: e.old.description,
                        rrp: e.old.price,
                      })}
                    />
                    &nbsp;has been updated to&nbsp;
                    <GreenText
                      text={convertExtraToString({
                        description: e.new.name,
                        rrp: e.new.price,
                      })}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
    />
  );
};
