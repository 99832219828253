import { ContractCustomerModel } from './repos/contracts/ContractCustomerModel';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { Permission } from 'api/enums/Permission';
import { StatusCode } from 'api/enums/StatusCode';
import { VehicleCondition } from 'api/enums/VehicleCondition';
import { IUpdateContractAssigneeCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractAssigneeCommandModel';
import { IUpdateContractCustomerCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractCustomerCommandModel';
import { IUpdateContractSettlementCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractSettlementCommandModel';
import { IUpdateContractSpecialConditionsCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractSpecialConditionsCommandModel';
import { IUpdateContractTradeInCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractTradeInCommandModel';
import { IUpdateContractVehicleCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateContractVehicleCommandModel';
import { IUpdateVehicleAssigneeCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/UpdateVehicleAssigneeCommandModel';
import { IVehicleDeliveredCommandModel } from 'api/models/Domain/Aggregates/Contract/Commands/VehicleDeliveredCommandModel';
import { HTTPError } from 'ky';
import { observable } from 'mobx';
import { cast, flow, getSnapshot, types } from 'mobx-state-tree';
import { AssociatedOrderModel } from './repos/contracts/AssociatedOrderModel';
import { AssociatedQuoteModel } from './repos/contracts/AssociatedQuoteModel';
import { ContractModel } from './repos/contracts/ContractModel';
import { ContractVersionModel } from './repos/contracts/ContractVersionModel';
import { emptyContract } from './repos/contracts/EmptyContract';
import { getAjax, getBus } from './RootStoreModel';
import { IUserProfileDto } from './singletons/SecurityModel';
import { CustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';

type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;
type ILoadContractResponse = Domain.Dtos.ISyncedContractDto;
type IUpdateContractResponse = Domain.Dtos.IContractDto;
type IDeleteContractResponse = Domain.Dtos.IContractDto;
type INewQuoteVersionResponse = Domain.Aggregates.Quote.Commands.NewQuoteVersionForContractCommand.INewQuoteVersionForContractCommandResponse;
type INewContractVersionResponse = Domain.Aggregates.Contract.Commands.NewContractVersionCommand.INewContractVersionResponse;
type IAssociatedQuoteResponse = Domain.Queries.Quote.IAssociatedQuoteDto;
type IContractVersionsResponse = Domain.Queries.Contract.GetContractVersionsQuery.IContractVersionDto[];
type ICreateOrderFromContractResponse = Domain.Aggregates.Order.Commands.CreateOrderFromContractCommand.ICreateOrderFromContractResponse;
type IAssociatedOrderResponse = Domain.Queries.Order.IAssociatedOrderDto;
type ICustomerDto = Domain.Queries.Customer.ICustomerDto;

interface ILoadingProcess {
  id: string;
  aborter: AbortController;
}

export type Section =
  | 'licensee'
  | 'customer'
  | 'vehicle'
  | 'trade-in'
  | 'settlement'
  | 'conditions'
  | undefined;

export const EditContractModel = types
  .model('EditContractModel', {
    contract: types.optional(ContractModel, () => ContractModel.create(emptyContract)),
    temporaryContract: types.optional(ContractModel, () => ContractModel.create(emptyContract)),
    failedToLoadContract: types.maybe(types.boolean),
    associatedQuote: types.maybe(AssociatedQuoteModel),
    versions: types.maybe(types.array(ContractVersionModel)),
    associatedOrder: types.maybe(AssociatedOrderModel),
    lmsCustomer: types.maybe(CustomerDtoModel),
    isCustomerOutOfSync: types.maybe(types.boolean),
  })
  .extend(self => {
    const localState = observable({
      contractLoading: null as ILoadingProcess | null,
      promptToModifyVersion: false,
      sectionToModify: undefined as Section,
      isReadOnly: true,
    });

    function clearContract() {
      localState.contractLoading?.aborter.abort();
      localState.contractLoading = null;
      localState.promptToModifyVersion = false;
      self.failedToLoadContract = false;
      self.associatedQuote = undefined;
      self.associatedOrder = undefined;
      self.versions = undefined;
      self.lmsCustomer = undefined;
      self.isCustomerOutOfSync = false;
      self.contract = ContractModel.create({ ...emptyContract });
      self.temporaryContract = ContractModel.create({ ...emptyContract });
    }

    function* loadContract(id: string, user: IUserProfileDto) {
      if (localState.contractLoading?.id === id) return;

      self.failedToLoadContract = false;
      try {
        localState.contractLoading = { id, aborter: new AbortController() };

        const dto: ILoadContractResponse = yield getAjax(self)
          .get(`/api/contracts/${id}`, {
            signal: localState.contractLoading?.aborter.signal,
          })
          .json();

        self.contract = cast(dto.contract);
        self.lmsCustomer = cast(dto.lmsCustomer);
        self.isCustomerOutOfSync = dto.customerOutOfSync;
        self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

        self.failedToLoadContract = false;
      } catch (exception) {
        if (exception instanceof HTTPError && exception.response.status >= 500) {
          self.failedToLoadContract = true;
        }
      } finally {
        localState.contractLoading = null;
      }

      localState.isReadOnly = !user.can(Permission.EditContract);
    }

    function* loadAssociatedQuote(id: string) {
      const dto: IAssociatedQuoteResponse = yield getAjax(self)
        .get(`/api/contracts/${id}/associated-quote`)
        .json();
      self.associatedQuote = dto ? cast(dto) : undefined;
    }

    function* loadAssociatedOrder(id: string) {
      const dto: IAssociatedOrderResponse = yield getAjax(self)
        .get(`/api/contracts/${id}/associated-order`)
        .json();
      self.associatedOrder = dto ? cast(dto) : undefined;
    }

    function* updateCustomer() {
      const contractId = self.temporaryContract.id;

      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/customer`, {
          json: {
            contractId: contractId,
            ...self.temporaryContract.customer,
          } as IUpdateContractCustomerCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* updateVehicle() {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/vehicle`, {
          json: {
            contractId: contractId,
            ...self.temporaryContract.vehicle,
          } as IUpdateContractVehicleCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* downloadContractPdf() {
      const response = yield getAjax(self).get(`/api/contracts/pdf/${self.contract.id}/contract`);
      return response.blob();
    }

    function* downloadFillablePdf(pdfKey: string) {
      const response = yield getAjax(self).get(
        `/api/contracts/pdf/${self.contract.id}/fillable-form?formKey=${pdfKey}`
      );
      return response.blob();
    }

    function* downloadStaticPdf(pdfKey: string) {
      const response = yield getAjax(self).get(
        `/api/contracts/pdf/${self.contract.id}/static-form?formKey=${pdfKey}`
      );
      return response.blob();
    }

    function* loadContractVersions() {
      const response: IContractVersionsResponse = yield getAjax(self)
        .get(`/api/contracts/${self.contract.number}/versions`)
        .json();
      self.versions = cast(response);
    }

    function* saveLicensee() {
      const contractId = self.temporaryContract.id;
      const contractResponse: IUpdateContractResponse = yield getAjax(self.temporaryContract)
        .put(`/api/contracts/${contractId}/licensee`, {
          json: {
            contractId,
            ...self.temporaryContract.licensee,
          },
        })
        .json();
      self.contract = cast(contractResponse);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* updateTradeIn() {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/tradeIn`, {
          json: {
            contractId: contractId,
            ...self.temporaryContract.tradeIn,
          } as IUpdateContractTradeInCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* updateSettlement() {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/settlement`, {
          json: {
            contractId: contractId,
            ...self.temporaryContract.settlement,
          } as IUpdateContractSettlementCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function cancelEditingContract() {
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* cancelContract() {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self.temporaryContract)
        .post(`/api/contracts/${contractId}/cancel`, { json: { contractId } })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Contract ${self.temporaryContract.numberAndVersion} has been cancelled`,
        level: 'success',
      });
    }

    function* finaliseContract() {
      const contractId = self.temporaryContract.id;
      const url = `${window.location.origin.toString()}/contracts/${contractId}`;

      const response: IUpdateContractResponse = yield getAjax(self.temporaryContract)
        .post(`/api/contracts/${contractId}/finalise`, { json: { contractId, url: url } })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Contract ${self.temporaryContract.numberAndVersion} has been finalised`,
        level: 'success',
      });
    }

    function* createOrderFromContract() {
      const contractId = self.temporaryContract.id;
      const contractResponse: ICreateOrderFromContractResponse = yield getAjax(self)
        .post(`/api/orders/from-contract/${contractId}`, { json: {} })
        .json();
      return contractResponse.orderId;
    }

    function* vehicleDelivered(deliveryDate: string) {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self.temporaryContract)
        .put(`/api/contracts/${contractId}/delivered`, {
          json: {
            contractId,
            deliveryDate,
          } as IVehicleDeliveredCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Contract vehicle ${self.temporaryContract.numberAndVersion} has been delivered`,
        level: 'success',
      });
    }

    function* modifyPricing() {
      if (!self.associatedQuote) return;
      const quoteId = self.associatedQuote.id;
      const contractResponse: INewQuoteVersionResponse = yield getAjax(self)
        .post(`/api/quotes/${quoteId}/new-version`, {
          json: { quoteId, contractId: self.contract.id },
        })
        .json();
      return contractResponse.id;
    }

    function* createNewDraftVersion() {
      if (!self.contract.isFinalised) return;
      const contractResponse: INewContractVersionResponse = yield getAjax(self)
        .post(`/api/contracts/${self.contract.id}/new-version`, { json: { id: self.contract.id } })
        .json();
      return contractResponse.contractId;
    }

    function* deleteDraftContract() {
      const contractResponse: IDeleteContractResponse = yield getAjax(self)
          .delete(`/api/contracts/${self.contract.id}?number=${self.contract.number}`)
          .json();

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Draft ${self.temporaryContract.numberAndVersion} has been deleted`,
        level: 'success',
      });

      return contractResponse?.id;
    }

    function* updateSpecialConditions() {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/special-conditions`, {
          json: {
            contractId: contractId,
            specialConditions: self.temporaryContract.specialConditions,
          } as IUpdateContractSpecialConditionsCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });
    }

    function* assignContract(user: IUserDto) {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/assign`, {
          json: {
            contractId: contractId,
            assignedTo: user.name,
            assignedToId: user.id,
          } as IUpdateContractAssigneeCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Contract ${self.temporaryContract.numberAndVersion} assigned to ${self.temporaryContract.assignedTo}`,
        level: 'success',
      });
    }

    function* assignVehicle(leadId: string, assetNo: string | undefined, salesPersonId: string, soldDate: string) {
      const contractId = self.temporaryContract.id;
      const response: IUpdateContractResponse = yield getAjax(self)
        .put(`/api/contracts/${contractId}/assignVehicle`, {
          json: {
            contractId: contractId,
            assignedToId: leadId,
            assetNo,
            salesPersonId,
            soldDate
          } as IUpdateVehicleAssigneeCommandModel,
        })
        .json();
      self.contract = cast(response);
      self.temporaryContract = ContractModel.create({ ...getSnapshot(self.contract) });

      yield getBus(self.temporaryContract).publish('ADD_NOTIFICATION', {
        message: `Vehicle with disposal order number ${response.vehicle.disposalOrderNo} assigned to Contract ${self.temporaryContract.numberAndVersion} 
        assetNo: ${response.vehicle.assetNo}`,
        level: 'success',
      });
    }

    function showPromptToModifyVersion(value: boolean, section?: Section) {
      localState.promptToModifyVersion = value;
      localState.sectionToModify = section;
    }

    function setSectionToModify(section: Section) {
      localState.sectionToModify = section;
    }

    const setCustomer = (customer: ICustomerDto | undefined) => {
      if (!customer) return;
      self.temporaryContract = {
        ...self.temporaryContract,
        customer: ContractCustomerModel.create({ ...customer, address: { ...customer.address }})
      };
    };

    return {
      actions: {
        clearContract,
        cancelEditingContract,
        loadContract: flow(loadContract),
        loadAssociatedQuote: flow(loadAssociatedQuote),
        saveLicensee: flow(saveLicensee),
        updateVehicle: flow(updateVehicle),
        updateCustomer: flow(updateCustomer),
        cancelContract: flow(cancelContract),
        finaliseContract: flow(finaliseContract),
        downloadContractPdf: flow(downloadContractPdf),
        downloadFillablePdf: flow(downloadFillablePdf),
        downloadStaticPdf: flow(downloadStaticPdf),
        updateTradeIn: flow(updateTradeIn),
        updateSettlement: flow(updateSettlement),
        modifyPricing: flow(modifyPricing),
        createNewDraftVersion: flow(createNewDraftVersion),
        updateSpecialConditions: flow(updateSpecialConditions),
        showPromptToModifyVersion: showPromptToModifyVersion,
        setSectionToModify: setSectionToModify,
        assignContract: flow(assignContract),
        assignVehicle:flow(assignVehicle),
        vehicleDelivered: flow(vehicleDelivered),
        loadContractVersions: flow(loadContractVersions),
        createOrderFromContract: flow(createOrderFromContract),
        loadAssociatedOrder: flow(loadAssociatedOrder),
        deleteDraftContract: flow(deleteDraftContract),
        setCustomer: setCustomer,
        confirmSyncCustomerDialog: () => {
          self.isCustomerOutOfSync = undefined;
        },
      },
      views: {
        get isLoadingContract() {
          return !!localState.contractLoading;
        },
        get canFinalise() {
          return self.contract.isValid;
        },
        get promptToModifyVersion() {
          return localState.promptToModifyVersion;
        },
        get sectionToModify() {
          return localState.sectionToModify;
        },
        isSectionToModify(section: Section) {
          return localState.sectionToModify === section;
        },
        get isReadOnly() {
          return (
            localState.isReadOnly ||
            self.contract.statusCode === ContractStatusCode.Delivered ||
            self.contract.statusCode === ContractStatusCode.Cancelled
          );
        },
        get canGenerateOrder() {
          return (
            !localState.isReadOnly && self.contract.isValid &&
            self.contract.statusCode === ContractStatusCode.Open &&
            self.contract.vehicle.condition === VehicleCondition.NewBuild &&
            !self.associatedOrder
          );
        },
        get canModifyPricing() {
          return self.contract.isValid;
        }
      }
    };
  })
  .views(self => ({
    get hasAssociatedQuoteThatIsBeingWorkedOn() {
      return (
        self.associatedQuote &&
        [
          StatusCode.Draft,
          StatusCode.Open,
          StatusCode.AwaitingApproval,
          StatusCode.Approved,
        ].includes(self.associatedQuote.statusCode)
      );
    },
    get hasInvalidAssociatedQuoteState() {
      return (self.associatedQuote && [
        StatusCode.Cancelled,
        StatusCode.OldVersion
      ].includes(self.associatedQuote.statusCode)
  )},
  }))
  .views(self => ({
    get isEditable() {
      return (
        !self.isReadOnly &&
        !self.hasAssociatedQuoteThatIsBeingWorkedOn &&
        !self.hasInvalidAssociatedQuoteState &&
        (self.contract.statusCode === ContractStatusCode.Draft ||
          self.contract.statusCode === ContractStatusCode.Open)
      );
    }
  }));
