import { types, Instance } from 'mobx-state-tree';
import { VehicleRebateDtoModel as DomainDtosVehicleRebateDtoModel } from './VehicleRebateDtoModel'

export const VehicleDtoModel = types.model('VehicleDtoModel', {
  assetNo: types.maybe(types.string),
  condition: types.maybe(types.frozen<Domain.Aggregates.Quote.Entities.VehicleCondition>()),
  discount: types.maybe(types.number),
  disposalOrderNo: types.maybe(types.string),
  floorPlan: types.maybe(types.string),
  idleLocation: types.maybe(types.string),
  make: types.maybe(types.string),
  model: types.maybe(types.string),
  price: types.maybe(types.number),
  rebate: types.maybe(DomainDtosVehicleRebateDtoModel),
  recommendedRetailPrice: types.maybe(types.number),
  registration: types.maybe(types.string),
  year: types.maybe(types.string),
});

export interface IVehicleDtoModel extends Instance<typeof VehicleDtoModel> {}
