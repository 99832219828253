export enum ContractStatusCode {
    Draft = 0,
    Open = 1,
    Cancelled = 2,
    Delivered = 3,
    OldVersion = 4
}
export function contractStatusCodeDescription(value: ContractStatusCode) {
    switch (value) {
        case ContractStatusCode.Draft:
            return `Draft`;
        case ContractStatusCode.Open:
            return `Open`;
        case ContractStatusCode.Cancelled:
            return `Cancelled`;
        case ContractStatusCode.Delivered:
            return `Delivered`;
        case ContractStatusCode.OldVersion:
            return `Archived`;
    }
}
export interface IContractStatusCodeDescriptor { value: ContractStatusCode; name: string; description: string; }
export var allContractStatusCode: IContractStatusCodeDescriptor[] = [
    { value: ContractStatusCode.Draft, name: `Draft`, description: `Draft` },
    { value: ContractStatusCode.Open, name: `Open`, description: `Open` },
    { value: ContractStatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` },
    { value: ContractStatusCode.Delivered, name: `Delivered`, description: `Delivered` },
    { value: ContractStatusCode.OldVersion, name: `OldVersion`, description: `Archived` }
];

export function getContractStatusCodeDescriptor(value: ContractStatusCode) {
    switch (value) {
        case ContractStatusCode.Draft:
            return { value: ContractStatusCode.Draft, name: `Draft`, description: `Draft` };
        case ContractStatusCode.Open:
            return { value: ContractStatusCode.Open, name: `Open`, description: `Open` };
        case ContractStatusCode.Cancelled:
            return { value: ContractStatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` };
        case ContractStatusCode.Delivered:
            return { value: ContractStatusCode.Delivered, name: `Delivered`, description: `Delivered` };
        case ContractStatusCode.OldVersion:
            return { value: ContractStatusCode.OldVersion, name: `OldVersion`, description: `Archived` };
    }
}
