import React from 'react';
import styles from './Button.module.scss';
import cn from 'classnames';
import { CircularProgress, Button as MuiButton, ButtonProps } from '@material-ui/core';
import { useComponentActive } from 'views/hooks';

type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface IButtonWithProgressProps extends ButtonProps {
  inProgress: boolean;
  alt?: boolean;
}

export const ButtonWithProgress: React.FC<IButtonWithProgressProps> = props => {
  const { inProgress, alt, className, ...rest } = props;
  return (
    <span className={styles['progress-container']}>
      <MuiButton
        type="button"
        variant="contained"
        color={alt ? 'secondary' : 'primary'}
        {...rest}
        className={cn(className, styles.button)}
      />
      {inProgress && (
        <CircularProgress size={24} thickness={5} color="secondary" className={styles.progress} />
      )}
    </span>
  );
};

interface IButtonProps extends ButtonProps {
  alt?: boolean;
  onClick?: (event: ClickEvent) => void | Promise<unknown>;
  component?: React.ElementType; // Is supported by MUI, but not currently in the TS types
  [key: string]: unknown; // forward all extra props
}

export const Button: React.FC<IButtonProps> = function(props) {
  const { alt, onClick, ...rest } = props;
  const componentActive = useComponentActive();
  const [inProgress, setInProgress] = React.useState<boolean>(false);

  const handleClick = (event: ClickEvent) => {
    setInProgress(true);
    const result = onClick && onClick(event);
    if (result) {
      result.finally(() => componentActive.current && setInProgress(false));
    } else {
      setInProgress(false);
    }
  };

  return (
    <ButtonWithProgress
      {...rest}
      alt={alt}
      onClick={onClick && handleClick}
      inProgress={inProgress}
    />
  );
};
