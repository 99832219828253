import { types, Instance } from 'mobx-state-tree';

export const OrderDetailDtoModel = types.model('OrderDetailDtoModel', {
  customerName: types.maybe(types.string),
  dealerContact: types.string,
  dealerReference: types.maybe(types.string),
  location: types.string,
  orderType: types.maybe(types.frozen<Domain.Aggregates.Order.Entities.OrderType>()),
  requestedDeliveryDate: types.maybe(types.string),
  soldDate: types.maybe(types.string),
});

export interface IOrderDetailDtoModel extends Instance<typeof OrderDetailDtoModel> {}
