import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingPane } from 'views/components/LoadingPane';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { useStore } from 'views/hooks';
import styles from '../../components/Listings.module.scss';
import { ContractStatus } from './components/ContractStatus';

export const ContractsList: React.FC = observer(function ContractsList() {
  const store = useStore();
  const history = useHistory();

  useEffect(() => {
    store.contractsRepo.loadContracts();
  }, [store.contractsRepo]);

  return (
    <>
      <FilterBar
        className={styles.filterbar}
        value={store.contractsRepo.filter}
        placeholder="Contract #, Customer or Vehicle"
        onChange={newValue => store.contractsRepo.loadContracts(newValue)}
      />
      <LoadingPane isLoading={store.contractsRepo.isLoadingContracts}>
        <Table className={styles.table}>
          <TableHead className={styles.tablehead}>
            <TableRow>
              <TableCell className={styles.number}>Contract #</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Vehicle Description</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Last Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.contractsRepo.contracts.map(contract => {
              const openContract = () => history.push(`/contracts/${contract.id}`);
              return (
                <TableRow key={contract.id} className={styles.row}>
                  <TableCell onClick={openContract}>{contract.numberAndVersion}</TableCell>
                  <TableCell onClick={openContract}>{contract.customerName}</TableCell>
                  <TableCell onClick={openContract}>{contract.vehicleDescription}</TableCell>
                  <TableCell onClick={openContract} align="center">
                    <ContractStatus statusCode={contract.status} />
                  </TableCell>
                  <TableCell onClick={openContract}>{contract.createdBy}</TableCell>
                  <TableCell onClick={openContract}>
                    {contract.lastModifiedAt.toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {store.contractsRepo.contracts.length === 0 && (
          <p className={styles.nodata}>There are no matching Contracts.</p>
        )}
        {store.contractsRepo.moreRecordsExist && <p>More records exist.</p>}
      </LoadingPane>
    </>
  );
});
