import { IApproveQuoteCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/ApproveQuoteCommandModel';
import { IEscalateQuoteApprovalCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/EscalateQuoteApprovalCommandModel';
import { IRejectQuoteCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/RejectQuoteCommandModel';
import { IQuoteDtoModel } from 'api/models/Domain/Dtos/QuoteDtoModel';
import { cast, flow, types } from 'mobx-state-tree';
import { emptyQuote } from './repos/quotes/EmptyQuote';
import { QuoteModel } from './repos/quotes/QuoteModel';
import { getAjax } from './RootStoreModel';

export const QuoteApprovalStore = types
  .model('QuoteApprovalStore', {
    quote: types.optional(QuoteModel, () => QuoteModel.create(emptyQuote)),
    isLoading: types.maybe(types.boolean),
  })
  .extend(self => {
    function* loadQuote(quoteNumber: number) {
      self.isLoading = true;
      const dto: IQuoteDtoModel = yield getAjax(self)
        .get(`/api/previewQuotes?quoteNumber=${quoteNumber}`)
        .json();

      self.quote = cast(dto);
      self.isLoading = false;
    }
    function * approve(quoteId: string, requestId: number, note: string) {
      const command: IApproveQuoteCommandModel = {
        quoteId: quoteId,
        requestId: requestId,
        note: note,
      };

      const dto = yield getAjax(self).put(`/api/quote-approvals/${quoteId}/approve`, { json: { ...command } }).json();
      self.quote = cast(dto);
    }

    function * reject(quoteId: string, requestId: number, note: string) {
      const command: IRejectQuoteCommandModel = {
        quoteId: quoteId,
        requestId: requestId,
        note: note,
      };

      const dto = yield getAjax(self).put(`/api/quote-approvals/${quoteId}/reject`, { json: { ...command } }).json();
      self.quote = cast(dto);
    }

    function * escalate(quoteId: string, requestId: number, assignedToId: string, note: string) {
      const command: IEscalateQuoteApprovalCommandModel = {
        quoteId: quoteId,
        requestId: requestId,
        note: note,
        assignedToId: assignedToId,
      };

      const dto = yield getAjax(self).put(`/api/quote-approvals/${quoteId}/escalate`, { json: { ...command } }).json();
      self.quote = cast(dto);
    }

    return {
      actions: {
        loadQuote: flow(loadQuote),
        approve: flow(approve),
        reject: flow(reject),
        escalate: flow(escalate),
      }
    }
  });