import { observer } from 'mobx-react-lite';
import React from 'react';
import { TextField } from 'views/components/forms/TextField';
import { useStore } from 'views/hooks';
import { FormSection } from 'views/components/forms/FormSection';

export const SpecialConditions: React.FC = observer(function SpecialConditions() {
  const { editContractModal } = useStore();
  const contract = editContractModal.temporaryContract;

  const save = async () => {
    await editContractModal.updateSpecialConditions();
  };

  return (
    <FormSection
      sectionTitle="Special Conditions / Promissory Notes"
      isFormActionAllowed={editContractModal.isEditable}
      expandedDefault={editContractModal.isSectionToModify('conditions')}
      editableDefault={false}
      canSave={true}
      canCancel={true}
      onSubmit={save}
      onCancel={editContractModal.cancelEditingContract}
      shouldModifyVersion={contract.isFinalised}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'conditions')}>
      {({ focusFieldRef, editable }) => (
        <>
          <TextField
            id="specialConditions"
            name="specialConditions"
            label="Special Conditions / Promissory Notes"
            readonly={!editable}
            value={contract.specialConditions ?? ''}
            onChange={event => contract.set('specialConditions', event.target.value)}
            multiline={true}
            rows={6}
          />
        </>
      )}
    </FormSection>
  );
});
