import { cast, flow, types } from 'mobx-state-tree';
import { getAjax } from '../RootStoreModel';
import { QuoteModel } from './quotes/QuoteModel';


type IQuoteDto = Domain.Dtos.IQuoteDto;

export const QuoteApprovalsRepo = types
  .model('QuoteApprovalsRepo', {
    quotes: types.array(QuoteModel),
    isLoading: types.maybe(types.boolean),
  })
  .extend(self => {
    function* loadQuotes() {
      self.isLoading = true;
      const dtos: IQuoteDto[] = yield getAjax(self).get(`/api/quote-approvals`).json();
      self.quotes = cast(dtos);
      self.isLoading = false;
    }

    return {
      actions: {
        loadQuotes: flow(loadQuotes),
      },
    };
  });