export enum StatusCode {
    Draft = 0,
    Open = 1,
    New = 2,
    Accepted = 3,
    Expired = 4,
    AwaitingApproval = 5,
    Approved = 6,
    OldVersion = 7,
    Cancelled = 8
}
export function statusCodeDescription(value: StatusCode) {
    switch (value) {
        case StatusCode.Draft:
            return `Draft`;
        case StatusCode.Open:
            return `Open`;
        case StatusCode.New:
            return `New`;
        case StatusCode.Accepted:
            return `Accepted`;
        case StatusCode.Expired:
            return `Expired`;
        case StatusCode.AwaitingApproval:
            return `Awaiting Approval`;
        case StatusCode.Approved:
            return `Approved`;
        case StatusCode.OldVersion:
            return `Archived`;
        case StatusCode.Cancelled:
            return `Cancelled`;
    }
}
export interface IStatusCodeDescriptor { value: StatusCode; name: string; description: string; }
export var allStatusCode: IStatusCodeDescriptor[] = [
    { value: StatusCode.Draft, name: `Draft`, description: `Draft` },
    { value: StatusCode.Open, name: `Open`, description: `Open` },
    { value: StatusCode.New, name: `New`, description: `New` },
    { value: StatusCode.Accepted, name: `Accepted`, description: `Accepted` },
    { value: StatusCode.Expired, name: `Expired`, description: `Expired` },
    { value: StatusCode.AwaitingApproval, name: `AwaitingApproval`, description: `Awaiting Approval` },
    { value: StatusCode.Approved, name: `Approved`, description: `Approved` },
    { value: StatusCode.OldVersion, name: `OldVersion`, description: `Archived` },
    { value: StatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` }
];

export function getStatusCodeDescriptor(value: StatusCode) {
    switch (value) {
        case StatusCode.Draft:
            return { value: StatusCode.Draft, name: `Draft`, description: `Draft` };
        case StatusCode.Open:
            return { value: StatusCode.Open, name: `Open`, description: `Open` };
        case StatusCode.New:
            return { value: StatusCode.New, name: `New`, description: `New` };
        case StatusCode.Accepted:
            return { value: StatusCode.Accepted, name: `Accepted`, description: `Accepted` };
        case StatusCode.Expired:
            return { value: StatusCode.Expired, name: `Expired`, description: `Expired` };
        case StatusCode.AwaitingApproval:
            return { value: StatusCode.AwaitingApproval, name: `AwaitingApproval`, description: `Awaiting Approval` };
        case StatusCode.Approved:
            return { value: StatusCode.Approved, name: `Approved`, description: `Approved` };
        case StatusCode.OldVersion:
            return { value: StatusCode.OldVersion, name: `OldVersion`, description: `Archived` };
        case StatusCode.Cancelled:
            return { value: StatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` };
    }
}
