import { StatusCode, statusCodeDescription } from 'api/enums/StatusCode';
import { QuoteDtoModel as QuoteListItemDtoModel } from 'api/models/Domain/Queries/Quote/GetQuotesQuery/QuoteDtoModel';
import { getAjax } from 'domain/store/RootStoreModel';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { observable } from 'mobx';
import { cast, flow, Instance, types } from 'mobx-state-tree';

type IQuotesDto = Domain.Queries.Quote.GetQuotesQuery.IQuotesDto;

const QuoteListItemModel = types
  .model('QuoteListItemModel', {
    ...QuoteListItemDtoModel.properties,
    lastModifiedAt: localDateTimeType,
  })
  .views(self => ({
    get isDraft(): boolean {
      return [StatusCode.Draft, StatusCode.AwaitingApproval, StatusCode.Approved]
        .includes(self.status);
    },
    get statusDescription(): string {
      return statusCodeDescription(self.status);
    }
  }));

interface ILoadingQuoteProcess {
  key: string;
  aborter: AbortController;
  reason: LoadingReason;
}

interface ILoadingQuotesProcess {
  filter: string;
  activeOnly: boolean;
  aborter: AbortController;
}

type LoadingReason = 'explicit' | 'update';

export const QuotesRepo = types
  .model('QuotesRepo', {
    quotes: types.array(QuoteListItemModel),
    moreRecordsExist: false,
    filter: '',
    opportunityId: '',
    activeOnly: false
  })
  .extend(self => {
    const localState = observable({
      quoteLoading: null as ILoadingQuoteProcess | null,
      quotesLoading: null as ILoadingQuotesProcess | null,
    });

    function* downloadQuotePdf(id: string) {
      const response = yield getAjax(self).get(`/api/pdf/${id}`);
      return response.blob();
    }

    function* loadQuotes(filter: string = '', activeOnly: boolean = false) {
      if (localState.quotesLoading?.filter === self.filter) {
        return;
      }
      self.filter = filter;
      self.activeOnly = activeOnly;
      localState.quotesLoading?.aborter.abort();
      try {
        localState.quotesLoading = {
          filter: self.filter,
          aborter: new AbortController(),
          activeOnly: self.activeOnly,
        };
        const dto: IQuotesDto = yield getAjax(self)
          .get(`/api/quotes`, {
            searchParams: { filter: self.filter, activeOnly: self.activeOnly },
            signal: localState.quotesLoading.aborter.signal,
          })
          .json();
        self.quotes = cast(dto.quotes);
        self.moreRecordsExist = dto.moreRecordsExist;
      } finally {
        localState.quotesLoading = null;
      }
    }

    return {
      views: {
        get loadingQuoteReason() {
          return localState.quoteLoading?.reason || null;
        },
        get isLoadingQuotes(): boolean {
          return !!localState.quotesLoading;
        },
      },
      actions: {
        loadQuotes: flow(loadQuotes),
        downloadQuotePdf: flow(downloadQuotePdf)
      },
    };
  });

export interface IQuotesRepo extends Instance<typeof QuotesRepo> { }
