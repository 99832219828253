import { OrderType } from 'api/enums/OrderType';
import { Permission } from 'api/enums/Permission';
import {
  IOrderDetailDtoModel,
  OrderDetailDtoModel
} from 'api/models/Domain/Dtos/OrderDetailDtoModel';
import { IRules, isValid, required, requiredString, validator } from 'infrastructure/validation';
import { observable } from 'mobx';
import { cast, SnapshotIn } from 'mobx-state-tree';
import { ISecurityModel } from '../../singletons/SecurityModel';

const rules: IRules<IOrderDetailDtoModel> = {
  location: requiredString,
  orderType: required,
  customerName: (val, form) => {
    if (form.orderType !== OrderType.Customer) return;
    return requiredString(val);
  },
  soldDate: (val, form) => {
    if (form.orderType !== OrderType.Customer) return;
    return requiredString(val);
  },
  requestedDeliveryDate:requiredString,
  dealerContact: requiredString,
  dealerReference: (val, form) => requiredString(val)
};

export const OrderDetailModel = OrderDetailDtoModel.named('OrderDetailModel')
  .extend(self => {
    const localState = observable({
      attemptedSubmit: false,
    });

    return {
      views: {
        get attemptedSubmitted() {
          return localState.attemptedSubmit;
        },
      },
      actions: {
        setAttemptedSubmitted: () => (localState.attemptedSubmit = true),
      },
    };
  })
  .views(self => ({
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      return isValid(self, rules);
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
    setDefaults(security: ISecurityModel) {
      self.dealerContact = security.currentUser.name;
      if (!self.location && security.locations.length === 1)
        self.location = security.locations[0].name;
      if (!security.currentUser.can(Permission.InitiateCustomerOrder))
        self.orderType = OrderType.Stock;
    },
  }));

export const emptyOrderDetail: IOrderDetailDtoModel = {
  customerName: '',
  dealerReference: '',
  dealerContact: '',
  location: '',
  orderType: undefined,
  requestedDeliveryDate: '',
  soldDate:''
};
