export enum Location {
    Geelong = 0,
    Adelaide = 1,
    Brisbane = 2,
    Newcastle = 3,
    Melbourne = 4,
    National = 5,
    Kratzmann = 6,
    SydneyRV = 7,
    GeorgeDay = 8,
    CaravansAway = 9,
    Cairns = 10
}
export function locationDescription(value: Location) {
    switch (value) {
        case Location.Geelong:
            return `Geelong`;
        case Location.Adelaide:
            return `Adelaide`;
        case Location.Brisbane:
            return `Brisbane`;
        case Location.Newcastle:
            return `Newcastle`;
        case Location.Melbourne:
            return `Melbourne`;
        case Location.National:
            return `National`;
        case Location.Kratzmann:
            return `Kratzmann Caravans & Motorhomes`;
        case Location.SydneyRV:
            return `Sydney RV Group`;
        case Location.GeorgeDay:
            return `George Day Caravans & Motorhomes`;
        case Location.CaravansAway:
            return `Caravans Away`;
        case Location.Cairns:
            return `Cairns`;
    }
}
export interface ILocationDescriptor { value: Location; name: string; description: string; }
export var allLocation: ILocationDescriptor[] = [
    { value: Location.Geelong, name: `Geelong`, description: `Geelong` },
    { value: Location.Adelaide, name: `Adelaide`, description: `Adelaide` },
    { value: Location.Brisbane, name: `Brisbane`, description: `Brisbane` },
    { value: Location.Newcastle, name: `Newcastle`, description: `Newcastle` },
    { value: Location.Melbourne, name: `Melbourne`, description: `Melbourne` },
    { value: Location.National, name: `National`, description: `National` },
    { value: Location.Kratzmann, name: `Kratzmann`, description: `Kratzmann Caravans & Motorhomes` },
    { value: Location.SydneyRV, name: `SydneyRV`, description: `Sydney RV Group` },
    { value: Location.GeorgeDay, name: `GeorgeDay`, description: `George Day Caravans & Motorhomes` },
    { value: Location.CaravansAway, name: `CaravansAway`, description: `Caravans Away` },
    { value: Location.Cairns, name: `Cairns`, description: `Cairns` }
];

export function getLocationDescriptor(value: Location) {
    switch (value) {
        case Location.Geelong:
            return { value: Location.Geelong, name: `Geelong`, description: `Geelong` };
        case Location.Adelaide:
            return { value: Location.Adelaide, name: `Adelaide`, description: `Adelaide` };
        case Location.Brisbane:
            return { value: Location.Brisbane, name: `Brisbane`, description: `Brisbane` };
        case Location.Newcastle:
            return { value: Location.Newcastle, name: `Newcastle`, description: `Newcastle` };
        case Location.Melbourne:
            return { value: Location.Melbourne, name: `Melbourne`, description: `Melbourne` };
        case Location.National:
            return { value: Location.National, name: `National`, description: `National` };
        case Location.Kratzmann:
            return { value: Location.Kratzmann, name: `Kratzmann`, description: `Kratzmann Caravans & Motorhomes` };
        case Location.SydneyRV:
            return { value: Location.SydneyRV, name: `SydneyRV`, description: `Sydney RV Group` };
        case Location.GeorgeDay:
            return { value: Location.GeorgeDay, name: `GeorgeDay`, description: `George Day Caravans & Motorhomes` };
        case Location.CaravansAway:
            return { value: Location.CaravansAway, name: `CaravansAway`, description: `Caravans Away` };
        case Location.Cairns:
            return { value: Location.Cairns, name: `Cairns`, description: `Cairns` };
    }
}
