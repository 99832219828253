import { types, Instance } from 'mobx-state-tree';

export const OrderDtoModel = types.model('OrderDtoModel', {
  cancellationReason: types.maybe(types.string),
  customerName: types.maybe(types.string),
  dealerContact: types.string,
  dealerReference: types.string,
  id: types.identifier,
  lastModifiedAt: types.string,
  modificationReason: types.maybe(types.string),
  numberAndVersion: types.string,
  orderType: types.frozen<Domain.Aggregates.Order.Entities.OrderType>(),
  statusCode: types.frozen<Domain.Aggregates.Order.Entities.OrderStatusCode>(),
  vehicleDescription: types.string,
});

export interface IOrderDtoModel extends Instance<typeof OrderDtoModel> {}
