import React from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IContractContractCancellationDialogProps {
  onClose(): void;
}

export const ContractCancellationDialog: React.FC<IContractContractCancellationDialogProps> = props => {
  const { editContractModal } = useStore();
  
  const cancelContract = async () => {
    if (!editContractModal) return;
    await editContractModal.cancelContract();
    props.onClose();
  };
  
  return(
    <BasicSubmitCancelDialog
      title="Cancel Contract"
      formik={{
        initialValues: {},
        onSubmit: cancelContract,
      }}
      onClose={() => props.onClose()}
      content={
        <div>
          <p>Are you sure you want to cancel this Contract?</p>
        </div>
      }
    />
  );
};