import { Chip } from '@material-ui/core';
import { StatusCode, statusCodeDescription } from 'api/enums/StatusCode';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './QuoteStatus.module.scss';

export interface IQuoteStatusProps {
  statusCode: StatusCode;
  className?: string;
}

const getStatusColor = (statusCode: StatusCode) => {
  switch (statusCode) {
    case StatusCode.Open:
      return styles.primary;
    case StatusCode.Accepted:
      return 'seagreen';
    case StatusCode.Expired:
      return 'red';
    case StatusCode.Cancelled:
        return 'red';
    case StatusCode.OldVersion:
      return 'red';
    case StatusCode.Draft:
      return 'grey';
    default:
      return 'grey';
  }
};

export const QuoteStatus: React.FC<IQuoteStatusProps> = observer(function QuoteStatus({
  statusCode,
  className,
}) {
  const statusColor = getStatusColor(statusCode);
  const labelText = statusCodeDescription(statusCode);
  return (
    <Chip
      className={className}
      label={labelText}
      component="span"
      color="primary"
      style={{ backgroundColor: statusColor, width: '6rem' }}
      variant="default"
    />
  );
});
