import { types, Instance } from 'mobx-state-tree';
import { ApplicableExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel } from './../../../Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel'
import { FittedExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel } from './../../../Domain/Queries/Vehicle/SearchVehicleDto/FittedExtraDtoModel'
import { VehicleRebateDtoModel as DomainDtosVehicleRebateDtoModel } from './../../../Domain/Dtos/VehicleRebateDtoModel'

export const SearchVehicleDtoModel = types.model('SearchVehicleDtoModel', {
  applicableExtras: types.array(DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel),
  assetNo: types.string,
  bodyColour: types.maybe(types.string),
  buildDate: types.maybe(types.string),
  compliancePlateDate: types.maybe(types.string),
  condition: types.frozen<Domain.Aggregates.Quote.Entities.VehicleCondition>(),
  disposalOrderNumber: types.maybe(types.string),
  engineNumber: types.maybe(types.string),
  engineSize: types.maybe(types.string),
  fittedExtras: types.array(DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel),
  floorPlan: types.maybe(types.string),
  idleLocation: types.maybe(types.string),
  make: types.string,
  minSell: types.maybe(types.number),
  model: types.string,
  odometer: types.maybe(types.number),
  price: types.maybe(types.number),
  rebate: types.maybe(DomainDtosVehicleRebateDtoModel),
  recommendedRetailPrice: types.maybe(types.number),
  registration: types.maybe(types.string),
  registrationExpiry: types.maybe(types.string),
  transmission: types.maybe(types.string),
  trimColour: types.maybe(types.string),
  type: types.maybe(types.string),
  vehicleAssetType: types.maybe(types.string),
  vinNumber: types.maybe(types.string),
  year: types.maybe(types.string),
});

export interface ISearchVehicleDtoModel extends Instance<typeof SearchVehicleDtoModel> {}
