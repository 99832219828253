import { CustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { StatusCode } from 'api/enums/StatusCode';
import { VehicleCondition } from 'api/enums/VehicleCondition';
import { ICancelQuoteApprovalCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/CancelQuoteApprovalCommandModel';
import { IRequestApprovalCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/RequestApprovalCommandModel';
import { IUpdateQuoteCustomerOfferCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/UpdateQuoteCustomerOfferCommandModel';
import { IUpdateQuoteLocationCommandModel } from 'api/models/Domain/Aggregates/Quote/Commands/UpdateQuoteLocationCommandModel';
import { HTTPError } from 'ky';
import { observable } from 'mobx';
import { cast, flow, getSnapshot, types } from 'mobx-state-tree';
import { Permission } from "../../api/enums/Permission";
import { IQuoteApprovedEvent, IQuoteRejectedEvent } from './bus/Bus';
import { AssociatedContractModel } from './repos/quotes/AssociatedContractModel';
import { emptyQuote } from './repos/quotes/EmptyQuote';
import { emptyQuoteCustomer, QuoteCustomerModel } from './repos/quotes/QuoteCustomerModel';
import { emptyQuoteDeposit, QuoteDepositModel } from './repos/quotes/QuoteDepositModel';
import { QuoteFittedExtraModel } from './repos/quotes/QuoteFittedExtraModel';
import { QuoteModel } from './repos/quotes/QuoteModel';
import { OptionalExtraModel } from './repos/quotes/QuoteOptionalExtraModel';
import { emptyQuoteTradeIn, QuoteTradeInModel } from './repos/quotes/QuoteTradeInModel';
import { QuoteVersionModel } from './repos/quotes/QuoteVersionModel';
import { emptyVehicle, VehicleModel } from './repos/quotes/VehicleModel';
import { getAjax, getBus } from './RootStoreModel';
import { SearchVehicleResultModel } from './SearchVehicleResultModel';
import { IUserProfileDto } from "./singletons/SecurityModel";
import { VehicleDiffModel } from './VehicleDiffModel';

type ISearchVehicleDto = Domain.Queries.Vehicle.ISearchVehicleDto;
type ILoadQuoteResponse = Domain.Dtos.ISyncedQuoteDto;
type IUpdateQuoteResponse = Domain.Dtos.IQuoteDto;
type ICustomerDto = Domain.Queries.Customer.ICustomerDto;
type IAddQuoteCommand = Domain.Aggregates.Quote.Commands.IAddQuoteCommand;
type IAddQuoteResponse = Domain.Aggregates.Quote.Commands.AddQuoteCommand.IAddQuoteResponse;
type IVehicleQuotesDto = Domain.Queries.Quote.GetQuotesForVehicleQuery.IVehicleQuotesDto;
type IUpdateQuoteCustomerCommand = Domain.Aggregates.Quote.Commands.IUpdateQuoteCustomerCommand;
type IUpdateQuoteVehicleCommand = Domain.Aggregates.Quote.Commands.IUpdateQuoteVehicleCommand;
type IUpdateQuoteTradeInCommand = Domain.Aggregates.Quote.Commands.IUpdateQuoteTradeInCommand;
type IUpdateQuoteNoteCommand = Domain.Aggregates.Quote.Commands.IUpdateQuoteNoteCommand;
type IAmendQuoteResponse = Domain.Aggregates.Quote.Commands.AmendQuoteCommand.IAmendQuoteResponse;
type IUpdateQuoteDepositCommand = Domain.Aggregates.Quote.Commands.IUpdateQuoteDepositCommand;
type ICreateContractFromQuoteResponse = Domain.Aggregates.Contract.Commands.CreateContractFromQuoteCommand.ICreateContractFromQuoteResponse;
type IAssociatedContractResponse = Domain.Queries.Contract.IAssociatedContractDto;
type IQuoteVersionsResponse = Domain.Queries.Quote.GetQuoteVersionsQuery.IQuoteVersionDto[];

interface ILoadingQuoteProcess {
  key: string;
  aborter: AbortController;
}

interface ILoadingProcess {
  id: string;
  aborter: AbortController;
}

export const EditQuoteModel = types
  .model('EditQuoteModel', {
    quote: types.optional(QuoteModel, () => QuoteModel.create(emptyQuote)),
    temporaryQuote: types.optional(QuoteModel, () => QuoteModel.create(emptyQuote)),
    vehicleOutOfSyncDialogModel: types.maybe(VehicleDiffModel),
    isCustomerOutOfSync: types.maybe(types.boolean),
    customerDetailsSynced: types.maybe(types.boolean),
    vibeVehicle: types.maybe(SearchVehicleResultModel),
    lmsCustomer: types.maybe(CustomerDtoModel),
    editCounter: types.number,
    vehicleIsConfirmed: types.boolean,
    customerIsConfirmed: types.boolean,
    otherQuotesForSameVehicle: types.array(types.string),
    failedToLoadQuote: types.maybe(types.boolean),
    associatedContract: types.maybe(AssociatedContractModel),
    isReadOnly: types.maybe(types.boolean),
    versions: types.maybe(types.array(QuoteVersionModel)),
    vehicleNotFound: types.maybe(types.boolean),
  })
  .extend(self => {
    const localState = observable({
      quoteLoading: null as ILoadingQuoteProcess | null,
      customerLoading: null as ILoadingProcess | null,
      searchingVehicle: null as ILoadingProcess | null,
    });

    function clearQuote() {
      localState.quoteLoading?.aborter.abort();
      localState.quoteLoading = null;
      self.failedToLoadQuote = false;
      self.vehicleNotFound = false;

      self.quote = QuoteModel.create({ ...emptyQuote });
      self.temporaryQuote = QuoteModel.create({ ...emptyQuote });
      self.vehicleOutOfSyncDialogModel = undefined;
      self.isCustomerOutOfSync = false;
      self.customerDetailsSynced = undefined;
      self.vibeVehicle = undefined;
      self.lmsCustomer = undefined;
      self.editCounter = 0;
      self.vehicleIsConfirmed = false;
      self.customerIsConfirmed = false;
      self.associatedContract = undefined;
    }

    function clearVehicle() {
      self.temporaryQuote = {
        ...self.temporaryQuote,
        vehicle: VehicleModel.create({ ...emptyVehicle }),
        fittedExtras: cast([]),
      };
    }
    function clearCustomer() {
      self.temporaryQuote = {
        ...self.temporaryQuote,
        customer: QuoteCustomerModel.create({ ...emptyQuoteCustomer }),
      };
    }

    function* loadCustomer(customerId: string) {
      if (localState.customerLoading?.id === customerId) {
        return;
      }

      localState.customerLoading?.aborter.abort();

      try {
        localState.customerLoading = { id: customerId, aborter: new AbortController() };
        const dto: ICustomerDto = yield fetchCustomer(customerId);
        self.quote = QuoteModel.create({
          ...emptyQuote,
          customer: QuoteCustomerModel.create({ ...dto }),
        });
        self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
      } finally {
        localState.customerLoading = null;
      }
    }

    function* loadQuote(id: string, user: IUserProfileDto) {
      if (localState.quoteLoading?.key === id) {
        return;
      }

      self.failedToLoadQuote = false;
      self.vehicleNotFound = false;

      try {
        localState.quoteLoading = { key: id, aborter: new AbortController() };

        const dto: ILoadQuoteResponse = yield getAjax(self)
          .get(`/api/quotes/${id}`, { signal: localState.quoteLoading?.aborter.signal })
          .json();

        self.quote = cast(dto.quote);
        self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
        self.vibeVehicle = cast(dto.vibeVehicle);
        self.lmsCustomer = cast(dto.lmsCustomer);

        if (dto.vehicleDiff) {
          self.vehicleOutOfSyncDialogModel = cast(dto.vehicleDiff);
        }

        if(dto.customerOutOfSync){
          self.isCustomerOutOfSync = cast(dto.customerOutOfSync);
        }

        if (self.quote.statusCode === StatusCode.Draft) {
          yield* alertIfQuotesExistsForVehicle();
        }

      } catch (exception) {
        if (exception instanceof HTTPError && exception.response.status === 404) {
          self.vehicleNotFound = true;
        }
        if (exception instanceof HTTPError && exception.response.status >= 500) {
          self.failedToLoadQuote = true;
        }
      } finally {
        localState.quoteLoading = null;
        self.vehicleIsConfirmed = !self.temporaryQuote.vehicle.isEmpty;
        self.customerIsConfirmed = !self.temporaryQuote.customer.isEmpty;
      }

      self.isReadOnly = !user.can(Permission.EditQuote);
    }

    function* loadAssociatedContract(id: string) {
      const dto: IAssociatedContractResponse = yield getAjax(self)
        .get(`/api/quotes/${id}/associated-contract`)
        .json();
      self.associatedContract = dto ? cast(dto) : undefined;
    }

    function* addQuote() {
      if (!self.temporaryQuote.location) {
        // TODO: is there a way to move this validation out?
        getBus(self).publish('ADD_NOTIFICATION', {
          level: 'warning',
          message: 'Please select a dealership',
        });
        return;
      }

      const command: IAddQuoteCommand = {
        leadId: self.temporaryQuote.leadId,
        opportunityId: self.temporaryQuote.opportunityId,
        customer: self.temporaryQuote.customer,
        vehicle: self.temporaryQuote.vehicle,
        fittedExtras: self.temporaryQuote.fittedExtras,
        location: self.temporaryQuote.location,
      };

      const dto: IAddQuoteResponse = yield getAjax(self)
        .post('/api/quotes/', { json: command })
        .json();
      return dto.id;
    }

    const setLeadId = (leadId: string) => {
      if (!leadId) return;
      self.temporaryQuote = {
        ...self.temporaryQuote,
        leadId: leadId
      };
    };

    const setCustomer = (customer: ICustomerDto | undefined) => {
      if (!customer) return;
      self.temporaryQuote = {
        ...self.temporaryQuote,
        customer: QuoteCustomerModel.create({ ...customer,address:{...customer.address} }),
      };
    };

    const setVehicle = (vehicle: ISearchVehicleDto | undefined) => {
      if (!vehicle) return;
      self.temporaryQuote = {
        ...self.temporaryQuote,
        subtotal: vehicle.price!,
        totalPrice: vehicle.price!,
        customerOffer: vehicle.price!,
        vehicle: VehicleModel.create({
          assetNo: vehicle.assetNo,
          disposalOrderNo: vehicle.disposalOrderNumber,
          condition: vehicle.condition,
          make: vehicle.make,
          model: vehicle.model,
          price: vehicle.price!,
          registration: vehicle.registration,
          year: vehicle.year?.toString(),
          discount: vehicle.recommendedRetailPrice! - vehicle.price!,
          floorPlan: vehicle.floorPlan,
          idleLocation: vehicle.idleLocation,
          rebate: vehicle.rebate,
          recommendedRetailPrice: vehicle.recommendedRetailPrice,
        }),
        fittedExtras: cast(
          vehicle.fittedExtras.map((extra, index) =>
            QuoteFittedExtraModel.create({
              code: extra.code,
              description: extra.name,
              recommendedRetailPrice: extra.recommendedRetailPrice ?? 0,
              quantity: extra.quantity ?? 0,
              unitPrice: extra.price ?? 0,
              parentCode: extra.parentCode,
              parentDescription: extra.parentName,
            })
          )
        ),
      };

      self.vibeVehicle = SearchVehicleResultModel.create({ ...vehicle });
    };

    const fetchCustomer = async (customerId: string): Promise<ICustomerDto> => {
      return await getAjax(self)
        .get(`/api/customers/${customerId}`)
        .json();
    };

    function* loadQuoteVersions() {
      const response: IQuoteVersionsResponse = yield getAjax(self)
        .get(`/api/quotes/${self.quote.number}/versions`)
        .json();
      self.versions = cast(response);
    }

    function* deleteDraft() {
      yield getAjax(self).delete(`/api/quotes/${self.quote.id}`);
      yield getBus(self.temporaryQuote).publish('ADD_NOTIFICATION', {
        message: `Draft ${self.temporaryQuote.numberAndVersion} has been deleted`,
        level: 'success',
      });
    }

    function resetVehicle() {
      self.temporaryQuote.vehicle = VehicleModel.create({ ...emptyVehicle });
      self.temporaryQuote.fittedExtras = cast([]);
      self.vibeVehicle = undefined;
      self.temporaryQuote.customerOffer = undefined;
      self.temporaryQuote.totalPrice = 0;
      self.temporaryQuote.subtotal = 0;
    }

    function resetCustomer() {
      self.temporaryQuote.customer = QuoteCustomerModel.create({ ...emptyQuoteCustomer });
    }

    function resetDeposit() {
      self.temporaryQuote.deposit = QuoteDepositModel.create({ ...emptyQuoteDeposit });
    }

    function resetTradeIn() {
      self.temporaryQuote.tradeIn = QuoteTradeInModel.create({ ...emptyQuoteTradeIn });
    }

    function startEditingQuote() {
      self.editCounter++;
    }

    function finishEditingQuote() {
      self.editCounter--;
    }

    function cancelEditingQuote() {
      self.editCounter--;
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* alertIfQuotesExistsForVehicle() {
      const { condition, assetNo, registration } = self.quote.vehicle;
      if (!assetNo || condition === VehicleCondition.NewBuild) {
        self.otherQuotesForSameVehicle = cast([]);
        return;
      }

      const existingQuotes: IVehicleQuotesDto = yield getAjax(self)
        .get(
          `/api/vehicles/currentQuotes/?assetNumber=${assetNo}&registrationNumber=${registration ||
            ''}`
        )
        .json();
      self.otherQuotesForSameVehicle = cast(existingQuotes.quotes.filter(a => a !== self.quote.id));
      if (self.otherQuotesForSameVehicle.length > 0) {
        getBus(self).publish('ADD_NOTIFICATION', {
          message: `Vehicle ${assetNo} has other open quotes`,
          level: 'warning',
        });
      }
    }

    function* updateCustomer() {
      const quoteId = self.temporaryQuote.id;
      const leadId = self.temporaryQuote.leadId;
      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self)
        .put(`/api/quotes/${quoteId}/customer`, {
          json: {
            quoteId: quoteId,
            leadId: leadId,
            ...self.temporaryQuote.customer,
         } as IUpdateQuoteCustomerCommand
        }).json();

      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* finaliseQuote() {
      const quoteId = self.temporaryQuote.id;
      const url = `${window.location.origin.toString()}/quotes/${quoteId}`;

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/finalise`, { json: { quoteId, url: url} })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });

      yield getBus(self.temporaryQuote).publish('ADD_NOTIFICATION', {
        message: `Quote ${self.temporaryQuote.numberAndVersion} has been finalised`,
        level: 'success',
      });
    }

    function* amendQuote() {
      const quoteId = self.temporaryQuote.id;
      const dto: IAmendQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/amend`, {
          json: { quoteId },
        })
        .json();

      return dto.id;
    }

    function* acceptQuote() {
      const quoteId = self.temporaryQuote.id;

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/accept`, { json: { quoteId } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });

      yield getBus(self.temporaryQuote).publish('ADD_NOTIFICATION', {
        message: `Quote ${self.temporaryQuote.quoteNumber} has been accepted`,
        level: 'success',
      });
    }

    function* createContractFromQuote() {
      const quoteId = self.temporaryQuote.id;
      const contractResponse: ICreateContractFromQuoteResponse = yield getAjax(self)
        .post(`/api/contracts/from-quote/${quoteId}`, { json: {} })
        .json();
      return contractResponse.contractId;
    }

    function* updateVehicle() {
      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteVehicleCommand = {
        quoteId: quoteId,
        vehicle: self.temporaryQuote.vehicle,
        fittedExtras: self.temporaryQuote.fittedExtras,
        optionalExtras: self.temporaryQuote.optionalExtras.filter(e => !e.deleted && e.description),
      };

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .put(`/api/quotes/${quoteId}/vehicle`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* updateTradeIn() {
      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteTradeInCommand = {
        quoteId: quoteId,
        ...self.temporaryQuote.tradeIn,
      };

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .put(`/api/quotes/${quoteId}/trade-in`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* updateNote(note?: string) {
      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteNoteCommand = { quoteId, note };
      yield getAjax(self).put(`/api/quotes/${quoteId}/note`, { json: command });
    }

    function* updateDeposit() {
      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteDepositCommand = {
        quoteId,
        depositPaymentType: self.temporaryQuote.deposit.paymentType,
        depositAmount: self.temporaryQuote.deposit.amount,
      };

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .put(`/api/quotes/${quoteId}/deposit`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function addExtra(user:IUserProfileDto) {
      self.temporaryQuote.optionalExtras = cast([
        ...self.temporaryQuote.optionalExtras,
        OptionalExtraModel.create({
          description: '',
          quantity: 1,
          unitPrice: 0,
          recommendedRetailPrice: 0,
          soldBy:user.name,
          soldById:user.externalId
        }),
      ]);
    }

    function updateLocation(location: string) {
      self.temporaryQuote.location = location;
    }

    function* saveLocation() {
      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteLocationCommandModel = {
        quoteId,
        location: self.temporaryQuote.location,
      };

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .put(`/api/quotes/${quoteId}/location`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* updateCustomerOffer(value: string) {
      const newOffer = parseFloat(value);

      self.temporaryQuote.customerOffer = newOffer;

      const quoteId = self.temporaryQuote.id;
      const command: IUpdateQuoteCustomerOfferCommandModel = {
        quoteId,
        amount: self.temporaryQuote.customerOffer,
      };

      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .put(`/api/quotes/${quoteId}/customerOffer`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* requestApproval(assignedToId: string) {
      const quoteId = self.temporaryQuote.id;
      const command: IRequestApprovalCommandModel = {
        quoteId,
        assignedToId,
      };
      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/request-approval`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* resendApprovalRequest(assignedToId: string) {
      const quoteId = self.temporaryQuote.id;
      const command: IRequestApprovalCommandModel = {
        quoteId,
        assignedToId,
      };
      const quoteRepsonse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/resend-approval-request`, { json: { ...command } })
        .json();
      self.quote = cast(quoteRepsonse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    function* cancelApprovalRequest() {
      const quoteId = self.temporaryQuote.id;
      const command: ICancelQuoteApprovalCommandModel = {
        quoteId
      };
      const quoteResponse: IUpdateQuoteResponse = yield getAjax(self.temporaryQuote)
        .post(`/api/quotes/${quoteId}/cancel-approval-request`, {
          json: { ...command }
        })
        .json();
      self.quote = cast(quoteResponse);
      self.temporaryQuote = QuoteModel.create({ ...getSnapshot(self.quote) });
    }

    return {
      views: {
        get isNewQuote() {
          return !self.quote.id;
        },
        get title() {
          if (!self.quote?.id) return 'New Quote';
          return `Quote ${self.quote?.numberAndVersion}`;
        },
        get isLoadingQuote() {
          return !!localState.quoteLoading;
        },
        get isLoadingCustomerDetails(): boolean {
          return !!localState.customerLoading;
        },
        get hasAssociatedContract(): boolean {
          return !!self.associatedContract;
        },
        get disableGenerateContract() {
          return !(
            self.quote.statusCode === StatusCode.Open &&
            self.associatedContract?.statusCode !== ContractStatusCode.Cancelled
          );
        },
        get disableDuplicateButton() {
          return self.editCounter > 0;
        }
      },
      actions: {
        clearQuote,
        addQuote: flow(addQuote),
        loadQuote: flow(loadQuote),
        loadAssociatedContract: flow(loadAssociatedContract),
        loadCustomer: flow(loadCustomer),
        resetVehicle: resetVehicle,
        resetCustomer: resetCustomer,
        resetDeposit: resetDeposit,
        resetTradeIn: resetTradeIn,
        startEditingQuote: startEditingQuote,
        finishEditingQuote: finishEditingQuote,
        cancelEditingQuote: cancelEditingQuote,
        alertIfQuotesExistsForVehicle: flow(alertIfQuotesExistsForVehicle),
        confirmSyncVehicleDialog: () => {
          self.vehicleOutOfSyncDialogModel = undefined;
        },
        confirmSyncCustomerDialog: () => {
          self.isCustomerOutOfSync = undefined;
        },
        setVehicleConfirmation: (vehicleConfirmationState: boolean) =>
          (self.vehicleIsConfirmed = vehicleConfirmationState),
        setCustomerConfirmation: (customerConfirmationState: boolean) =>
          (self.customerIsConfirmed = customerConfirmationState),
        updateTradeIn: flow(updateTradeIn),
        updateCustomer: flow(updateCustomer),
        updateVehicle: flow(updateVehicle),
        finaliseQuote: flow(finaliseQuote),
        amendQuote: flow(amendQuote),
        updateNote: flow(updateNote),
        acceptQuote: flow(acceptQuote),
        updateDeposit: flow(updateDeposit),
        updateLocation: updateLocation,
        saveLocation: flow(saveLocation),
        addExtra: addExtra,
        updateCustomerOffer: flow(updateCustomerOffer),
        requestApproval: flow(requestApproval),
        resendApprovalRequest: flow(resendApprovalRequest),
        cancelApprovalRequest: flow(cancelApprovalRequest),
        createContractFromQuote: flow(createContractFromQuote),
        loadQuoteVersions: flow(loadQuoteVersions),
        clearVehicle: clearVehicle,
        clearCustomer: clearCustomer,
        setVehicle: setVehicle,
        setCustomer: setCustomer,
        setLeadId: setLeadId,
        deleteDraft: flow(deleteDraft)
      },
    };
  })
  .views(self => ({
    get applicableExtras() {
      return self.vibeVehicle?.applicableExtras;
    },
    get isEditable() {
      return (
        !self.isReadOnly &&
        (self.quote.statusCode === StatusCode.New || self.quote.statusCode === StatusCode.Draft)
      );
    },
  }))
  .actions(self => ({
    afterAttach() {
      getBus(self).registerHandler('QUOTE_APPROVED', (e: IQuoteApprovedEvent) => {
        if (e.quoteId !== self.temporaryQuote.id || !self.temporaryQuote.latestApproval) return;
        self.temporaryQuote.setAsApproved(e.updatedBy, e.note);
        getBus(self).publish('ADD_NOTIFICATION', {
          message: `The quote has been approved`,
          level: 'success',
        });
      });
      getBus(self).registerHandler('QUOTE_REJECTED', (e: IQuoteRejectedEvent) => {
        if (e.quoteId !== self.temporaryQuote.id || !self.temporaryQuote.latestApproval) return;
        self.temporaryQuote.setAsRejected(e.updatedBy, e.note);
        getBus(self).publish('ADD_NOTIFICATION', {
          message: `The quote has been rejected`,
          level: 'warning',
        });
      });
    },
  }));
