/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'views/hooks';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import Alert from '@material-ui/lab/Alert';
import { Button, ButtonWithProgress, TextField } from 'views/components/forms';
import { GetDealerId } from 'infrastructure/mapLocationDealerId';
import { ICustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';
import styles from './SearchCustomerSection.module.scss';

interface ISearchCustomerSectionProps {
  onCancelEditing: () => void;
  onBeforeSearch?: () => void;
  onCustomerFound: (customer: ICustomerDtoModel | undefined) => void;
}

export const SearchCustomerSection: React.FC<ISearchCustomerSectionProps> = observer(
  (props) => {
    const focusFieldRef = useRef<HTMLInputElement | null>(null);
    const { customerSelection, editQuoteModel } = useStore();
    const history = useHistory();

    const [leadId, setLeadId] = useState('');
    const [touched, setTouched] = useState(false);

    const quote = editQuoteModel.temporaryQuote;
    const dealerId = GetDealerId(quote.location)! ;

    useEffect(() => {
      focusFieldRef.current && focusFieldRef.current.focus();
    }, []);

    const searchCustomer = async () => {
      if (!dealerId || !leadId) return null;
      if (props.onBeforeSearch) props.onBeforeSearch();

      const customer = await customerSelection.searchCustomer(dealerId, leadId);

      if(!customerSelection.notFound){
        props.onCustomerFound(customer);
        if(!quote.id){
          const id = await editQuoteModel.addQuote();
          if (id) history.replace(`/quotes/${id}`);
        }
      }
    };

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            <span className={cn(styles.title, styles.editingSection)}>Search Customer</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <div className={styles.tabBodyWrapper}>
              <div className={styles.fields}>
                <TextField
                  id="quote-customer-leadId"
                  className={styles.leadId}
                  name="leadId"
                  label="Lead Id"
                  inputRef={focusFieldRef}
                  value={leadId}
                  onChange={event => { setLeadId(event.target.value.replace(/\s/g, '')); editQuoteModel.setLeadId(event.target.value.replace(/\s/g, ''));}}
                />
                {(!leadId && touched) && (
                  <Alert className={styles.warning} severity="error">
                    Lead Id is required.
                  </Alert>
                )}
                {customerSelection.notFound && (
                  <Alert className={styles.warning} severity="warning">
                    DP360 lead either doesn't exist or lead status is 'closed'. Please search again.
                  </Alert>
                )}
              </div>
              <div className={styles.actions}>
                <ButtonWithProgress
                  type="submit"
                  inProgress={customerSelection.isSearchingCustomer}
                  onClick={ () => { setTouched(true); searchCustomer(); }}
                >
                  Load Customer
                </ButtonWithProgress>

                <div className={styles.spacer} aria-hidden="true" />

                <Button alt onClick={props.onCancelEditing}>
                  Cancel
                </Button>
              </div>
            </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
);