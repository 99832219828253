import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import styles from './VehicleSection.module.scss';
import { FormSection } from 'views/components/forms/FormSection';

export const VehicleSection: React.FC = observer(function VehicleSection() {
  const { editOrderModel, vehicleSelection } = useStore();

  const order = editOrderModel.temporaryOrder;
  const vehicle = order.vehicle;

  const save = async () => {
    await editOrderModel.updateVehicle();
    editOrderModel.setVehicleConfirmation(true);
  };

  const cancelEditing = () => {
    editOrderModel.cancelEditingOrder();
    editOrderModel.setVehicleConfirmation(!editOrderModel.temporaryOrder.vehicle.isEmpty);
  };

  const onClear = () => {
    editOrderModel.setVehicleConfirmation(!editOrderModel.temporaryOrder.vehicle.isEmpty);
    editOrderModel.resetVehicle();
    vehicleSelection.reset();
  };

  const editingByDefault =
    vehicle.isEmpty || editOrderModel.isNewOrder || vehicleSelection.vehicleFoundInSearch || false;
  const [isEditing] = React.useState<boolean>(editingByDefault);

  return (
    <FormSection
      sectionTitle={`Vehicle${vehicle.isEmpty ? '' : ': ' + vehicle.description}`}
      sectionSummary={`Vehicle Price: ${vehicle.priceFormatted}`}
      isFormActionAllowed={editOrderModel.isEditable && order.isFirstVersion}
      expandedDefault={isEditing}
      editableDefault={isEditing}
      clearBtnText="Search Again"
      canSave={true}
      saveBtnText={'Confirm'}
      canCancel={!editOrderModel.isNewOrder}
      onSubmit={save}
      onCancel={cancelEditing}
      onClear={onClear}
      shouldModifyVersion={false}>
      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            <TextField
              name="assetNo"
              label="Asset No"
              readonly={true}
              value={vehicle.assetNo ?? ''}
            />
            <TextField name="make" label="Make" readonly={true} value={vehicle.make ?? ''} />
            <TextField name="model" label="Model" readonly={true} value={vehicle.model ?? ''} />
            <TextField
              name="floorPlan"
              label="Floor Plan"
              readonly={true}
              value={vehicle.floorPlan ?? ''}
            />
            <TextField name="year" label="Year" readonly={true} value={vehicle.year ?? ''} />
            <CurrencyTextField
              name="price"
              label="Price"
              readonly={true}
              value={vehicle.price ?? ''}
            />
          </div>
        </>
      )}
    </FormSection>
  );
});
