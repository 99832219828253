import React, { useState } from 'react';
import { TextField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';
import { requiredString } from 'infrastructure/validation';

interface IRejectOrderDialogProps {
  onClose(): void;
}

export const RejectOrderDialog: React.FC<IRejectOrderDialogProps> = props => {
  const { editOrderModel } = useStore();
  const [rejectionReason, setRejectionReason] = useState('');
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const rejectOrder = async () => {
    await editOrderModel.rejectOrder(rejectionReason);
  };

  return (
    <BasicSubmitCancelDialog
      title="Reject Order Modification"
      formik={{
        initialValues: {},
        onSubmit: rejectOrder,
      }}
      onValidate={() => {
        setAttemptedSubmit(true);
        return !requiredString(rejectionReason);
      }}
      onClose={() => props.onClose()}
      content={
        <>
          <p>Are you sure you want to reject this Order modification?</p>
          <TextField
            name="rejectionReason"
            label="Reason for Rejection"
            value={rejectionReason}
            onChange={event => setRejectionReason(event.target.value)}
            multiline={true}
            rows={3}
            required={true}
            autoFocus={true}
            shrink={true}
            fullWidth={true}
            validationError={attemptedSubmit ? requiredString(rejectionReason) : undefined}
          />
        </>
      }
    />
  );
};
