import { types, Instance } from 'mobx-state-tree';
import { OrderExtrasCompareResultDtoModel as DomainDtosOrderExtrasCompareResultDtoModel } from './OrderExtrasCompareResultDtoModel'

export const OrderVehicleDiffDtoModel = types.model('OrderVehicleDiffDtoModel', {
  newRrp: types.maybe(types.number),
  oldRrp: types.maybe(types.number),
  orderExtrasCompareResult:DomainDtosOrderExtrasCompareResultDtoModel,
});

export interface IOrderVehicleDiffDtoModel extends Instance<typeof OrderVehicleDiffDtoModel> {}
