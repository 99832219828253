import { QuoteOptionalExtraDtoModel } from 'api/models/Domain/Dtos/QuoteOptionalExtraDtoModel';
import { IApplicableExtraDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel';
import { AsAuCurrency } from "infrastructure/formatUtils";
import { Instance, types } from 'mobx-state-tree';

export const OptionalExtraModel = QuoteOptionalExtraDtoModel.named('OptionalExtraModel')
  .props({
    deleted: types.maybe(types.boolean)
  })
  .views(self => ({
    get totalPrice() {
      return self.unitPrice * self.quantity;
    },
    get quantityValidationError(): string | undefined {
      if (self.maxQuantity !== undefined && self.quantity > self.maxQuantity) {
        return `This extra only allow max quantity of ${self.maxQuantity}`;
      }
      if (self.quantity <= 0) {
        return 'Invalid quantity';
      }
      return undefined;
    },
    get discount() {
      return (self.recommendedRetailPrice - self.unitPrice) * self.quantity;
    },
    get isVibeExtra() {
      return !!self.code;
    },
  }))
  .views(self => ({
    get recommendedRetailPriceFormatted() {
      return AsAuCurrency(self.recommendedRetailPrice);
    },

    get unitPriceFormatted() {
      return AsAuCurrency(self.unitPrice);
    },

    get totalPriceFormatted() {
      return AsAuCurrency(self.totalPrice);
    },

    get discountFormatted() {
      return self.discount > 0 ? AsAuCurrency(self.discount) : '';
    },

    get isInvalid(): boolean {
      return !!self.quantityValidationError;
    }
  }))
  .actions(self => ({
    softDelete: () => { self.deleted = true },
    setDescription: (value: string) => { self.description = value },
    setUnitPrice: (value?: number) => { self.unitPrice = value ?? 0 },
    setQuantity: (value?: number) => { self.quantity = value ?? 0 },
    setMaxQuantity: (value?: number) => { self.maxQuantity = value },
    set: (value: IApplicableExtraDtoModel | null | string) => {
      if (typeof value === 'string') {
        self.code = '';
        self.description = value;
        self.maxQuantity = undefined;
      } else {
        self.code = value?.code;
        self.description = value?.name ?? '';
        self.unitPrice = value?.price ?? 0;
        self.recommendedRetailPrice = value?.price ?? 0;
        self.quantity = 1;
        self.maxQuantity = value?.maxQuantity;
        self.parentCode = value?.parentCode;
        self.parentDescription = value?.parentName;
      }
    },
  }));


export interface IQuoteOptionalExtraModel extends Instance<typeof OptionalExtraModel> { } 
