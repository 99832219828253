import { ContractExtraType } from 'api/enums/ContractExtraType';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { ContractDtoModel } from 'api/models/Domain/Dtos/ContractDtoModel';
import { IOrderLine } from 'domain/store/types/IOrderLine';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { cast, SnapshotIn, types } from 'mobx-state-tree';
import { ContractCustomerModel } from './ContractCustomerModel';
import { ContractExtraModel } from './ContractExtraModel';
import { ContractSettlementModel } from './ContractSettlementModel';
import { ContractTradeInModel } from './ContractTradeInModel';
import { ContractVehicleModel } from './ContractVehicleModel';
import { ContractLicenseeModel } from './ContractLicenseeModel';
import { ContractPaymentType } from 'api/enums/ContractPaymentType';

type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;

export const ContractModel = types
  .model('ContractModel', {
    ...ContractDtoModel.properties,
    lastModifiedAt: localDateTimeType,
    customer: ContractCustomerModel,
    vehicle: ContractVehicleModel,
    extras: types.array(ContractExtraModel),
    settlement: ContractSettlementModel,
    tradeIn: ContractTradeInModel,
    licensee: ContractLicenseeModel,
  })
  .views(self => ({
    get isTradeInEmpty() {
      return Object.values(self.tradeIn).every(x => !x);
    },
    get subTotalFormatted() {
      return AsAuCurrency(self.subTotal);
    },
    get totalExtrasPriceFormatted() {
      return AsAuCurrency(self.totalExtrasPrice);
    },
    get totalBalancePayableFormatted() {
      return AsAuCurrency(self.totalBalancePayable);
    },
    get totalDiscountFormatted() {
      return AsAuCurrency(self.totalDiscount);
    },
    get totalShortfallFormatted() {
      return AsAuCurrency(self.totalShortfall);
    },
    get netTradeInAllowanceFormatted() {
      return AsAuCurrency(self.netTradeInAllowance);
    },
    get depositReceivedFormatted() {
      return AsAuCurrency(self.settlement.depositReceived);
    },
    get minimumDepositRequiredFormatted() {
      return AsAuCurrency(self.minimumDepositRequired);
    },
    get isTradeInDepositValid() {
      if (self.settlement.depositPaymentType !== ContractPaymentType.TradeIn) return true;
      return (self.settlement.depositReceived ?? 0) <= (self.tradeIn.netTradeInValue ?? 0);
    },
    get minimumDepositAmountSatisfiedLive() {
      if (self.settlement.depositPaymentType === ContractPaymentType.TradeIn) return true;
      return (self.settlement.depositReceived ?? 0) >= self.minimumDepositRequired;
    },
    get shortfallIsNegative() {
      return self.totalShortfall < 0;
    },
    get orderLines(): IOrderLine[] {
      const items = [
        {
          description: self.vehicle.makeAndModel,
          discountFormatted: self.vehicle.discount ? AsAuCurrency(self.vehicle.discount) : '',
          priceFormatted: AsAuCurrency(self.vehicle.rrp),
          quantity: 1,
          index: 0,
          productType: 'Vehicle Sell',
          lineTotalFormatted: AsAuCurrency(self.vehicle.price),
        } as IOrderLine,
        ...self.extras.map<IOrderLine>(e => ({
          description: e.code === '' ? `(D) ${e.description}`: e.description,
          discountFormatted: e.discount ? AsAuCurrency(e.discount) : '',
          priceFormatted: AsAuCurrency(e.price),
          quantity: e.quantity,
          index: 0,
          productType: e.type === ContractExtraType.Fitted ? 'Fitted Extra' : 'Optional Extra',
          lineTotalFormatted: AsAuCurrency(e.totalPrice),
        })),
      ];

      return items.map((item, i) => ({ ...item, index: i + 1 }));
    },
    get canBeCancelled() {
      return (
        self.statusCode === ContractStatusCode.Open
      );
    },
    get isFinalised() {
      return self.statusCode === ContractStatusCode.Open;
    },
    get assignee() {
      return {
        id: self.assignedToId,
        name: self.assignedTo,
      } as IUserDto;
    },
  }))
  .views(self => ({
    get isValid() {
      return (
        self.licensee.isValid &&
        self.tradeIn.isValid &&
        self.customer.isValid &&
        self.settlement.isValid &&
        self.vehicle.isValid &&
        self.isTradeInDepositValid
      );
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));
