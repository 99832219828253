import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;

interface ISalesPersonAutoCompleteProps {
  value?: IUserDto;
  validationError?: string;
  required?: boolean;
  readonly?: boolean;
  label?: string;
  onChange(user: IUserDto | undefined): void;
}

export const SalesPersonAutoComplete: React.FC<ISalesPersonAutoCompleteProps> = observer(
  function SalesPersonAutoComplete(props) {
    const { usersRepo } = useStore();
    const [open, setOpen] = React.useState(false);
    const loading = open && usersRepo.users.length === 0;

    React.useEffect(() => {
      if (!loading) return undefined;
      (async () => {
        await usersRepo.loadUsers();
      })();
    }, [loading, usersRepo]);

    return (
      <Autocomplete
        open={open}
        freeSolo={true}
        value={props.value}
        disabled={props.readonly}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name}
        options={usersRepo.users}
        onChange={(event: ChangeEvent<{}>, value: IUserDto | null) => props.onChange(value ?? undefined)}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label ?? 'Sales Person'}
            variant="outlined"
            size="small"
            margin="normal"
            required={props.required}
            error={!!props.validationError}
            helperText={props.validationError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }
);
