import { AppBar, Tab, Tabs } from '@material-ui/core';
import { Permission } from 'api/enums/Permission';
import React from 'react';
import { Link, Redirect, Route, Router, Switch, useHistory } from 'react-router-dom';
import { PermissionError } from 'views/components/application/PermissionError';
import { PageLayout } from 'views/components/layout/PageLayout';
import { ContractsList } from 'views/routes/contracts/ContractsList';
import { NotFound } from 'views/routes/notFound/NotFound';
import { QuotesList } from 'views/routes/quotes/QuotesList';
import { useStore } from '../../hooks';
import { OrdersList } from '../orders/components/OrdersList';
import styles from './Home.module.scss';

export const Home = () => {
  const quotes = '/home/quotes';
  const contracts = '/home/contracts';
  const orders = '/home/orders';
  const history = useHistory();
  const { security } = useStore();
  const contractFeatureEnabled = security.currentUser.contractFeatureEnabled;
  const orderFeatureEnabled = security.currentUser.orderFeatureEnabled;
  return (
    <PageLayout title="" parent="none">
      <Router history={history}>
        <Route
          path="/"
          render={({ match, history }) => (
            <>
              <AppBar position="relative" className={styles.tabs}>
                <Tabs
                  value={
                    history.location.pathname === '/home' ? quotes : history.location.pathname
                  }>
                  {security.currentUser.can(Permission.ViewQuote) &&
                    <Tab label="Quotes" value={quotes} component={Link} to={quotes} />}
                  {contractFeatureEnabled && security.currentUser.can(Permission.ViewContract) && (
                    <Tab label="Contracts" value={contracts} component={Link} to={contracts} />
                  )}
                  {orderFeatureEnabled && security.currentUser.can(Permission.ViewOrder) && (
                    <Tab label="Orders" value={orders} component={Link} to={orders} />
                  )}
                </Tabs>
              </AppBar>

              <Switch>
                {security.currentUser.can(Permission.ViewQuote) &&
                  <Route path={quotes}>
                    <div className={styles.tab}>
                      <QuotesList />
                    </div>
                  </Route>
                }
                {contractFeatureEnabled && security.currentUser.can(Permission.ViewContract) &&
                  <Route path={contracts}>
                    <div className={styles.tab}>
                      <ContractsList />
                    </div>
                  </Route>
                }
                {orderFeatureEnabled && security.currentUser.can(Permission.ViewOrder) &&
                  <Route path={orders}>
                    <div className={styles.tab}>
                      <OrdersList />
                    </div>
                  </Route>
                }
                <Route exact path="/home">
                  { security.currentUser.can(Permission.ViewQuote) ? <Redirect to={quotes} />
                    : security.currentUser.can(Permission.ViewOrder) ? <Redirect to={orders} />
                    : <PermissionError />}
                </Route>
                <Route>
                {security.currentUser.permissions.length === 0 ? <PermissionError />
                  : <NotFound />}
                </Route>
              </Switch>
            </>
          )}
        />
      </Router>
    </PageLayout>
  );
};
