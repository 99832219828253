export enum State {
    Qld = 0,
    Sa = 1,
    Vic = 2,
    Nsw = 3,
    Wa = 4,
    Tas = 5,
    Nt = 6,
    Act = 7,
    WTC = 8,
    NTL = 9,
    CAN = 10,
    AUK = 11,
    OTA = 12,
    MWT = 13,
    WKO = 14,
    STL = 15,
    MBH = 16,
    TASM = 17,
    WGN = 18,
    HKB = 19,
    BOP = 20,
    TKI = 21,
    GIS = 22,
    NSN = 23
}
export function stateDescription(value: State) {
    switch (value) {
        case State.Qld:
            return `QLD`;
        case State.Sa:
            return `SA`;
        case State.Vic:
            return `VIC`;
        case State.Nsw:
            return `NSW`;
        case State.Wa:
            return `WA`;
        case State.Tas:
            return `TAS`;
        case State.Nt:
            return `NT`;
        case State.Act:
            return `ACT`;
        case State.WTC:
            return `West Coast`;
        case State.NTL:
            return `Northland`;
        case State.CAN:
            return `Canterbury`;
        case State.AUK:
            return `Auckland`;
        case State.OTA:
            return `Otago`;
        case State.MWT:
            return `Manawatu-Wanganui`;
        case State.WKO:
            return `Waikato`;
        case State.STL:
            return `Southland`;
        case State.MBH:
            return `Marlborough`;
        case State.TASM:
            return `Tasman`;
        case State.WGN:
            return `Wellington`;
        case State.HKB:
            return `Hawke's Bay`;
        case State.BOP:
            return `Bay of Plenty`;
        case State.TKI:
            return `Taranaki`;
        case State.GIS:
            return `Gisborne`;
        case State.NSN:
            return `Nelson`;
    }
}
export interface IStateDescriptor { value: State; name: string; description: string; }
export var allState: IStateDescriptor[] = [
    { value: State.Qld, name: `Qld`, description: `QLD` },
    { value: State.Sa, name: `Sa`, description: `SA` },
    { value: State.Vic, name: `Vic`, description: `VIC` },
    { value: State.Nsw, name: `Nsw`, description: `NSW` },
    { value: State.Wa, name: `Wa`, description: `WA` },
    { value: State.Tas, name: `Tas`, description: `TAS` },
    { value: State.Nt, name: `Nt`, description: `NT` },
    { value: State.Act, name: `Act`, description: `ACT` },
    { value: State.WTC, name: `WTC`, description: `West Coast` },
    { value: State.NTL, name: `NTL`, description: `Northland` },
    { value: State.CAN, name: `CAN`, description: `Canterbury` },
    { value: State.AUK, name: `AUK`, description: `Auckland` },
    { value: State.OTA, name: `OTA`, description: `Otago` },
    { value: State.MWT, name: `MWT`, description: `Manawatu-Wanganui` },
    { value: State.WKO, name: `WKO`, description: `Waikato` },
    { value: State.STL, name: `STL`, description: `Southland` },
    { value: State.MBH, name: `MBH`, description: `Marlborough` },
    { value: State.TASM, name: `TASM`, description: `Tasman` },
    { value: State.WGN, name: `WGN`, description: `Wellington` },
    { value: State.HKB, name: `HKB`, description: `Hawke's Bay` },
    { value: State.BOP, name: `BOP`, description: `Bay of Plenty` },
    { value: State.TKI, name: `TKI`, description: `Taranaki` },
    { value: State.GIS, name: `GIS`, description: `Gisborne` },
    { value: State.NSN, name: `NSN`, description: `Nelson` }
];

export function getStateDescriptor(value: State) {
    switch (value) {
        case State.Qld:
            return { value: State.Qld, name: `Qld`, description: `QLD` };
        case State.Sa:
            return { value: State.Sa, name: `Sa`, description: `SA` };
        case State.Vic:
            return { value: State.Vic, name: `Vic`, description: `VIC` };
        case State.Nsw:
            return { value: State.Nsw, name: `Nsw`, description: `NSW` };
        case State.Wa:
            return { value: State.Wa, name: `Wa`, description: `WA` };
        case State.Tas:
            return { value: State.Tas, name: `Tas`, description: `TAS` };
        case State.Nt:
            return { value: State.Nt, name: `Nt`, description: `NT` };
        case State.Act:
            return { value: State.Act, name: `Act`, description: `ACT` };
        case State.WTC:
            return { value: State.WTC, name: `WTC`, description: `West Coast` };
        case State.NTL:
            return { value: State.NTL, name: `NTL`, description: `Northland` };
        case State.CAN:
            return { value: State.CAN, name: `CAN`, description: `Canterbury` };
        case State.AUK:
            return { value: State.AUK, name: `AUK`, description: `Auckland` };
        case State.OTA:
            return { value: State.OTA, name: `OTA`, description: `Otago` };
        case State.MWT:
            return { value: State.MWT, name: `MWT`, description: `Manawatu-Wanganui` };
        case State.WKO:
            return { value: State.WKO, name: `WKO`, description: `Waikato` };
        case State.STL:
            return { value: State.STL, name: `STL`, description: `Southland` };
        case State.MBH:
            return { value: State.MBH, name: `MBH`, description: `Marlborough` };
        case State.TASM:
            return { value: State.TASM, name: `TASM`, description: `Tasman` };
        case State.WGN:
            return { value: State.WGN, name: `WGN`, description: `Wellington` };
        case State.HKB:
            return { value: State.HKB, name: `HKB`, description: `Hawke's Bay` };
        case State.BOP:
            return { value: State.BOP, name: `BOP`, description: `Bay of Plenty` };
        case State.TKI:
            return { value: State.TKI, name: `TKI`, description: `Taranaki` };
        case State.GIS:
            return { value: State.GIS, name: `GIS`, description: `Gisborne` };
        case State.NSN:
            return { value: State.NSN, name: `NSN`, description: `Nelson` };
    }
}
