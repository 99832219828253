import { StatusCode } from "api/enums/StatusCode";
import { IQuoteDtoModel } from 'api/models/Domain/Dtos/QuoteDtoModel';
import { DateTime } from "luxon";
import { cast } from "mobx-state-tree";
import { emptyQuoteCustomer } from "./QuoteCustomerModel";
import { emptyQuoteDeposit } from "./QuoteDepositModel";
import { emptyQuoteTradeIn } from "./QuoteTradeInModel";
import { emptyVehicle } from "./VehicleModel";

export const emptyQuote: IQuoteDtoModel = {
  id: '',
  quoteNumber: 0,
  numberAndVersion: '',
  number: '',
  location: '',
  statusCode: StatusCode.New,
  lastModifiedAt: DateTime.local().toString(),
  leadId:'',
  customer: { ...emptyQuoteCustomer },
  vehicle: { ...emptyVehicle },
  tradeIn: { ...emptyQuoteTradeIn },
  deposit: { ...emptyQuoteDeposit },
  fittedExtras: cast([]),
  optionalExtras: cast([]),
  subtotal: 0,
  totalNonVibeExtrasPrice: 0,
  totalVibeExtrasPrice: 0,
  totalExtrasPrice: 0,
  totalPrice: 0,
  note: undefined,
  opportunityId: undefined,
  opportunityInstanceId: undefined,
  customerOffer: undefined,
  latestApproval: undefined,
  belowMinSell: false,
  belowMinSellAmount: 0,
  belowRecommendedRetailPrice: false,
  minSell: 0,
  recommendedRetailPrice: 0,
};
