import { types, Instance } from 'mobx-state-tree';
import { OrderExtraDtoModel as DomainDtosOrderExtraDtoModel } from './OrderExtraDtoModel'
import { UpdatedOrderExtraDtoModel as DomainDtosUpdatedOrderExtraDtoModel } from './UpdatedOrderExtraDtoModel'

export const OrderExtrasCompareResultDtoModel = types.model('OrderExtrasCompareResultDtoModel', {
  removed: types.array(DomainDtosOrderExtraDtoModel),
  updated: types.array(DomainDtosUpdatedOrderExtraDtoModel),
});

export interface IOrderExtrasCompareResultDtoModel extends Instance<typeof OrderExtrasCompareResultDtoModel> {}
