export enum QuoteApprovalStatus {
    New = 0,
    Approved = 1,
    Rejected = 2,
    Escalated = 3,
    Cancelled = 4
}
export function quoteApprovalStatusDescription(value: QuoteApprovalStatus) {
    switch (value) {
        case QuoteApprovalStatus.New:
            return `New`;
        case QuoteApprovalStatus.Approved:
            return `Approved`;
        case QuoteApprovalStatus.Rejected:
            return `Rejected`;
        case QuoteApprovalStatus.Escalated:
            return `Escalated`;
        case QuoteApprovalStatus.Cancelled:
            return `Cancelled`;
    }
}
export interface IQuoteApprovalStatusDescriptor { value: QuoteApprovalStatus; name: string; description: string; }
export var allQuoteApprovalStatus: IQuoteApprovalStatusDescriptor[] = [
    { value: QuoteApprovalStatus.New, name: `New`, description: `New` },
    { value: QuoteApprovalStatus.Approved, name: `Approved`, description: `Approved` },
    { value: QuoteApprovalStatus.Rejected, name: `Rejected`, description: `Rejected` },
    { value: QuoteApprovalStatus.Escalated, name: `Escalated`, description: `Escalated` },
    { value: QuoteApprovalStatus.Cancelled, name: `Cancelled`, description: `Cancelled` }
];

export function getQuoteApprovalStatusDescriptor(value: QuoteApprovalStatus) {
    switch (value) {
        case QuoteApprovalStatus.New:
            return { value: QuoteApprovalStatus.New, name: `New`, description: `New` };
        case QuoteApprovalStatus.Approved:
            return { value: QuoteApprovalStatus.Approved, name: `Approved`, description: `Approved` };
        case QuoteApprovalStatus.Rejected:
            return { value: QuoteApprovalStatus.Rejected, name: `Rejected`, description: `Rejected` };
        case QuoteApprovalStatus.Escalated:
            return { value: QuoteApprovalStatus.Escalated, name: `Escalated`, description: `Escalated` };
        case QuoteApprovalStatus.Cancelled:
            return { value: QuoteApprovalStatus.Cancelled, name: `Cancelled`, description: `Cancelled` };
    }
}
