import { types, Instance } from 'mobx-state-tree';

export const ContractSettlementDtoModel = types.model('ContractSettlementDtoModel', {
  amountFinanced: types.maybe(types.number),
  depositPaymentType: types.maybe(types.frozen<Domain.Aggregates.Contract.Entities.ContractPaymentType>()),
  depositReceived: types.maybe(types.number),
  financeProvider: types.maybe(types.string),
  otherPayment: types.maybe(types.number),
});

export interface IContractSettlementDtoModel extends Instance<typeof ContractSettlementDtoModel> {}
