import { types, Instance } from 'mobx-state-tree';

export const VehicleDtoModel = types.model('VehicleDtoModel', {
  assetNo: types.string,
  assetType: types.string,
  benchTop: types.string,
  buildDate: types.string,
  extraPrice: types.maybe(types.number),
  hardFurnishing: types.string,
  idleLocation: types.string,
  odometer: types.maybe(types.number),
  primaryPrice: types.maybe(types.number),
  recommendedRetailPrice: types.maybe(types.number),
  softFurnishing: types.string,
  totalPrice: types.maybe(types.number),
});

export interface IVehicleDtoModel extends Instance<typeof VehicleDtoModel> {}
