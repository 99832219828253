import { types, Instance } from 'mobx-state-tree';
import { AddressDtoModel as DomainDtosAddressDtoModel } from './AddressDtoModel'

export const ContractCustomerDtoModel = types.model('ContractCustomerDtoModel', {
  abn: types.maybe(types.string),
  address: types.maybe(DomainDtosAddressDtoModel),
  companyName: types.maybe(types.string),
  contactId: types.maybe(types.string),
  dob: types.maybe(types.string),
  driversLicense: types.maybe(types.string),
  email: types.maybe(types.string),
  financeQuoteRequest: types.maybe(types.boolean),
  firstName: types.string,
  fullName: types.string,
  insuranceQuoteRequest: types.maybe(types.boolean),
  phone: types.maybe(types.string),
  phoneAlternate: types.maybe(types.string),
  secondaryDob: types.maybe(types.string),
  secondaryDriversLicense: types.maybe(types.string),
  secondaryFirstName: types.maybe(types.string),
  secondarySurname: types.maybe(types.string),
  surname: types.string,
});

export interface IContractCustomerDtoModel extends Instance<typeof ContractCustomerDtoModel> {}
