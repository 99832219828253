import { DateTime } from 'luxon';

export function AsAuCurrency(value: number | undefined) {
  return value?.toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
  });
}

export function AsFormattedDateTime(value: string) {
  return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
}

export function AsFormattedDate(value: DateTime | undefined |string ) {
  if (value === undefined) return "";
  if (typeof(value) === "string") value = DateTime.fromISO(value);
  return value!.toLocaleString(DateTime.DATE_MED); 
} 

  
