import { types, Instance } from 'mobx-state-tree';
import { OrderDetailDtoModel as DomainDtosOrderDetailDtoModel } from './OrderDetailDtoModel'
import { OrderExtraDtoModel as DomainDtosOrderExtraDtoModel } from './OrderExtraDtoModel'
import { OrderVehicleDtoModel as DomainDtosOrderVehicleDtoModel } from './OrderVehicleDtoModel'

export const OrderDtoModel = types.model('OrderDtoModel', {
  cancellationReason: types.maybe(types.string),
  detail:DomainDtosOrderDetailDtoModel,
  externalOrderId: types.maybe(types.string),
  externalStatus: types.maybe(types.string),
  extras: types.array(DomainDtosOrderExtraDtoModel),
  id: types.identifier,
  lastModifiedAt: types.string,
  modificationCutoffDate: types.maybe(types.string),
  modificationReason: types.maybe(types.string),
  number: types.string,
  numberAndVersion: types.string,
  plannedCompletionDate: types.maybe(types.string),
  rejectionReason: types.maybe(types.string),
  statusCode: types.frozen<Domain.Aggregates.Order.Entities.OrderStatusCode>(),
  totalExtrasPrice: types.number,
  totalPrice: types.number,
  vehicle:DomainDtosOrderVehicleDtoModel,
  version: types.integer,
});

export interface IOrderDtoModel extends Instance<typeof OrderDtoModel> {}
