import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { QuoteStatus } from './QuoteStatus';
import { IQuoteVersionModel } from 'domain/store/repos/quotes/QuoteVersionModel';
import { HistoryDialog } from 'views/components/dialogs/HistoryDialog';

interface IQuoteHistoryDialogProps {
  onClose(): void;
}

export const QuoteHistoryDialog: React.FC<IQuoteHistoryDialogProps> = observer(props => {
  const { editQuoteModel } = useStore();
  useEffect(() => {
    (async () => {
      await editQuoteModel.loadQuoteVersions();
    })();
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryDialog
      title="Quote History"
      items={(editQuoteModel.versions || ([] as IQuoteVersionModel[])).map(v => ({
        number: <Link to={`/quotes/${v.id}`}>{v.numberAndVersion}</Link>,
        status: <QuoteStatus statusCode={v.status} />,
        lastModifiedAt: v.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED),
        lastModifiedBy: v.lastModifiedBy,
      }))}
      onClose={props.onClose}
    />
  );
});
