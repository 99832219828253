export enum OrderStatusCode {
    Draft = 0,
    Open = 1,
    OldVersion = 2,
    AwaitingApproval = 3,
    Rejected = 4,
    Placed = 5,
    Cancelled = 6
}
export function orderStatusCodeDescription(value: OrderStatusCode) {
    switch (value) {
        case OrderStatusCode.Draft:
            return `Draft`;
        case OrderStatusCode.Open:
            return `Open`;
        case OrderStatusCode.OldVersion:
            return `Archived`;
        case OrderStatusCode.AwaitingApproval:
            return `Awaiting Approval`;
        case OrderStatusCode.Rejected:
            return `Rejected`;
        case OrderStatusCode.Placed:
            return `Placed`;
        case OrderStatusCode.Cancelled:
            return `Cancelled`;
    }
}
export interface IOrderStatusCodeDescriptor { value: OrderStatusCode; name: string; description: string; }
export var allOrderStatusCode: IOrderStatusCodeDescriptor[] = [
    { value: OrderStatusCode.Draft, name: `Draft`, description: `Draft` },
    { value: OrderStatusCode.Open, name: `Open`, description: `Open` },
    { value: OrderStatusCode.OldVersion, name: `OldVersion`, description: `Archived` },
    { value: OrderStatusCode.AwaitingApproval, name: `AwaitingApproval`, description: `Awaiting Approval` },
    { value: OrderStatusCode.Rejected, name: `Rejected`, description: `Rejected` },
    { value: OrderStatusCode.Placed, name: `Placed`, description: `Placed` },
    { value: OrderStatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` }
];

export function getOrderStatusCodeDescriptor(value: OrderStatusCode) {
    switch (value) {
        case OrderStatusCode.Draft:
            return { value: OrderStatusCode.Draft, name: `Draft`, description: `Draft` };
        case OrderStatusCode.Open:
            return { value: OrderStatusCode.Open, name: `Open`, description: `Open` };
        case OrderStatusCode.OldVersion:
            return { value: OrderStatusCode.OldVersion, name: `OldVersion`, description: `Archived` };
        case OrderStatusCode.AwaitingApproval:
            return { value: OrderStatusCode.AwaitingApproval, name: `AwaitingApproval`, description: `Awaiting Approval` };
        case OrderStatusCode.Rejected:
            return { value: OrderStatusCode.Rejected, name: `Rejected`, description: `Rejected` };
        case OrderStatusCode.Placed:
            return { value: OrderStatusCode.Placed, name: `Placed`, description: `Placed` };
        case OrderStatusCode.Cancelled:
            return { value: OrderStatusCode.Cancelled, name: `Cancelled`, description: `Cancelled` };
    }
}
