import { IQuoteDepositDtoModel, QuoteDepositDtoModel } from "api/models/Domain/Dtos/QuoteDepositDtoModel";
import { AsAuCurrency } from "infrastructure/formatUtils";

export const QuoteDepositModel = QuoteDepositDtoModel.named('QuoteDepositModel')
  .views(self => ({
    get amountFormatted() {
      return AsAuCurrency(self.amount);
    },
    get validationError(): string | undefined {
      return self.paymentType && !self.amount
        ? 'Deposit amount is required'
        : undefined;
    },
  }))
  .actions(self => ({
    updatePaymentType: (value?: string) => { self.paymentType = value ? parseInt(value, 10) : undefined },
    updateAmount: (value?: number) => { self.amount = value }
  }));

export const emptyQuoteDeposit: IQuoteDepositDtoModel = {
  amount: undefined,
  paymentType: undefined,
  isEmpty: true,
};
