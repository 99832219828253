import React, { useState } from 'react';
import { TextField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';
import { requiredString } from 'infrastructure/validation';

interface IOrderCancellationDialogProps {
  onClose(): void;
}

export const OrderCancellationDialog: React.FC<IOrderCancellationDialogProps> = props => {
  const { editOrderModel } = useStore();
  const [cancellationReason, setCancellationReason] = useState('');
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const cancelOrder = async () => {
    if (!editOrderModel) return;
    await editOrderModel.cancelOrder(cancellationReason);
  };

  return (
    <BasicSubmitCancelDialog
      title="Cancel Order"
      formik={{
        initialValues: {},
        onSubmit: cancelOrder,
      }}
      onValidate={() => {
        setAttemptedSubmit(true);
        return !requiredString(cancellationReason);
      }}
      onClose={() => props.onClose()}
      content={
        <div>
          <p>Are you sure you want to cancel this Order?</p>
          <TextField
            id="cancel-notes"
            name="cancel-notes"
            label="Reason for Cancellation"
            value={cancellationReason}
            onChange={event => setCancellationReason(event.target.value)}
            multiline={true}
            rows={3}
            required={true}
            shrink={true}
            autoFocus={true}
            fullWidth={true}
            validationError={attemptedSubmit ? requiredString(cancellationReason) : undefined}
          />
        </div>
      }
    />
  );
};
