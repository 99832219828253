import {
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { StatusCode } from 'api/enums/StatusCode';
import { IApplicableExtraDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel';
import cn from 'classnames';
import { IQuoteFittedExtraModel } from 'domain/store/repos/quotes/QuoteFittedExtraModel';
import { IQuoteOptionalExtraModel } from 'domain/store/repos/quotes/QuoteOptionalExtraModel';
import { getInitials } from 'infrastructure/getInitials';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import React, { ChangeEvent } from 'react';
import { Button, ButtonWithProgress, CurrencyTextField, NumberTextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import styles from './ExtrasSection.module.scss';

export const ExtrasSection: React.FC = observer(function OptionalExtrasSection() {
  const { editQuoteModel,security } = useStore();
  const quote = editQuoteModel.temporaryQuote;

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [editable, setEditable] = React.useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);

  const icon = <ExpandMoreIcon className={styles.expand} />;

  const startEditing = () => {
    setEditable(true);
    editQuoteModel.startEditingQuote();
  };
  const cancelEditing = () => {
    setEditable(false);
    editQuoteModel.cancelEditingQuote();
  };

  const addExtra = () => {
    editQuoteModel.addExtra(security.currentUser);
  };

  const useStyles = makeStyles(theme => ({
    removeButton: {
      color: theme.palette.error.dark,
    },
    addButton: {
      color: theme.paletteExtensions.success.dark,
    },
  }));
  const classes = useStyles();

  const saveChanges = async () => {
    try {
      setSaveInProgress(true);
      await editQuoteModel.updateVehicle();
      setEditable(false);
    } finally {
      setSaveInProgress(false);
      editQuoteModel.finishEditingQuote();
    }
  };

  
  const canDeleteExtra = (e:IQuoteOptionalExtraModel) => editable && ((security.currentUser.externalId === e.soldById) || 
                                                        (security.currentUser.isSalesManager && security.currentUser.managingLocations.includes(quote.location)) );
  return (
    <ExpansionPanel expanded={expanded} onChange={(_, e) => setExpanded(e)}>
      <ExpansionPanelSummary expandIcon={icon}>
        <Typography>
          <span className={cn(styles.title, {[styles.editingSection]: editable})}>Extras</span>
        </Typography>

        <span className={styles.spacer} aria-hidden="true" />
        {quote && (quote.optionalExtras.length > 0 || quote.fittedExtras.length > 0) && (
          <Typography>
            <span>Extras Price: {quote?.totalExtrasPriceFormatted}</span>
          </Typography>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table className={styles.table}>
          <TableHead className={styles.tablehead}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={styles.description}>Description</TableCell>
              <TableCell className={styles.price}>RRP (GST Incl.)</TableCell>
              <TableCell className={styles.price}>Sell Price (GST Incl.)</TableCell>
              <TableCell className={styles.qty}>QTY</TableCell>
              <TableCell className={styles.total}>Line Total (GST Incl.)</TableCell>
              <TableCell className={styles.soldBy}>Sold By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              quote.fittedExtras
                .map((extra, index) =>
                editable ?
                  <EditFittedExtraTableRow key={index} index={index} extra={extra} />
                  : <ReadOnlyFittedExtraTableRow key={index} index={index} extra={extra} />
                )
            }
            {
              quote.optionalExtras
                .filter(e => !e.deleted)
                .map((e, i) => {
                  const index = i + (quote.fittedExtras.length);
                  return canDeleteExtra(e)? <EditOptionalExtraTableRow key={index} index={index} extra={e} /> : <ReadOnlyOptionalExtraTableRow key={index} index={index} extra={e} />
                })
            }
            {
              editable &&
              <TableRow>
                <TableCell>
                  <IconButton color="primary" className={classes.addButton} onClick={addExtra}>
                    <AddCircleIcon/>
                  </IconButton>
                </TableCell>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell/>
                <TableCell/>
              </TableRow>
            }
            {
              quote.duplicateExtras.length > 0
              &&
              <TableRow className={styles.validationRow}>
                <TableCell colSpan={6}>
                  <Alert severity="error">Duplicate extras: {quote.duplicateExtras.join(',')}</Alert>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
      {
        quote.statusCode !== StatusCode.New &&
        editQuoteModel.isEditable &&
        <ExpansionPanelActions className={styles.actions}>
          {editable ? (
            <>
              <ButtonWithProgress inProgress={saveInProgress} onClick={saveChanges} disabled={!quote.canSaveExtra}>
                Save
              </ButtonWithProgress>
              <div className={styles.spacer} aria-hidden="true" />
              <Button alt onClick={cancelEditing}>
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={startEditing} disabled={!editQuoteModel.vehicleIsConfirmed}>Edit</Button>
          )}
        </ExpansionPanelActions>
      }
    </ExpansionPanel>
  );
});

interface OptionalExtraRowProps {
  index: number,
  extra: IQuoteOptionalExtraModel
}

interface FittedExtraRowProps {
  index: number,
  extra: IQuoteFittedExtraModel
}

const EditOptionalExtraTableRow: React.FC<OptionalExtraRowProps> = observer(({ index, extra }) => {
  const { editQuoteModel } = useStore();
  const useStyles = makeStyles(theme => ({
    removeButton: {
      color: theme.palette.error.dark,
    },
    addButton: {
      color: theme.paletteExtensions.success.dark,
    },
  }));
  const classes = useStyles();

  return (
    <TableRow key={index} className={styles.row} >
      <TableCell className={styles.cell}>
        <IconButton className={classes.removeButton} onClick={extra.softDelete}>
          <RemoveCircleIcon></RemoveCircleIcon>
        </IconButton>
      </TableCell>
      <TableCell>Optional</TableCell>
      <TableCell className={styles.cell}>
        <>
          {editQuoteModel.applicableExtras &&
          <Autocomplete
            freeSolo={true}
            filterOptions={filterOptions}
            autoSelect={true}
            options={getSnapshot(editQuoteModel.applicableExtras)}
            getOptionLabel={(option: IApplicableExtraDtoModel | string) =>
              typeof option === 'string' ? option as string : (option as IApplicableExtraDtoModel).name
            }
            getOptionSelected={(option: IApplicableExtraDtoModel) => option.code === extra.code}
            className={styles.field}
            renderInput={(params) => <TextField {...params} label="Description" variant="outlined" size="small" margin="normal" />}
            onChange={(event: ChangeEvent<{}>, value: IApplicableExtraDtoModel | null) => extra.set(value)}
            value={extra.description as any}
          />
          }
          {!editQuoteModel.applicableExtras &&
          <TextField
            className={styles.field}
            value={extra.description}
            autoFocus
            onChange={event => extra.setDescription(event.target.value)}
          />
          }
        </>
      </TableCell>
      <TableCell className={styles.cell}>
        {extra.recommendedRetailPriceFormatted}
      </TableCell>
      <TableCell className={styles.cell}>
        <CurrencyTextField
          label="Sell Price"
          name="unitPrice"
          className={styles.field}
          value={extra.unitPrice}
          onChange={(event) => extra.setUnitPrice(event.target.value ? parseFloat(event.target.value) : undefined)}
        />
      </TableCell>
      <TableCell className={styles.cell}>
        <NumberTextField
          label="Quantity"
          name="quantity"
          className={styles.field}
          value={extra.quantity}
          onChange={event => extra.setQuantity(event.target.value ? parseInt(event.target.value) : undefined)}
          validationError={extra.quantityValidationError}
        />
      </TableCell>
      <TableCell align="right" className={styles.cell}>
        {extra.totalPriceFormatted}
      </TableCell>
      <TableCell className={styles.cell}>
        {getInitials(extra.soldBy!)}
      </TableCell>
    </TableRow >
  );
});

const ReadOnlyOptionalExtraTableRow: React.FC<OptionalExtraRowProps> = observer(({ index, extra }) => {
  return (
    <TableRow key={index} className={styles.row}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>Optional</TableCell>
      <TableCell>
        {!extra.isVibeExtra && '(D) '}
        {extra.description}
      </TableCell>
      <TableCell>{extra.recommendedRetailPriceFormatted}</TableCell>
      <TableCell>{extra.unitPriceFormatted}</TableCell>
      <TableCell>{extra.quantity}</TableCell>
      <TableCell align="right">{extra.totalPriceFormatted}</TableCell>
      <TableCell >
        {getInitials(extra.soldBy!)}
      </TableCell>
    </TableRow>
  );
});

const EditFittedExtraTableRow: React.FC<FittedExtraRowProps> = observer(({ index, extra }) => {
  return (
    <TableRow key={index} className={styles.row}>
      <TableCell className={styles.cell}></TableCell>
      <TableCell>Fitted</TableCell>
      <TableCell>{extra.description}</TableCell>
      <TableCell>{extra.recommendedRetailPriceFormatted}</TableCell>
      <TableCell className={styles.cell}>
        <CurrencyTextField
          label="Sell Price"
          name="unitPrice"
          className={styles.field}
          value={extra.unitPrice}
          onChange={(event) => extra.setUnitPrice(event.target.value ? parseFloat(event.target.value) : undefined)}
        />
      </TableCell>
      <TableCell>{extra.quantity}</TableCell>
      <TableCell align="right">{extra.totalPriceFormatted}</TableCell>
    </TableRow >
  );
});

const ReadOnlyFittedExtraTableRow: React.FC<FittedExtraRowProps> = observer(({ index, extra }) => {
  return (
    <TableRow key={index} className={styles.row}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>Fitted</TableCell>
      <TableCell>{extra.description}</TableCell>
      <TableCell>{extra.recommendedRetailPriceFormatted}</TableCell>
      <TableCell>{extra.unitPriceFormatted}</TableCell>
      <TableCell>{extra.quantity}</TableCell>
      <TableCell align="right">{extra.totalPriceFormatted}</TableCell>
    </TableRow>
  );
});

const filterOptions = createFilterOptions({
  stringify: (option: IApplicableExtraDtoModel) => option.name,
});
