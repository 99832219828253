import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { Permission } from 'api/enums/Permission';
import { VehicleCondition } from 'api/enums/VehicleCondition';
import { Section } from 'domain/store/EditContractModel';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ActionMenu, ActionMenuItem } from 'views/components/ActionMenu';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { useStore } from '../../hooks';
import { AssignContractDialog } from './components/AssignContractDialog';
import { AssignVehicleToContractDialog } from './components/AssignVehicleToContractDialog';
import { ContractActions } from './components/ContractActions';
import { ContractHistoryDialog } from './components/ContractHistoryDialog';
import { ContractStatus } from './components/ContractStatus';
import { CustomerSection } from './components/CustomerSection';
import { LicenseeSection } from './components/LicenseeSection';
import { PricingSummary } from './components/PricingSummary';
import { QuoteWarning } from './components/QuoteWarning';
import { ReadOnlyWarning } from './components/ReadOnlyWarning';
import { SettlementSection } from './components/SettlementSection';
import { SpecialConditions } from './components/SpecialConditionsSection';
import { TradeInSection } from './components/TradeInSection';
import { VehicleSection } from './components/VehicleSection';
import styles from './Contract.module.scss';

type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;
type ISalesPersonDto = Domain.Queries.User.GetVibeSalesPeopleQuery.ISalesPersonDto;

export const Contract: React.FC = observer(function Contract() {
  const { editContractModal, security } = useStore();
  const { search } = useLocation();

  useEffect(() => {
    const values = queryString.parse(search);
    editContractModal.setSectionToModify(values['section'] as Section);
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { contractId } = useParams<{ contractId?: string }>();
  const [openAssignContract, setOpenAssignContract] = useState(false);
  const [openAssignVehicle, setOpenAssignVehicle] = useState(false);
  const [openContractHistory, setOpenContractHistory] = useState(false);
  const history = useHistory();

  const createDraft = async () => {
    const newContractId = await editContractModal.createNewDraftVersion();
    const url = `/contracts/${newContractId}${
      editContractModal.sectionToModify ? `?section=${editContractModal.sectionToModify}` : ''
    }`;
    editContractModal.showPromptToModifyVersion(false);
    history.push(url);
  };

  const canAssignVehicle = editContractModal.contract.statusCode === ContractStatusCode.Draft || editContractModal.contract.statusCode === ContractStatusCode.Open;
  const assignVehicleDialogTitle = editContractModal.contract.vehicle.condition === VehicleCondition.NewBuild?"Assign Lead and Vehicle":"Assign Lead";

  const assignContract = async (user: IUserDto | undefined) => {
    if (!user) return;
    await editContractModal.assignContract(user);
    setOpenAssignContract(false);
  };
  const isTemplateAssetNo = editContractModal.contract.vehicle.condition === VehicleCondition.NewBuild;
  const assignVehicle = async (leadId: string, assetNo:string | undefined, salesPerson:ISalesPersonDto | undefined, soldDate:string) => {
    if (leadId === "" || assetNo === "") return;
    await editContractModal.assignVehicle(leadId, assetNo, salesPerson?.personId || "", soldDate);
    setOpenAssignVehicle(false);
  };
  useEffect(() => {
    editContractModal.clearContract();
    if (contractId) {
      (async () => {
        await Promise.all([
          editContractModal.loadContract(contractId, security.currentUser),
          editContractModal.loadAssociatedQuote(contractId),
          editContractModal.loadAssociatedOrder(contractId),
        ]);
      })();
    }
    return () => {
      editContractModal.clearContract();
    };
  }, [contractId, editContractModal, security.currentUser]);

  const subTitle = editContractModal.contract && (
    <div className={styles.subtitle}>
      <ContractStatus statusCode={editContractModal.contract.statusCode} />
      {editContractModal.associatedQuote && (
        <a href={`/quotes/${editContractModal.associatedQuote.id}`}>
          {editContractModal.associatedQuote.numberAndVersion}
        </a>
      )}
    </div>
  );

  const audit = editContractModal.contract && (
    <span>
      Updated:{' '}
      <span>{editContractModal.contract.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED)}</span>
    </span>
  );

  const menu = (
    <ActionMenu>
      {{
        items: [
          <ActionMenuItem
            title="Assign Contract"
            subtext={`Assignee: ${editContractModal.contract.assignedTo}`}
            onClick={() => setOpenAssignContract(true)}
            restrictedTo={Permission.AssignContract}
          />,
         canAssignVehicle? <ActionMenuItem
          title={assignVehicleDialogTitle}
          subtext={"Assign Actual Vehicle Disposal Order Number to the Lead"}
          onClick={() => setOpenAssignVehicle(true)}
          restrictedTo={Permission.AssignLeadAndVehicle}
        />: <></>,
          <ActionMenuItem
            title="Contract History"
            subtext="View Previous Versions"
            onClick={() => setOpenContractHistory(true)}
          />,
        ],
      }}
    </ActionMenu>
  );

  return (
    <>
      {!editContractModal.failedToLoadContract && (
        <PageLayout
          title={`Contract ${editContractModal.contract.numberAndVersion}`}
          subtitle={subTitle}
          parent={['All Contracts', '/home/contracts']}
          audit={audit}
          menu={menu}>
          <LoadingPane isLoading={editContractModal.isLoadingContract}>
            <div className={styles.content}>
              <QuoteWarning />
              <ReadOnlyWarning />
              <LicenseeSection />
              <CustomerSection />
              <VehicleSection />
              {!editContractModal.contract.isTradeInEmpty && <TradeInSection />}
              <SettlementSection />
              <PricingSummary />
              <SpecialConditions />
              <ContractActions />
            </div>
          </LoadingPane>
        </PageLayout>
      )}
      {editContractModal.failedToLoadContract && (
        <PageLayout title="Contract" parent={['All Contracts', '/contracts']}>
          <p>The server has experienced an error. Please contact your system administrator.</p>
        </PageLayout>
      )}
      {editContractModal.promptToModifyVersion && (
        <BasicSubmitCancelDialog
          title="Modify Contract"
          okLabel="Create new draft >"
          formik={{
            initialValues: {},
            onSubmit: createDraft,
          }}
          onClose={() => editContractModal.showPromptToModifyVersion(false)}
          content={<p>To modify this contract a new draft version must be created.</p>}
        />
      )}
      {openAssignContract && (
        <AssignContractDialog
          assignee={editContractModal.contract.assignee}
          onAssign={assignContract}
          onClose={() => setOpenAssignContract(false)}
        />
      )}
      {
      openAssignVehicle && (
        <AssignVehicleToContractDialog
          location={editContractModal.contract.licensee.location}
          salesPerson={{personId:"",displayName:"Please select a sales person"}}
          title={assignVehicleDialogTitle}
          leadId={editContractModal.contract.leadId ?? ""}
          soldDate={editContractModal.contract.initialContractDate.slice(0,10)}
          assetNo={isTemplateAssetNo?"":editContractModal.contract.vehicle.assetNo}
          onAssign={assignVehicle}
          onClose={() => setOpenAssignVehicle(false)}
        />
      )}
      {openContractHistory && (
        <ContractHistoryDialog onClose={() => setOpenContractHistory(false)} />
      )}
    </>
  );
});
