import React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import styles from './ProductsTable.module.scss';
import { IOrderLine } from 'domain/store/types/IOrderLine';

interface IProductsTableProps {
  orderLines: IOrderLine[];
  hideDiscount?: boolean;
  className?: string;
}

export const ProductsTable: React.FC<IProductsTableProps> = observer(function ProductsTable(props) {
  return (
    <Table className={props.className}>
      <TableHead className={styles.tablehead}>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Product Type</TableCell>
          <TableCell className={styles.description}>Description</TableCell>
          <TableCell>RRP (Inc GST)</TableCell>
          {!props.hideDiscount && <TableCell>Discount</TableCell>}
          <TableCell align="right" className={styles.qty}>
            QTY
          </TableCell>
          <TableCell align="right">Line Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.orderLines.map(line => {
          return (
            <TableRow key={line.index} className={styles.row}>
              <TableCell>{line.index}</TableCell>
              <TableCell>{line.productType}</TableCell>
              <TableCell>{line.description}</TableCell>
              <TableCell>{line.priceFormatted}</TableCell>
              {!props.hideDiscount && <TableCell>{line.discountFormatted}</TableCell>}
              <TableCell align="right">{line.quantity}</TableCell>
              <TableCell align="right">{line.lineTotalFormatted}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});
