import { ContractVersionDtoModel } from 'api/models/Domain/Queries/Contract/GetContractVersionsQuery/ContractVersionDtoModel';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import {Instance, types} from 'mobx-state-tree';

export const ContractVersionModel = types.model('ContractVersionModel', {
  ...ContractVersionDtoModel.properties,
  lastModifiedAt: localDateTimeType,
});

export interface IContractVersionModel extends Instance<typeof ContractVersionModel> {}
