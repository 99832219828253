import { types, Instance } from 'mobx-state-tree';
import { ModelModel as DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryModelModel } from './ModelModel'

export const MakeModel = types.model('MakeModel', {
  code: types.string,
  models: types.array(DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryModelModel),
  name: types.string,
});

export interface IMakeModel extends Instance<typeof MakeModel> {}
