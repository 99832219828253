import { types, Instance } from 'mobx-state-tree';
import { AddressDtoModel as DomainDtosAddressDtoModel } from './../../../Domain/Dtos/AddressDtoModel'

export const CustomerDtoModel = types.model('CustomerDtoModel', {
  abn: types.maybe(types.string),
  address: types.maybe(DomainDtosAddressDtoModel),
  companyName: types.maybe(types.string),
  contactId: types.maybe(types.string),
  dob: types.maybe(types.string),
  email: types.maybe(types.string),
  firstName: types.string,
  fullName: types.string,
  phone: types.maybe(types.string),
  phoneAlternate: types.maybe(types.string),
  spouseDob: types.maybe(types.string),
  spouseFirstName: types.maybe(types.string),
  spouseSurname: types.maybe(types.string),
  surname: types.string,
});

export interface ICustomerDtoModel extends Instance<typeof CustomerDtoModel> {}
