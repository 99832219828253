import {
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button, ButtonWithProgress } from 'views/components/forms';
import styles from './FormSection.module.scss';
import cn from 'classnames';

export interface IFormSectionProps {
  sectionTitle: string;
  sectionSummary?: string;
  isFormActionAllowed: boolean;
  expandedDefault: boolean;
  editableDefault?: boolean;
  clearBtnText?: string;
  saveBtnText?: string;
  canSave: boolean;
  canCancel?: boolean;
  onEditClick?: () => void | Promise<void>;
  disableEditing?: boolean;
  onSubmit: () => void | Promise<void> | Promise<() => void> | Promise<boolean>;
  onValidate?: () => boolean;
  onClear?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  hasErrors?: boolean;
  shouldModifyVersion?: boolean;
  onModifyVersion?: () => void | Promise<void>;
  children: (args: {
    focusFieldRef: React.MutableRefObject<HTMLInputElement | null>;
    editable: boolean;
  }) => React.ReactNode;
}

type FormSectionComponent = (props: IFormSectionProps) => React.ReactElement | null;

export const FormSection: FormSectionComponent = observer(function FormSection({
  sectionTitle,
  sectionSummary,
  isFormActionAllowed,
  expandedDefault,
  editableDefault,
  clearBtnText,
  saveBtnText,
  canSave,
  canCancel,
  onEditClick,
  disableEditing = false,
  onSubmit,
  onValidate,
  onClear,
  onCancel,
  hasErrors,
  shouldModifyVersion,
  onModifyVersion,
  children,
}: IFormSectionProps) {
  const focusFieldRef = React.useRef<HTMLInputElement | null>(null);
  const [expanded, setExpanded] = React.useState<boolean>(expandedDefault);
  const [isEditing, setIsEditing] = React.useState<boolean>(editableDefault ?? false);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);

  const IconComponent = expanded ? CloseIcon : ExpandMoreIcon;
  const icon = <IconComponent className={styles.expand} />;

  const componentUnmounted = React.useRef(false);

  React.useEffect(() => {
    expanded && isEditing && focusFieldRef.current && focusFieldRef.current.focus();
  }, [expanded, isEditing]);

  React.useEffect(() => {
    setExpanded(expandedDefault);
  }, [expandedDefault]);

  React.useEffect(() => {
    setIsEditing(editableDefault ?? false);
  }, [editableDefault]);

  // On unmount
  React.useEffect(() => {
    return () => {
      componentUnmounted.current = true;
    };
  }, []);

  const handleSave = async () => {
    try {
      setSaveInProgress(true);

      if (onValidate && !onValidate()) return;

      const response = await onSubmit();

      if (!componentUnmounted.current) {
        
        if (typeof response === 'boolean') 
          setExpanded(response);
        else 
          setExpanded(false);
        
        setIsEditing(false);
      }
    } finally {
      if (!componentUnmounted.current) {
        setSaveInProgress(false);
      }
    }
  };

  return (
    <form noValidate autoComplete="off">
      <ExpansionPanel
        expanded={expanded}
        onChange={(_, e) => {
          setExpanded(e);
          if (isEditing) {
            onCancel && onCancel();
            setIsEditing(false);
          }
        }}>
        <ExpansionPanelSummary
          expandIcon={icon}
          className={hasErrors ? styles.error : ''}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>
            <span className={cn(styles.title, { [styles.editingSection]: isEditing })}>
              {sectionTitle} {isEditing}
            </span>
          </Typography>
          {sectionSummary && (
            <>
              <span className={styles.spacer} aria-hidden="true" />
              <Typography>
                <span className={styles.summary}>{sectionSummary}</span>
              </Typography>
            </>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {children({ focusFieldRef, editable: isEditing })}
        </ExpansionPanelDetails>
        {isFormActionAllowed && (
          <ExpansionPanelActions className={styles.actions} {...{ disableSpacing: true }}>
            {isEditing ? (
              <>
                {canSave && (
                  <ButtonWithProgress onClick={handleSave} inProgress={saveInProgress}>
                    {saveBtnText || 'Save'}
                  </ButtonWithProgress>
                )}
                <div className={styles.spacer} aria-hidden="true" />
                {onClear && (
                  <Button alt onClick={() => onClear && onClear()}>
                    {clearBtnText || 'Clear'}
                  </Button>
                )}
                {canCancel && (
                  <Button
                    alt
                    onClick={() => {
                      onCancel && onCancel();
                      setIsEditing(false);
                    }}>
                    Cancel
                  </Button>
                )}
              </>
            ) : shouldModifyVersion ? (
              <Button
                onClick={() => {
                  onModifyVersion && onModifyVersion();
                }}>
                Modify
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (onEditClick) onEditClick();
                  else setIsEditing(true);
                }}
                disabled={disableEditing}>
                Edit
              </Button>
            )}
          </ExpansionPanelActions>
        )}
      </ExpansionPanel>
    </form>
  );
}) as any;
