import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';

interface IDeleteDraftDialogProps {
  onClose(): void;
}

export const DeleteDraftDialog: React.FC<IDeleteDraftDialogProps> = props => {
  const { editQuoteModel } = useStore();
  const history = useHistory();

  const deleteDraft = async () => {
    if (!editQuoteModel) return;
    await editQuoteModel.deleteDraft();
    history.goBack();
  };

  return (
    <BasicSubmitCancelDialog
      title="Delete Draft Quote"
      formik={{
        initialValues: {},
        onSubmit: deleteDraft,
      }}
      onClose={() => props.onClose()}
      content={
        <p>
          Are you sure you want to delete this draft quote?
        </p>
      }
    />
  );
};
