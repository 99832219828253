import {Location} from '../api/enums/Location';

type RoleLocation = {
  Role:string,
  Location:string
}

type User = {
    id:string,
    name:string,
    roles:string,
}
type UserLocation = {
    id:string,
    name:string,
    roles:string,
    locations:string[]
}

const RoleLocationMap=new Map<string, RoleLocation>();
RoleLocationMap.set("GeelongAftermarket",{Role:"Aftermarket", Location:"Geelong"});
RoleLocationMap.set("GeelongSalesPerson",{Role:"SalesPerson", Location:"Geelong"});
RoleLocationMap.set("GeelongSalesManager",{Role:"SalesManager", Location:"Geelong"});
RoleLocationMap.set("AdelaideAftermarket",{Role:"Aftermarket", Location:"Adelaide"});
RoleLocationMap.set("AdelaideSalesPerson",{Role:"SalesPerson", Location:"Adelaide"});
RoleLocationMap.set("AdelaideSalesManager",{Role:"SalesManager", Location:"Adelaide"});
RoleLocationMap.set("BrisbaneAftermarket",{Role:"Aftermarket", Location:"Brisbane"});
RoleLocationMap.set("BrisbaneSalesPerson",{Role:"SalesPerson", Location:"Brisbane"});
RoleLocationMap.set("BrisbaneSalesManager",{Role:"SalesManager", Location:"Brisbane"});
RoleLocationMap.set("CairnsAftermarket",{Role:"Aftermarket", Location:"Cairns"});
RoleLocationMap.set("CairnsSalesPerson",{Role:"SalesPerson", Location:"Cairns"});
RoleLocationMap.set("CairnsSalesManager",{Role:"SalesManager", Location:"Cairns"});
RoleLocationMap.set("NewcastleAftermarket",{Role:"Aftermarket", Location:"Newcastle"});
RoleLocationMap.set("NewcastleSalesPerson",{Role:"SalesPerson", Location:"Newcastle"});
RoleLocationMap.set("NewcastleSalesManager",{Role:"SalesManager", Location:"Newcastle"});
RoleLocationMap.set("MelbourneAftermarket",{Role:"Aftermarket", Location:"Melbourne"});
RoleLocationMap.set("MelbourneSalesPerson",{Role:"SalesPerson", Location:"Melbourne"});
RoleLocationMap.set("MelbourneSalesManager",{Role:"SalesManager", Location:"Melbourne"});
RoleLocationMap.set("NationalSalesManager",{Role:"SalesManager", Location:"National"});
RoleLocationMap.set("ExecutiveManager",{Role:"ExecutiveManager", Location:"National"});
RoleLocationMap.set("KratzmannSalesPerson",{Role:"WholesaleSalesPerson", Location:"Kratzmann"});
RoleLocationMap.set("GeorgeDaySalesPerson",{Role:"WholesaleSalesPerson", Location:"GeorgeDay"});
RoleLocationMap.set("SydneyRVSalesPerson",{Role:"WholesaleSalesPerson", Location:"SydneyRV"});
RoleLocationMap.set("FleetSalesAdmin",{Role:"FleetSalesAdmin", Location:"National"});
RoleLocationMap.set("CaravansAwaySalesPerson",{Role:"WholesaleSalesPerson", Location:"CaravansAway"});

export const matches = (s: string | undefined, pattern: string, error: string) => {
  if (!s) return undefined;
  return s.match(new RegExp(pattern)) ? undefined : error;
};

const mapUsersToLocation = (users:User[]) => {
    var usersWithLocation = users.map(user => ({...user,locations:[...new Set(GetLocations(user.roles.split(',')))]}));
    const UsersPerLocation=new Map<string, UserLocation[]>();
    Object.keys(Location).forEach(element => UsersPerLocation.set(element, usersWithLocation.filter((user => user.locations.includes(element)))));
    // for national dealership, only allow FSA as dealer contacts
    UsersPerLocation.set(Location[Location.National],usersWithLocation.filter(user => user.roles.includes("FleetSalesAdmin")));
    return UsersPerLocation;
}

const GetRoleLocation = (roleId:string) =>
{
    return RoleLocationMap.has(roleId) ? RoleLocationMap.get(roleId) : null;
}

const GetRoleLocations = (roleIds:string[] ) =>
{
    var results:RoleLocation[] = [];
    roleIds.forEach(roleId => {
      var roleLocation = GetRoleLocation(roleId);
      if (roleLocation != null)
      {
          results.push(roleLocation);
      }
  } )  
    return results;
}
const GetLocations = (roleIds:string[] ) =>
{
    var results:string[] = [];
    roleIds.forEach(roleId => {
        var location = GetRoleLocation(roleId)?.Location;
        if (location != null)
        {
            results.push(location);
        }
    })
    return results;
}

export {GetRoleLocation,GetRoleLocations,GetLocations,mapUsersToLocation}


