import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextField,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { QuoteApprovalStatus } from 'api/enums/QuoteApprovalStatus';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { observer } from 'mobx-react-lite';
import React  from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { EditableCurrencyLabel } from 'views/components/forms/EditableCurrencyLabel';
import { ProductsTable } from 'views/components/ProductsTable';
import { useStore } from 'views/hooks';
import styles from './SummarySection.module.scss';

export const SummarySection: React.FC = observer(function SummarySection() {
  const store = useStore();
  const editQuoteModel = store.editQuoteModel;
  const quote = editQuoteModel.temporaryQuote;
  const currentUser = store.security.currentUser;
  const notAuthorisedMessage = 'You are not authorised to approve this deal. Contact Management.';
  const [updateCustomerOfferProgress, setUpdateCustomerOfferProgress] = React.useState<boolean>(
    false
  );

  const updateCustomerOffer = async (value?: string) => {
    try {
      setUpdateCustomerOfferProgress(true);

      if (value !== undefined && value.trim().length > 0) {
        await editQuoteModel.updateCustomerOffer(value);
      }
    } finally {
      setUpdateCustomerOfferProgress(false);
    }
  };

  const totals = (
    <ul className={styles.totals}>
      <li>
        <span>Subtotal:</span>
        <span>{quote?.subTotalFormatted}</span>
      </li>
      {!!(quote && quote.vehicle.discount) && (
        <li>
          <span>Less Discount:</span>
          <span>{AsAuCurrency(quote.totalDiscount)}</span>
        </li>
      )}
      {!!(quote && quote.vehicle.rebate) && (
        <li>
          <span>Less Rebate:</span>
          <span>{quote?.vehicle.rebateFormatted}</span>
        </li>
      )}
      {quote && !quote.tradeIn.isEmpty && (
        <li>
          <span>Less Trade-in:</span>
          <span>{quote?.tradeIn.priceFormatted}</span>
        </li>
      )}
      {quote?.deposit && (
        <li>
          <span>Deposit Payable:</span>
          <span>{quote?.deposit.amountFormatted}</span>
        </li>
      )}
      <li>
        <span>Total Price (Inc GST):</span>
        <EditableCurrencyLabel       
          initialValue={quote?.totalPrice}
          label="Total Price"
          name="totalPrice"
          isEditable = {currentUser.isAftermarketPerson?false:true}
          finishEditing={updateCustomerOffer}
        />
        {updateCustomerOfferProgress && (
          <CircularProgress size={24} thickness={5} color="secondary" className={styles.progress} />
        )}
      </li>
    </ul>
  );

  const warnings = (
    <div className={styles.warnings}>
      {!!editQuoteModel.otherQuotesForSameVehicle.length && (
        <Alert severity="warning">
          <span>
            Vehicle {quote.vehicle.assetNo} has other{' '}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`/home/quotes?filter=${quote.vehicle.assetNo}&activeOnly=true`}>
              open quotes
            </a>
          </span>
        </Alert>
      )}
      {!quote.canBeApprovedBy(store.security.currentUser) &&
        quote.needManagerApproval &&
        quote.latestApproval?.status !== QuoteApprovalStatus.Approved && (
          <Alert severity="warning">
            <span>{notAuthorisedMessage}</span>
          </Alert>
        )}
    </div>
  );

  const [onNoteChange] = useDebouncedCallback((note: string) => {
    editQuoteModel.updateNote(note);
  }, 1000);

  const notes = (
    <TextField
      className={styles.notes}
      label="Sales Notes"
      placeholder="notes"
      multiline
      fullWidth
      variant="outlined"
      rows="10"
      defaultValue={editQuoteModel.quote.note}
      inputProps={{ readOnly: !editQuoteModel.isEditable }}
      onChange={e => onNoteChange(e.target.value)}
    />
  );

  return (
    <ExpansionPanel expanded>
      <ExpansionPanelSummary>
        <Typography>
          <span className={styles.title}>Quote Summary</span>
        </Typography>
        <span className={styles.spacer} aria-hidden="true" />
        <Typography>
          <span>Total Price  (Inc GST): {quote?.totalPriceFormatted}</span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.details}>
        <ProductsTable orderLines={quote?.orderLines} className={styles.products} />
        {totals} {notes} {warnings}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
});
