import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { TextField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { VibeSalesPeopleAutoComplete } from 'views/components/VibeSalesPeopleAutoComplete';
type ISalesPersonDto = Domain.Queries.User.GetVibeSalesPeopleQuery.ISalesPersonDto;

const useStyles = makeStyles((theme) => ({
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& div': {
      display:'flex'
    },
   },
}));

interface IAssignVehicleDialogProps {
  title:string,
  location:string,
  leadId: string,
  soldDate:string,
  assetNo:string | undefined,
  salesPerson: ISalesPersonDto | undefined,
  onClose(): void;
  onAssign: (leadId:string, assestNo:string | undefined, salesPerson: ISalesPersonDto | undefined, soldDate:string) => void;
}

interface IValidationProps {
  leadId: string,
  assetNo: string | undefined,
  salesPerson: ISalesPersonDto | undefined
}

export const AssignVehicleToContractDialog: React.FC<IAssignVehicleDialogProps> = props => {

  const [leadId, setLeadId] = useState(props.leadId);
  const [assetNo, setAssetNo] = useState(props.assetNo);
  const [soldDate, setSoldDate] = useState(props.soldDate);
  const [salesPerson, setSalesPerson] = useState(props.salesPerson);
  const [openAssignConfirmation, setOpenAssignConfirmation] = useState(false);
  const classes = useStyles();

  let validateAssetNo = (assetNo: string | undefined) => !assetNo ? "Please enter an asset no.": "";
  let validateSalesPerson = (salesPerson: ISalesPersonDto | undefined) => !salesPerson?.personId ? "Please Select a sales person" : "";
  let validateLeadId = (leadId: string) => {
    if (!leadId) return "Please enter a valid Lead Id";

    let regex = /[^0-9]/g;
    if (regex.test(leadId)) return "A Lead Id can only contain numbers 0-9";

    return "";
  };

  let validateForm = (values: IValidationProps) => !validateLeadId(values.leadId)
    && !validateAssetNo(values.assetNo)
    && !validateSalesPerson(values.salesPerson);

  return (
    <div>
<BasicSubmitCancelDialog
      title={props.title}
      okLabel={props.title}
      closeAfterSubmit={false}
      onValidate={() => validateForm({leadId, assetNo, salesPerson})}
      formik={{
        initialValues: {},
        onSubmit:() => {setOpenAssignConfirmation(true)}
      }}
      onClose={props.onClose}
      content={
        <div>
          <p>
            Link new builds contract to the newly created asset in VIBE
          </p>
          <form className={classes.root}>
            <div>
              <TextField
                label='Assest No'
                name="assestNo"
                required={true}
                value={assetNo}
                validationError={validateAssetNo(assetNo)}
                onChange={event => setAssetNo(event.target.value)}
              >
              </TextField>
              <TextField
                label='LeadId'
                name="leadId"
                readonly={!!props.leadId}
                required={true}
                value={leadId}
                validationError={validateLeadId(leadId)}
                onChange={event => setLeadId(event.target.value.replace(/\s/g, ""))}
              >
              </TextField>
            </div>

            <div>
              <TextField
                name="soldDate"
                label="Sold Date"
                type="date"
                shrink={true}
                value={soldDate}
                onChange={event => setSoldDate(event.target.value)}
              >
              </TextField>

              <VibeSalesPeopleAutoComplete
                value={salesPerson}
                location={props.location}
                required={true}
                validationError={validateSalesPerson(salesPerson)}
                onChange={salesPerson => setSalesPerson(salesPerson)}
              />
            </div>
          </form>
        </div>
      }
    />
    {openAssignConfirmation && (
      <BasicSubmitCancelDialog
        title={props.title}
        okLabel={props.title}
        formik={{
          initialValues: {},
          onSubmit: () => props.onAssign(leadId, assetNo, salesPerson, soldDate),
        }}
        onClose={props.onClose}
        content={
          <p>
            Are you sure you want to assign vehicle {assetNo} to lead {leadId}, with sales person set as {salesPerson?.displayName}?
          </p>
        }
      />
    )}
    </div>
  );
};
