export enum Country {
    AU = 0,
    NZ = 1
}
export function countryDescription(value: Country) {
    switch (value) {
        case Country.AU:
            return `Australia`;
        case Country.NZ:
            return `New Zealand`;
    }
}
export interface ICountryDescriptor { value: Country; name: string; description: string; }
export var allCountry: ICountryDescriptor[] = [
    { value: Country.AU, name: `AU`, description: `Australia` },
    { value: Country.NZ, name: `NZ`, description: `New Zealand` }
];

export function getCountryDescriptor(value: Country) {
    switch (value) {
        case Country.AU:
            return { value: Country.AU, name: `AU`, description: `Australia` };
        case Country.NZ:
            return { value: Country.NZ, name: `NZ`, description: `New Zealand` };
    }
}
