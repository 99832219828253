import {
  ContractTradeInDtoModel,
  IContractTradeInDtoModel
} from 'api/models/Domain/Dtos/ContractTradeInDtoModel';
import { cast, SnapshotIn } from 'mobx-state-tree';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import {
  IRules,
  isValid,
  matches,
  requiredNumber,
  requiredString,
  validator,
} from 'infrastructure/validation';

const rules: IRules<IContractTradeInDtoModel> = {
  vinNumber: (val, form) =>
    requiredString(val) ?? matches(val, '^[a-zA-Z0-9]{17}$', 'Requires 17 characters'),
  compliancePlateDate: requiredString,
  year: val => requiredString(val) ?? matches(val, '^[0-9]{4}$', 'Requires 4 numbers'),
  registration: requiredString,
  registrationExpiryDate: requiredString,
  engineNumber: requiredString,
  engineSize: requiredString,
  trimColour: requiredString,
  bodyColour: requiredString,
  kms: requiredNumber,
  creditProvider: (val, form) => {
    if (!form.isTradeInUnderFinance) return;
    return requiredString(form.creditProvider);
  },
  accountNumber: (val, form) => {
    if (!form.isTradeInUnderFinance) return;
    return requiredString(form.creditProvider);
  },
  creditPayoutRequired: (val, form) => {
    if (!form.isTradeInUnderFinance) return;
    return requiredNumber(form.creditPayoutRequired);
  },
  creditPayoutValidationDueDate: (val, form) => {
    if (!form.isTradeInUnderFinance) return;
    return requiredString(form.creditPayoutValidationDueDate);
  },
};

export const ContractTradeInModel = ContractTradeInDtoModel.named('ContractTradeInModel')
  .views(self => ({
    get priceFormatted() {
      return AsAuCurrency(self.price);
    },
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      if (!self.make && !self.model) return true;
      return isValid(self, rules);
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));
export const emptyContractTradeIn: IContractTradeInDtoModel = {
  accessories: '',
  accountNumber: '',
  bodyColour: '',
  compliancePlateDate: '',
  year: '',
  creditPayoutRequired: 0,
  creditPayoutValidationDueDate: '',
  creditProvider: '',
  engineNumber: '',
  engineSize: '',
  floorPlan: '',
  kms: 0,
  make: '',
  model: '',
  price: 0,
  registration: '',
  registrationExpiryDate: '',
  trimColour: '',
  vinNumber: '',
  isTradeInUnderFinance: false,
  description: '',
  netTradeInValue: 0
};
