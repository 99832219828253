import { types, Instance } from 'mobx-state-tree';
import { ApplicableExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel } from './../../Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel'
import { QuoteOptionalExtraDtoModel as DomainDtosQuoteOptionalExtraDtoModel } from './QuoteOptionalExtraDtoModel'

export const UpdatedOptionalExtraDtoModel = types.model('UpdatedOptionalExtraDtoModel', {
  new:DomainQueriesVehicleSearchVehicleDtoApplicableExtraDtoModel,
  old:DomainDtosQuoteOptionalExtraDtoModel,
});

export interface IUpdatedOptionalExtraDtoModel extends Instance<typeof UpdatedOptionalExtraDtoModel> {}
