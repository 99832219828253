import { SalesPersonDtoModel } from 'api/models/Domain/Queries/User/GetVibeSalesPeopleQuery/SalesPersonDtoModel';
import { cast, flow, types } from 'mobx-state-tree';
import { getAjax } from '../RootStoreModel';

type ISalesPersonDtoModel = Domain.Queries.User.GetVibeSalesPeopleQuery.ISalesPersonDto;

export const VibeSalesPeopleRepo = types
  .model('VibeSalesPeopleRepo', {
    salesPeople: types.array(SalesPersonDtoModel),
    isLoading: types.maybe(types.boolean),
  })
  .extend(self => {
    function* loadSalesPeople() {
      self.isLoading = true;
      const dtos: ISalesPersonDtoModel[] = yield getAjax(self).get(`/api/salesPeople`).json();
      self.salesPeople = cast(dtos);
      self.isLoading = false;
    }
    
    function clearSalesPeople() {
      self.salesPeople = cast([]);
    }

    return {
      actions: {
        loadSalesPeople: flow(loadSalesPeople),
        clearSalesPeople: clearSalesPeople
      },
    };
  });