import { Link, List, ListItem, ListItemText } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconPopoverButton } from 'views/components/forms/IconPopoverButton';
import { useStore } from 'views/hooks';
import styles from './UserMenu.module.scss';
import { Permission } from 'api/enums/Permission';

export const UserMenu: React.FC = observer(function UserMenu() {
  const store = useStore();
  const currentUser = store.security.currentUser;

  return (
    <IconPopoverButton
      icon={
        <img
          src={currentUser.userPictureUrl}
          alt={`Current User: ${currentUser.name}`}
          className={styles['menu-img']}
        />
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {({ close }) => (
        <>
          <List component="nav" aria-label="secondary mailbox folders">
            {currentUser.can(Permission.ViewQuote) && (
              <Link
                className={styles.menuLink}
                color="inherit"
                component={RouterLink}
                to="/home/quotes">
                <ListItem button>Quotes</ListItem>
              </Link>
            )}
            {currentUser.roleLevel > 1 && (
              <Link
                className={styles.menuLink}
                color="inherit"
                component={RouterLink}
                to="/deal-approvals">
                <ListItem button>Deal Approvals</ListItem>
              </Link>
            )}
            {(currentUser.contractFeatureEnabled && currentUser.can(Permission.ViewContract)) && (
              <Link
                className={styles.menuLink}
                color="inherit"
                component={RouterLink}
                to="/home/contracts">
                <ListItem button>Contracts</ListItem>
              </Link>
            )}
            {(currentUser.orderFeatureEnabled && currentUser.can(Permission.ViewOrder)) && (
              <Link
                className={styles.menuLink}
                color="inherit"
                component={RouterLink}
                to="/home/orders">
                <ListItem button>Orders</ListItem>
              </Link>
            )}
            <ListItem button>
              <ListItemText onClick={store.security.signOut}>
                Sign out {currentUser.name}
              </ListItemText>
            </ListItem>
          </List>
        </>
      )}
    </IconPopoverButton>
  );
});
