import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { config } from 'domain/lib/config';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { LoadingPane } from 'views/components/LoadingPane';
import { FilterBar } from 'views/components/simpleFields/FilterBar';
import { useStore } from 'views/hooks';
import styles from '../../components/Listings.module.scss';
import { QuoteStatus } from './components/QuoteStatus';
import { Permission } from '../../../api/enums/Permission';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const QuotesList: React.FC = observer(function QuotesList() {
  const store = useStore();
  const query = useQuery();
  const filter = query.get('filter') || '';
  const opportunityId = query.get('opportunityId') || '';
  const activeOnly = query.get('activeOnly') === 'true';
  const history = useHistory();
  const allowCreate = !!config?.allowCreatingQuoteFromRetail && store.security.currentUser.can(Permission.EditQuote) && !store.security.currentUser.isAftermarketPerson;
  useEffect(() => {
    store.quotesRepo.loadQuotes(filter, activeOnly);
  }, [store.quotesRepo, filter, opportunityId, activeOnly]);

  return (
    <>
      <div className={styles.actions}>
        <FilterBar
          className={styles.filterbar}
          value={store.quotesRepo.filter}
          placeholder="Quote #, Customer or Vehicle"
          onChange={newValue => store.quotesRepo.loadQuotes(newValue)}
        />
        {allowCreate && (
          <Button
            className={styles.newitem}
            color="primary"
            variant="contained"
            component={RouterLink}
            to="/quotes/new">
            Create Quote
          </Button>
        )}
      </div>
      <LoadingPane isLoading={store.quotesRepo.isLoadingQuotes}>
        <Table className={styles.table}>
          <TableHead className={styles.tablehead}>
            <TableRow>
              <TableCell className={styles.number}>Quote #</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Vehicle description</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell>Salesperson</TableCell>
              <TableCell>Last Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.quotesRepo.quotes.map(q => {
              const openQuote = () => history.push(`/quotes/${q.id}`);
              return (
                <TableRow key={q.id} className={styles.row}>
                  <TableCell onClick={openQuote}>
                    {q.numberAndVersion} {q.isDraft && "(Draft)"}
                  </TableCell>
                  <TableCell onClick={openQuote}>{`${q.customerFirstName} ${q.customerSurname ??
                    ''}`}</TableCell>
                  <TableCell onClick={openQuote}>{q.vehicleDescription}</TableCell>
                  <TableCell onClick={openQuote} align="center">
                    <QuoteStatus statusCode={q.status} />
                  </TableCell>
                  <TableCell onClick={openQuote}>{q.salesName}</TableCell>
                  <TableCell onClick={openQuote}>
                    {q.lastModifiedAt.toLocaleString(DateTime.DATE_MED)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {store.quotesRepo.quotes.length === 0 && (
          <p className={styles.nodata}>There are no matching Quotes.</p>
        )}
        {store.quotesRepo.moreRecordsExist && <p className={styles.hasmore}>More records exist.</p>}
      </LoadingPane>
    </>
  );
});
