import { AsAuCurrency } from 'infrastructure/formatUtils';
import {
  IOrderVehicleDtoModel,
  OrderVehicleDtoModel,
} from 'api/models/Domain/Dtos/OrderVehicleDtoModel';

export const OrderVehicleModel = OrderVehicleDtoModel.named('OrderVehicleModel').views(self => ({
  get priceFormatted() {
    return AsAuCurrency(self.price);
  },
  get isEmpty(): boolean {
    return !self.make && !self.model && !self.year && !self.price;
  },
  get description() {
    // Orders can only be new builds so we only need the
    // series (floorplan) or model
    return [self.year, self.floorPlan || self.model].filter(x => x).join(' ');
  },
}));

export const emptyOrderVehicle: IOrderVehicleDtoModel = {
  assetNo: '',
  disposalOrderNo: '',
  floorPlan: '',
  make: '',
  model: '',
  price: 0,
  year: '',
};
