import { types, Instance } from 'mobx-state-tree';

export const ContractExtraDtoModel = types.model('ContractExtraDtoModel', {
  code: types.maybe(types.string),
  description: types.string,
  discount: types.number,
  parentCode: types.maybe(types.string),
  parentDescription: types.maybe(types.string),
  price: types.number,
  quantity: types.integer,
  totalPrice: types.number,
  type: types.frozen<Domain.Aggregates.Contract.Entities.ContractExtraType>(),
});

export interface IContractExtraDtoModel extends Instance<typeof ContractExtraDtoModel> {}
