import { types, Instance } from 'mobx-state-tree';

export const LicenseeDtoModel = types.model('LicenseeDtoModel', {
  location: types.string,
  salesPerson: types.string,
  salesPersonId: types.string,
  stateOfSale: types.frozen<Domain.Aggregates.Shared.State>(),
});

export interface ILicenseeDtoModel extends Instance<typeof LicenseeDtoModel> {}
