import { cast, SnapshotIn } from 'mobx-state-tree';
import { ILicenseeDtoModel, LicenseeDtoModel } from 'api/models/Domain/Dtos/LicenseeDtoModel';
import { State } from 'api/enums/State';
import {
    IRules,
    isValid, required, requiredString,
    validator,
} from 'infrastructure/validation';

const rules: IRules<ILicenseeDtoModel> = {
    stateOfSale: required,
    location: requiredString,
};

export const ContractLicenseeModel = LicenseeDtoModel.named('ContractLicenseeModel')
  .views(self => ({
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      return isValid(self, rules);
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));

export const emptyContractLicensee: ILicenseeDtoModel = {
  salesPersonId: '',
  salesPerson: '',
  stateOfSale: State.Qld,
  location: '',
};
