import { types, Instance } from 'mobx-state-tree';

export const AddressDtoModel = types.model('AddressDtoModel', {
  city: types.maybe(types.string),
  country: types.maybe(types.frozen<Domain.Aggregates.Shared.Country>()),
  postCode: types.maybe(types.string),
  state: types.maybe(types.frozen<Domain.Aggregates.Shared.State>()),
  streetAddress: types.maybe(types.string),
  suburb: types.maybe(types.string),
});

export interface IAddressDtoModel extends Instance<typeof AddressDtoModel> {}
