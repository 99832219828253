import { types, Instance } from 'mobx-state-tree';

export const ContractVehicleDtoModel = types.model('ContractVehicleDtoModel', {
  assetNo: types.maybe(types.string),
  bodyColour: types.maybe(types.string),
  buildDate: types.maybe(types.string),
  compliancePlateDate: types.maybe(types.string),
  condition: types.frozen<Domain.Aggregates.Quote.Entities.VehicleCondition>(),
  description: types.maybe(types.string),
  discount: types.maybe(types.number),
  disposalOrderNo: types.maybe(types.string),
  engineNumber: types.maybe(types.string),
  engineSize: types.maybe(types.string),
  estDeliveryDate: types.maybe(types.string),
  floorPlan: types.maybe(types.string),
  make: types.maybe(types.string),
  makeAndModel: types.maybe(types.string),
  model: types.maybe(types.string),
  odometer: types.maybe(types.number),
  price: types.maybe(types.number),
  registration: types.maybe(types.string),
  registrationExpiryDate: types.maybe(types.string),
  rrp: types.maybe(types.number),
  transmission: types.maybe(types.string),
  trimColour: types.maybe(types.string),
  vehicleType: types.maybe(types.string),
  vinNumber: types.maybe(types.string),
  year: types.maybe(types.string),
});

export interface IContractVehicleDtoModel extends Instance<typeof ContractVehicleDtoModel> {}
