import { types, Instance } from 'mobx-state-tree';

export const ContractDtoModel = types.model('ContractDtoModel', {
  createdBy: types.maybe(types.string),
  customerName: types.maybe(types.string),
  id: types.identifier,
  lastModifiedAt: types.string,
  location: types.string,
  numberAndVersion: types.string,
  status: types.frozen<Domain.Aggregates.Contract.Entities.ContractStatusCode>(),
  vehicleDescription: types.string,
});

export interface IContractDtoModel extends Instance<typeof ContractDtoModel> {}
