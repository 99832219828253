export enum ApplicableMinimumDepositRules {
    FatalBelow5kNew2kUsed = 0,
    WarningBelow10Percent = 1
}
export function applicableMinimumDepositRulesDescription(value: ApplicableMinimumDepositRules) {
    switch (value) {
        case ApplicableMinimumDepositRules.FatalBelow5kNew2kUsed:
            return `FatalBelow5kNew2kUsed`;
        case ApplicableMinimumDepositRules.WarningBelow10Percent:
            return `WarningBelow10Percent`;
    }
}
export interface IApplicableMinimumDepositRulesDescriptor { value: ApplicableMinimumDepositRules; name: string; description: string; }
export var allApplicableMinimumDepositRules: IApplicableMinimumDepositRulesDescriptor[] = [
    { value: ApplicableMinimumDepositRules.FatalBelow5kNew2kUsed, name: `FatalBelow5kNew2kUsed`, description: `FatalBelow5kNew2kUsed` },
    { value: ApplicableMinimumDepositRules.WarningBelow10Percent, name: `WarningBelow10Percent`, description: `WarningBelow10Percent` }
];

export function getApplicableMinimumDepositRulesDescriptor(value: ApplicableMinimumDepositRules) {
    switch (value) {
        case ApplicableMinimumDepositRules.FatalBelow5kNew2kUsed:
            return { value: ApplicableMinimumDepositRules.FatalBelow5kNew2kUsed, name: `FatalBelow5kNew2kUsed`, description: `FatalBelow5kNew2kUsed` };
        case ApplicableMinimumDepositRules.WarningBelow10Percent:
            return { value: ApplicableMinimumDepositRules.WarningBelow10Percent, name: `WarningBelow10Percent`, description: `WarningBelow10Percent` };
    }
}
