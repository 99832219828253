import {Location} from '../api/enums/Location';

const LocationCodeMap=new Map<string, string>();

LocationCodeMap.set(Location[Location.Adelaide],"ART");
LocationCodeMap.set(Location[Location.Geelong],"GRT");
LocationCodeMap.set(Location[Location.Brisbane],"BRT");
LocationCodeMap.set(Location[Location.Melbourne],"MRT");
LocationCodeMap.set(Location[Location.Newcastle],"NRT");
LocationCodeMap.set(Location[Location.Cairns],"CNS");


const GetVibeLocationCode = (location:string) =>
{
    return LocationCodeMap.has(location) ? LocationCodeMap.get(location) : null;
}

export {GetVibeLocationCode}


