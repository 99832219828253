import { QuoteApprovalStatus } from 'api/enums/QuoteApprovalStatus';
import { QuoteApprovalDtoModel } from 'api/models/Domain/Dtos/QuoteApprovalDtoModel';

export const QuoteApprovalModel = QuoteApprovalDtoModel.named('QuoteApprovalModel')
  .actions(self => {
    function setAsApproved(updateBy: string, note?: string) {
      self.status = QuoteApprovalStatus.Approved;
      self.updatedBy = updateBy;
      self.note = note;
    }
    function setAsRejected(updateBy: string, note?: string) {
      self.status = QuoteApprovalStatus.Rejected;
      self.updatedBy = updateBy;
      self.note = note;
    }

    return {
      setAsApproved,
      setAsRejected
    }
  })