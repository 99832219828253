import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, NumberTextField, RadioField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { FormSection } from 'views/components/forms/FormSection';
import styles from './TradeInSection.module.scss';

export const TradeInSection: React.FC = observer(function TradeInSection() {
  const { editContractModal } = useStore();

  const contract = editContractModal.temporaryContract;
  const tradeIn = contract.tradeIn;

  const save = async () => {
    await editContractModal.updateTradeIn();
  };

  return (
    <FormSection
      sectionTitle={`Trade-in: ${contract.tradeIn.description!}`}
      sectionSummary={`Trade-in Price: ${contract.tradeIn.priceFormatted}`}
      isFormActionAllowed={editContractModal.isEditable}
      expandedDefault={editContractModal.isSectionToModify('trade-in')}
      editableDefault={false}
      canSave={true}
      canCancel={true}
      onSubmit={save}
      hasErrors={!tradeIn.isValid}
      onValidate={() => tradeIn.isValid}
      onCancel={editContractModal.cancelEditingContract}
      shouldModifyVersion={contract.isFinalised}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'trade-in')}>
      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            <TextField
              id="vinNumber"
              className={styles.vinNumber}
              name="vin"
              label="VIN Number"
              readonly={!editable}
              value={tradeIn.vinNumber ?? ''}
              onChange={event => tradeIn.set('vinNumber', event.target.value)}
              required={true}
              validationError={tradeIn.validator('vinNumber')}
            />
            <TextField
              id="compliancePlateDate"
              className={styles.compliancePlateDate}
              name="compliancePlateDate"
              label="Compl Plate Date"
              readonly={!editable}
              type="date"
              shrink={true}
              value={tradeIn.compliancePlateDate?.toString() ?? ''}
              onChange={event => tradeIn.set('compliancePlateDate', event.target.value)}
              required={true}
              validationError={tradeIn.validator('compliancePlateDate')}
            />
            <NumberTextField
              id="buildDate"
              className={styles.buildDate}
              name="buildDate"
              label="Build Year"
              readonly={!editable}
              value={tradeIn.year ?? ''}
              onChange={event => tradeIn.set('year', event.target.value)}
              required={true}
              validationError={tradeIn.validator('year')}
            />
            <TextField
              id="registration"
              className={styles.registration}
              name="registrationNumber"
              label="Registration Number"
              readonly={!editable}
              value={tradeIn.registration ?? ''}
              onChange={event => tradeIn.set('registration', event.target.value)}
              required={true}
              validationError={tradeIn.validator('registration')}
            />
            <TextField
              id="registrationExpriyDate"
              className={styles.registrationExpriyDate}
              name="registrationExpiryDate"
              label="Registration Expiry"
              readonly={!editable}
              value={tradeIn.registrationExpiryDate?.toString() ?? ''}
              type="date"
              shrink={true}
              onChange={event => tradeIn.set('registrationExpiryDate', event.target.value)}
              required={true}
              validationError={tradeIn.validator('registrationExpiryDate')}
            />
            <TextField
              id="engineNumber"
              className={styles.engineNumber}
              name="engineNumber"
              label="Engine Number"
              readonly={!editable}
              value={tradeIn.engineNumber ?? ''}
              onChange={event => tradeIn.set('engineNumber', event.target.value)}
              required={true}
              validationError={tradeIn.validator('engineNumber')}
            />
            <TextField
              id="engineSize"
              className={styles.engineSize}
              name="engine-size"
              label="Engine Size"
              readonly={!editable}
              value={tradeIn.engineSize ?? ''}
              onChange={event => tradeIn.set('engineSize', event.target.value)}
              required={true}
              validationError={tradeIn.validator('engineSize')}
            />
            <TextField
              id="trimColour"
              className={styles.trimColour}
              name="trimColour"
              label="Trim Colour"
              readonly={!editable}
              value={tradeIn.trimColour ?? ''}
              onChange={event => tradeIn.set('trimColour', event.target.value)}
              required={true}
              validationError={tradeIn.validator('trimColour')}
            />
            <TextField
              id="bodyColour"
              className={styles.bodyColour}
              name="bodyColour"
              label="Body Colour"
              readonly={!editable}
              value={tradeIn.bodyColour ?? ''}
              onChange={event => tradeIn.set('bodyColour', event.target.value)}
              required={true}
              validationError={tradeIn.validator('bodyColour')}
            />
            <NumberTextField
              id="odometer"
              className={styles.odometer}
              name="odometer"
              label="Odometer"
              readonly={!editable}
              value={tradeIn.kms ?? ''}
              onChange={event => tradeIn.set('kms', event.target.value ? parseInt(event.target.value) : undefined)}
              required={true}
              validationError={tradeIn.validator('kms')}
            />
            <TextField
              id="accessories"
              className={styles.accessories}
              name="accessories"
              label="Accessories"
              readonly={!editable}
              value={tradeIn.accessories ?? ''}
              onChange={event => tradeIn.set('accessories', event.target.value)}
              multiline={true}
              rows={3}
            />
            <RadioField
              id="finance"
              className={styles.finance}
              name="tradeInFinance"
              label="Trade-in under finance?"
              readonly={!editable}
              direction="row"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={tradeIn.isTradeInUnderFinance?.toString()}
              onChange={event =>
                tradeIn.set('isTradeInUnderFinance', event.target.value === 'true')
              }
            />
            {contract.tradeIn.isTradeInUnderFinance && (
              <>
                <TextField
                  id="creditProvider"
                  className={styles.creditProvider}
                  name="creditProvider"
                  label="Credit Provider"
                  readonly={!editable}
                  value={tradeIn.creditProvider ?? ''}
                  onChange={event => tradeIn.set('creditProvider', event.target.value)}
                  required={true}
                  validationError={tradeIn.validator('creditProvider')}
                />
                <TextField
                  id="accountNumber"
                  className={styles.accountNumber}
                  name="accountNumber"
                  label="Account Number"
                  readonly={!editable}
                  value={tradeIn.accountNumber ?? ''}
                  onChange={event => tradeIn.set('accountNumber', event.target.value)}
                  required={true}
                  validationError={tradeIn.validator('accountNumber')}
                />
                <CurrencyTextField
                  id="payoutRequired"
                  className={styles.payoutRequired}
                  name="payoutRequired"
                  label="Payout Required"
                  readonly={!editable}
                  value={tradeIn.creditPayoutRequired ?? ''}
                  onChange={event =>
                    tradeIn.set('creditPayoutRequired', parseFloat(event.target.value))
                  }
                  required={true}
                  validationError={tradeIn.validator('creditPayoutRequired')}
                />
                <TextField
                  id="payoutValidationDueDate"
                  className={styles.payoutValidationDueDate}
                  name="payoutValidationDueDate"
                  label="Payout Validation Due"
                  readonly={!editable}
                  type="date"
                  shrink={true}
                  value={tradeIn.creditPayoutValidationDueDate ?? ''}
                  onChange={event =>
                    tradeIn.set('creditPayoutValidationDueDate', event.target.value)
                  }
                  required={true}
                  validationError={tradeIn.validator('creditPayoutValidationDueDate')}
                />
              </>
            )}
          </div>
        </>
      )}
    </FormSection>
  );
});
