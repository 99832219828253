import { HubConnectionBuilder } from '@microsoft/signalr';
import { isDevelopment } from 'appSettings';
import { getDefaultStore } from 'domain/store/RootStoreModel';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import { App } from 'views/App';
import { AppLoadFailed } from 'views/components/application/CannotSignIn';
// required until cypress support fetch API - https://github.com/cypress-io/cypress/issues/95
import 'whatwg-fetch';
import './index.scss';

async function init() {
  const history = createBrowserHistory();
  const store = getDefaultStore();

  const hubUrl = isDevelopment ? 'http://localhost:5000/hubs/retailApp' : '/hubs/retailApp';
  const signalRHubConnection = new HubConnectionBuilder()
    .withUrl(hubUrl)
    .withAutomaticReconnect()
    .build();

  if (isDevelopment) {
    const { enable } = await import('devtools');
    enable(store);
  }

  const signedIn = await store.security.signInCurrentUser();

  // If user is not signed in, then the browser will be redirected away
  if (signedIn) {
    ReactDOM.render(<App history={history} store={store} signalRHubConnection={signalRHubConnection} />, document.getElementById('root'));
  }
}

init().catch(e => {
  console.error('App initialization failed', e);
  ReactDOM.render(<AppLoadFailed />, document.getElementById('root'));
});
