import { Instance, types } from 'mobx-state-tree';
import { OrderExtraDtoModel } from 'api/models/Domain/Dtos/OrderExtraDtoModel';
import { AsAuCurrency } from '../../../../infrastructure/formatUtils';
import { createGuid } from '../../../../infrastructure/random';
import { IApplicableExtraDtoModel } from 'api/models/Domain/Queries/Vehicle/SearchVehicleDto/ApplicableExtraDtoModel';

export const OrderExtraModel = OrderExtraDtoModel.named('OrderExtraModel')
  .props({
    id: types.maybe(types.string),
  })
  .views(self => ({
    get priceFormatted() {
      return AsAuCurrency(self.price);
    },
    get totalPriceFormatted() {
      return AsAuCurrency(self.price * self.quantity);
    },
  }))
  .actions(self => ({
    setQuantity: (value?: number) => {
      self.quantity = value ?? 0;
    },
    set: (value: IApplicableExtraDtoModel) => {
      self.code = value.code;
      self.description = value.name;
      self.price = value.price ?? 0;
      self.quantity = 1;
      self.parentCode = value.parentCode ?? '';
      self.parentDescription = value.parentName ?? '';
    },
    afterCreate() {
      self.id = createGuid();
    },
  }));

export interface IOrderExtraModel extends Instance<typeof OrderExtraModel> {}
