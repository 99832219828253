import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'views/hooks';
import Alert from '@material-ui/lab/Alert';
import { statusCodeDescription } from 'api/enums/StatusCode';

export const QuoteWarning: React.FC = observer(function QuoteWarning() {
  const { editContractModal } = useStore();
  return (
    <>
      { editContractModal.hasAssociatedQuoteThatIsBeingWorkedOn && (
        <Alert severity="warning">
          The quote {editContractModal.associatedQuote!.numberAndVersion} has a status of
            "{statusCodeDescription(editContractModal.associatedQuote!.statusCode)}". Before this contract
          can be modified you must finish modifying{' '}
          {editContractModal.associatedQuote!.numberAndVersion} and click Generate Contract.
        </Alert>
      )}

      { editContractModal.hasInvalidAssociatedQuoteState && (
        <Alert severity="error">
          The quote {editContractModal.associatedQuote!.numberAndVersion} has a status of
          "{statusCodeDescription(editContractModal.associatedQuote!.statusCode)}". This
          contract can not be modified. You need to generate a contract from the latest
          quote version. If this is not possible, contact the system administrator.
      </Alert>
      )}
  </>
  );
});
