import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'views/components/application/ErrorBoundary';
import { Shell } from 'views/components/application/Shell';
import { Contract } from './contracts/Contract';
import { Home } from './home/Home';
import { NotFound } from './notFound/NotFound';
import { Order } from './orders/Order';
import { QuoteApproval } from './QuoteApprovals/QuoteApproval';
import { QuoteApprovalList } from './QuoteApprovals/QuoteApprovalList';
import { Quote } from './quotes/Quote';

export const Routes: React.FC = function() {
  const location = useLocation();

  return (
    <Shell>
      <ErrorBoundary key={location.pathname}>
        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route exact path="/quotes/new">
            <Quote />
          </Route>
          <Route exact path="/quotes/:quoteId">
            <Quote />
          </Route>
          <Route exact path="/deal-approvals">
            <QuoteApprovalList />
          </Route>
          <Route exact path="/deal-approvals/:quoteNumber">
            <QuoteApproval />
          </Route>
          <Route exact path="/contracts/:contractId">
            <Contract />
          </Route>
          <Route exact path="/orders/new">
            <Order />
          </Route>
          <Route exact path="/orders/:orderId">
            <Order />
          </Route>
          <Redirect exact path="/" to="/home" />
          <Redirect exact path="/quotes" to="/home" />
          <Redirect exact path="/contracts" to="/home" />
          <Redirect exact path="/orders" to="/home" />
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Shell>
  );
};
