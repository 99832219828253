import { types, Instance } from 'mobx-state-tree';
import { QuoteOptionalExtraDtoModel as DomainDtosQuoteOptionalExtraDtoModel } from './QuoteOptionalExtraDtoModel'
import { UpdatedOptionalExtraDtoModel as DomainDtosUpdatedOptionalExtraDtoModel } from './UpdatedOptionalExtraDtoModel'

export const OptionalExtrasCompareResultDtoModel = types.model('OptionalExtrasCompareResultDtoModel', {
  removed: types.array(DomainDtosQuoteOptionalExtraDtoModel),
  updated: types.array(DomainDtosUpdatedOptionalExtraDtoModel),
});

export interface IOptionalExtrasCompareResultDtoModel extends Instance<typeof OptionalExtrasCompareResultDtoModel> {}
