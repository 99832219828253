import { types, Instance } from 'mobx-state-tree';

export const OrderVehicleDtoModel = types.model('OrderVehicleDtoModel', {
  assetNo: types.maybe(types.string),
  disposalOrderNo: types.maybe(types.string),
  floorPlan: types.maybe(types.string),
  make: types.maybe(types.string),
  model: types.maybe(types.string),
  price: types.maybe(types.number),
  year: types.maybe(types.string),
});

export interface IOrderVehicleDtoModel extends Instance<typeof OrderVehicleDtoModel> {}
