import { State } from 'api/enums/State';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SelectField, TextField } from 'views/components/forms';
import { FormSection } from 'views/components/forms/FormSection';
import { useStore } from 'views/hooks';
import styles from './LicenseeSection.module.scss';

const dealerStates = [
  { value: State.Qld, name: `Qld`, description: `QLD` },
  { value: State.Sa, name: `Sa`, description: `SA` },
  { value: State.Vic, name: `Vic`, description: `VIC` },
  { value: State.Nsw, name: `Nsw`, description: `NSW` },
];

export const LicenseeSection: React.FC = observer(function LicenseeSection() {
  const { editContractModal, security } = useStore();
  const contract = editContractModal.temporaryContract;
  const title = `Licensee${contract ? ': ' + contract.licensee.location : ''}`;

  const save = async () => {
    await editContractModal.saveLicensee();
  };

  const cancel = () => {
    editContractModal.cancelEditingContract();
  };

  return (
    <FormSection
      sectionTitle={title}
      isFormActionAllowed={editContractModal.isEditable && !security.currentUser.isAftermarketPerson}
      expandedDefault={editContractModal.isSectionToModify('licensee')}
      editableDefault={false}
      onSubmit={save}
      shouldModifyVersion={contract.isFinalised}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'licensee')}
      canSave={true}
      canCancel={true}
      onCancel={cancel}>
      {({ focusFieldRef, editable }) => (
        <div className={styles.fields}>
          {!editable ? (
            <TextField
              name="dealership"
              label="Dealership"
              readonly={true}
              value={contract.licensee.location}
            />
          ) : (
            <SelectField
              name="dealership"
              label="Dealership"
              options={security.locations.map(x => ({ label: x.description, value: x.name }))}
              readonly={security.locations.length <= 1}
              value={contract.licensee.location}
              onChange={event =>
                contract.licensee.set(
                  'location',
                  security.locations.find(l => l.name === event.target.value)!.name
                )
              }
              validationError={contract.licensee.validator('location')}
              required={true}
            />
          )}
          <SelectField
            name="stateOfSale"
            label="State of Sale"
            options={dealerStates.map(x => ({ label: x.description, value: x.value.toString() }))}
            ignoreDefaultOption={true}
            readonly={!editable}
            value={contract.licensee.stateOfSale.toString()}
            onChange={event =>
              contract.licensee.set(
                'stateOfSale',
                dealerStates.find(s => s.value.toString() === event.target.value)!.value
              )
            }
            validationError={contract.licensee.validator('stateOfSale')}
            required={true}
          />
          <TextField
            name="salesPerson"
            label="Sales Person"
            readonly={true}
            value={contract.licensee.salesPerson}
          />
        </div>
      )}
    </FormSection>
  );
});
