import { types, Instance } from 'mobx-state-tree';
import { FittedExtrasCompareResultDtoModel as DomainDtosFittedExtrasCompareResultDtoModel } from './FittedExtrasCompareResultDtoModel'
import { OptionalExtrasCompareResultDtoModel as DomainDtosOptionalExtrasCompareResultDtoModel } from './OptionalExtrasCompareResultDtoModel'

export const VehicleDiffDtoModel = types.model('VehicleDiffDtoModel', {
  fittedExtrasCompareResult:DomainDtosFittedExtrasCompareResultDtoModel,
  newRebate: types.maybe(types.number),
  newRrp: types.maybe(types.number),
  oldRebate: types.maybe(types.number),
  oldRrp: types.maybe(types.number),
  optionalExtrasCompareResult:DomainDtosOptionalExtrasCompareResultDtoModel,
  quoteTotal: types.number,
});

export interface IVehicleDiffDtoModel extends Instance<typeof VehicleDiffDtoModel> {}
