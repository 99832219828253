import { VehicleCondition, vehicleConditionDescription } from "api/enums/VehicleCondition";
import { IVehicleDtoModel, VehicleDtoModel } from "api/models/Domain/Dtos/VehicleDtoModel";
import { AsAuCurrency } from "infrastructure/formatUtils";

export const VehicleModel = VehicleDtoModel.named('QuoteVehicleModel')
  .views(self => ({
    get conditionAsString() {
      return vehicleConditionDescription(self.condition ?? VehicleCondition.New);
    },
    get isVibeVehicle() {
      return !!self.assetNo;
    }
  }))
  .views(self => ({
    get yearValidationError(): string | undefined {
      return !self.year || /^[0-9]{4}$/.test(self.year)
        ? undefined
        : 'Year must be four digits';
    },
    get displayRrp() {
      if (!self.recommendedRetailPrice || !self.price) return undefined;
      // Show higher price between customer offer and RRP
      return Math.max(self.price, self.recommendedRetailPrice);
    },
    get displayPrice() {
      if (!self.recommendedRetailPrice || !self.price) return undefined;

      if (self.price > self.recommendedRetailPrice) {
        // When customer offer > RRP
        return self.price;
      }

      return self.recommendedRetailPrice - (self.discount ?? 0);
    },
    get displayDiscount() {
      return (self.discount !== undefined && self.discount > 0)
      ? self.discount
      : '';
    },
    get lineTotalPrice() {
      if (!self.recommendedRetailPrice || !self.price) return undefined;
      return Math.max(self.price, self.recommendedRetailPrice);
    },
    get description() {
      return [self.conditionAsString, self.year, (self.floorPlan || this.makeAndModel), self.registration]
        .filter(x => x)
        .join(' ');
    },
    get makeAndModel() {
      // Ensure that make and model is consistent across asset types
      if (self.floorPlan) return self.floorPlan;
      
      const isNew = self.conditionAsString === "New" || self.conditionAsString === "New Build" || false;
      const isUsed = self.conditionAsString === "Used" || false;

      if (isNew && self.model) return self.model;
      if (isUsed && self.make && self.model) return `${self.make} ${self.model}`;

      // We shouldn't have the case where the vehicle condition is an unexpected value,
      // but return an empty string just in case
      return "";
    },
  }))
  .views(self => ({
    get discountFormatted() {
      return self.discount !== undefined && self.discount > 0
        ? AsAuCurrency(self.discount)
        : undefined;
    },
    get totalPriceFormatted() {
      return AsAuCurrency(self.displayPrice);
    },
    get rebateFormatted() {
      return AsAuCurrency(Math.abs(!!self.rebate ? self.rebate.amount : 0))
    },
    get isEmpty(): boolean {
      return !self.make
        && !self.model
        && !self.year
        && !self.registration
        && !self.price;
    },
  }))
  .actions(self => ({
    updateCondition: (condition: string) => { self.condition = parseInt(condition) },
    updateMake: (make: string) => { self.make = make },
    updateModel: (model: string) => { self.model = model },
    updateYear: (year: string) => { self.year = year },
    updateIdleLocation: (idleLocation: string) => { self.idleLocation = idleLocation },
    updateRegistration: (registration: string) => { self.registration = registration },
    updateFloorPlan: (floorPlan: string) => { self.floorPlan = floorPlan },
    updateRecommendedRetailPrice: (rrp?: number) => {
      self.recommendedRetailPrice = rrp;
      if (rrp === undefined) self.price = undefined;
      else self.price = rrp - (self.discount ?? 0);
    },
    updatePrice: (price?: number) => {
      self.price = price;
    },
    updateDiscount: (discount?: number) => {
      if (discount !== undefined && discount < 0) {
        return;
      }
      self.discount = discount;
      if (self.recommendedRetailPrice !== undefined) {
        self.price = self.recommendedRetailPrice - (self.discount ?? 0);
      }
    },
  }));

export const emptyVehicle: IVehicleDtoModel = {
  assetNo: '',
  disposalOrderNo: '',
  condition: VehicleCondition.New,
  make: '',
  model: '',
  year: '',
  registration: '',
  price: undefined,
  discount: undefined,
  floorPlan: '',
  idleLocation: '',
  rebate: undefined,
  recommendedRetailPrice: undefined
};
