import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AsAuCurrency, AsFormattedDateTime } from 'infrastructure/formatUtils';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { useStore } from 'views/hooks';
import styles from './QuoteApprovalList.module.scss';

export const QuoteApprovalList: React.FC = observer(() => {
  const { quoteApprovalsRepo } = useStore();
  const history = useHistory();

  useEffect(() => {
    quoteApprovalsRepo.loadQuotes();
  }, [quoteApprovalsRepo]);

  const openQuoteApproval = (quoteNumber: number) => history.push(`/deal-approvals/${quoteNumber}`);

  return (
    <PageLayout title="Deal Approvals" parent="none">
      <LoadingPane isLoading={!!quoteApprovalsRepo.isLoading}>
        <Table className={styles.table}>
          <TableHead className={styles.tablehead}>
            <TableRow>
              <TableCell>Quote Number</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Vehicle description</TableCell>
              <TableCell>Total Sell Price</TableCell>
              <TableCell>Min Sell</TableCell>
              <TableCell>Requested By</TableCell>
              <TableCell>Requested At</TableCell>
              <TableCell>Assigned To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              quoteApprovalsRepo.quotes.map(quote => {
                return (
                  <TableRow key={quote.id} className={styles.row}>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.numberAndVersion}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.customer.fullName}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.vehicle.description}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.totalPriceFormatted}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {AsAuCurrency(quote.minSell)}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.latestApproval!.createdBy}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {AsFormattedDateTime(quote.latestApproval!.createdAt)}
                    </TableCell>
                    <TableCell onClick={() => openQuoteApproval(quote.quoteNumber)}>
                      {quote.latestApproval!.assignedTo.name}
                    </TableCell>
                  </TableRow>);
              })
            }
          </TableBody>
        </Table>
        {
          quoteApprovalsRepo.quotes.length === 0 &&
          <p className={styles.noquotes}>There are no requests.</p>
        }
      </LoadingPane>
    </PageLayout>
  )
});