import { IQuoteCustomerDtoModel, QuoteCustomerDtoModel } from 'api/models/Domain/Dtos/QuoteCustomerDtoModel';
import { cast, SnapshotIn } from 'mobx-state-tree';
import { IRules, isValid,  requiredString } from "infrastructure/validation";
import { ICustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';

type ICustomerDto = Domain.Queries.Customer.ICustomerDto;
const rules: IRules<IQuoteCustomerDtoModel> = {
  firstName: requiredString,
  surname: requiredString
};

export const QuoteCustomerModel = QuoteCustomerDtoModel.named('QuoteCustomerModel')
  .views(self => ({
    firstNameValidationError(): string | undefined {
      return !self.firstName
        ? 'Required'
        : undefined;
    },
    surnameValidationError(): string | undefined {
      return !self.surname
        ? 'Required'
        : undefined;
    },
    get isValid() {
      return isValid(self, rules);
    },

    equals(customer: ICustomerDto) : boolean {
      return customer.firstName === self.firstName &&
        customer.surname === self.surname &&
        (customer.address || undefined) === self.address &&
        (customer.phone || undefined) === self.phone &&
        (customer.email || undefined) === self.email &&
        (customer.phoneAlternate || undefined) === self.phoneAlternate &&
        (customer.abn || undefined) === self.abn
    },
    get isEmpty(): boolean {
      return !self.abn
        && !self.address
        && !self.companyName
        && !self.email
        && !self.contactId
        && !self.firstName
        && !self.firstName
        && !self.phone
        && !self.phoneAlternate
        && !self.surname;
    },
  }))
  .actions(self => ({
    getSyncState(lmsCustomer: ICustomerDtoModel | undefined) {
      if (!lmsCustomer) return;
      return {
        "abnChanged": self.abn !== lmsCustomer?.abn && !(self.abn == null && lmsCustomer?.abn === ""),
        "streetAddressChanged": self.address?.streetAddress !== lmsCustomer?.address?.streetAddress && !(self.address?.streetAddress == null && lmsCustomer?.address?.streetAddress === ""),
        "suburbChanged": self.address?.suburb !== lmsCustomer?.address?.suburb && !(self.address?.suburb == null && lmsCustomer?.address?.suburb === ""),
        "countryChanged": self.address?.country !== lmsCustomer?.address?.country,
        "stateChanged": self.address?.state !== lmsCustomer?.address?.state,
        "cityChanged": self.address?.city !== lmsCustomer?.address?.city && !(self.address?.city == null && lmsCustomer?.address?.city === ""),
        "postCodeChanged": self.address?.postCode !== lmsCustomer?.address?.postCode && !(self.address?.postCode == null && lmsCustomer?.address?.postCode === ""),
        "companyNameChanged": self.companyName !== lmsCustomer?.companyName && !(self.companyName == null && lmsCustomer?.companyName === ""),
        "emailChanged": self.email !== lmsCustomer?.email && !(self.email == null && lmsCustomer?.email === ""),
        "firstNameChanged": self.firstName !== lmsCustomer?.firstName,
        "phoneChanged": self.phone !== lmsCustomer?.phone && !(self.phone == null && lmsCustomer?.phone === ""),
        "phoneAlternateChanged": self.phoneAlternate !== lmsCustomer?.phoneAlternate && !(self.phoneAlternate == null && lmsCustomer?.phoneAlternate === ""),
        "surNameChanged": self.surname !== lmsCustomer?.surname && !(self.surname == null && lmsCustomer?.surname === "")
      };
    },
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
        key: K,
        value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));

export const emptyQuoteCustomer: IQuoteCustomerDtoModel = {
  firstName: '',
  surname: '',
  fullName:'',
  phone: '',
  phoneAlternate: '',
  address: undefined,
  email: '',
  companyName: '',
  abn: '',
  contactId: undefined,
};
