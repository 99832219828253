import { types, Instance } from 'mobx-state-tree';
import { SalesManagerDtoModel as DomainDtosSalesManagerDtoModel } from './SalesManagerDtoModel'

export const QuoteApprovalDtoModel = types.model('QuoteApprovalDtoModel', {
  assignedTo:DomainDtosSalesManagerDtoModel,
  createdAt: types.string,
  createdBy: types.string,
  escalatedNote: types.maybe(types.string),
  id: types.identifierNumber,
  note: types.maybe(types.string),
  status: types.frozen<Domain.Aggregates.Quote.Entities.QuoteApprovalStatus>(),
  updatedAt: types.maybe(types.string),
  updatedBy: types.maybe(types.string),
});

export interface IQuoteApprovalDtoModel extends Instance<typeof QuoteApprovalDtoModel> {}
