import { types, Instance } from 'mobx-state-tree';
import { FittedExtraDtoModel as DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel } from './../../Domain/Queries/Vehicle/SearchVehicleDto/FittedExtraDtoModel'
import { QuoteFittedExtraDtoModel as DomainDtosQuoteFittedExtraDtoModel } from './QuoteFittedExtraDtoModel'

export const UpdatedFittedExtraDtoModel = types.model('UpdatedFittedExtraDtoModel', {
  new:DomainQueriesVehicleSearchVehicleDtoFittedExtraDtoModel,
  old:DomainDtosQuoteFittedExtraDtoModel,
});

export interface IUpdatedFittedExtraDtoModel extends Instance<typeof UpdatedFittedExtraDtoModel> {}
