import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks';
import { HistoryDialog } from 'views/components/dialogs/HistoryDialog';
import { IOrderVersionModel } from 'domain/store/repos/orders/OrderVersionModel';
import { Link } from 'react-router-dom';
import { OrderStatus } from './OrderStatus';
import { DateTime } from 'luxon';

interface IOrderHistoryDialogProps {
  onClose(): void;
}

export const OrderHistoryDialog: React.FC<IOrderHistoryDialogProps> = observer(props => {
  const { editOrderModel } = useStore();
  useEffect(() => {
    (async () => {
      await editOrderModel.loadOrderVersions();
    })();
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <HistoryDialog
      title="Order History"
      items={(editOrderModel.versions || ([] as IOrderVersionModel[])).map(v => ({
        number: <Link to={`/orders/${v.id}`}>{v.numberAndVersion}</Link>,
        status: <OrderStatus statusCode={v.status} />,
        lastModifiedAt: v.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED),
        lastModifiedBy: v.lastModifiedBy,
      }))}
      onClose={props.onClose}
    />
  );
});
