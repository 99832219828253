import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, ButtonWithProgress } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { ContractCancellationDialog } from 'views/routes/contracts/components/ContractCancellationDialog';
import { ContractDownloadDialog } from 'views/routes/contracts/components/ContractDownloadDialog';
import { CreateOrderDialog } from 'views/routes/contracts/components/CreateOrderDialog';
import { DeliveredDialog } from 'views/routes/contracts/components/DeliveredDialog';
import styles from './ContractActions.module.scss';
import { DeleteDraftDialog } from '../../contracts/components/DeleteDraftDialog';
import { StatusCode } from 'api/enums/StatusCode';

export const ContractActions: React.FC = observer(function ContractActions() {
  const store = useStore();
  const currentUser = store.security.currentUser;
  const editContractModal = store.editContractModal;
  const contract = editContractModal.temporaryContract;
  const [openCancellationDialog, setOpenCancellationDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [finaliseInProgress, setFinaliseInProgress] = useState<boolean>(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState<boolean>(false);
  const [openDelivered, setOpenDelivered] = useState(false);
  const [openCreateOrderDialog, setOpenCreateOrderDialog] = useState<boolean>(false);

  const finaliseContract = async () => {
    if (!contract) return;
    try {
      setFinaliseInProgress(true);
      await editContractModal.finaliseContract();
    } finally {
      setFinaliseInProgress(false);
    }
  };
 
  const disableCancelAction = currentUser.isAftermarketPerson
    || ![StatusCode.Draft, StatusCode.AwaitingApproval, StatusCode.Accepted].includes(editContractModal.associatedQuote?.statusCode!);

  if (editContractModal.isReadOnly ) return <div className={styles.actions}>
    {contract.statusCode !== ContractStatusCode.Draft && (
    <Button onClick={() => setOpenDownloadDialog(true)}>Download</Button>
    )}
    {openDownloadDialog && (
      <ContractDownloadDialog onClose={() => setOpenDownloadDialog(false)} />
    )}
  </div>     


  return (
    <div className={styles.actions}>
      {contract.canBeCancelled && (
        <Button disabled={ disableCancelAction } onClick={() => setOpenCancellationDialog(true)}>Cancel</Button>
      )}
      {contract.statusCode === ContractStatusCode.Open && (
        <Button disabled={!editContractModal.isEditable || contract.totalBalancePayable !== 0} onClick={() => setOpenDelivered(true)}>
          Delivered
        </Button>
      )}
      {contract.statusCode === ContractStatusCode.Draft && (
        <ButtonWithProgress
          inProgress={finaliseInProgress}
          disabled={!editContractModal.isEditable || !editContractModal.canFinalise}
          onClick={finaliseContract}>
          Finalise
        </ButtonWithProgress>
      )}
      {editContractModal.canGenerateOrder && currentUser.orderFeatureEnabled &&!currentUser.isAftermarketPerson && (
        <Button onClick={() => setOpenCreateOrderDialog(true)}>Generate Order</Button>
      )}
      {contract.statusCode !== ContractStatusCode.Draft && (
        <Button onClick={() => setOpenDownloadDialog(true)}>Download</Button>
      )}
      {contract.statusCode === ContractStatusCode.Draft && !contract.isFromQuote && (
        <Button onClick={() => setOpenDeleteDialog(true)}>Delete</Button>
      )}

      {openCancellationDialog && (
        <ContractCancellationDialog onClose={() => setOpenCancellationDialog(false)} />
      )}
      {openDownloadDialog && (
        <ContractDownloadDialog onClose={() => setOpenDownloadDialog(false)} />
      )}
      {openDelivered && (
        <DeliveredDialog
          onClose={() => setOpenDelivered(false)}
          onSave={editContractModal.vehicleDelivered}
        />
      )}
      {openCreateOrderDialog && (
        <CreateOrderDialog onClose={() => setOpenCreateOrderDialog(false)} />
      )}
      {openDeleteDialog && <DeleteDraftDialog onClose={() => setOpenDeleteDialog(false)} />}
    </div>
  );
});
