import { requiredNumber, requiredString } from 'infrastructure/validation';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, NumberTextField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { QuoteSection } from 'views/routes/quotes/components/QuoteSection';
import styles from './TradeInSection.module.scss';

export const TradeInSection: React.FC = observer(function TradeInSection() {
  const { editQuoteModel,security } = useStore();
  const quote = editQuoteModel.temporaryQuote;
  const tradeIn = quote.tradeIn;
  const isEmpty = tradeIn.isEmpty;
  const sectionTitle = `Trade-in${isEmpty ? '' : ': ' + tradeIn.description}`;

  const save = async() => {
    await editQuoteModel.updateTradeIn();
  };

  return (
    <QuoteSection
      sectionTitle={sectionTitle}
      sectionSummary={
        isEmpty ? undefined : `Trade-in Price: ${quote?.tradeIn.priceFormatted || '<not set>'}`
      }
      isFormActionAllowed={editQuoteModel.isEditable && !security.currentUser.isAftermarketPerson}
      expandedDefault={false}
      canSave={!editQuoteModel.isNewQuote}
      canCancel={true}
      onSubmit={save}
      onCancel={editQuoteModel.cancelEditingQuote}
      onClear={editQuoteModel.resetTradeIn}>
      {({ focusFieldRef, editable }) => (
        <div className={styles.fields}>
          <TextField
            id="quote-tradein-make"
            inputRef={focusFieldRef}
            className={styles.make}
            name="make"
            label="Make"
            readonly={!editable}
            value={tradeIn.make ?? ''}
            onChange={event => tradeIn.updateMake(event.target.value)}
            required={tradeIn.isRequired}
            validationError={tradeIn.isRequired ? requiredString(tradeIn.make) : undefined}
          />
          <TextField
            id="quote-tradein-model"
            className={styles.model}
            name="model"
            label="Model"
            readonly={!editable}
            value={tradeIn.model ?? ''}
            onChange={event => tradeIn.updateModel(event.target.value)}
            required={tradeIn.isRequired}
            validationError={tradeIn.isRequired ? requiredString(tradeIn.model) : undefined}
          />
          <TextField
            id="quote-tradein-year"
            className={styles.year}
            name="year"
            label="Year"
            readonly={!editable}
            value={tradeIn.year ?? ''}
            onChange={event => tradeIn.updateYear(event.target.value)}
            validationError={tradeIn.isRequired ? tradeIn.yearValidationError : undefined}
            required={tradeIn.isRequired}
          />
          <TextField
            id="quote-tradein-registration"
            className={styles.registration}
            name="registration"
            label="Registration"
            readonly={!editable}
            value={tradeIn.registration ?? ''}
            onChange={event => tradeIn.updateRegistration(event.target.value)}
            validationError={tradeIn.registrationValidationError}
          />
          <NumberTextField
            id="quote-tradein-kms"
            className={styles.kms}
            name="kms"
            label="Kilometres"
            readonly={!editable}
            value={tradeIn.kms ?? ''}
            onChange={event =>
              event.target.value
                ? tradeIn.updateKms(parseInt(event.target.value))
                : tradeIn.updateKms(undefined)
            }
            validationError={tradeIn.kmsValidationError}
          />
          <CurrencyTextField
            id="quote-tradein-price"
            className={styles.price}
            name="price"
            label="Trade-in Price"
            readonly={!editable}
            value={tradeIn.price ?? ''}
            onChange={event =>
              event.target.value
                ? tradeIn.updatePrice(parseFloat(event.target.value))
                : tradeIn.updatePrice(undefined)
            }
            required={tradeIn.isRequired}
            validationError={tradeIn.isRequired ? requiredNumber(tradeIn.price) : undefined}
          />
        </div>
      )}
    </QuoteSection>
  );
});
