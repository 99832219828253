import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "views/hooks";

export function useQuoteApprovalStore() {
  const { quoteApprovalStore } = useStore();
  const { quoteNumber } = useParams<{ quoteNumber?: string }>();

  useEffect(() => {
    if (quoteNumber) {
      quoteApprovalStore.loadQuote(parseInt(quoteNumber));
    }
  }, [quoteNumber, quoteApprovalStore]);

  return quoteApprovalStore;
}
