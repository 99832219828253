import { types, Instance } from 'mobx-state-tree';

export const QuoteVersionDtoModel = types.model('QuoteVersionDtoModel', {
  id: types.identifier,
  lastModifiedAt: types.string,
  lastModifiedBy: types.string,
  numberAndVersion: types.string,
  status: types.frozen<Domain.Aggregates.Quote.Entities.StatusCode>(),
});

export interface IQuoteVersionDtoModel extends Instance<typeof QuoteVersionDtoModel> {}
