import { types, Instance } from 'mobx-state-tree';
import { TypeModel as DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryTypeModel } from './TypeModel'

export const ConditionModel = types.model('ConditionModel', {
  code: types.string,
  name: types.string,
  types: types.array(DomainQueriesVehicleAdvanceSearchVehicleOptionsQueryTypeModel),
});

export interface IConditionModel extends Instance<typeof ConditionModel> {}
