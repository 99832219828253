import { ContractStatusCode, contractStatusCodeDescription } from 'api/enums/ContractStatusCode';
import { ContractDtoModel as ContractListItemDtoModel } from 'api/models/Domain/Queries/Contract/GetContractsQuery/ContractDtoModel';
import { getAjax } from 'domain/store/RootStoreModel';
import { observable } from 'mobx';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { localDateTimeType } from '../types/LocalDateTimeType';

type IContractsDto = Domain.Queries.Contract.GetContractsQuery.IContractsDto;

const ContractListItemModel = types
  .model('ContractListItemModel', {
    ...ContractListItemDtoModel.properties,
    lastModifiedAt: localDateTimeType
  })
  .views(self => ({
    get showContractNumber(): boolean {
      return ![ContractStatusCode.Cancelled, ContractStatusCode.Delivered, ContractStatusCode.Draft, ContractStatusCode.Open]
        .includes(self.status);
    },
    get contractStatusCodeDescription(): string {
      return contractStatusCodeDescription(self.status);
    }
  }));

interface ILoadingContractProcess {
    key: string;
    aborter: AbortController;
    reason: LoadingReason;
}
  
interface ILoadingContractsProcess {
    filter: string;
    aborter: AbortController;
}

type LoadingReason = 'explicit' | 'update';

export const ContractsRepo = types
  .model('ContractsRepo', {
    contracts: types.array(ContractListItemModel),
    moreRecordsExist: false,
    filter: '',
  })
  .extend(self => {
    const localState = observable({
      contractLoading: null as ILoadingContractProcess | null,
      contractsLoading: null as ILoadingContractsProcess | null,
    });

    function* loadContracts(filter: string = '') {
      if (localState.contractsLoading?.filter === self.filter) {
        return;
      }
      self.filter = filter;
      localState.contractsLoading?.aborter.abort();
      try {
        localState.contractsLoading = {
          filter: self.filter,
          aborter: new AbortController(),
        };
        const dto: IContractsDto = yield getAjax(self).get(`/api/contracts`, 
        {
          searchParams: { filter: self.filter },
          signal: localState.contractsLoading.aborter.signal,
        }).json();
        self.contracts = cast(dto.contracts);
        self.moreRecordsExist = dto.moreRecordsExist;
      } finally {
        localState.contractsLoading = null;
      }
    }

    return {
      views: {
        get loadingContractReason() {
          return localState.contractLoading?.reason || null;
        },
        get isLoadingContracts(): boolean {
          return !!localState.contractsLoading;
        },
      },
      actions: {
        loadContracts: flow(loadContracts),
      },
    };
  });

export interface IContractsRepo extends Instance<typeof ContractsRepo> { }
