import { DateTime } from 'luxon';
import { Instance, types } from 'mobx-state-tree';
import { IAddNotificationEvent } from '../bus/Bus';
import { getBus } from '../RootStoreModel';

const MAX_PAST_NOTIFICATIONS = 10;

export interface INotification
  extends Readonly<{
    message: string;
    level: 'error' | 'warning' | 'information' | 'success';
    requireAcknowledgement: boolean;
    when: DateTime;
  }> {}

export const NotificationsModel = types
  .model('NotificationModel', {
    notificationsToShow: types.array(types.frozen<INotification>()),
    pastNotifications: types.array(types.frozen<INotification>()),
  })
  .actions(self => {
    function add(message: string, level: INotification['level'], requireAcknowledgement: boolean) {
      const newNotification = { message, level, requireAcknowledgement, when: DateTime.utc() };
      self.notificationsToShow.push(newNotification);
    }

    const addError = (msg: string) => add(msg, 'error', true);
    const addWarning = (msg: string) => add(msg, 'warning', true);
    const addInformation = (msg: string) => add(msg, 'information', false);
    const addSuccess = (msg: string) => add(msg, 'success', false);

    function setNotificationsAsShown() {
      self.pastNotifications.push(...self.notificationsToShow);
      self.notificationsToShow.clear();

      if (self.pastNotifications.length > MAX_PAST_NOTIFICATIONS) {
        self.pastNotifications.splice(0, self.pastNotifications.length - MAX_PAST_NOTIFICATIONS);
      }
    }

    return { add, addError, addWarning, addInformation, addSuccess, setNotificationsAsShown };
  })
  .actions(self => ({
    afterAttach() {
      getBus(self).registerHandler('ADD_NOTIFICATION', (e: IAddNotificationEvent) =>
        self.add(e.message, e.level, e.level === 'error' || e.level === 'warning')
      );
    },
  }));

export interface INotificationsModel extends Instance<typeof NotificationsModel> {}
