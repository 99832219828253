import {Location,getLocationDescriptor} from '../api/enums/Location';

const LocationDealerIdMap=new Map<string, string>();
LocationDealerIdMap.set(getLocationDescriptor(Location.Adelaide).name,"2085");
LocationDealerIdMap.set(getLocationDescriptor(Location.Brisbane).name,"2091");
LocationDealerIdMap.set(getLocationDescriptor(Location.Geelong).name,"2092");
LocationDealerIdMap.set(getLocationDescriptor(Location.Melbourne).name,"2093");
LocationDealerIdMap.set(getLocationDescriptor(Location.Newcastle).name,"2094");
LocationDealerIdMap.set(getLocationDescriptor(Location.Cairns).name,"2558");

const GetDealerId = (location:string) =>
{
    return LocationDealerIdMap.has(location) ? LocationDealerIdMap.get(location) : "";
}

export {GetDealerId};