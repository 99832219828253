import React from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { ToastContainer, toast, ToastId } from 'react-toastify';
import { INotification } from 'domain/store/singletons/NotificationsModel';
import { useStore } from 'views/hooks';
import { IRootStoreModel } from 'domain/store/RootStoreModel';

import 'react-toastify/scss/main.scss';
import styles from './Notifications.module.scss';

const MAX_NOTIFICATIONS_TO_SHOW = 3;

interface INotificationProps {
  notification: INotification;
}

export const Notification: React.FC<INotificationProps> = observer(function Notification({
  notification,
}) {
  return (
    <div className={styles.notification}>
      <span className={styles.icon}>{getLevelIcon(notification.level)}</span>
      &nbsp;
      <span>{notification.message}</span>
    </div>
  );
});

export const Notifications: React.FC = observer(function Notifications() {
  const store = useStore();
  const openToastIds = React.useRef<ToastId[]>([]);

  React.useEffect(() => {
    return autorun(() =>
      showNotifications(store, openToastIds, store.notifications.notificationsToShow)
    );
  }, [store]);

  return (
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      pauseOnHover
      closeButton={false}
      className={styles.container}
    />
  );
});

function showNotifications(
  store: IRootStoreModel,
  toastIds: React.MutableRefObject<ToastId[]>,
  toShow: INotification[]
) {
  toShow.forEach(n => {
    const id: ToastId = toast(<Notification notification={n} />, {
      className: `${styles.toast} ${getLevelClassName(n.level)}`,
      progressClassName: 'progress',
      autoClose: n.requireAcknowledgement ? false : 5000,
      onOpen: () => {
        toastIds.current.push(id);
        handleTooManyOpenNotifications(toastIds);
      },
      onClose: () => {
        toastIds.current = toastIds.current.filter(i => i !== id);
      },
    });
  });

  toShow.length && store.notifications.setNotificationsAsShown();
}

function getLevelIcon(level: INotification['level']) {
  switch (level) {
    case 'warning':
      return '⚠';
    case 'information':
      return '🛈';
    case 'success':
      return '🗹';
  }
  return '🛇';
}

function getLevelClassName(level: INotification['level']) {
  switch (level) {
    case 'warning':
      return styles.warning;
    case 'information':
      return styles.information;
    case 'success':
      return styles.success;
  }
  return styles.error;
}

function handleTooManyOpenNotifications(toastIds: React.MutableRefObject<ToastId[]>) {
  if (toastIds.current.length > MAX_NOTIFICATIONS_TO_SHOW) {
    const idsToClose = toastIds.current.splice(
      0,
      toastIds.current.length - MAX_NOTIFICATIONS_TO_SHOW
    );
    idsToClose.forEach(toast.dismiss);
  }
}
