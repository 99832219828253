import { types, Instance } from 'mobx-state-tree';

export const QuoteFittedExtraDtoModel = types.model('QuoteFittedExtraDtoModel', {
  code: types.string,
  description: types.string,
  parentCode: types.maybe(types.string),
  parentDescription: types.maybe(types.string),
  quantity: types.integer,
  recommendedRetailPrice: types.number,
  unitPrice: types.number,
});

export interface IQuoteFittedExtraDtoModel extends Instance<typeof QuoteFittedExtraDtoModel> {}
