import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'views/hooks';
import { ExpansionPanel, ExpansionPanelActions, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import { QuoteApprovalStatus } from 'api/enums/QuoteApprovalStatus';
import { ButtonWithProgress, SelectField } from 'views/components/forms';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog'
import styles from './ManagerApprovalSection.module.scss';

export const ManagerApprovalSection: React.FC = observer(function ManagerApprovalSection() {

  const { editQuoteModel, security } = useStore();
  const [salesManagerId, setSalesManagerId] = useState('');
  const [requestApprovalInProgress, setRequestApprovalInProgress] = useState(false);
  const [resendRequestInProgress, setResendRequestInProgress] = useState(false);
  const [cancelApprovalRequestInProgress, setCancelApprovalRequestInProgress] = useState(false);
  const [touched, setTouched] = useState(false);
  const [openCancelApprovalDialog, setOpenCancelApprovalDialog] = useState(false);

  const salesManagers = security.currentUser.managers
    .map(sm => ({
      value: sm.id,
      label: sm.name
    }));

  const quote = editQuoteModel.temporaryQuote;
  const isAwaitingApproval = quote.latestApproval && quote.latestApproval.status === QuoteApprovalStatus.New;
  const requestWasApproved = quote.latestApproval && quote.latestApproval.status === QuoteApprovalStatus.Approved;
  const requestWasRejected = quote.latestApproval && quote.latestApproval.status === QuoteApprovalStatus.Rejected;

  const formTouched = () => setTouched(true);

  const requestApproval = async () => {
    formTouched();
    if (!salesManagerId) return;
    setRequestApprovalInProgress(true);

    try {
      await editQuoteModel.requestApproval(salesManagerId);
    } finally {
      setRequestApprovalInProgress(false);
    }
  };

  const resendRequest = async () => {
    formTouched();
    if (!salesManagerId) return;
    setResendRequestInProgress(true);
    try {
      await editQuoteModel.resendApprovalRequest(salesManagerId);
    } finally {
      setResendRequestInProgress(false);
    }
  };

  const cancelApprovalRequest = async () => {
    setCancelApprovalRequestInProgress(true);
    try {
      await editQuoteModel.cancelApprovalRequest();
    }
    finally {
      setCancelApprovalRequestInProgress(false);
    }
  }

  const approvalMessage = quote.latestApproval?.note ? 'Message: ' + quote.latestApproval?.note : '';

  return (
    <>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary>
          <Typography>
            <span className={styles.title}>Manager Approval</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={styles.details}>
          <div className={styles.fields}>
            {
              isAwaitingApproval &&
              <span>{`Awaiting approval from ${quote.latestApproval?.assignedTo.name}`}</span>
            }
            {
              requestWasApproved &&
              <span>{`Approved by ${quote.latestApproval?.updatedBy}. ${approvalMessage}`}</span>
            }
            {
              requestWasRejected &&
              <span>{`Rejected by ${quote.latestApproval?.updatedBy}. ${approvalMessage}`}</span>
            }
            {
              (!requestWasApproved) &&
              <SelectField
                label= {isAwaitingApproval?'Resend request to':'Send request to'}
                ignoreDefaultOption = {true}
                name='sales-manager'
                options={salesManagers}
                value={salesManagerId}
                required={true}
                onChange={(event) => setSalesManagerId(event.target.value as string)}
              />
            }
            {(touched && !salesManagerId) && (
                <>
                  <div className={ styles.error }>A manager is required to submit an approval request.</div>
                </>
              )}
          </div>
        </ExpansionPanelDetails>
        <ExpansionPanelActions className={styles.actions}>
          {
            quote.needManagerApproval && !isAwaitingApproval && !requestWasApproved &&
            <ButtonWithProgress
              inProgress={requestApprovalInProgress}
              onClick={requestApproval}
            >
              Request Approval
            </ButtonWithProgress>
          }
          {
            quote.needManagerApproval && isAwaitingApproval &&
            <ButtonWithProgress
              inProgress={cancelApprovalRequestInProgress}
              onClick={() => setOpenCancelApprovalDialog(true)}
            >
              Cancel Request
            </ButtonWithProgress>
          }
          {
            quote.needManagerApproval && isAwaitingApproval &&
            <ButtonWithProgress
              inProgress={resendRequestInProgress}
              onClick={resendRequest}
            >
              Resend Request
            </ButtonWithProgress>
          }        
        </ExpansionPanelActions>
      </ExpansionPanel >
      {openCancelApprovalDialog && (
        <BasicSubmitCancelDialog
          title="Cancel Approval"
          okLabel="Cancel Approval >"
          formik={{
            initialValues: {},
            onSubmit: cancelApprovalRequest,
          }}
          onClose={() => setOpenCancelApprovalDialog(false)}
          content={
            <p>
              Are you sure you want to cancel this approval request?
            </p>
          }
        />
      )}
    </>
  );
});
