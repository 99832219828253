import { QuoteFittedExtraDtoModel } from "api/models/Domain/Dtos/QuoteFittedExtraDtoModel";
import { AsAuCurrency } from "infrastructure/formatUtils";
import { Instance } from "mobx-state-tree";

export const QuoteFittedExtraModel = QuoteFittedExtraDtoModel.named('QuoteFittedExtraModel')
  .views(self => ({
    get totalPrice() {
      return self.unitPrice * self.quantity;
    },
    get discount() {
      return (self.recommendedRetailPrice - self.unitPrice) * self.quantity;
    },
  }))
  .views(self => ({
    get unitPriceFormatted() {
      return AsAuCurrency(self.unitPrice);
    },

    get recommendedRetailPriceFormatted() {
      return AsAuCurrency(self.recommendedRetailPrice);
    },

    get totalPriceFormatted() {
      return AsAuCurrency(self.totalPrice);
    },

    get discountFormatted() {
      return self.discount > 0 ? AsAuCurrency(self.discount) : '';
    },
  }))
  .actions(self => ({
    setUnitPrice: (value?: number) => { self.unitPrice = value ?? 0 },
  }));

export interface IQuoteFittedExtraModel extends Instance<typeof QuoteFittedExtraModel> { } 
