import { cast, flow, types } from 'mobx-state-tree';
import { getAjax } from '../RootStoreModel';
import { UserDtoModel } from 'api/models/Domain/Queries/User/GetUsersQuery/UserDtoModel';

type IUserDto = Domain.Queries.User.GetUsersQuery.IUserDto;

export const UsersRepo = types
  .model('UsersRepo', {
    users: types.array(UserDtoModel),
    isLoading: types.maybe(types.boolean),
  })
  .extend(self => {
    function* loadUsers() {
      self.isLoading = true;
      const dtos: IUserDto[] = yield getAjax(self).get(`/api/users`).json();
      self.users = cast(dtos);
      self.isLoading = false;
    }
    
    function clearUsers() {
      self.users = cast([]);
    }

    return {
      actions: {
        loadUsers: flow(loadUsers),
        clearUsers: clearUsers
      },
    };
  });