import React from 'react';
import { ConfirmationDialog } from 'views/components/forms/Dialog';
import { countryDescription } from '../../../api/enums/Country';
import { stateDescription } from '../../../api/enums/State';
import styles from './SyncLmsCustomerDialog.module.scss';
import { IQuoteCustomerDtoModel } from 'api/models/Domain/Dtos/QuoteCustomerDtoModel';
import { IContractCustomerDtoModel } from 'api/models/Domain/Dtos/ContractCustomerDtoModel';
import { ICustomerDtoModel } from 'api/models/Domain/Queries/Customer/CustomerDtoModel';


interface ITextProps {
  text?: string
}
const GreenText: React.FC<ITextProps> = ({ text }: ITextProps) => (<span className={ styles.greenText }>{ text }</span>)
const RedText: React.FC<ITextProps> = ({ text }: ITextProps) => (<span className={ styles.redText }>{ text }</span>)

type SyncState = {
    [key: string]: boolean
}

interface SyncLmsCustomerDialogProps {
    currentCustomer: (IQuoteCustomerDtoModel | IContractCustomerDtoModel);
    lmsCustomer: ICustomerDtoModel;
    syncState: SyncState | undefined;
    onOk: () => void;
    onCancel: () => void;
}

export const SyncLmsCustomerDialog = (({ currentCustomer, lmsCustomer, syncState, onOk, onCancel }: SyncLmsCustomerDialogProps) => {

    if (!currentCustomer || !lmsCustomer) return <></>;

  return (
    <ConfirmationDialog
      dialogTitle="Customer is out of sync with DP360"
      okLabel="Update from DP360"
      open={ true }
      onOk={ onOk }
      onCancel={ onCancel }
      dialogContent={
        <div>
          {syncState?.abnChanged &&
            <p>Current ABN is <RedText text={currentCustomer.abn ?? "Empty"} /> and DP360 ABN is <GreenText text={lmsCustomer?.abn  === "" ? "Empty" : lmsCustomer?.abn} />.</p>}
          {syncState?.streetAddressChanged &&
            <p>Current streetAddress is <RedText text={currentCustomer.address?.streetAddress ?? "Empty"} /> and DP360 address is <GreenText text={lmsCustomer?.address?.streetAddress === "" ? "Empty" : lmsCustomer?.address?.streetAddress} />.</p>}
          {syncState?.suburbChanged &&
            <p>Current suburb is <RedText text={currentCustomer.address?.suburb ?? "Empty"} /> and DP360 suburb is <GreenText text={lmsCustomer?.address?.suburb === "" ? "Empty" : lmsCustomer?.address?.suburb} />.</p>}
          {syncState?.cityChanged &&
            <p>Current city is <RedText text={currentCustomer.address?.city ?? "Empty"} /> and DP360 city is <GreenText text={lmsCustomer?.address?.city === "" ? "Empty" : lmsCustomer?.address?.city} />.</p>}
          {syncState?.stateChanged &&
            <p>Current state is <RedText text={stateDescription(currentCustomer.address?.state!) ?? "Empty"} /> and DP360 state is <GreenText text={stateDescription(lmsCustomer?.address?.state!)} />.</p>}
          {syncState?.countryChanged &&
            <p>Current country is <RedText text={countryDescription(currentCustomer.address?.country!) ?? "Empty"} /> and DP360 country is <GreenText text={countryDescription(lmsCustomer?.address?.country!)} />.</p>}
          {syncState?.postCodeChanged &&
            <p>Current postCode is <RedText text={currentCustomer.address?.postCode ?? "Empty"} /> and DP360 postCode is <GreenText text={lmsCustomer?.address?.postCode === "" ? "Empty" :lmsCustomer?.address?.postCode} />.</p>}
          {syncState?.companyNameChanged &&
            <p>Current company name is <RedText text={currentCustomer.companyName ?? "Empty"} /> and DP360 company name is <GreenText text={lmsCustomer?.companyName === "" ? "Empty" : lmsCustomer?.companyName} />.</p>}
          {syncState?.emailChanged &&
            <p>Current email is <RedText text={currentCustomer.email ?? "Empty"} /> and DP360 email is <GreenText text={lmsCustomer?.email === "" ? "Empty" : lmsCustomer?.email } />.</p>}
          {syncState?.firstNameChanged &&
            <p>Current firstName is <RedText text={currentCustomer.firstName ?? "Empty"} /> and DP360 firstName is <GreenText text={lmsCustomer?.firstName} />.</p>}
          {syncState?.phoneChanged &&
            <p>Current phone number is <RedText text={currentCustomer.phone ?? "Empty"} /> and DP360 phone number is <GreenText text={lmsCustomer?.phone === "" ? "Empty" : lmsCustomer?.phone} />.</p>}
          {syncState?.phoneAlternateChanged &&
            <p> Current alternate phone number is <RedText text={currentCustomer.phoneAlternate ?? "Empty"} /> and DP360 alternate phone number is <GreenText text={lmsCustomer?.phoneAlternate === "" ? "Empty" : lmsCustomer?.phoneAlternate} />.</p>}
          {syncState?.surNameChanged &&
            <p>Current surName is <RedText text={currentCustomer.surname === "" ? "Empty" : currentCustomer.surname} /> and DP360 surName is <GreenText text={lmsCustomer?.surname === "" ? "Empty" : lmsCustomer?.surname} />.</p>}
        </div>
      }
    />
  );
})
