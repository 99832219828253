import Alert from '@material-ui/lab/Alert';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'views/hooks';

export const ReadOnlyWarning: React.FC = observer(function ReadOnlyWarning() {
  const { editContractModal } = useStore();
  const contract = editContractModal.contract;
  const renderWarning = [
    ContractStatusCode.Delivered,
    ContractStatusCode.Cancelled,
    ContractStatusCode.OldVersion,
  ].includes(contract.statusCode);

  return editContractModal.isReadOnly && renderWarning ? (
    <Alert severity="warning">
      {contract.statusCode === ContractStatusCode.Delivered ? (
        <>
          This contract was delivered on{' '}
          {DateTime.fromISO(contract.deliveryDate ?? '')?.toLocaleString(
            DateTime.DATE_MED
          )}
          . No changes can be made.
        </>
      ) : contract.statusCode === ContractStatusCode.Cancelled ? (
        <>No changes can be made to this contract as it has been cancelled.</>
      ) : contract.statusCode === ContractStatusCode.OldVersion ? (
        <>No changes can be made to old versions.</>
      ) : (
        <></>
      )}
    </Alert>
  ) : (
    <></>
  );
});
