import { types, Instance } from 'mobx-state-tree';

export const QuoteTradeInDtoModel = types.model('QuoteTradeInDtoModel', {
  description: types.string,
  isEmpty: types.boolean,
  kms: types.maybe(types.integer),
  make: types.maybe(types.string),
  model: types.maybe(types.string),
  price: types.maybe(types.number),
  registration: types.maybe(types.string),
  year: types.maybe(types.string),
});

export interface IQuoteTradeInDtoModel extends Instance<typeof QuoteTradeInDtoModel> {}
