import { INotification } from '../singletons/NotificationsModel';

// -- Add Events here ------------------------------
type BusEvent = IAddNotificationEvent | IQuoteApprovedEvent | IQuoteRejectedEvent; // | IAnotherEvent | IAndAnotherEvent (etc...)

export interface IAddNotificationEvent {
  type: 'ADD_NOTIFICATION';
  message: string;
  level: INotification['level'];
}

export interface IQuoteApprovedEvent {
  type: 'QUOTE_APPROVED';
  quoteId: string;
  updatedBy: string;
  note?: string;
}

export interface IQuoteRejectedEvent {
  type: 'QUOTE_REJECTED';
  quoteId: string;
  updatedBy: string;
  note?: string;
}

// -- Bus Implementation ---------------------------

type BusEventType = GetTypes<BusEvent>;

export class Bus {
  private _handlers: HandlerStoreBuilder<BusEventType> = {};

  async publish<TType extends BusEventType>(
    type: TType,
    event: Omit<RefineEventByType<BusEvent, TType>, 'type'>
  ) {
    const handlers: HandlerBase[] | undefined = this._handlers[type];
    if (handlers) {
      for (const handler of handlers) {
        const eventBody: any = { type, ...event };
        await handler(eventBody);
      }
    }
  }

  registerHandler<TType extends BusEventType>(type: TType, handler: Handler<TType>) {
    if (!this._handlers[type]) {
      this._handlers[type] = [];
    }
    this._handlers[type]?.push(handler as HandlerBase);
  }
}

type GetTypes<T> = T extends { type: infer X } ? X : never;

type HandlerBase = (event: BusEvent) => void | Promise<void>;

type HandlerStoreBuilder<TType extends keyof any> = {
  [P in TType]?: HandlerBase[];
};

type Handler<TType extends BusEventType> = (
  event: RefineEventByType<BusEvent, TType>
) => void | Promise<void>;

type RefineEventByType<TEvent, TType extends BusEventType> = TEvent extends { type: TType }
  ? TEvent
  : never;
