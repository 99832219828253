import { QuoteVersionDtoModel } from 'api/models/Domain/Queries/Quote/GetQuoteVersionsQuery/QuoteVersionDtoModel';
import { localDateTimeType } from 'domain/store/types/LocalDateTimeType';
import {Instance, types} from 'mobx-state-tree';

export const QuoteVersionModel = types.model('QuoteVersionModel', {
  ...QuoteVersionDtoModel.properties,
  lastModifiedAt: localDateTimeType,
});

export interface IQuoteVersionModel extends Instance<typeof QuoteVersionModel> {}
