import {
  ContractVehicleDtoModel,
  IContractVehicleDtoModel,
} from 'api/models/Domain/Dtos/ContractVehicleDtoModel';
import { VehicleCondition } from 'api/enums/VehicleCondition';
import { cast, SnapshotIn } from 'mobx-state-tree';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { IRules, isValid, requiredString, validator } from 'infrastructure/validation';

const rules: IRules<IContractVehicleDtoModel> = {
  vehicleType: (val, form) => {
    if (form.assetNo) return;
    return requiredString(val);
  },
};

export const ContractVehicleModel = ContractVehicleDtoModel.named('ContractVehicleModel')
  .views(self => ({
    get isVibeVehicle() {
      return !!self.assetNo;
    },
    get priceFormatted() {
      return AsAuCurrency(self.price);
    },
    get validator() {
      return validator(rules)(self);
    },
    get isValid() {
      return isValid(self, rules);
    },
  }))
  .actions(self => ({
    set<K extends keyof SnapshotIn<typeof self>, T extends SnapshotIn<typeof self>>(
      key: K,
      value: T[K]
    ) {
      self[key] = cast(value);
    },
  }));

export const emptyContractVehicle: IContractVehicleDtoModel = {
  assetNo: '',
  bodyColour: '',
  buildDate: '',
  compliancePlateDate: '',
  condition: VehicleCondition.New,
  engineNumber: '',
  engineSize: '',
  estDeliveryDate: '',
  floorPlan: '',
  make: '',
  model: '',
  makeAndModel: '',
  odometer: 0,
  price: 0,
  rrp: 0,
  registration: '',
  registrationExpiryDate: '',
  transmission: '',
  trimColour: '',
  vehicleType: '',
  vinNumber: '',
  year: '',
  discount: 0,
  description: '',
  disposalOrderNo:''
};
