import React, { useState, useEffect } from 'react';
import { useStore } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import { FormHelperText } from '@material-ui/core';
import { RadioField, SelectField, TextField } from 'views/components/forms';
import { FormSection } from 'views/components/forms/FormSection';
import { ContractStatusCode } from 'api/enums/ContractStatusCode';
import { SyncLmsCustomerDialog } from '../../../components/dialogs/SyncLmsCustomerDialog';
import { allCountry, Country } from 'api/enums/Country';
import { allState, IStateDescriptor } from 'api/enums/State';
import styles from './CustomerSection.module.scss';

export const CustomerSection: React.FC = observer(function CustomerSection() {
  const [stateOptions, setStateOptions] = useState<IStateDescriptor[]>([]);
  const { editContractModal, security } = useStore();

  const contract = editContractModal.temporaryContract;
  let customer = contract.customer;
  let leadId = contract.leadId ?? "";

  const sectionTitle = `Customer${ contract ? ': ' + customer.fullName + ' ' : '' } ${ leadId && '(LeadId: ' + leadId +')' }`;

  useEffect(() => {
    setStateOptions(customer?.address?.country === Country.AU ? allState.slice(0,8) : allState.slice(8));
  }, [customer]);

  const saveCustomer = async () => {
    await editContractModal.updateCustomer();
  };

  return (
    <FormSection
      sectionTitle={sectionTitle}
      isFormActionAllowed={editContractModal.isEditable&& !security.currentUser.isAftermarketPerson}
      expandedDefault={editContractModal.isSectionToModify('customer')}
      editableDefault={false}
      onSubmit={saveCustomer}
      onValidate={() => customer.isValid}
      hasErrors={!customer.isValid}
      canSave={true}
      canCancel={true}
      onCancel={editContractModal.cancelEditingContract}
      shouldModifyVersion={contract.isFinalised}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'customer')}>
      {({ focusFieldRef, editable }) => (
        <>
         {editContractModal.isCustomerOutOfSync && contract.statusCode === ContractStatusCode.Draft && (
          <SyncLmsCustomerDialog
            currentCustomer = { customer }
            lmsCustomer = { editContractModal.lmsCustomer! }
            syncState = { customer.getSyncState(editContractModal.lmsCustomer!) }
            onOk = { () => {
              editContractModal.setCustomer(customer.getSyncedDetails(editContractModal.lmsCustomer));
              editContractModal.updateCustomer();
              editContractModal.confirmSyncCustomerDialog();}}
            onCancel = { editContractModal.confirmSyncCustomerDialog }
          />)}
          <div className={styles.fields}>
            <TextField
              id="contract-customer-first-name"
              inputRef={focusFieldRef}
              name="first-name"
              label="First Name 1"
              required={true}
              readonly={!editable}
              value={customer.firstName}
              validationError={customer.validator('firstName')}
              onChange={event => customer.set('firstName', event.target.value)}
            />
            <TextField
              id="contract-customer-surname"
              inputRef={focusFieldRef}
              name="surname"
              label="Surname 1"
              required={true}
              readonly={!editable}
              value={customer.surname ?? ''}
              validationError={customer.validator('surname')}
              onChange={event => customer.set('surname', event.target.value)}
            />
            <TextField
              id="contract-customer-dob"
              name="dob"
              label="Date of Birth 1"
              type="date"
              shrink={true}
              readonly={!editable}
              value={contract.customer.dob ?? ''}
              required={true}
              validationError={customer.validator('dob')}
              onChange={event => customer.set('dob', event.target.value)}
            />
            <TextField
              id="contract-customer-drivers-license"
              name="driversLicense"
              label="Drivers License 1"
              readonly={!editable}
              value={customer.driversLicense ?? ''}
              required={true}
              validationError={customer.validator('driversLicense')}
              onChange={event => customer.set('driversLicense', event.target.value)}
            />
            </div>
            <div className={styles.fields}>
            <TextField
              id="contract-secondary-customer-name"
              name="secondary-first-name"
              label="First Name 2"
              readonly={!editable}
              value={customer.secondaryFirstName ?? ''}
              onChange={event => customer.set('secondaryFirstName', event.target.value)}
            />
            <TextField
              id="contract-secondary-customer-surname"
              name="secondary-surname"
              label="Surname 2"
              readonly={!editable}
              value={customer.secondarySurname ?? ''}
              onChange={event => customer.set('secondarySurname', event.target.value)}
            />
            <TextField
              id="contract-secondary-customer-dob"
              name="secondaryDob"
              label="Date of Birth 2"
              type="date"
              shrink={true}
              readonly={!editable}
              value={customer.secondaryDob ?? ''}
              onChange={event => customer.set('secondaryDob', event.target.value)}
            />
            <TextField
              id="contract-secondary-customer-drivers-license"
              name="secondaryDriversLicense"
              label="Drivers License 2"
              readonly={!editable}
              value={customer.secondaryDriversLicense ?? ''}
              onChange={event => customer.set('secondaryDriversLicense', event.target.value)}
            />
            </div>
            <div className={styles.fields}>
              <TextField
                id="contract-customer-street-address"
                name="streetAddress"
                label="Street Address"
                required={true}
                readonly={!editable}
                value={customer.address?.streetAddress ?? ''}
                onChange={event => customer.set('address', {...customer.address, streetAddress: event.target.value})}
              />
              <TextField
                id="contract-customer-suburb"
                name="suburb"
                label="Suburb"
                required={true}
                readonly={!editable}
                value={customer.address?.suburb ?? ''}
                onChange={event => customer.set('address', {...customer.address, suburb: event.target.value})}
              />
               <SelectField
                name="country"
                label="Country"
                required={true}
                options={allCountry.map(x => ({ label: x.description, value: x.value.toString() }))}
                ignoreDefaultOption={true}
                readonly={!editable}
                value={customer.address?.country?.toString() ?? ''}
                onChange={event => {
                  customer.set('address', {...customer.address, country: allCountry.find(s => s.value.toString() === event.target.value)!.value});
                  customer.set('address', {...customer.address, state:undefined });
                  setStateOptions(customer?.address?.country === Country.AU? allState.slice(0,8):allState.slice(8));
                }}
              />
              <SelectField
                name="state"
                label={ customer.address?.country === Country.AU ? "State":"Province" }
                required={true}
                options={stateOptions.map(x => ({ label: x.description, value: x.value.toString() }))}
                ignoreDefaultOption={true}
                readonly={!editable}
                value={customer.address?.state?.toString() ?? ''}
                onChange={event =>
                  customer.set('address', {...customer.address, state: allState.find(s => s.value.toString() === event.target.value)!.value})
                }
              />
              <TextField
                id="contract-customer-city"
                name="city"
                label="City"
                required={true}
                readonly={!editable}
                value={customer.address?.city ?? ''}
                onChange={event => customer.set('address', {...customer.address, city: event.target.value})}
              />
              <TextField
                id="contract-customer-postcode"
                name="postcode"
                label="Postcode"
                required={true}
                readonly={!editable}
                value={customer.address?.postCode ?? ''}
                onChange={event => customer.set('address', {...customer.address, postCode: event.target.value})}
              />
              {customer.validator('address') &&
              <FormHelperText error={true}>
                {customer.validator('address')}
              </FormHelperText>}
            </div>
            <div className={styles.fields}>
            <TextField
              id="contract-customer-phone"
              name="phone"
              label="Phone"
              required={true}
              readonly={!editable}
              value={customer.phone ?? ''}
              validationError={customer.validator('phone')}
              onChange={event => customer.set('phone', event.target.value)}
            />
            <TextField
              id="contract-customer-phone-alternate"
              name="phoneAlternate"
              label="Alternate Phone"
              readonly={!editable}
              value={customer.phoneAlternate ?? ''}
              onChange={event => customer.set('phoneAlternate', event.target.value)}
            />
            <TextField
              id="contract-customer-email"
              name="email"
              label="Email"
              readonly={!editable}
              value={customer.email ?? ''}
              onChange={event => customer.set('email', event.target.value)}
            />
            <TextField
              id="contract-customer-company-name"
              name="companyName"
              label="Company Name"
              readonly={!editable}
              value={customer.companyName ?? ''}
              onChange={event => customer.set('companyName', event.target.value)}
            />
            <TextField
              id="contract-customer-abn"
              name="abn"
              label="ABN"
              readonly={!editable}
              value={customer.abn ?? ''}
              onChange={event => customer.set('abn', event.target.value)}
            />
          </div>
          <div className={`${styles.fields} ${styles.requests}`}>
            <RadioField
              id="contract-customer-quote-request"
              name="insurance-quote-request"
              label="Insurance Quote Request"
              readonly={!editable}
              direction="row"
              required={true}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={customer.insuranceQuoteRequest?.toString()}
              validationError={customer.validator('insuranceQuoteRequest')}
              onChange={event => customer.set('insuranceQuoteRequest', event.target.value === 'true')}
            />
            <RadioField
              id="contract-customer-finance-request"
              name="finance-quote-request"
              label="Finance Quote Request"
              readonly={!editable}
              direction="row"
              required={true}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={customer.financeQuoteRequest?.toString()}
              validationError={customer.validator('financeQuoteRequest')}
              onChange={event => customer.set('financeQuoteRequest', event.target.value === 'true')}
            />
          </div>
        </>
      )}
    </FormSection>
  );
});
