import { types, Instance } from 'mobx-state-tree';
import { SeriesModel as DomainQueriesVehicleAdvanceSearchVehicleOptionsQuerySeriesModel } from './SeriesModel'

export const ModelModel = types.model('ModelModel', {
  code: types.string,
  name: types.string,
  series: types.array(DomainQueriesVehicleAdvanceSearchVehicleOptionsQuerySeriesModel),
});

export interface IModelModel extends Instance<typeof ModelModel> {}
