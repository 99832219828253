import { allLocation } from 'api/enums/Location';
import { allOrderType, OrderType } from 'api/enums/OrderType';
import { Permission } from 'api/enums/Permission';
import { observer } from 'mobx-react-lite';
import React, { useEffect,useState} from 'react';
import { useHistory } from 'react-router-dom';
import { RadioField, SelectField, TextField } from 'views/components/forms';
import { FormSection } from 'views/components/forms/FormSection';
import { useStore } from 'views/hooks';
import styles from './DetailSection.module.scss';
import {mapUsersToLocation} from  '../../../../infrastructure/mapLocationUsers';
import { AsFormattedDate} from 'infrastructure/formatUtils';
export const DetailSection: React.FC = observer(function DetailSection() {
  const { editOrderModel, security,usersRepo } = useStore();
  const order = editOrderModel.temporaryOrder;
  const users = mapUsersToLocation(usersRepo.users);
  const [usersOfLocation,setUsersOfLocation] = useState(users.get(order.detail.location));
 
  const history = useHistory();
  const getLastDayOfMonth = (yearmonth:string) => {
       let date = new Date(yearmonth);
       date.setMonth(date.getMonth()+1);
       date.setDate(date.getDate()-1);
       return date.toJSON().slice(0,10);       
  }
  
  useEffect(() => {
    (async () => {
       await usersRepo.loadUsers();
       setUsersOfLocation(mapUsersToLocation(usersRepo.users).get(order.detail.location))
    })();
    if (!editOrderModel.isNewOrder) return;
    order.detail.setDefaults(security);

  }, [order, editOrderModel, security,usersRepo]);

  const save = async () => {
    order.detail.setAttemptedSubmitted();
    if (!order.detail.isValid) return;
    if (editOrderModel.isNewOrder) {
      const id = await editOrderModel.addOrder();
      if(id) history.replace(`/orders/${id}`);}
      else{editOrderModel.updateDetail();}
  };

  const cancel = () => {
    editOrderModel.cancelEditingOrder();
  };

  const canEditOrderType =
    (security.currentUser.can(Permission.InitiateCustomerOrder) &&
      security.currentUser.can(Permission.InitiateStockOrder)) ||
    // Only allow orders to be switched from customer orders to stock after v1
    (!order.isFirstVersion && order.detail.orderType === OrderType.Customer) ||
    // If the Contracts flow is not enabled allow dealers to create customer orders regardless
    !security.currentUser.contractFeatureEnabled;

  const showManufacturingInfo =
    order.modificationCutoffDate ||
    order.plannedCompletionDate ||
    order.externalStatus ||
    order.externalOrderId;

  const locations = (security.currentUser.can(Permission.AssignOrder)
    ? allLocation
    : security.locations
  )
    .slice(0)
    .sort((a, b) => a.description.localeCompare(b.description));

  return (
    <FormSection
      sectionTitle={`Order Detail`}
      isFormActionAllowed={editOrderModel.isEditable}
      expandedDefault={editOrderModel.isNewOrder}
      editableDefault={editOrderModel.isNewOrder}
      onSubmit={save}
      onValidate={() => {
        order.detail.setAttemptedSubmitted();
        return order.detail.isValid;
      }}
      hasErrors={!order.detail.isValid}
      canSave={true}
      canCancel={true}
      onCancel={cancel}>

      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            {editable ? (
              <SelectField
                name="dealership"
                label="Dealership"
                ignoreDefaultOption = {true}
                options={locations.map(x => ({
                  label: x.description,
                  value: x.name,
                }))}
                readonly={locations.length <= 1}
                value={order.detail.location}
                onChange={event => {
                  order.detail.set(
                    'location', 
                    locations.find(l => l.name === event.target.value)!.name
                  );
                  order.detail.set(
                    'dealerContact', 
                    ""
                  )  
                  setUsersOfLocation(users.get(order.detail.location))
                }

                }
                validationError={order.detail.validator('location')}
                required={true}
              />
            ) : (
              <TextField
                name="dealership"
                label="Dealership"
                readonly={true}
                value={allLocation.find(l => l.name === order.detail.location)?.description}
              />
            )}
            {editable? (
              <SelectField
                name="dealerContact"
                label="Dealer Contact"
                ignoreDefaultOption = {true}              
                options={usersOfLocation?.map(x => ({
                  label: x.name,
                  value: x.name,
                })) || []}
                value={order.detail.dealerContact}
                onChange={event =>
                  order.detail.set(
                    'dealerContact', 
                    usersRepo.users.find(u => u.name === event.target.value)!.name
                  )}
                validationError={order.detail.validator('dealerContact')}
                required={true}
              />
            ) : (
              <TextField
                name="dealerContact"
                label="Dealer Contact"
                readonly={true}
                value={order.detail.dealerContact}
              />
            )}
            <TextField
              name="requestedDeliveryDate"
              label="Requested Production Month"
              type="month"
              shrink={true}
              required={true}
              readonly={!editable}
              value={order.detail.requestedDeliveryDate?.slice(0,7) ?? ''}
              onChange={event => order.detail.set('requestedDeliveryDate', getLastDayOfMonth(event.target.value) )}
              validationError={order.detail.validator('requestedDeliveryDate')}
            />
          </div>
          <div className={`${styles.fields} ${styles.ordertype}`}>
            <RadioField
              name="orderType"
              label="Order Type"
              readonly={!editable || !canEditOrderType}
              direction="row"
              required={true}
              options={allOrderType.map(t => ({ label: t.description, value: t.value.toString() }))}
              value={order.detail.orderType?.toString() ?? ''}
              validationError={order.detail.validator('orderType')}
              onChange={event =>
                order.detail.set(
                  'orderType',
                  allOrderType.find(s => s.value.toString() === event.target.value)!.value
                )
              }
            />
          </div>
          <div className={`${styles.fields}`}>
            <TextField
              name="dealerReference"
              label="Contract No / Dealer Ref"
              readonly={!editable}
              value={order.detail.dealerReference ?? ''}
              onChange={event => order.detail.set('dealerReference', event.target.value)}
              required={true}
              validationError={order.detail.validator('dealerReference')}
            />
            {order.detail.orderType === OrderType.Customer && (
              <TextField
                name="customerName"
                label="Customer Name"
                required={order.detail.orderType === OrderType.Customer}
                readonly={!editable}
                value={order.detail.customerName ?? ''}
                onChange={event => order.detail.set('customerName', event.target.value)}
                validationError={order.detail.validator('customerName')}
              />
            )}
            {order.detail.orderType === OrderType.Customer && (
              <TextField
              name="soldDate"
              label="Sold Date"
              type="date"
              shrink={true}
              required={order.detail.orderType === OrderType.Customer}
              readonly={!editable}
              value={order.detail.soldDate}
              onChange={event => order.detail.set('soldDate', event.target.value)}
              validationError={order.detail.validator('soldDate')}
            /> 
            )}
          </div>
          {showManufacturingInfo && (
            <div className={styles.fields}>
              <TextField
                name="externalOrderReference"
                label="External Order Reference"
                readonly={true}
                value={order.externalOrderId}
              />
              <TextField
                name="externalStatus"
                label="External Status"
                readonly={true}
                value={order.externalStatus}
              />
              <TextField
                name="modificationCutoffDate"
                label="Cutoff Date"
                type="text"
                readonly={true}
                value={AsFormattedDate(order.modificationCutoffDate)}
              />
              <TextField
                name="plannedCompletionDate"
                label="Planned Completion Date"
                type="text"
                readonly={true}
                value={AsFormattedDate(order.plannedCompletionDate)}
              />
            </div>
          )}
        </>
      )}
    </FormSection>
  );
});
