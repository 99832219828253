import { VehicleCondition } from 'api/enums/VehicleCondition';

export type Form = {
  key: string;
  condition: Array<VehicleCondition>;
  description: string;
  dataInjection?: boolean;
};

export const govFormMap: {
  [state: string]: Array<Form>;
} = {
  Qld: [
    {
      key: 'qld-form12',
      condition: [VehicleCondition.Used],
      description: 'Form 12 - Cooling-off Period and Statutory Warranty',
      dataInjection: true,
    },
    {
      key: 'qld-registration-application',
      condition: [VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Vehicle Registration Application',
      dataInjection: true,
    },
    {
      key: 'qld-used-transfer-of-registration',
      condition: [VehicleCondition.Used],
      description: 'Vehicle Registration Transfer Application',
      dataInjection: true,
    },
    {
      key: 'qld-form-3123',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Form 3123 - Pig Trailer Inspection Form',
      dataInjection: false,
    },
    {
      key: 'qld-form-f3529',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Form F3529 - Vehicle Details Inspection Sheet',
      dataInjection: false,
    },
    {
      key: 'qld-form-f4550',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Form F4550 - Motorhome/Campervan Inspection Form - over 4.5t',
      dataInjection: false,
    },
  ],
  Nsw: [
    {
      key: 'nsw-vehicle-registration',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Vehicle Registration',
      dataInjection: true,
    },
    {
      key: 'nsw-form-5',
      condition: [VehicleCondition.Used],
      description: 'Form 5 - Motor Dealer\'s Notice for Motor Vehicles',
      dataInjection: true,
    },
    {
      key: 'nsw-form-9',
      condition: [VehicleCondition.Used],
      description: 'Form 9 - Motor Dealer\'s Notice for Trailers',
      dataInjection: true,
    },
    {
      key: 'nsw-form-12',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Form 12 - Notice for Dealer Finance Cooling Off Period',
      dataInjection: true,
    }
  ],
  Vic: [
    {
      key: 'vic-vehicle-registration',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Vehicle Registration',
      dataInjection: true,
    },
    {
      key: 'vic-light-trailer',
      condition: [VehicleCondition.Used, VehicleCondition.New, VehicleCondition.NewBuild],
      description: 'Light Trailer Registration',
      dataInjection: true,
    },
    {
      key: 'vic-form-4',
      condition: [VehicleCondition.Used],
      description: 'Form 4 - Used Motor Car Price and Data Sheet',
      dataInjection: true,
    },
  ],
  Sa: [
    {
      key: 'sa-mr1',
      condition: [VehicleCondition.NewBuild, VehicleCondition.Used],
      description: 'MR1 - Application for Registration and CTP Insurance',
      dataInjection: true,
    }
  ],
};

export const nationalInternalFormMap: Array<{ key: string; description: string }> = [
  { key: 'manufacturers-warranty', description: 'Apollo Manufacturers Warranty' },
  { key: 'caravan-weights', description: 'Caravan Weights Disclaimer' },
  { key: 'interstate-registration', description: 'Interstate Registration' },
];
