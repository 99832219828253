import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import { getStateDescriptor } from 'api/enums/State';
import {
  getVehicleConditionDescriptor, VehicleCondition,
  vehicleConditionDescription
} from 'api/enums/VehicleCondition';
import { Form, govFormMap, nationalInternalFormMap } from 'domain/store/types/FormMappings';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { BasicSubmitCancelDialog } from 'views/components/forms/Dialog';
import { useStore } from 'views/hooks';
import styles from './ContractDownloadDialog.module.scss';

interface IContractDownloadDialogProps {
  onClose(): void;
}

export const ContractDownloadDialog: React.FC<IContractDownloadDialogProps> = props => {
  const { editContractModal } = useStore();
  const [contractSelected, setContractSelected] = useState(false);
  const [termsSelected, setTermsSelected] = useState(false);
  const [govFormsSelected, setGovFormsSelected] = useState([] as Form[]);
  const [internalFormsSelected, setInternalFormsSelected] = useState([] as string[]);
  const state = getStateDescriptor(editContractModal.contract.licensee.stateOfSale);

  const downloadPdfs = async () => {
    await Promise.all(
      [
        ...(contractSelected ? [() => downloadContractPdf()] : []),
        ...govFormsSelected.filter(f => f.dataInjection).map(f => () => downloadFillablePdf(f.key)),
        ...govFormsSelected
          .filter(f => !f.dataInjection)
          .map(f => () => downloadStaticPdf(f.key)),
        ...internalFormsSelected.map(k => () => downloadStaticPdf(k)),
        ...(termsSelected ? [() => downloadStaticPdf(termsKey())] : []),
      ].map(d => d())
    );

    props.onClose();
  };

  const termsKey = () => {
    const condition = getVehicleConditionDescriptor(editContractModal.contract.vehicle.condition);
    return `${state.name.toLowerCase()}-${condition.value === VehicleCondition.Used ? 'used' : 'new'}-terms`;
  };

  const downloadContractPdf = async (): Promise<void> => {
    const pdf = await editContractModal.downloadContractPdf();
    saveAs(pdf, `${editContractModal.contract.numberAndVersion}.pdf`);
  };

  const downloadFillablePdf = async (key: string): Promise<void> => {
    const pdf = await editContractModal.downloadFillablePdf(key);
    saveAs(pdf, `${key}.pdf`);
  };

  const downloadStaticPdf = async (key: string): Promise<void> => {
    const pdf = await editContractModal.downloadStaticPdf(key);
    saveAs(pdf, `${key}.pdf`);
  };

  const toggleGovFormSelection = (form: Form) => {
    if (govFormsSelected.includes(form))
      setGovFormsSelected([...govFormsSelected.filter(f => f.key !== form.key)]);
    else setGovFormsSelected([...govFormsSelected, form]);
  };

  const toggleInternalFormSelection = (key: string) => {
    if (internalFormsSelected.includes(key))
      setInternalFormsSelected([...internalFormsSelected.filter(k => k !== key)]);
    else setInternalFormsSelected([...internalFormsSelected, key]);
  };

  return (
    <BasicSubmitCancelDialog
      title="Download"
      formik={{
        initialValues: {},
        onSubmit: downloadPdfs,
      }}
      onClose={() => props.onClose()}
      content={
        <div className={styles.fields}>
          <FormControl component="fieldset" className={styles.fieldset}>
            <FormLabel component="legend">Contract</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contractSelected}
                    onChange={e => setContractSelected(!contractSelected)}
                  />
                }
                label={editContractModal.contract.numberAndVersion}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsSelected}
                    onChange={e => setTermsSelected(!termsSelected)}
                  />
                }
                label="Terms & Conditions"
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={styles.fieldset}>
            <FormLabel component="legend">Government Forms ({state.description}):</FormLabel>
            {govFormMap[state.name].filter(
              p => p.condition.includes(editContractModal.contract.vehicle.condition)
            ).length === 0 && (
              <p>
                <i>
                  Not applicable for{' '}
                  {vehicleConditionDescription(editContractModal.contract.vehicle.condition)}{' '}
                  vehicles
                </i>
              </p>
            )}
            <FormGroup>
              {govFormMap[state.name]
                .filter(f => f.condition.includes(editContractModal.contract.vehicle.condition))
                .sort((a, b) => a.description.localeCompare(b.description))
                .map(f => (
                  <FormControlLabel
                    key={f.key}
                    control={
                      <Checkbox
                        checked={govFormsSelected.includes(f)}
                        onChange={e => toggleGovFormSelection(f)}
                      />
                    }
                    label={f.description}
                  />
                ))}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={styles.fieldset}>
            <FormLabel component="legend">Internal Forms:</FormLabel>
            <FormGroup>
              {nationalInternalFormMap.map(p => (
                <FormControlLabel
                  key={p.key}
                  control={
                    <Checkbox
                      checked={internalFormsSelected.includes(p.key)}
                      onChange={e => toggleInternalFormSelection(p.key)}
                    />
                  }
                  label={p.description}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      }
    />
  );
};
