import { IQuoteTradeInDtoModel, QuoteTradeInDtoModel } from "api/models/Domain/Dtos/QuoteTradeInDtoModel";
import { AsAuCurrency } from "infrastructure/formatUtils";

export const QuoteTradeInModel = QuoteTradeInDtoModel.named('QuoteTradeInModel')
  .views(self => ({
    get priceFormatted() {
      return AsAuCurrency(self.price);
    },
    get yearValidationError(): string | undefined {
      return !self.year ? 'Required' : !self.year || /^[0-9]{4}$/.test(self.year)
        ? undefined
        : 'Year must be four digits';
    },
    get registrationValidationError(): string | undefined {
      return !self.registration || self.registration.length <= 10
        ? undefined
        : 'Registration is too long';
    },
    get kmsValidationError(): string | undefined {
      return !self.kms || self.kms > 0
        ? undefined
        : 'Kilometres must be a positive number';
    },
    get isRequired() {
     return  !!(self.make || self.model || self.year ||
             self.registration || self.kms || self.price);
    },
  }))
  .actions(self => ({
    updateMake: (make: string) => { self.make = make },
    updateModel: (model: string) => { self.model = model },
    updateYear: (year: string) => { self.year = year },
    updateRegistration: (registration: string) => { self.registration = registration },
    updatePrice: (price?: number) => { self.price = price },
    updateKms: (kms?: number) => { self.kms = kms },
  }));

export const emptyQuoteTradeIn: IQuoteTradeInDtoModel = {
  make: '',
  model: '',
  year: '',
  registration: '',
  kms: undefined,
  description: '',
  isEmpty: true,
  price: undefined
};
