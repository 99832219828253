import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import { QuoteApprovalStatus } from 'api/enums/QuoteApprovalStatus';
import { useQuoteApprovalStore } from 'hooks/useQuoteApprovalStore';
import { AsAuCurrency } from 'infrastructure/formatUtils';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ButtonWithProgress, SelectField } from 'views/components/forms';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { useStore } from 'views/hooks';
import styles from './QuoteApproval.module.scss';

export const QuoteApproval: React.FC = observer(() => {
  const quoteApprovalStore = useQuoteApprovalStore();
  const { security } = useStore();
  const quote = quoteApprovalStore.quote;
  const [note, setNote] = useState('');
  const [approveInProcess, setApproveInProcess] = useState(false);
  const [rejectInProcess, setRejectInProcess] = useState(false);
  const [escalateInProcess, setEscalateInProcess] = useState(false);
  const [quoteSummaryExpanded, setQuoteSummaryExpanded] = React.useState<boolean>(false);
  const [upperManagerId, setUpperManagerId] = useState('');

  const currentUser = security.currentUser;
  const upperManagers = currentUser.managers.map(m => ({
    value: m.id,
    label: m.name,
  }));
  const canBeApprovedByCurrentUser = quote.canBeApprovedBy(currentUser);
  const requestWasEscalated =
    quote.latestApproval && quote.latestApproval.assignedTo.roleLevel > currentUser.roleLevel;

  const IconComponent = quoteSummaryExpanded ? CloseIcon : ExpandMoreIcon;
  const expandIcon = <IconComponent className={styles.expand} />;

  const containsNonVibeExtras = quote.totalNonVibeExtrasPrice > 0;

  const approveRequest = async () => {
    setApproveInProcess(true);
    try {
      await quoteApprovalStore.approve(quote.id, quote.latestApproval!.id, note);
    } finally {
      setApproveInProcess(false);
    }
  }

  const rejectRequest = async () => {
    setRejectInProcess(true);
    await quoteApprovalStore.reject(quote.id, quote.latestApproval!.id, note);
    setRejectInProcess(false);
  }

  const escalateRequest = async () => {
    if (!upperManagerId) return;
    setEscalateInProcess(true);
    try {
      await quoteApprovalStore.escalate(quote.id, quote.latestApproval!.id, upperManagerId, note);
    } finally {
      setEscalateInProcess(false);
    }
  }

  const quoteSummarySection = (
    <Table>
      <TableHead className={styles.tablehead}>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Product Type</TableCell>
          <TableCell className={styles.description}>Description</TableCell>
          <TableCell>Sell Price (Inc GST)</TableCell>
          <TableCell>Discount</TableCell>
          <TableCell align="right" className={styles.qty}>
            QTY
          </TableCell>
          <TableCell align="right">Line Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {quote.orderLines.map(line => {
          return (
            <TableRow key={line.index} className={styles.row}>
              <TableCell>{line.index}</TableCell>
              <TableCell>{line.productType}</TableCell>
              <TableCell>{line.description}</TableCell>
              <TableCell>{line.priceFormatted}</TableCell>
              <TableCell>{line.discountFormatted}</TableCell>
              <TableCell align="right">{line.quantity}</TableCell>
              <TableCell align="right">{line.lineTotalFormatted}</TableCell>
            </TableRow>
          );
        })}
        {!!quote.vehicle.rebate &&
          <TableRow key={quote.orderLines.length + 1} className={styles.row}>
            <TableCell>{quote.orderLines.length + 1}</TableCell>
            <TableCell>Rebate</TableCell>
            <TableCell>{quote.vehicle.rebate.description}</TableCell>
            <TableCell>$0.00</TableCell>
            <TableCell>{quote.vehicle.rebateFormatted}</TableCell>
            <TableCell align="right">1</TableCell>
            <TableCell align="right">{quote.vehicle.rebateFormatted}</TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );

  const totalsSection = (
    <div className={styles.totals}>
      <ul>
        <li>
          <span>Subtotal{containsNonVibeExtras && '* '}:</span>
          <span>{quote.subTotalFormatted}</span>
        </li>
        {!!(quote && quote.vehicle.discount) && (
          <li>
            <span>Less Discount:</span>
            <span>{quote.vehicle.discountFormatted}</span>
          </li>
        )}
        {
          !!(quote && quote.vehicle.rebate) && (
            <li>
              <span>Less Rebate:</span>
              <span>{quote.vehicle.rebateFormatted}</span>
            </li>
          )
        }
        {quote && !quote.tradeIn.isEmpty && (
          <li>
            <span>Less Trade-in:</span>
            <span>{quote.tradeIn.priceFormatted}</span>
          </li>
        )}
        {
          quote.deposit && (
            <li>
              <span>Deposit Payable:</span>
              <span>{quote.deposit.amountFormatted}</span>
            </li>
          )
        }
        <li className={styles.priceSummary}>
          <span>Total Price (Inc GST){containsNonVibeExtras && '* '}:</span>
          <span>{quote.totalPriceFormatted}</span>
        </li>
        {
          quote.belowMinSell &&
          <>
            <li className={styles.priceSummary}>
              <span>Min Sell:</span>
              <span>{AsAuCurrency(quote.minSell)}</span>
            </li>
            <li className={styles.priceSummary}>
              <span>Difference:</span>
              <span>{AsAuCurrency(quote.belowMinSellAmount)}</span>
            </li>
          </>
        }
        {
          quote.belowRecommendedRetailPrice &&
          <li className={styles.priceSummary}>
            <span>RRP:</span>
            <span>{AsAuCurrency(quote.recommendedRetailPrice)}</span>
          </li>
        }
      </ul>
      {
        containsNonVibeExtras &&
        <Alert severity="warning" style={ { marginTop: '0.5rem' } }>
          This quote contains external vendor products
        </Alert>
      }
    </div>
  );

  const notesSection = (
    <div className={styles.notes}>
      <TextField
        className={styles.salesNotes}
        label="Sales Notes"
        multiline
        fullWidth
        variant="outlined"
        defaultValue={quote.note}
        disabled={true}
      />
      {
        quote.latestApproval?.escalatedNote &&
        < TextField
          className={styles.escalateNotes}
          label="Escalate Notes"
          placeholder="Sales Notes"
          multiline
          fullWidth
          variant="outlined"
          defaultValue={quote.latestApproval?.escalatedNote}
          disabled={true}
        />
      }
      <TextField
        className={styles.managerNotes}
        label="Manager Notes"
        placeholder="Manager Notes"
        multiline
        fullWidth
        variant="outlined"
        rows="10"
        defaultValue={note}
        onChange={e => setNote(e.target.value)}
      />
    </div>
  );

  const approveButton = (
    <ButtonWithProgress inProgress={approveInProcess} onClick={approveRequest}>
      Approve
    </ButtonWithProgress>
  );

  const rejectButton = (
    <ButtonWithProgress inProgress={rejectInProcess} onClick={rejectRequest}>
      Reject
    </ButtonWithProgress>
  );

  const escalateButton = (
    <ButtonWithProgress inProgress={escalateInProcess} onClick={escalateRequest}>
      Escalate
    </ButtonWithProgress>
  );

  const execManagerSelectField = (
    <div className={styles.escalate}>
      <SelectField
        label='Escalate request to'
        name='sales-manager'
        options={upperManagers}
        value={upperManagerId}
        required={true}
        onChange={(event) => setUpperManagerId(event.target.value as string)}
      />
    </div>
  )
  
  return (
    <PageLayout
      title=''
      parent={["Deal Approvals", "/deal-approvals"]}
    >
      <LoadingPane isLoading={!!quoteApprovalStore.isLoading}>
        <div className={styles.belowMinSellRequest}>
          <div className={styles.requestInfo}>
            <span>
              Requested by {quote.latestApproval?.createdBy} - {quote.location}
            </span>

            {
              quote.latestApproval?.status === QuoteApprovalStatus.Approved &&
              <span className={ styles.statusMessage }>This request has been approved</span>
            }
            {
              quote.latestApproval?.status === QuoteApprovalStatus.Rejected &&
              <span className={ styles.statusMessage }>This request has been rejected</span>
            }
            {
              !canBeApprovedByCurrentUser && requestWasEscalated && quote.latestApproval?.status === QuoteApprovalStatus.New &&
              <span className={ styles.statusMessage }>This request has been escalated to {quote.latestApproval?.assignedTo.name}</span>
            }
            {
              quote.latestApproval?.status === QuoteApprovalStatus.Cancelled &&
              <span className={ styles.statusMessage }>This request has been cancelled</span>
            }
            
          </div>
          <div className={styles.quoteSummary}>
            <ExpansionPanel
              expanded={quoteSummaryExpanded}
              onChange={(_, e) => {
                setQuoteSummaryExpanded(e);
              }}>
              <ExpansionPanelSummary expandIcon={expandIcon}>
                <Grid container spacing={4}>
                  <Grid item className={styles.title}>Quote Summary</Grid>
                  <Grid item>Quote Number:{quote.numberAndVersion}</Grid>
                  <Grid item>Customer Name:{quote.customer.fullName}</Grid>
                </Grid>
                <span className={styles.spacer} aria-hidden="true" />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {quoteSummarySection}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          {totalsSection}
          {notesSection}
          {!canBeApprovedByCurrentUser && !requestWasEscalated && execManagerSelectField}
          <div className={styles.actions}>
            {
              quote.latestApproval?.status === QuoteApprovalStatus.New &&
              canBeApprovedByCurrentUser && (
                <>
                  {approveButton}
                  {rejectButton}
                </>
              )
            }
            {
              quote.latestApproval?.status === QuoteApprovalStatus.New &&
              !canBeApprovedByCurrentUser &&
              !requestWasEscalated && (
                <>
                  {escalateButton}
                  {rejectButton}
                </>
              )
            }
          </div>

        </div>
      </LoadingPane>
    </PageLayout>
  )
});