import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { Alert } from '@material-ui/lab';
import { allContractPaymentType } from 'api/enums/ContractPaymentType';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurrencyTextField, SelectField, TextField } from 'views/components/forms';
import { useStore } from 'views/hooks';
import { FormSection } from 'views/components/forms/FormSection';
import styles from './SettlementSection.module.scss';
import { ApplicableMinimumDepositRules } from 'api/enums/ApplicableMinimumDepositRules';

export const SettlementSection: React.FC = observer(function SettlementSection() {
  const { editContractModal ,security} = useStore();

  const contract = editContractModal.temporaryContract;
  const settlement = contract.settlement;

  const save = async () => {
    await editContractModal.updateSettlement();
    return editContractModal.contract.totalShortfall < 0;
  };

  const isValid = () => contract.isTradeInDepositValid && settlement.isValid;

  return (
    <FormSection 
      sectionTitle={`Settlement Cleared Funds`}
      sectionSummary={`Total Contract Amount: ${contract.subTotalFormatted} / Balance Payable: ${contract.totalBalancePayableFormatted}`}
      isFormActionAllowed={editContractModal.isEditable && !security.currentUser.isAftermarketPerson}
      expandedDefault={editContractModal.isSectionToModify('settlement') || contract.shortfallIsNegative || !contract.minimumDepositAmountSatisfied}
      editableDefault={false}
      hasErrors={!isValid()}
      canSave={true}
      canCancel={true}
      onSubmit={save}
      onValidate={isValid}
      onCancel={editContractModal.cancelEditingContract}
      shouldModifyVersion={contract.isFinalised}
      onModifyVersion={() => editContractModal.showPromptToModifyVersion(true, 'settlement')}>
      {({ focusFieldRef, editable }) => (
        <>
          <div className={styles.fields}>
            <SelectField
              name="paymentType"
              className={styles.paymentType}
              label="Payment Type"
              options={allContractPaymentType.map(t => ({
                label: t.description,
                value: t.value.toString(),
              }))}
              readonly={!editable}
              value={settlement.depositPaymentType?.toString() ?? ''}
              onChange={event =>
                settlement.set(
                  'depositPaymentType',
                  allContractPaymentType.find(p => p.value.toString() === event.target.value)?.value
                )
              }
              required={true}
              validationError={settlement.validator('depositPaymentType')}
            />
            <CurrencyTextField
              id="deposit"
              className={styles.deposit}
              name="deposit"
              label="Deposit Received"
              readonly={!editable}
              value={settlement.depositReceived}
              onChange={event => settlement.set('depositReceived', parseFloat(event.target.value))}
              required={true}
              validationError={settlement.validator('depositReceived')}
            />
            <CurrencyTextField
              id="amountFinanced"
              className={styles.amountFinanced}
              name="amountFinanced"
              label="Amount Financed"
              readonly={!editable}
              value={settlement.amountFinanced ?? ''}
              onChange={event => settlement.set('amountFinanced', parseFloat(event.target.value))}
            />
            <TextField
              id="financeProvider"
              className={styles.financeProvider}
              name="financeProvider"
              label="Finance By"
              readonly={!editable}
              value={settlement.financeProvider ?? ''}
              onChange={event => settlement.set('financeProvider', event.target.value)}
              required={settlement.amountFinanced! > 0}
              validationError={settlement.validator('financeProvider')}
            />
            <CurrencyTextField
              id="otherPayment"
              className={styles.amountFinanced}
              name="otherPayment"
              label="Other Payment"
              readonly={!editable}
              value={settlement.otherPayment}
              onChange={event => settlement.set('otherPayment', parseFloat(event.target.value))}
            />
          </div>
          {!contract.isTradeInDepositValid && (
            <FormHelperText error={true}>
              Trade-in deposit cannot be greater and the trade-in allowance
            </FormHelperText>
          )}
          {
            !contract.minimumDepositAmountSatisfiedLive && contract.applicableMinimumDepositRules === ApplicableMinimumDepositRules.WarningBelow10Percent && (
              <Alert severity="warning">
                Warning: Deposit received ({contract.depositReceivedFormatted}) is less than 10% of the vehicle base price. Minimum amount required: {contract.minimumDepositRequiredFormatted}.
              </Alert>
            )
          }
          {
            !contract.minimumDepositAmountSatisfiedLive && contract.applicableMinimumDepositRules === ApplicableMinimumDepositRules.FatalBelow5kNew2kUsed && (
              <Alert severity="error">
                Deposit received ({contract.depositReceivedFormatted}) is less than the minimum amount required for this vehicle ({contract.minimumDepositRequiredFormatted})
              </Alert>
            )
          }
          {contract.shortfallIsNegative &&
            <Alert severity="warning">
              Shortfall amount is negative. Please review your settlement details.
            </Alert>}
        </>
      )}
    </FormSection>
  );
});
