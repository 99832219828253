import { Alert } from '@material-ui/lab';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionMenu, ActionMenuItem } from 'views/components/ActionMenu';
import { PageLayout } from 'views/components/layout/PageLayout';
import { LoadingPane } from 'views/components/LoadingPane';
import { useStore } from '../../hooks';
import { SearchVehicleSection } from '../quotes/components/SearchVehicleSection';
import { DetailSection } from './components/DetailSection';
import { ExtrasSection } from './components/ExtrasSection';
import { OrderActions } from './components/OrderActions';
import { OrderHistoryDialog } from './components/OrderHistoryDialog';
import { OrderStatus } from './components/OrderStatus';
import { ReadOnlyWarning } from './components/ReadOnlyWarning';
import { SummarySection } from './components/SummarySection';
import { SyncVibeVehicleDialog } from './components/SyncVibeVehicleDialog';
import { VehicleSection } from './components/VehicleSection';
import styles from './Order.module.scss';

export const Order: React.FC = observer(function Order() {
  const { editOrderModel, vehicleSelection, security } = useStore();
  const [openOrderHistory, setOpenOrderHistory] = useState(false);
  const { orderId } = useParams<{ orderId?: string }>();
  useEffect(() => {
    editOrderModel.clearOrder();
    vehicleSelection.reset();
    if (orderId) {
      (async () => {
        await editOrderModel.loadOrder(orderId, security.currentUser);
      })();
    }
    return () => {
      editOrderModel.clearOrder();
      vehicleSelection.reset();
    };
  }, [orderId, editOrderModel, vehicleSelection, security.currentUser]);

  const order = editOrderModel.temporaryOrder;

  const showSearchVehicleSection = () => !editOrderModel.isNewOrder && order.vehicle.isEmpty;

  const showVehicleSection = () =>
    !editOrderModel.isNewOrder && !editOrderModel.temporaryOrder.vehicle.isEmpty;

  const showExtrasSection = () =>
    !editOrderModel.isNewOrder && !editOrderModel.order.vehicle.isEmpty;

  const showSummarySection = (): boolean =>
    !editOrderModel.isNewOrder && editOrderModel.vehicleIsConfirmed;

  const cancelEditing = () => {
    editOrderModel.cancelEditingOrder();
    editOrderModel.setVehicleConfirmation(!editOrderModel.temporaryOrder.vehicle.isEmpty);
  };

  const subTitle = order && (
    <div className={styles.subtitle}>
      <OrderStatus className={styles.status} statusCode={order.statusCode} />
    </div>
  );

  const audit = order && (
    <span>
      Updated: <span>{order.lastModifiedAt.toLocaleString(DateTime.DATETIME_MED)}</span>
    </span>
  );

  const modificationReason = order.modificationReason && (
    <Alert severity="info">This order was modified due to: {order.modificationReason}</Alert>
  );
  const menu = (
    <ActionMenu>
      {{
        items: [
          <ActionMenuItem
            title="Order History"
            subtext="View Previous Versions"
            onClick={() => setOpenOrderHistory(true)}
          />,
        ],
      }}
    </ActionMenu>
  );

  return (
    <>
      {!editOrderModel.failedToLoadOrder && !editOrderModel.vehicleNotFound &&(
        <PageLayout
          title={editOrderModel.title}
          subtitle={subTitle}
          parent={['All Orders', '/home/orders']}
          menu={menu}
          audit={audit}>
          <LoadingPane isLoading={editOrderModel.isLoadingOrder}>
            <div className={styles.content}>
              {modificationReason}
              <ReadOnlyWarning />
              <DetailSection />
              {showSearchVehicleSection() && (
                <SearchVehicleSection
                  newBuildOnly={true}
                  allowManualEntry={false}
                  onCancelEditing={cancelEditing}
                  onBeforeSearch={editOrderModel.clearVehicle}
                  onVehicleFound={editOrderModel.setVehicle}
                />
              )}
              {showVehicleSection() && <VehicleSection />}
              {showExtrasSection() && <ExtrasSection />}
              {showSummarySection() && <SummarySection />}
              {!editOrderModel.isNewOrder && <OrderActions />}
            </div>
          </LoadingPane>
        </PageLayout>
      )}
      {editOrderModel.vehicleNotFound && (
        <PageLayout title="Order" parent={['All Orders', '/home/orders']}>
          <p>Vehicle is no longer available in VIBE</p>
        </PageLayout>
      )}
      {editOrderModel.failedToLoadOrder && (
        <PageLayout title="Order" parent={['All Orders', '/home/orders']}>
          <p>The server has experienced an error. Please contact your system administrator.</p>
        </PageLayout>
      )}
      {openOrderHistory && <OrderHistoryDialog onClose={() => setOpenOrderHistory(false)} />}
      {editOrderModel.vehicleOutOfSyncDialogModel && <SyncVibeVehicleDialog />}
    </>
  );
});
